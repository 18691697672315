import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Card,
  Grid,
  Button,
  CircularProgress
} from "@mui/material";
import CustomInput from "../../components/inputField";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../components/toastNotification";
import { useNavigate } from "react-router-dom";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import organizationService from "../../services/organizationService/organization-service";
import { RootState } from "../../utils/userDetailsCommonType";
import { loginDetails } from "../../globalState/actions/authAction";
import { useTranslation } from "react-i18next";

interface DataState {
  orgName: string;
}

const ViewOrganizationName = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.authReducer);

  const [orgData, setOrgData] = useState<DataState>({ orgName: "" });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Partial<DataState>>({});

  const { t } = useTranslation();

  useEffect(() => {
    if (userDetails.loginDetials.organization) {
      handleGetOrganizationData();
    }
  }, []);

  const handleTextData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setOrgData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };
  const formValidate = (): boolean => {
    const newErrors: Partial<DataState> = {};
    if (!orgData.orgName.trim()) {
      newErrors.orgName = t("Organization name cannot be blank");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleGetOrganizationData = async () => {
    await organizationService
      .getOrganizationAPI()
      .then((response) => {
        if (response.data) {
          setOrgData((prevData) => ({
            ...prevData,
            ["orgName"]: response.data.description,
          }));
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };

  const handleCreateOrganization = async () => {
    if (formValidate()) {
      setIsLoading(true);
      const inputData = {
        description: orgData.orgName,
      };

      try {
        const response = !userDetails.loginDetials?.organization
          ? await organizationService.createOrganizationAPI(inputData)
          : await organizationService.updateOrganizationAPI(inputData);

        if (
          response &&
          response.data.message !==
          "User is already a member of an organization"
        ) {
          dispatch(headerActiveLink(""));
          const userData = {
            ...userDetails?.loginDetials,
            is_organization_admin: true,
            organization: true,
          };

          dispatch(loginDetails(userData));
          // showToast(
          //   `Organization ${
          //     userDetails.loginDetials?.organization ? t("updated") : t("created")
          //   }  successfully`,
          //   "success"
          // );
          showToast(
            t("Organization {{action}} successfully", {
              action: userDetails.loginDetials?.organization ? t("updated") : t("created"),
            }),
            "success"
          );          
          navigate("/");
        } else {
          setIsLoading(false);
          showToast(t(response?.data?.message), "error");
        }
      } catch (error) {
        setOrgData({
          orgName: "",
        });
        setIsLoading(false);
        console.error("API Error", error);
      }
    }
  };

  const handlePageRedirection = () => {
    navigate("/");
    dispatch(headerActiveLink(""));
  };

  const checkRoles = () => {
    if (
      userDetails.loginDetials.is_organization_admin &&
      userDetails.loginDetials.organization
    ) {
      return true;
    } else if (
      !userDetails.loginDetials.is_organization_admin &&
      userDetails.loginDetials.organization
    ) {
      return false;
    } else if (
      !userDetails.loginDetials.is_organization_admin &&
      !userDetails.loginDetials.organization
    ) {
      return true;
    }
  };
  return (
    <>
      <Box className="contain__area">
        <Box
          className="breadcrumbs"
          sx={{
            margin: {
              xs: "10px 0px 0px 1px",
              sm: "10px 56px 0px 1px",
              md: "30px 56px 0px 56px",
            },
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="#147AFF" href="/">
                {t("Home")}
              </Link>
              <Typography color="#000000">
                {userDetails.loginDetials?.organization
                  ? t("Update/View Organization Name")
                  : t("Create Organization")}
              </Typography>
            </Breadcrumbs>
          </div>
        </Box>
        <Box>
          <Box
            sx={{
              margin: { md: "1rem 10rem", sm: "1rem 10rem", xs: "1rem 0rem" },
            }}
          >
            <Card
              elevation={0}
              sx={{
                bgcolor: "#fff",
                border: "1px solid #DFDFDF",
                borderRadius: "30px 30px",
                opacity: 1,
                m: { md: 4, sm: 2, xs: 0 },
              }}
            >
              <Box sx={{ marginTop: { md: "3rem", sm: "3rem", xs: "1rem" } }}>
                <Box>
                  <Typography
                    variant="h4"
                    mx={{ md: 4, sm: 3, xs: 1 }}
                    color="#0464AB;"
                    textAlign="center"
                    gutterBottom
                    sx={{
                      fontFamily: "DM Serif Display",
                      borderBottom: "1px solid #DFDFDF",
                      pb: 2,
                      px: 2,
                    }}
                    className="h4"
                  >
                    {userDetails.loginDetials?.organization
                      ? t("Update/View Organization Name")
                      : t("Create Organization")}
                  </Typography>
                </Box>
              </Box>

              <Box m={{ md: 3, sm: 3, xs: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      mx={2}
                      fontWeight="bold"
                      gutterBottom
                      sx={{
                        color: "#022E4C",
                        fontSize: "14px",
                      }}
                    >
                      {t("Organization")}
                    </Typography>
                    <Box m={2}>
                      {checkRoles() ? (
                        <CustomInput
                          name="orgName"
                          label={t("Organization Name")}
                          value={orgData.orgName}
                          onChange={handleTextData}
                          placeholder={t("Enter Organization Name")}
                          type="text"
                          required={true}
                          error={errors.orgName}
                        />
                      ) : (
                        <CustomInput
                          name="orgName"
                          label={t("Organization Name")}
                          value={orgData.orgName}
                          onChange={() => {}}
                          placeholder={t("Enter Organization Name")}
                          type="text"
                          required={true}
                          error={errors.orgName}
                          disabled={true}
                        />
                      )}
                    </Box>
                    <Box display="flex" justifyContent="flex-start" mb={3}>
                      {checkRoles() ? (
                        <>
                          <Button
                            variant="contained"
                            disableElevation
                            onClick={handleCreateOrganization}
                            sx={{
                              borderRadius: "20px",
                              width: 100,
                              mx: 2,
                              fontSize: "14px",
                              fontWeight: "600",
                              backgroundColor: "#0464AB",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#FBB522",
                                color: "#022E4C",
                                fontWeight: "600",
                              },
                            }}
                            disabled={isLoading}
                          >
                            {t("Save")}
                            {isLoading && (
                              <CircularProgress
                                size={18}
                                sx={{ padding: "0px 8px" }}
                              />
                            )}
                          </Button>
                          <Button
                            variant="outlined"
                            disableElevation
                            onClick={handlePageRedirection}
                            sx={{
                              borderRadius: "20px",
                              width: 100,
                              fontSize: "14px",
                              textTransform: "none",
                              backgroundColor: "#fff",
                              border: "1px solid #0464AB",
                              color: "#0464AB",
                              fontWeight: "600",
                              "&:hover": {
                                backgroundColor: "#FBB522",
                                color: "#022E4C",
                                border: "1px solid #FBB522",
                                fontWeight: "600",
                              },
                            }}
                          >
                            {t("Cancel")}
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={handlePageRedirection}
                          sx={{
                            borderRadius: "20px",
                            minWidth: "fit-content",
                            width: 150,
                            mx: 2,
                            textTransform: "none",
                            backgroundColor: "#fff",
                            border: "1px solid #0464AB",
                            color: "#0464AB",
                            fontWeight: "600",
                            "&:hover": {
                              backgroundColor: "#FBB522",
                              color: "#022E4C",
                              border: "1px solid #FBB522",
                              fontWeight: "600",
                            },
                          }}
                        >
                          Return To Home Page
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewOrganizationName;
