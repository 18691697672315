// utils/browserDetails.ts

interface BrowserDetails {
  browserName: string;
  browserVersion: string;
  platform: string;
  userAgent: string;
}

export const getBrowserDetails = (): BrowserDetails => {
  const { userAgent, platform } = navigator;

  let browserName = "Unknown";
  let browserVersion = "Unknown";

  if (userAgent.includes("Firefox")) {
    browserName = "Mozilla Firefox";
    browserVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("Chrome")) {
    browserName = "Google Chrome";
    browserVersion = userAgent.match(/Chrome\/(\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("Safari")) {
    browserName = "Safari";
    browserVersion = userAgent.match(/Version\/(\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("Edg")) {
    browserName = "Microsoft Edge";
    browserVersion = userAgent.match(/Edg\/(\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
    browserName = "Internet Explorer";
    browserVersion =
      userAgent.match(/(?:MSIE |rv:)(\d+\.\d+)/)?.[1] || "Unknown";
  }

  return {
    browserName,
    browserVersion,
    platform,
    userAgent,
  };
};
