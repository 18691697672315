import { ChildDetail } from "../../../types/create-worksheet";
import {
  AddExtraEducationalExpenses,
  AddExtraMedicalExpenses,
  AddExtraSpecialExpenses,
  AddProposedLowIncome,
  AddspeNonspecificDeviation,
} from "../../../types/create-worksheet/DeviationsTab";
import { DropDown, FormErrors } from "../../../types/global";

/// validation for Proposed Low Income
export const AddProposedLowIncomeValidation = (
  formData: AddProposedLowIncome
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  if (!formData.deviation_is_for.trim()) {
    formErrors.deviation_is_for = "Must choose non custodial parent";
  }

  const lowIncomeDeviationValue = parseFloat(formData.low_income_deviation);

  if (isNaN(lowIncomeDeviationValue) || lowIncomeDeviationValue >= 0) {
    formErrors.low_income_deviation =
      "Low income deviation amount must be a negative number";
  }

  return formErrors;
};

/// validation for Specific Nonspecific Deviations
export const AddSpecificNonspecificDeviationsValidation = (
  formData: AddspeNonspecificDeviation
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  if (!formData.deviation_type.trim()) {
    formErrors.deviation_type = "Must choose Deviation type";
  }
  if (!formData.deviation_is_for.trim()) {
    formErrors.deviation_is_for = "Must choose Deviation for";
  }

  return formErrors;
};

/// validation form Extraordinary Educational Expenses
export const AddExtraEducationalExpensesValidation = (
  formData: AddExtraEducationalExpenses
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  if (!formData.child_name.trim()) {
    formErrors.child_name = "Must choose child name";
  }

  if (
    !formData.tuition_room_board_books_yearly.trim() &&
    !formData.other_extraordinary_expenses_yearly.trim()
  ) {
    formErrors.tuition_room_board_books_yearly =
      "At least one field must be filled: either Tuition or Other amount Paid";
  }

  if (!formData.paid_by.trim()) {
    formErrors.paid_by = "Must choose paid by";
  }

  return formErrors;
};

/// validation form Extraordinary Medical Expenses
export const AddExtraordinaryMedicalExpensesValidation = (
  formData: AddExtraMedicalExpenses
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  if (!formData.child_name.trim()) {
    formErrors.child_name = "Must choose child name";
  }

  if (!formData.paid_by.trim()) {
    formErrors.paid_by = "Must choose paid by";
  }

  if (!formData.extraordinary_medical_expenses_yearly.trim()) {
    formErrors.extraordinary_medical_expenses_yearly =
      "Please enter medical expenses";
  }

  return formErrors;
};

/// validation form Extraordinary Special Expenses
export const AddExtraordinarySpecialExpensesValidation = (
  formData: AddExtraSpecialExpenses
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  if (!formData.child_name.trim()) {
    formErrors.child_name = "Must choose child name";
  }

  if (!formData.amount_paid_yearly.trim()) {
    formErrors.amount_paid_yearly = "Please enter an amount paid";
  }

  if (!formData.paid_by.trim()) {
    formErrors.paid_by = "Must choose paid by";
  }

  if (!formData.explanation_of_expense.trim()) {
    formErrors.explanation_of_expense = "Please enter an explanation";
  }

  return formErrors;
};

///create Parents option drop-down data
export function createParentsOptionsValue(
  firstName: string,
  lastName: string
): DropDown[] {
  return [
    { value: firstName, label: firstName },
    { value: lastName, label: lastName },
    {
      value: "Nonparent Custodian",
      label: "Nonparent Custodian",
    },
  ];
}

/// create a parents drop-down data
export function createOptionsValue(parentsName: string): DropDown[] {
  const finalData = parentsName?.split(" and ")?.map((item) => ({
    value: item.trim(),
    label: item.trim(),
  }));
  return finalData;
}
///get Extraordinary Educational Expenses Total sum
export const getTotalExtraordinaryEducationalSums = (
  array: AddExtraEducationalExpenses[],
  ChildData: ChildDetail[] | undefined
) => {
  return array.reduce((acc, item) => {
    const child = ChildData?.find((c) => c.child_name === item.child_name);
    if (!child || child.child_status !== "excluded") {
      const total =
        (parseFloat(item.tuition_room_board_books_yearly) || 0) +
        (parseFloat(item.other_extraordinary_expenses_yearly) || 0);

      if (acc[item.paid_by]) {
        acc[item.paid_by] += total;
      } else {
        acc[item.paid_by] = total;
      }
    }
    return acc;
  }, {} as Record<string, number>);
};

///get Extraordinary Medical Expenses Total sum
export const getTotalExtraordinaryMedicalSums = (
  array: AddExtraMedicalExpenses[],
  ChildData: ChildDetail[] | undefined
) => {
  return array.reduce((acc, item) => {
    const child = ChildData?.find((c) => c.child_name === item.child_name);
    if (!child || child.child_status !== "excluded") {
      const total = parseFloat(item.extraordinary_medical_expenses_yearly) || 0;

      if (acc[item.paid_by]) {
        acc[item.paid_by] += total;
      } else {
        acc[item.paid_by] = total;
      }
    }
    return acc;
  }, {} as Record<string, number>);
};

///get Extraordinary Special Expenses Total sum
export const getTotalExtraordinarySpecialSums = (
  array: AddExtraSpecialExpenses[],
  ChildData: ChildDetail[] | undefined
) => {
  return array.reduce((acc, item) => {
    const child = ChildData?.find((c) => c.child_name === item.child_name);
    if (!child || child.child_status !== "excluded") {
      const total = parseFloat(item.amount_paid_yearly) || 0;

      if (acc[item.paid_by]) {
        acc[item.paid_by] += total;
      } else {
        acc[item.paid_by] = total;
      }
    }
    return acc;
  }, {} as Record<string, number>);
};

///get Proposed Deviation Total sum
export const getTotalProposedDeviation = (
  array: AddspeNonspecificDeviation[]
) => {
  return array.reduce((acc, item) => {
    const amount = parseFloat(item.proposed_deviation_amount) || 0;

    if (acc[item.deviation_is_for]) {
      acc[item.deviation_is_for] += amount;
    } else {
      acc[item.deviation_is_for] = amount;
    }

    return acc;
  }, {} as Record<string, number>);
};

///get Judicial Deviation Total sum
export const getTotalJudicialDeviation = (
  array: AddspeNonspecificDeviation[]
) => {
  return array.reduce((acc, item) => {
    const amount =
      parseFloat(item.judicial_discretion_applied_deviation_amount) || 0;

    if (acc[item.deviation_is_for]) {
      acc[item.deviation_is_for] += amount;
    } else {
      acc[item.deviation_is_for] = amount;
    }

    return acc;
  }, {} as Record<string, number>);
};
