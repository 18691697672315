import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "../inputField";
import TextareaField from "../text-area";
import styles from "./reportTechnical.module.css";
import { useTranslation } from "react-i18next";

import { CreateWorksheetInitialState } from "../../types/create-worksheet";
import { useAppSelector } from "../../globalState/hooks";
import { RootState } from "../../utils/userDetailsCommonType";
import { getBrowserDetails } from "../../utils/browserDetails";
import { GenerateCaptcha } from "../../utils/generateCaptcha";

export interface ReportTechnicalFormHandle {
  submitForm: () => void;
}

interface ReportInputValues {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
  captcha: string;
}

interface ReportTechnicalFormProps {
  inputCaptcha: string;
  handleSubmitTechnicalReport: (inputData: ReportInputValues) => void;
  states: CreateWorksheetInitialState;
}

const ReportTechnicalForm = forwardRef<
  ReportTechnicalFormHandle,
  ReportTechnicalFormProps
>(({ inputCaptcha, handleSubmitTechnicalReport, states }, ref) => {
  const userDetails = useAppSelector((state: RootState) => state.authReducer);
  const [browserDetails, setBrowserDetails] = useState(getBrowserDetails());
  const [captcha, setCaptcha] = useState(inputCaptcha);

  const [errors, setErrors] = useState<Partial<ReportInputValues>>({});
  const { t } = useTranslation();
  const [reportInputValues, setReportInputValues] = useState<ReportInputValues>(
    {
      worksheet_id: states.worksheet_id ? states.worksheet_id.toString() : "",
      email: userDetails.loginDetials.user_email?.toString() || "",
      browser: browserDetails.userAgent,
      name: states.name_person_preparing_worksheet,
      phone: "",
      technical_issue: "",
      captcha: "",
    }
  );

  useEffect(() => {
    setBrowserDetails(getBrowserDetails());
  }, []);

  const handleReportInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setReportInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useImperativeHandle(ref, () => ({
    submitForm: onSubmitData,
  }));

  const formValidate = (): boolean => {
    const newErrors: Partial<ReportInputValues> = {};

    if (!reportInputValues.worksheet_id.trim()) {
      newErrors.worksheet_id = t("Worksheet ID cannot be blank");
    }
    if (!reportInputValues.email.trim()) {
      newErrors.email = t("Email Address cannot be blank");
    } else if (!/\S+@\S+\.\S+/.test(reportInputValues.email)) {
      newErrors.email = t("Email Address is invalid");
    }
    if (!reportInputValues.technical_issue.trim()) {
      newErrors.technical_issue = t("Technical issue cannot be blank");
    }

    if (reportInputValues.phone.length > 10) {
      newErrors.phone = t("Phone Number must be 10 digits");
    }

    if (!reportInputValues.captcha.trim()) {
      newErrors.captcha = t("Captcha cannot be blank");
    } else if (reportInputValues.captcha.trim() !== captcha) {
      newErrors.captcha = t("Captcha is not correct.");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitData = async () => {
    if (formValidate()) {
      handleSubmitTechnicalReport(reportInputValues);
    }
  };

  const handleCaptchaClick = () => {
    const newCaptcha = GenerateCaptcha();
    setCaptcha(newCaptcha);
  };

  const renderCaptcha = (Captcha: string) => {
    return Captcha.split("").map((char, index) => (
      <span
        key={index}
        style={{ fontSize: 20, cursor: "pointer" } as React.CSSProperties}
        onClick={handleCaptchaClick}
        title={t("Click to regenerate captcha")}
      >
        {char}
      </span>
    ));
  };

  return (
    <>
      <Typography
        gutterBottom
        id="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          borderBottom: "1px solid #DFDFDF",
          pb: 1,
        }}
      >
        {t("Enter the Technical Issue that you encountered in the")}
        &quot;{t("Technical Issue")}&quot;
        {t("field and then press the")}
        &quot;{t("Send")}&quot;
        {t("Button to send an email to Technical Support")}
      </Typography>
      <Box sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="worksheet_id"
              label={t("Worksheet ID")}
              value={reportInputValues.worksheet_id}
              onChange={handleReportInputChange}
              placeholder={t("Enter Worksheet ID")}
              error={errors.worksheet_id}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="email"
              label={t("Email")}
              value={reportInputValues.email}
              onChange={handleReportInputChange}
              placeholder={t("example@gmail.com")}
              error={errors.email}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CustomInput
              name="browser"
              label={t("Browser")}
              value={reportInputValues.browser}
              onChange={handleReportInputChange}
              placeholder={t("Enter Browser Details")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="name"
              label={t("Name")}
              value={reportInputValues.name}
              onChange={handleReportInputChange}
              placeholder={t("Enter Name")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="phone"
              label={t("Phone Number")}
              type="number"
              value={reportInputValues.phone}
              onChange={handleReportInputChange}
              placeholder={t("Enter Phone Number")}
              inputProps={{ maxLength: 10 }}
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextareaField
              name="technical_issue"
              label={t("Technical Issue")}
              value={reportInputValues.technical_issue}
              onChange={handleReportInputChange}
              placeholder={t("Write Issue")}
              error={errors.technical_issue}
              required={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            display="flex"
            paddingTop={"0px!important"}
          >
            <CustomInput
              name="captcha"
              label={t("Captcha")}
              value={reportInputValues.captcha}
              onChange={handleReportInputChange}
              placeholder={t("Enter Captcha")}
              error={errors.captcha}
              required={true}
            />
            <Box
              pt={3.5}
              ml={{ xs: 2.5, sm: 3, md: 3 }}
              className={styles.captchaText}
            >
              {renderCaptcha(captcha)}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
ReportTechnicalForm.displayName = "ReportTechnicalForm";

export default ReportTechnicalForm;
