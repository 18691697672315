import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Fade,
  Modal,
  Backdrop,
  IconButton
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onCancel?: () => void;
  title: string;
  descriptionMessage: string;
}

const ValidationErrorModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onCancel,
  title,
  descriptionMessage
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 600px)": {
          p: 2,
          width: "90%"
        }
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            marginTop: "3%",
            marginBottom: "3%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: { md: "30%", sm: "30%", xs: "80%" },
            maxHeight: "85vh",
            bgcolor: "background.paper",
            border: "none",
            borderRadius: "25px",
            boxShadow: 24,
            p: 4
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: 20,
              top: 10,
              color: "red"
            }}
          >
            <CancelOutlinedIcon />
          </IconButton>
          {title !== "" && (
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{
                pb: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#0464AB",
                borderBottom: "1px solid #DFDFDF"
              }}
              className="h4"
            >
              {title}
            </Typography>
          )}
          <Box
            sx={{
              overflowY: "auto",
              flexGrow: 1,
              py: 2
            }}
          >
            <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
              {descriptionMessage}
            </Typography>
          </Box>
          <Box sx={{ pt: 1, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: "21px",
                    backgroundColor: "#0464AB",
                    minWidth: "120px",
                    width: 0,
                    height: 30,
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#FBB522",
                      color: "#022E4C",
                      fontWeight: "600"
                    }
                  }}
                  onClick={onClose}
                >
                  {t("Ok")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ValidationErrorModal;
