import { httpCommon } from "./interceptor";

class ForgetPasswordService {
  resetPasswordAPI(inputdata: { email: string }) {
    return httpCommon.post("users/password-reset-email/", inputdata);
  }
  createPasswordAPI(
    email: string,
    payload: {
      new_password: string;
      confirm_password: string;
    }
  ) {
    return httpCommon.post(`users/password-reset/?email=${email}`, payload);
  }
  postSignUpOTP(inputdata: { email: string }) {
    return httpCommon.post("users/send_otp_register/", inputdata);
  }
}

const forgetPasswordService = new ForgetPasswordService();
export default forgetPasswordService;
