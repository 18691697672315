import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tab,
  Breadcrumbs,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import reportMonthlyNum from "../../services/reportMonthlyNum/reportMonthly-service";
import PageLoader from "../../components/loader";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import { useTranslation } from "react-i18next";

interface UserData {
  totalUsers: number;
  years: {
    year: number;
    months: string[];
    user: number[];
    yearlyTotal: number | null;
  }[];
}
interface worksheetData {
  totalWorksheet: number;
  years: {
    year: number;
    months: string[];
    user: number[];
    yearlyCreatedTotal: number | null;
    yearlyUpdatedTotal: number | null;
    updated: number[];
    created: number[];
  }[];
}

const ReportMonthlyNum: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [worksheetData, setWorksheetData] = useState<worksheetData | null>(
    null
  );
  const [tabValue, setTabValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    handleWorksheetData();
  }, []);

  useEffect(() => {
    if (tabValue === "2" && !userData) {
      handleUserData();
    }
  }, [tabValue]);

  const handleWorksheetData = async () => {
    try {
      setLoading(true);
      const response = await reportMonthlyNum.getWorksheetReportMonthlyNumAPI();

      const data = response.data.data;
      const yearsData = Object.keys(data).filter(
        (key) =>
          key !== "Total_Worksheets" &&
          key !== "message" &&
          key !== "created" &&
          key !== "updated"
      );

      const transformedWorksheetData: worksheetData = {
        totalWorksheet: data.Total_Worksheets,
        years: yearsData.map((year) => {
          const monthsData = Object.keys(data[year]).filter(
            (month) => month !== "Created_Total" && month !== "Updated_Total"
          );
          return {
            year: parseInt(year),
            months: monthsData.map((month) => month),
            user: monthsData.map((month) => data[year][month].created),
            yearlyCreatedTotal: data[year].Created_Total,
            yearlyUpdatedTotal: data[year].Updated_Total,
            updated: monthsData.map((month) => data[year][month].updated),
            created: monthsData.map((month) => data[year][month].created),
          };
        }),
      };
      setWorksheetData(transformedWorksheetData);
    } catch (error) {
      console.log(error, "API Error");
    } finally {
      const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
      await delay(500);
      setLoading(false);
    }
  };

  const handleUserData = async () => {
    try {
      setLoading(true);
      const response = await reportMonthlyNum.getUserReportMonthlyNumAPI();
      const data = response.data.data;

      const yearsData = Object.keys(data).filter(
        (key) => key !== "Total_Users" && key !== "message"
      );

      const transformedUserData: UserData = {
        totalUsers: data.Total_Users,
        years: yearsData.map((year) => {
          const monthsData = Object.keys(data[year]).filter(
            (month) => month !== `${year}_Yearly_Total`
          );
          return {
            year: parseInt(year),
            months: monthsData.map((month) => month.replace("_", " ")),
            user: monthsData.map((month) => data[year][month]),
            yearlyTotal: data[year][`${year}_Yearly_Total`],
          };
        }),
      };
      setUserData(transformedUserData);
    } catch (error) {
      console.log(error, "API Error");
    } finally {
      const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
      await delay(500);
      setLoading(false);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const commonCellStyles = {
    border: "1px solid #DFDFDF",
    padding: "10px 10px",
    textAlign: "left",
    minWidth: { md: "30px", sm: "50px", xs: "50px" },
    maxWidth: "45px",
  };

  const headerCellStyles = {
    ...commonCellStyles,
    background: "#E6F5FF",
    color: "#022E4C",
    fontWeight: "600 ",
    fontSize: "14px",
    minWidth: { md: "55px" }
  };
  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };
  return (
    <>
      {loading && <PageLoader />}
      <Box
        className="contain__area"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          mt={3}
          sx={{
            display: "flex",
            textAlign: "left",
            width: { md: "90% ", sm: "90%", xs: "100%" },
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handleHomeRedirection}
              >
                {t("Home")}
              </Link>
              <Link
                to="/system-administration/reportMonthlyNumber"
                className="LinkColorDark"
              >
                {" "}
                {t("Reports-Monthly Numbers")}
              </Link>
            </Breadcrumbs>
          </div>
        </Box>

        <TabContext value={tabValue}>
          <Box
            sx={{
              borderColor: "#E0E0E0",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              width: { md: "90% ", sm: "90%", xs: "100%" },
              overflowX: "auto",
              fontSize: 12,
              mt: 3,
            }}
          >
            <TabList
              onChange={handleTabChange}
              aria-label="Worksheet"
              variant="fullWidth"
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                  justifyContent: "center",
                },
                width: { md: "45%", sm: "50%", xs: "100%" },
              }}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              {[
                { label: t("Georgia Child Support Worksheets"), value: "1" },
                { label: t("Georgia Child Support Users"), value: "2" },
              ].map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "14px",
                      md: "14px",
                    },
                    color: "#666666",
                    backgroundColor: "#fff",
                    textTransform: "capitalize",
                    height: {
                      xs: "40px",
                      sm: "40px",
                      md: "55px",
                    },
                    width: "fit-content",
                    padding: "9px",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                    marginRight: { md: 1, sm: 1, xs: 0 },

                    "&.Mui-selected": {
                      color: "#FFFFFF",
                      backgroundColor: "#0464AB",
                    },
                    position: "relative",
                  }}
                />
              ))}
            </TabList>
          </Box>
          <Box
            sx={{
              backgroundColor: "#fff",
              width: { md: "90% ", sm: "90%", xs: "100%" },
            }}
          >
            <TabPanel value="1" sx={{ padding: 1.5 }}>
              {worksheetData &&
                worksheetData.years &&
                worksheetData.years.length > 0 && (
                  <>
                    <Typography
                      sx={{
                        backgroundColor: "#E8FFE5",
                        color: "#309725",
                        fontWeight: "bold",
                        padding: 1,
                        textAlign: "center",
                        border: "1px solid #E8FFE5",
                      }}
                    >
                      {t("Total Worksheet:")} {worksheetData.totalWorksheet}
                    </Typography>
                    <Box
                      sx={{
                        overflowY: "scroll",
                        maxHeight: { xl: "900px", md: "400px" },
                      }}
                    >
                      {worksheetData.years.map((yearData, yearIndex) => (
                        <Box
                          key={yearIndex}
                          sx={{ marginBottom: { md: 3, sm: 0, xs: 0 } }}
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              p: 1,
                              bgcolor: "#022E4C",
                              color: "#FFFFFF",
                            }}
                          >
                            {yearData.year}
                          </Typography>
                          <TableContainer>
                            <Table
                              sx={{
                                border: "1px solid black",
                                fontSize: "14px",
                              }}
                            >
                              <caption style={{ padding: 0 }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    padding: 1,
                                    color: "#022E4C",
                                    bgcolor: "#F8F8F8",
                                    border: "1px solid #DFDFDF",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {t("Yearly Created Total:")}{" "}
                                  {yearData.yearlyCreatedTotal !== null &&
                                    yearData.yearlyCreatedTotal >= 1
                                    ? yearData.yearlyCreatedTotal
                                    : "-"}{" "}
                                  | {t("Yearly Updated Total:")}{" "}
                                  {yearData.yearlyUpdatedTotal !== null &&
                                    yearData.yearlyUpdatedTotal >= 1
                                    ? yearData.yearlyUpdatedTotal
                                    : "-"}
                                </Typography>
                              </caption>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={headerCellStyles}>
                                    {t("Month")}
                                  </TableCell>
                                  {yearData.months.map((month, monthIndex) => (
                                    <TableCell
                                      key={monthIndex}
                                      sx={commonCellStyles}
                                    >
                                      {t(month)}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell sx={headerCellStyles}>
                                    {t("Created")}
                                  </TableCell>
                                  {yearData.created.map(
                                    (created, createdIndex) => (
                                      <TableCell
                                        key={createdIndex}
                                        sx={commonCellStyles}
                                      >
                                        {created !== null && created >= 1
                                          ? created
                                          : "-"}
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={headerCellStyles}>
                                    {t("Updated")}
                                  </TableCell>
                                  {yearData.updated.map(
                                    (updated, updatedIndex) => (
                                      <TableCell
                                        key={updatedIndex}
                                        sx={commonCellStyles}
                                      >
                                        {updated !== null && updated >= 1
                                          ? updated
                                          : "-"}
                                      </TableCell>
                                    )
                                  )}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
            </TabPanel>

            <TabPanel value="2" sx={{ padding: 1.5 }}>
              {userData ? (
                <>
                  <Typography
                    sx={{
                      backgroundColor: "#E8FFE5",
                      color: "#309725",
                      fontWeight: "bold",
                      padding: 1,
                      textAlign: "center",
                      border: "1px solid #E8FFE5",
                    }}
                  >
                    {t("Total Users:")} {userData.totalUsers}
                  </Typography>
                  <Box sx={{ overflowY: "scroll", maxHeight: "450px" }}>
                    {userData.years && userData.years.length > 0 ? (
                      userData.years.map((yearData, yearIndex) => (
                        <Box
                          key={yearIndex}
                          sx={{ marginBottom: { md: 3, sm: 0, xs: 0 } }}
                        >
                          <Typography
                            sx={{
                              textAlign: "center",
                              p: 1,
                              bgcolor: "#022E4C",
                              color: "#FFFFFF",
                            }}
                          >
                            {yearData.year}
                          </Typography>
                          <TableContainer>
                            <Table sx={{ border: "1px solid black" }}>
                              <caption style={{ padding: 0 }}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    padding: 1,
                                    color: "#022E4C",
                                    bgcolor: "#F8F8F8",
                                    border: "1px solid #DFDFDF",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {t("Yearly Total:")}{" "}
                                  {yearData.yearlyTotal !== null &&
                                    yearData.yearlyTotal >= 1
                                    ? yearData.yearlyTotal
                                    : "-"}
                                </Typography>
                              </caption>
                              <TableHead>
                                <TableRow>
                                  <TableCell sx={headerCellStyles}>
                                    {t("Month")}
                                  </TableCell>
                                  {yearData.months &&
                                    yearData.months.map((month, monthIndex) => (
                                      <TableCell
                                        key={monthIndex}
                                        sx={commonCellStyles}
                                      >
                                        {t(month)}
                                      </TableCell>
                                    ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell sx={headerCellStyles}>
                                    {t("User")}
                                  </TableCell>
                                  {yearData.user &&
                                    yearData.user.map((user, userIndex) => (
                                      <TableCell
                                        key={userIndex}
                                        sx={commonCellStyles}
                                      >
                                        {user !== null && user >= 1
                                          ? user
                                          : "-"}
                                      </TableCell>
                                    ))}
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ))
                    ) : (
                      <Typography sx={{ textAlign: "center", marginTop: 1 }}>
                        {t("No Data available.")}
                      </Typography>
                    )}
                  </Box>
                </>
              ) : (
                <Typography sx={{ textAlign: "center", marginTop: 1 }}>
                  {t("No Data Available")}
                </Typography>
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </>
  );
};

export default ReportMonthlyNum;
