import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import CustomTooltip from "../tooltip";
import interrogation from "../../assets/images/interrogation.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FAQcss from "./exportFileDropdown.module.css";

interface DropdownProps {
  name: string;
  label: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: { value: string; label: string; icon: React.ReactElement }[];
  error?: string;
  required?: boolean;
  tooltip?: string;
  placeholder: React.ReactNode;
  backgroundColor?: string;
  disabled?: boolean;
}

const ExportFileDropdown: React.FC<DropdownProps> = ({
  name,
  label,
  value,
  onChange,
  options,
  error,
  required,
  tooltip,
  placeholder,
  backgroundColor,
  disabled,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "150px",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: "medium",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}
        {tooltip && (
          <CustomTooltip title={tooltip}>
            <img src={interrogation} alt="help icon" />
          </CustomTooltip>
        )}
      </Typography>
      <FormControl
        sx={{
          width: { xs: "fit-content" },
          marginLeft: { xs: "-10px", sm: '0px', md: "0px" },
          minWidth: { xs: "130px", sm: "150px", md: "150px" },
        }}
      >
        <Select
          value={value}
          onChange={onChange}
          name={name}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={ExpandMoreIcon}
          disabled={disabled}
          sx={{
            borderRadius: "28px",
            height: "35px",
            color: "#404040",
            borderColor: "#E2E0E0",
            fontSize: "14px",
            padding: "0px",
            margin: "0px",
            backgroundColor: backgroundColor || "#fff",
            "& .MuiInputLabel-root": {
              top: "-5px",
            },
          }}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ filter: "brightness(25%)" }}>
                {options.find((option) => option.value === selected)?.icon}
              </Box>
              <Typography
                sx={{ fontSize: "14px", marginLeft: "6px", color: "#404040" }}
              >
                {selected ? selected : placeholder}
              </Typography>
            </Box>
          )}
        >
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                backgroundColor:
                  value === option.value ? "#f0f0f0" : "transparent",
                color: value === option.value ? "#0464AB" : "#9C9BAA",
              }}
            >
              {option.icon && (
                <span
                  style={{ marginRight: "6px" }}
                  className={
                    value === option.value
                      ? FAQcss.activeSVG
                      : FAQcss.inactiveSVG
                  }
                >
                  {option.icon}
                </span>
              )}
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <Typography
            variant="subtitle1"
            sx={{ mt: 1, fontSize: "0.75rem", color: "error.main" }}
          >
            {error}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};

export default ExportFileDropdown;
