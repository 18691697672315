import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
  Grid,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  Select,
  TableCell,
  Collapse,
} from "@mui/material";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Link, useNavigate } from "react-router-dom";
import styles from "./searchFolder.module.css";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
import ExportFileDropdown from "../../components/exportFileDropDown";
import CustomModal from "../../components/customModal";
import CustomInput from "../../components/inputField";
import CustomDropdown from "../../components/drop-down";
import ExportOptionData from "../../components/exportDataSelection";
import { useAppDispatch } from "../../globalState/hooks";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import {
  ApiResponse,
  CountryData,
  CourtData,
  DropDown,
  FormErrors,
} from "../../types/global";
import searchFolderWorksheet from "../../services/searchFolderWorksheet";
import { getFileName } from "../../utils/fileNameUtil";
import { AddShareWorksheetValidation } from "./searchFolderWorksheetValidation";
import { showToast } from "../../components/toastNotification";
import {
  createWorksheetDataEmpty,
  setWorksheetAPIData,
} from "../../globalState/actions/create-worksheet-action";
import { SetWorksheetDataToRedux } from "../../utils/setWorksheetDataToRedux";
import { useTranslation } from "react-i18next";

interface searchFolderWorkshitData {
  worksheet_id: number;
  plaintiff_name: string;
  defendant_name: string;
  county_name: string;
  court_name: string;
  county_id__county_name: string;
  court_id__court_name: string;
  civil_action_case_number: string;
  dhsc_no: string;
  prepared_by: string;
  prepared_by__username: string;
}

interface subWorkshitData {
  map(
    arg0: (
      item: {
        display_worksheet: boolean;
        is_archived: boolean;
        share_type: string;
        description: string;
        worksheet_id: number;
        created_at:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
        version:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
        make_available_to_court: boolean;
        is_expired: boolean;
      },
      i: React.Key | null | undefined
    ) => JSX.Element
  ): React.ReactNode;
  worksheet_id: number;
  description: string;
  created_at: string;
  version: number;
  is_expired: boolean;
  display_worksheet: boolean;
  is_archived: boolean;
  make_available_to_court: boolean;
  share_type: string | null;
}
interface TableSearchData {
  plaintiff_name: string;
  defendant_name: string;
  county_id__county_name: string;
  court_id__court_name: string;
  civil_action_case_number: string;
  dhsc_no: string;
  prepared_by__username: string;
}

const SearchFolderWorksheet: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [recallFromCourtModal, setRecallFromCourtModal] = useState(false);
  const [shareWorksheetModal, setShareWorksheetModal] = useState(false);
  const [archiveWorksheetModal, setArchiveWorksheetModal] = useState(false);
  const [subTableWorksheetId, setSubTableWorksheetId] = useState<number>(0);
  const [worksheetArchiveId, setWorksheetArchiveId] = useState<number>(0);
  const [allRowsExpanded, setAllRowsExpanded] = useState<boolean>(false);
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [expandedWorksheetIds, setExpandedWorksheetIds] = useState<string>("");
  const [searchFolderWorksheetData, setSearchFolderWorksheetData] = useState<
    searchFolderWorkshitData[]
  >([]);
  const [subWorksheetTblData, setSubWorksheetTblData] = useState<
    subWorkshitData[]
  >([]);
  const [courtData, setCourtData] = useState<DropDown[]>([]);
  const [countyData, setCountyData] = useState<DropDown[]>([]);
  const [totalTableRecords, setTotalTableRecords] = useState(0);
  const [shareWorksheetObj, setShareWorksheetObj] = useState({
    shareEmail: "",
    shareType: "",
    shareTerm: "",
  });
  const [shareWorksheetErrors, setShareWorksheetErrors] = useState<FormErrors>(
    {}
  );
  const [tableSearchData, setTableSearchData] = useState<TableSearchData>({
    plaintiff_name: "",
    defendant_name: "",
    county_id__county_name: "",
    court_id__court_name: "",
    civil_action_case_number: "",
    dhsc_no: "",
    prepared_by__username: "",
  });

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({
    key: "",
    direction: "asc",
  });



  useEffect(() => {
    const searchTime = setTimeout(() => {
      handleGetSearchFolderWorksheetListData();
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [pageIndex, perPage, tableSearchData, sortConfig]);

  useEffect(() => {
    handleGetCourtListData();
    handleGetCountyListData();
  }, []);

  ///-------------API calling Function start-------------------
  /// fetch Search Folder Worksheet Listinst data
  const handleGetSearchFolderWorksheetListData = () => {
    const tableOrdering =
      sortConfig.direction === "asc" ? sortConfig.key : `-${sortConfig.key}`;

    const inputData = {
      pageIndex,
      perPage,
      tableSearchData,
      tableOrdering,
    };
    searchFolderWorksheet
      .getSearchFolderWorksheetTblListAPI(inputData)
      .then(
        (
          response: ApiResponse<{
            count: number;
            results: searchFolderWorkshitData[];
          }>
        ) => {
          if (response?.data?.results?.length > 0) {
            const tempArrayList = response?.data.results;
            const updatedArray = tempArrayList.map((item) => ({
              ...item,
              county_id__county_name: item.county_name,
              court_id__court_name: item.court_name,
              prepared_by__username: item.prepared_by,
            }));

            setSearchFolderWorksheetData(updatedArray);
            setTotalTableRecords(response?.data.count);
          } else {
            setSearchFolderWorksheetData([]);
            setTotalTableRecords(0);
          }
        }
      )
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch Court List data
  const handleGetCourtListData = () => {
    searchFolderWorksheet
      .getCourtListAPI()
      .then((response: { data: ApiResponse<CourtData[]> }) => {
        if (response?.data?.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (court_name: CourtData) => ({
              value: court_name.court_name,
              label: court_name.court_name,
            })
          );
          setCourtData(finalData);
        } else {
          setCourtData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch County List data
  const handleGetCountyListData = () => {
    searchFolderWorksheet
      .getCountyListAPI()
      .then((response: { data: ApiResponse<CountryData[]> }) => {
        if (response?.data.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (item: CountryData) => ({
              value: item.county_name,
              label: item.county_name,
            })
          );
          setCountyData(finalData);
        } else {
          setCountyData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch Search Folder Worksheet Listinst data
  const handleGetSubTableWorksheetList = (id: number | string) => {
    searchFolderWorksheet
      .getSearchFolderWorksheetSubTblListAPI(id)
      .then((response: ApiResponse<subWorkshitData[]>) => {
        if (response?.data) {
          setSubWorksheetTblData(response?.data);
        } else {
          setSubWorksheetTblData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  //// handle Download Worksheet Files data
  const handleDownloadWorksheetData = (key: string) => {
    const arrayIDs = searchFolderWorksheetData.map((item) => item.worksheet_id);
    searchFolderWorksheet
      .getDownloadWorkSheetAPI(key, arrayIDs)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          getFileName(key, "SearchFoldersWorksheets")
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("API Error", error);
      });
  };

  //// handle Share worksheet data
  const handleShareWorksheetData = () => {
    const formErrors = AddShareWorksheetValidation(shareWorksheetObj);
    setShareWorksheetErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });
    const isFormValid = Object.keys(formErrors).length === 0;

    if (isFormValid) {
      const inputData = {
        share_email: shareWorksheetObj.shareEmail.trim(),
        share_type: shareWorksheetObj.shareType,
        share_term: shareWorksheetObj.shareTerm,
      };
      searchFolderWorksheet
        .getShareWorksheetAPI(inputData, subTableWorksheetId)
        .then((response) => {
          if (response.data?.data?.length > 0) {
            showToast(t(response?.data?.message), "success");
            setShareWorksheetModal(false);
            setShareWorksheetObj({
              shareEmail: "",
              shareType: "",
              shareTerm: "",
            });
          } else {
            showToast(t(response?.data?.message), "error");
          }
        })
        .catch((error) => {
          console.error(error, "Login Error");
        });
    }
  };
  /// API called for Copy Worksheet data
  const handleCopyWorksheetData = (id: number) => {
    searchFolderWorksheet
      .postCopyWorksheetAPI(id)
      .then((response) => {
        if (response.data?.message === "Worksheet created successfully") {
          showToast("Worksheet copied successfully", "success");
          handleGetSubTableWorksheetList(expandedWorksheetIds);
        } else {
          showToast(t(response?.data?.message), "error");
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API called for Recall From Court data
  const handleRecallFromCourtData = () => {
    searchFolderWorksheet
      .postRecallFromCourtAPI(subTableWorksheetId)
      .then((response) => {
        if (response.data?.worksheet_id) {
          setRecallFromCourtModal(false);
          handleGetSubTableWorksheetList(subTableWorksheetId);
        } else {
          showToast(t(response?.data?.message), "error");
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API called for Worksheet Archive
  const handleWorksheetArchive = () => {
    searchFolderWorksheet
      .worksheetArchiveAPI(worksheetArchiveId)
      .then((response) => {
        if (response.data?.message === "Worksheet archived successfully") {
          showToast(t(response?.data?.message), "success");
          setArchiveWorksheetModal(false);
          handleGetSearchFolderWorksheetListData();
          handleGetSubTableWorksheetList(expandedWorksheetIds);
        } else {
          showToast(t(response?.data?.message), "error");
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// handle get Worksheet data by Id
  const handleGetWorksheetDataById = (worksheetId: number) => {
    searchFolderWorksheet
      .getWorksheetInfoDataAPI(Number(worksheetId))
      .then((response) => {
        if (response?.data.worksheet_id) {
          const finalWorksheetData = SetWorksheetDataToRedux(response.data);
          dispatch(setWorksheetAPIData(finalWorksheetData));
          setTimeout(() => {
            navigate(`/WorkSheets/updateWorksheet/${worksheetId}`);
            dispatch(headerActiveLink("/WorkSheets/updateWorksheet"));
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  ///-------------API calling Function end-------------------
  const handleRecallFromCourtMdlOpen = (workshitId: number) => {
    setRecallFromCourtModal(true);
    setSubTableWorksheetId(workshitId);
  };
  const handleArchiveWorksheetModal = (workshitId: number) => {
    setArchiveWorksheetModal(true);
    setWorksheetArchiveId(workshitId);
  };
  const handleShareWorksheet = (worksheetId: number) => {
    setShareWorksheetModal(true);
    setSubTableWorksheetId(worksheetId);
    setShareWorksheetObj({
      shareEmail: "",
      shareType: "",
      shareTerm: "",
    });
    setShareWorksheetErrors({});
  };
  // To handle Expand and Collapse All rows
  const handleExpandAllRows = () => {
    if (allRowsExpanded) {
      setExpandedRows([]);
    } else {
      const allWorksheetIds = searchFolderWorksheetData.map(
        (item) => item.worksheet_id
      );
      setExpandedRows(allWorksheetIds);
      handleGetSubTableWorksheetList(allWorksheetIds.join(","));
    }
    setAllRowsExpanded(!allRowsExpanded);
  };

  // Function to toggle individual rows
  const handleTableRowOpen = (workshitId: number) => {
    let updatedExpandedRows = [];
    if (expandedRows.includes(workshitId)) {
      updatedExpandedRows = expandedRows.filter((id) => id !== workshitId);
    } else {
      updatedExpandedRows = [...expandedRows, workshitId];
    }

    setExpandedRows(updatedExpandedRows);
    const expandedWorksheetIdsString = updatedExpandedRows.join(",");
    setExpandedWorksheetIds(expandedWorksheetIdsString);
    if (expandedWorksheetIdsString) {
      handleGetSubTableWorksheetList(expandedWorksheetIdsString);
    }
  };

  // Determine if a row is expanded (based on expandedRows array)
  const isRowExpanded = (workshitId: number) => {
    return expandedRows.includes(workshitId);
  };
  /// handle Modal close code
  const handleModalClose = () => {
    setRecallFromCourtModal(false);
    setShareWorksheetModal(false);
    setArchiveWorksheetModal(false);
  };

  /// handle File Download onchange
  const handleFileDownloadOnChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    handleDownloadWorksheetData(event.target.value);
  };

  const OptionData = ExportOptionData(selectedValue);

  /// handle page onchange
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
  };

  /// handle Modal input onchange
  const handleModalInputChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { name, value } = event.target;
    setShareWorksheetObj((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setShareWorksheetErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  /// handle Row select for per page size
  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setExpandedRows([]);
    setAllRowsExpanded(false);
    setPerPage(Number(event.target.value));
    setPageIndex(1);
  };
  /// handle table input onchange
  const searchInputOnchange = (
    e:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setTableSearchData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPageIndex(1);
    setPerPage(10);
  };
  /// handle table search reset data
  const handleResetGridData = () => {
    setTableSearchData({
      plaintiff_name: "",
      defendant_name: "",
      county_id__county_name: "",
      court_id__court_name: "",
      civil_action_case_number: "",
      dhsc_no: "",
      prepared_by__username: "",
    });
    setPageIndex(1);
    setPerPage(10);
    setSelectedValue("");
    setSortConfig({
      key: "",
      direction: "asc",
    });
  };
  /// table sorting code
  const handleSort = (columnId: keyof searchFolderWorkshitData) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnId, direction });
  };
  ///table sorting icons
  const getSortIcon = (columnId: keyof searchFolderWorkshitData) => {
    if (!sortConfig) return null;
    return (
      <>
        {sortConfig.key === columnId ? (
          sortConfig.direction === "asc" ? (
            <ArrowUpwardIcon
              fontSize="small"
              sx={{ verticalAlign: "middle" }}
            />
          ) : (
            <ArrowDownwardIcon
              fontSize="small"
              sx={{ verticalAlign: "middle" }}
            />
          )
        ) : (
          <ArrowUpwardIcon
            fontSize="small"
            sx={{ opacity: 0.2, verticalAlign: "middle" }}
          />
        )}
      </>
    );
  };

  const tableHeadings = [
    { id: "1", label: "#", sortable: false, search: false },
    {
      id: "2",
      label: (
        <IconButton onClick={handleExpandAllRows}>
          {allRowsExpanded ? (
            <RemoveCircleOutlineIcon sx={{ color: "#fff" }} />
          ) : (
            <AddCircleOutlineIcon sx={{ color: "#fff" }} />
          )}
        </IconButton>
      ),
      sortable: false,
      search: false,
    },
    {
      id: "plaintiff_name",
      label: t("Plaintiff Name"),
      sortable: true,
      search: true,
    },
    {
      id: "defendant_name",
      label: t("Defendant Name"),
      sortable: true,
      search: true,
    },
    {
      id: "county_id__county_name",
      label: t("County"),
      sortable: true,
      search: true,
    },
    {
      id: "court_id__court_name",
      label: t("Court"),
      sortable: true,
      search: true,
    },
    {
      id: "civil_action_case_number",
      label: t("CACN"),
      sortable: true,
      search: true,
    },
    { id: "dhsc_no", label: t("DHS/DCSS"), sortable: true, search: true },
    {
      id: "prepared_by__username",
      label: t("Prepared By"),
      sortable: true,
      search: true,
    },
  ];
  const totalPages = Math.ceil(totalTableRecords / perPage);
  const startIndex = (pageIndex - 1) * perPage + 1;
  const endIndex = Math.min(pageIndex * perPage, totalTableRecords);
  /// handle Home Page redirection code
  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        className="contain__area"
      >
        <Box
          className="breadcrumbs"
          sx={{
            mt: { md: "2rem", sm: "1rem", xs: "1rem" },
            mb: { md: "1.5rem", sm: "1rem", xs: "1rem" },
            marginX: { md: "56px", sm: "0px", xs: "0px" },
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handleHomeRedirection}
              >
                {t("Home")}
              </Link>
              <Typography color="#000000">{t("Search-Folder-Worksheet")}</Typography>
            </Breadcrumbs>
          </div>
        </Box>
        <Box>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "5px",
              border: "1px solid #DFDFDF",
              margin: { md: "0px 56px", sm: " 0px", xs: "0px" },
            }}
          >
            <Box m={2}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h5"
                    className="h5"
                    color="#0464AB"
                    gutterBottom
                    sx={{
                      fontSize: { md: 23, sm: 19, xs: 19 },
                      letterSpacing: "1px",
                      marginBottom: "1px",
                      textAlign: { md: "left", sm: "left", xs: "center" },
                    }}
                  >
                    {t("Search Folders-Worksheets")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  sx={{
                    justifyContent: { md: "right", sm: "right", xs: "center" },
                  }}
                >
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "20px",
                      bgcolor: "#0464AB",
                      fontWeight: "600",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "#022E4C",
                        fontWeight: "600",
                      },
                    }}
                    onClick={() => {
                      navigate("/WorkSheets/createWorksheet");
                      dispatch(createWorksheetDataEmpty());
                      dispatch(headerActiveLink("/WorkSheets/createWorksheet"));
                    }}
                  >
                    {t("Create Folder-Worksheet")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box mx={2} mb={1}>
              <Typography
                p={2}
                border="1px solid #DFDFDF"
                bgcolor="#F8F8F8"
                borderRadius={1}
              >
                <span style={{ fontWeight: 600 }}>{t("NOTE -")} </span> {t("Users will create a Folder with one Original Worksheet. Additional worksheets for the same case must be created by selecting the Copy Worksheet button associated with the original worksheet and the worksheet will be added to the Folder.")}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent={{
                md: "space-between",
                sm: "space-between",
                xs: "center",
              }}
              flexWrap="wrap"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                mx={2}
                marginLeft={{ xs: "30%", sm: "2%", md: "1.5%" }}
                flexWrap="wrap"
              >
                <Grid
                  container
                  alignItems={{ xs: "center" }}
                  justifyContent={{ xs: "center" }}
                  spacing={1}
                >
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography variant="body1">
                      {t("Showing")}{" "}
                      <strong>
                        {startIndex}-{endIndex}
                      </strong>{" "}
                      {t("of")} <strong>{totalTableRecords} </strong> {t("items.")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" mr={1}>
                        {t("Show")}
                      </Typography>
                      <Select
                        value={perPage}
                        onChange={handleRowsPerPageChange}
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "50px" }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <Typography variant="body1" ml={1}>
                        {t("entries")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1} mr={4} ml={1}>
                <Grid

                  display={"flex"}
                  alignItems="center"
                  justifyContent="end"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="outlined"
                      className={styles.resetButtoncss}
                      sx={{
                        textTransform: "none",
                        height: "35px",
                        width: {
                          lg: "max-content",
                          md: "135px",
                          sm: "135px",
                          xs: "fit-content",
                        },
                        fontSize: {
                          md: "12px",
                          sm: "12px",
                          xs: "9px",
                        },
                      }}
                      onClick={handleResetGridData}
                    >
                      <ReplayOutlinedIcon sx={{ marginRight: "2px" }} />
                      {t("Reset Grid")}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    paddingLeft={{ xs: "5px!important", md: "5px!important" }}
                  >
                    <Box>
                      <ExportFileDropdown
                        name="dropdown"
                        label=""
                        value={selectedValue}
                        onChange={handleFileDownloadOnChange}
                        options={OptionData.slice(1)}
                        placeholder={
                          <div style={{ display: "flex", alignItems: "left" }}>
                            <FileExporticon
                              className={
                                selectedValue === "Export"
                                  ? styles.activeSVG
                                  : styles.inactiveSVG
                              }
                            />
                            <Typography
                              sx={{
                                marginLeft: { md: "6px", sm: "6px", xs: "0px" },
                              }}
                            >
                              {OptionData[0].label}
                            </Typography>
                          </div>
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box mt={1}>
              <TableContainer>
                <Table
                  aria-label="collapsible table"
                  sx={{
                    border: "1px solid rgba(224, 224, 224, 1)",
                    "& .MuiTableCell-root": {
                      padding: "7px 13px",
                      fontSize: '13px',
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                  }}
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "#404040" }}>
                      {tableHeadings.map((label, index) => (
                        <TableCell
                          key={index}
                          onClick={() =>
                            label.sortable &&
                            handleSort(
                              label.id as keyof searchFolderWorkshitData
                            )
                          }
                          sx={{
                            color: "#F8F8F8",
                            width:
                              label.label === t("Plaintiff Name")
                                ? "16%"
                                : label.label === t("Defendant Name")
                                  ? "20%"
                                  : "auto",
                            maxWidth:
                              label.label === "Plaintiff Name"
                                ? "16%"
                                : label.label === t("Defendant Name")
                                  ? "20%"
                                  : "auto",
                            minWidth: {
                              md:
                                label.label === "#" || label.id === "2"
                                  ? "25px" : label.label === t("Prepared By") ? "80px"
                                    : "88px",
                              sm:
                                label.label === "#" || label.id === "2"
                                  ? "25px"
                                  : "130px",
                              xs:
                                label.label === "#" || label.id === "2"
                                  ? "25px"
                                  : "130px",
                            },
                            textAlign:
                              label.label === "#" || label.id === "2"
                                ? "center"
                                : "inherit",

                            cursor: label.sortable ? "pointer" : "default",
                          }}
                        >
                          {label.label}
                          {label.sortable &&
                            getSortIcon(
                              label.id as keyof searchFolderWorkshitData
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {tableHeadings.map((label, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            color: "#F8F8F8",
                            width:
                              label.label === t("Plaintiff Name")
                                ? "15%"
                                : label.label === t("Defendant Name")
                                  ? "20%"
                                  : label.label === t("County") ||
                                    label.label === t("Court")
                                    ? "5%"
                                    : "auto",
                          }}
                        >
                          {label.label === t("Court") ? (
                            <CustomDropdown
                              mbHeading="0px"
                              height={35}
                              name="court_id__court_name"
                              value={
                                tableSearchData[
                                label.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputOnchange}
                              options={courtData}
                              placeholder={t("Select")}
                            />
                          ) : label.label === "County" ? (
                            <CustomDropdown
                              mbHeading="0px"
                              height={35}
                              name="county_id__county_name"
                              value={
                                tableSearchData[
                                label.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputOnchange}
                              options={countyData}
                              placeholder={t("Select")}
                            />
                          ) : (
                            label.search && (
                              <CustomInput
                                name={label.id}
                                placeholder={t("Search")}
                                value={
                                  tableSearchData[
                                  label.id as keyof TableSearchData
                                  ] || ""
                                }
                                onChange={searchInputOnchange}
                                endAdornment={<SearchOutlinedIcon />}
                              />
                            )
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchFolderWorksheetData.map((row, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            "& > *": { borderBottom: "unset" },
                            padding: "0px",
                          }}
                        >
                          <TableCell>
                            {(pageIndex - 1) * perPage + index + 1}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() =>
                                handleTableRowOpen(row.worksheet_id)
                              }
                            >
                              {isRowExpanded(row.worksheet_id) ? (
                                <RemoveCircleOutlineIcon
                                  sx={{ color: "#0464AB" }}
                                />
                              ) : (
                                <AddCircleOutlineIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>{row.plaintiff_name}</TableCell>
                          <TableCell>{row.defendant_name}</TableCell>
                          <TableCell>{row.county_id__county_name}</TableCell>
                          <TableCell>{row.court_id__court_name}</TableCell>
                          <TableCell>
                            {row.civil_action_case_number
                              ? row.civil_action_case_number
                              : "N/A"}
                          </TableCell>
                          <TableCell>
                            {row.dhsc_no ? row.dhsc_no : "N/A"}
                          </TableCell>
                          <TableCell>{row.prepared_by__username}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{
                              paddingBottom: 0,
                              paddingTop: 0,
                              background: "#E6F5FF",
                            }}
                            colSpan={9}
                          >
                            <Collapse
                              in={isRowExpanded(row.worksheet_id)}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ background: "#E6F5FF", my: 2, mx: 1 }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  component="div"
                                  sx={{ color: "#022E4C", fontWeight: 600 }}
                                >
                                  {t("Child Support Worksheets For:")}{" "}
                                  {row.plaintiff_name} Vs {row.defendant_name}
                                </Typography>
                                <Table size="small" aria-label="worksheets">
                                  <TableHead
                                    sx={{ backgroundColor: "#022E4C" }}
                                  >
                                    <TableRow>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        {t("Worksheet Description")}
                                      </TableCell>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        {t("Date Entered")}
                                      </TableCell>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        {t("Version")}
                                      </TableCell>
                                      <TableCell sx={{ color: "#FFFFFF" }}>
                                        {t("Options")}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody sx={{ background: "#fff" }}>
                                    {subWorksheetTblData[row.worksheet_id]?.map(
                                      (item, i) => (
                                        <>
                                          {!item.is_archived && (
                                            <>
                                              <TableRow
                                                key={i}
                                                sx={{
                                                  verticalAlign: "top",
                                                }}
                                              >
                                                {item.share_type ===
                                                  "Read-Only" ? (
                                                  <TableCell
                                                    sx={{ width: "30%" }}
                                                  >
                                                    {t("Read-Only - Shared Worksheet Of ") +
                                                      item.description}
                                                  </TableCell>
                                                ) : (
                                                  <TableCell
                                                    sx={{ width: "30%" }}
                                                  >
                                                    <Button
                                                      variant="contained"
                                                      disabled={item.is_expired}
                                                      className={
                                                        item.is_expired
                                                          ? styles.button_disable
                                                          : styles.globalContainedButton
                                                      }
                                                      onClick={() =>
                                                        handleGetWorksheetDataById(
                                                          item.worksheet_id
                                                        )
                                                      }
                                                    >
                                                      {t("Open - ") +
                                                        item.description}
                                                    </Button>
                                                  </TableCell>
                                                )}
                                                <TableCell
                                                  sx={{ width: "15%" }}
                                                >
                                                  {item.created_at}
                                                </TableCell>
                                                <TableCell sx={{ width: "5%" }}>
                                                  {item.version}
                                                </TableCell>
                                                {item.is_expired ? (
                                                  <TableCell>
                                                    <Button
                                                      variant="outlined"
                                                      sx={{
                                                        margin: "5px",
                                                      }}
                                                      className={
                                                        styles.globalButtonStyle
                                                      }
                                                      onClick={() =>
                                                        handleArchiveWorksheetModal(
                                                          item.worksheet_id
                                                        )
                                                      }
                                                    >
                                                      {t("Archive Worksheet")}
                                                    </Button>
                                                  </TableCell>
                                                ) : (
                                                  <>
                                                    {item.share_type ===
                                                      "Read-Only" ? (
                                                      <TableCell>
                                                        {item.display_worksheet && (
                                                          <Button
                                                            variant="outlined"
                                                            sx={{
                                                              margin: "5px",
                                                            }}
                                                            className={
                                                              styles.globalButtonStyle
                                                            }
                                                            onClick={() => {
                                                              navigate(
                                                                `/searchWorksheet/${item.worksheet_id}`
                                                              );
                                                              dispatch(
                                                                headerActiveLink(
                                                                  ""
                                                                )
                                                              );
                                                            }}
                                                          >
                                                            {t("Display/Print Worksheet Results")}
                                                          </Button>
                                                        )}
                                                        {item.make_available_to_court ? (
                                                          <Button
                                                            variant="outlined"
                                                            sx={{
                                                              margin: "5px",
                                                            }}
                                                            className={
                                                              styles.globalButtonStyle
                                                            }
                                                            onClick={() =>
                                                              handleRecallFromCourtMdlOpen(
                                                                item.worksheet_id
                                                              )
                                                            }
                                                          >
                                                            {t("Recall From Court")}
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            variant="outlined"
                                                            sx={{
                                                              margin: "5px",
                                                            }}
                                                            className={
                                                              styles.globalButtonStyle
                                                            }
                                                            onClick={() => {
                                                              navigate(
                                                                `/makeAvailableToCourt/${item.worksheet_id}`
                                                              );
                                                              dispatch(
                                                                headerActiveLink(
                                                                  ""
                                                                )
                                                              );
                                                            }}
                                                          >
                                                            {t("Make Available To Court")}
                                                          </Button>
                                                        )}
                                                        <Button
                                                          variant="outlined"
                                                          sx={{
                                                            margin: "5px",
                                                          }}
                                                          className={
                                                            styles.globalButtonStyle
                                                          }
                                                          onClick={() => {
                                                            handleShareWorksheet(
                                                              item.worksheet_id
                                                            );
                                                          }}
                                                        >
                                                          {t("Share Worksheet")}
                                                        </Button>
                                                        <Button
                                                          variant="outlined"
                                                          sx={{
                                                            margin: "5px",
                                                          }}
                                                          className={
                                                            styles.globalButtonStyle
                                                          }
                                                          onClick={() =>
                                                            handleCopyWorksheetData(
                                                              item.worksheet_id
                                                            )
                                                          }
                                                        >
                                                          {t("Copy Worksheet")}
                                                        </Button>
                                                        <Button
                                                          variant="outlined"
                                                          sx={{
                                                            margin: "5px",
                                                          }}
                                                          className={
                                                            styles.globalButtonStyle
                                                          }
                                                          onClick={() =>
                                                            handleArchiveWorksheetModal(
                                                              item.worksheet_id
                                                            )
                                                          }
                                                        >
                                                          {t("Archive Worksheet")}
                                                        </Button>
                                                      </TableCell>
                                                    ) : (
                                                      <TableCell
                                                        sx={{
                                                          padding:
                                                            "5px!important",
                                                        }}
                                                      >
                                                        {item.display_worksheet && (
                                                          <Button
                                                            variant="outlined"
                                                            sx={{
                                                              margin: "5px",
                                                            }}
                                                            className={
                                                              styles.globalButtonStyle
                                                            }
                                                            onClick={() => {
                                                              navigate(
                                                                `/searchWorksheet/${item.worksheet_id}`
                                                              );
                                                              dispatch(
                                                                headerActiveLink(
                                                                  ""
                                                                )
                                                              );
                                                            }}
                                                          >
                                                            {t("Display/Print Worksheet Results")}
                                                          </Button>
                                                        )}
                                                        {item.make_available_to_court ? (
                                                          <Button
                                                            variant="outlined"
                                                            sx={{
                                                              margin: "5px",
                                                            }}
                                                            className={
                                                              styles.globalButtonStyle
                                                            }
                                                            onClick={() =>
                                                              handleRecallFromCourtMdlOpen(
                                                                item.worksheet_id
                                                              )
                                                            }
                                                          >
                                                            {t("Recall From Court")}
                                                          </Button>
                                                        ) : (
                                                          <Button
                                                            variant="outlined"
                                                            sx={{
                                                              margin: "5px",
                                                            }}
                                                            className={
                                                              styles.globalButtonStyle
                                                            }
                                                            onClick={() => {
                                                              navigate(
                                                                `/makeAvailableToCourt/${item.worksheet_id}`
                                                              );
                                                              dispatch(
                                                                headerActiveLink(
                                                                  ""
                                                                )
                                                              );
                                                            }}
                                                          >
                                                            {t("Make Available To Court")}
                                                          </Button>
                                                        )}
                                                        <Button
                                                          variant="outlined"
                                                          sx={{
                                                            margin: "5px",
                                                          }}
                                                          className={
                                                            styles.globalButtonStyle
                                                          }
                                                          onClick={() => {
                                                            handleShareWorksheet(
                                                              item.worksheet_id
                                                            );
                                                          }}
                                                        >
                                                          {t("Share Worksheet")}
                                                        </Button>
                                                        <Button
                                                          variant="outlined"
                                                          sx={{
                                                            margin: "5px",
                                                          }}
                                                          className={
                                                            styles.globalButtonStyle
                                                          }
                                                          onClick={() =>
                                                            handleCopyWorksheetData(
                                                              item.worksheet_id
                                                            )
                                                          }
                                                        >
                                                          {t("Copy Worksheet")}
                                                        </Button>
                                                        <Button
                                                          variant="outlined"
                                                          sx={{
                                                            margin: "5px",
                                                          }}
                                                          className={
                                                            styles.globalButtonStyle
                                                          }
                                                          onClick={() =>
                                                            handleArchiveWorksheetModal(
                                                              item.worksheet_id
                                                            )
                                                          }
                                                        >
                                                          {t("Archive Worksheet")}
                                                        </Button>
                                                      </TableCell>
                                                    )}
                                                  </>
                                                )}
                                              </TableRow>
                                              {item.is_expired && (
                                                <TableRow>
                                                  <TableCell
                                                    colSpan={4}
                                                    sx={{
                                                      padding: 0,
                                                      bgcolor: "#F8F8F8",
                                                      border:
                                                        "1px solid #DFDFDF",
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        textAlign: "center",
                                                        padding: 1,
                                                        color: "#022E4C",
                                                        fontWeight: "bolder",
                                                      }}
                                                    >
                                                      {t("Shared Worksheet Has Expired")}
                                                    </Typography>
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                container
                my={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ textAlign: { xs: "center", sm: "left", md: "left" } }}
                >
                  <Typography sx={{ marginX: "20px" }} variant="body1">
                    {t("Showing")} {startIndex}-{endIndex} of {totalTableRecords}{" "}
                    {t("items.")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-end" },
                  }}
                >
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={pageIndex}
                    onChange={handlePageChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
          {/*---------- Recall From Court Modal----------- */}
          <CustomModal
            onClose={handleModalClose}
            actionButtonLabel={t("Recall From Court")}
            open={recallFromCourtModal}
            title={t("Recall From Court")}
            boxStyle={{ width: { md: "33%", sm: "70%", xs: "90%" } }}
            save={handleRecallFromCourtData}
          >
            <Typography sx={{ mt: 2, textAlign: "center", fontWeight: "bold" }}>
              {t("You have decided to recall a worksheet previously made available to the court_name. This action is possible because the court_name has not yet made the worksheet final. Recalling this worksheet means that the court_name will no longer have access to the worksheet.")}
            </Typography>

            <Typography sx={{ mt: 2, textAlign: "center", fontWeight: "bold" }}>
              {t("Click the “Recall From Court” button to proceed. The “Search Folders-Worksheets” screen will display and the recalled worksheet will be available.")}
            </Typography>
          </CustomModal>
          {/*----------Share Worksheet Modal----------- */}
          <CustomModal
            onClose={handleModalClose}
            actionButtonLabel={t("Save")}
            open={shareWorksheetModal}
            title={t("Share Worksheet")}
            borderBottom="0px"
            pb="0px"
            boxStyle={{ width: { md: "33%", sm: "70%", xs: "90%" } }}
            save={handleShareWorksheetData}
          >
            <Typography
              variant="h6"
              component="h2"
              color="#022E4C"
              fontWeight="bold"
              sx={{ mt: 1, textAlign: "center", fontSize: 14, mb: 2 }}
            >
              {t("SECTION 1. Share Worksheet")}
            </Typography>
            <Typography border={"1px solid #DFDFDF"}></Typography>
            <Grid container spacing={2} mb={2} mt={1}>
              <Grid item xs={12}>
                <Box mb={1}>
                  <CustomInput
                    label={t("Share Email")}
                    name="shareEmail"
                    value={shareWorksheetObj.shareEmail}
                    type="email"
                    placeholder={t("Enter Email")}
                    onChange={handleModalInputChange}
                    error={t(shareWorksheetErrors.shareEmail)}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mb={1}>
                  <CustomDropdown
                    label={t("Share Type")}
                    name="shareType"
                    value={shareWorksheetObj.shareType}
                    options={[
                      { value: "Read-Only", label: t("Read-Only") },
                      { value: "Modify", label: t("Modify") },
                    ]}
                    onChange={handleModalInputChange}
                    placeholder={t("- Choose Share Type -")}
                    error={t(shareWorksheetErrors.shareType)}
                    required
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mb={1}>
                  <CustomDropdown
                    label={t("Share Term")}
                    name="shareTerm"
                    value={shareWorksheetObj.shareTerm}
                    options={[
                      { value: "One Week", label: t("One Week") },
                      { value: "One Month", label: t("One Month") },
                      { value: "One Year", label: t("One Year") },
                      { value: "Never Expire", label: t("Never Expires") },
                    ]}
                    placeholder={t("- Choose Share Term -")}
                    onChange={handleModalInputChange}
                    error={t(shareWorksheetErrors.shareTerm)}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
          </CustomModal>
          {/*----------Archive Worksheet Modal----------- */}
          <CustomModal
            onClose={handleModalClose}
            actionButtonLabel={t("Archive Worksheet")}
            open={archiveWorksheetModal}
            title={t("Archive Worksheet")}
            boxStyle={{ width: { md: "33%", sm: "70%", xs: "90%" } }}
            save={handleWorksheetArchive}
          >
            <Typography sx={{ mt: 2, textAlign: "center", fontWeight: "bold" }}>
              {t("If You Archive The Worksheet, You Will Not Have The Ability To Review, Edit and/or Print The Worksheet")}
            </Typography>
          </CustomModal>
        </Box>
      </Box>
    </>
  );
};

export default SearchFolderWorksheet;
