import React, {
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  FocusEvent,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Grid,
  IconButton,
  Link,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../assets/images/info.svg";
import CustomTooltip from "../../../components/tooltip";
import interrogation from "../../../assets/images/interrogation.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import style from "../workSheet.module.css";
import CustomInput from "../../../components/inputField";
import TextareaField from "../../../components/text-area";
import { SelectChangeEvent } from "@mui/material";
import SettingIcons from "../../../assets/images/SettingIcon.svg";
import CustomModal from "../../../components/customModal";
import WorksheetButtons from "../../../components/worksheetButtons";
import CurrencyValue from "../../../components/currencyInputWithoutSelect";
import { useAppDispatch, useAppSelector } from "../../../globalState/hooks";
import {
  deleteFromArray,
  updateArrayInfo,
  updateBasicInfo,
} from "../../../globalState/actions/create-worksheet-action";
import CustomDropdown from "../../../components/drop-down";
import {
  AddPreexistingOrder,
  AddProposedAdjustmentIncomeOther,
} from "../../../types/create-worksheet/AdjustedIncomeTab";
import {
  AddPreexistingOrderValidation,
  ProposedAdjustmentIncomeValidation,
} from "./AdjustedIncomeTabValidation";
import { FormErrors } from "../../../types/global";
import {
  Adjustment,
  PreexistingSupport,
} from "../../../types/create-worksheet";
import { generate18YearFromCurrentYearDropdown } from "../../../utils/constants";
import ReportTechnicalForm, {
  ReportTechnicalFormHandle,
} from "../../../components/reportTechnicalIssue";
import technicalReportService from "../../../services/technicalReport-service";
import { showToast } from "../../../components/toastNotification";
import { GenerateCaptcha } from "../../../utils/generateCaptcha";
import ValidationErrorModal from "../../../components/validationErrorModal";
import DeleteModal from "../../../components/globalDeleteModal";
import { useTranslation } from "react-i18next";

interface RadioGroupInputProps {
  label: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  id?: string;
}
interface TechnicalReportInput {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
}

const AdjustedIncomeTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const workSheetStates = useAppSelector((state) => state.createWorksheet);
  const formRef = useRef<ReportTechnicalFormHandle>(null);

  const adjustmentRequestedByDropDownData = [
    {
      value: `${workSheetStates.parent_name_1}`,
      label: `${workSheetStates.parent_name_1}`,
    },
    {
      value: `${workSheetStates.parent_name_2}`,
      label: `${workSheetStates.parent_name_2}`,
    },
  ];

  const years = generate18YearFromCurrentYearDropdown();
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [reportTechnicalModal, setReportTechnicalModal] = useState(false);
  const [formPreExistingErrors, setPreExistingFormErrors] =
    useState<FormErrors>({});
  const [
    proposedAdjustmentIncomeOtherErrors,
    setProposedAdjustmentIncomeOtherErrors,
  ] = useState<FormErrors>({});
  const [isEdit, setIsEdit] = useState<string>("");
  const [addModalData, setAddModalData] = useState<AddPreexistingOrder>({
    court_name: "",
    civil_action_case_number: "",
    children_names_years_of_birth: "",
    date_of_initial_order: "",
    time_of_initial_order: "",
    preexisting_child_support_paid_monthly1: "",
    preexisting_child_support_paid_monthly2: "",
  });
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [inputValidationErrorModal, setInputValidationErrorModal] =
    useState<boolean>(false);
  const [inputValidationMessage, setInputValidationMessage] = useState("");
  const [secondValidationErrorModalOpen, setSecondValidationErrorModalOpen] =
    useState(false);
  const [
    addProposedAdjustmentIncomeOther,
    setAddProposedAdjustmentIncomeOther,
  ] = useState<AddProposedAdjustmentIncomeOther>({
    legally_responsible_for_child: "",
    child_lives_in_parents_home: "",
    parent_actually_supporting_child: "",
    child_not_before_court: "",
    child_not_subject_to_support_order: "",
    child_name: "",
    year_of_birth: "",
    adjustment_requested_by: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [isFirstTblDelete, setIsFirstTblDelete] = useState<boolean>(false);
  const { t } = useTranslation();

  //// handle drop-down onchange
  const handleDropdownChange = (
    event: SelectChangeEvent | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const validTwoDecimalRegex = /^\d*\.?\d{0,2}$/;

    if (
      name === "monthly_self_employment_income1" ||
      name === "monthly_self_employment_income2"
    ) {
      if (validTwoDecimalRegex.test(value)) {
        dispatch(updateBasicInfo(name, value));
      }
    } else {
      dispatch(updateBasicInfo(name, value));
    }
  };
  /// handle check box onchange
  const handleCheckboxChange = () => {
    if (workSheetStates.isAdjustmentAcknowledgeCheck) {
      // Check if the table has data
      if (
        workSheetStates.pre_existing_child_support &&
        workSheetStates.pre_existing_child_support.length > 0
      ) {
        // Show validation modal if table is not empty
        setInputValidationMessage(
          "You cannot uncheck this box until the table is empty. Please delete all records before unchecking."
        );
        setSecondValidationErrorModalOpen(true);
      } else {
        // Allow uncheck
        dispatch(
          updateBasicInfo(
            "isAdjustmentAcknowledgeCheck",
            !workSheetStates.isAdjustmentAcknowledgeCheck
          )
        );
      }
    } else {
      // Allow check
      dispatch(
        updateBasicInfo(
          "isAdjustmentAcknowledgeCheck",
          !workSheetStates.isAdjustmentAcknowledgeCheck
        )
      );
    }
  };



  //// onblur Insured Input Handle
  const uninsuredInputHandle = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numValue = parseFloat(value);
    let formattedValue = value;
    if (!isNaN(numValue)) {
      if (value.includes(".")) {
        formattedValue = numValue.toFixed(2);
      }
    }
    dispatch(updateBasicInfo(name, formattedValue));
  };

  // Modal's visibility Modals
  const handleFirstModalOpen = () => {
    if (workSheetStates.parent_name_1 && workSheetStates.parent_name_2) {
      setFirstModalOpen(true);
    } else {
      setInputValidationErrorModal(true);
      setInputValidationMessage(
        t("Please enter the names for both parents first")
      );
    }
  };
  const handleFirstModalClose = () => setFirstModalOpen(false);
  const handleSecondModalOpen = () => {
    if (workSheetStates.parent_name_1 && workSheetStates.parent_name_2) {
      setSecondModalOpen(true);
    } else {
      setInputValidationErrorModal(true);
      setInputValidationMessage(
        t("Please enter the names for both parents first")
      );
    }
  };
  const handleSecondModalClose = () => setSecondModalOpen(false);
  const handleReportModalOpen = () => {
    setInputCaptcha(GenerateCaptcha);
    setReportTechnicalModal(true);
  };
  const handleReportModalClose = () => {
    setReportTechnicalModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // First Modal's Handlers
  const handleAddModalDataChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const validTwoDecimalRegex = /^\d*\.?\d{0,2}$/;
    if (
      name === "preexisting_child_support_paid_monthly1" ||
      name === "preexisting_child_support_paid_monthly2"
    ) {
      if (validTwoDecimalRegex.test(value)) {
        setAddModalData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setAddModalData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    if (formPreExistingErrors[name]) {
      setPreExistingFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
    if (
      name === "preexisting_child_support_paid_monthly1" ||
      name === "preexisting_child_support_paid_monthly2"
    ) {
      setPreExistingFormErrors((prevErrors) => ({
        ...prevErrors,
        preexisting_child_support_paid_monthly2: "",
      }));
    }
  };
  //modal data empty code
  const setModalDataEmpty = () => {
    handleFirstModalClose();
    setAddModalData((prev) => {
      return {
        ...prev,
        court_name: "",
        civil_action_case_number: "",
        children_names_years_of_birth: "",
        date_of_initial_order: "",
        time_of_initial_order: "",
        preexisting_child_support_paid_monthly1: "",
        preexisting_child_support_paid_monthly2: "",
      };
    });
    setPreExistingFormErrors({});
    setIsEdit("");
  };
  // add Preexisting Order function
  const addPreexistingOrder = () => {
    const formErrors = AddPreexistingOrderValidation(addModalData);
    setPreExistingFormErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });
    const isFormValid = Object.keys(formErrors).length === 0;

    if (isFormValid) {
      const newData = {
        arrayKey: "pre_existing_child_support",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          court_name: addModalData.court_name.trim(),
          civil_action_case_number:
            addModalData.civil_action_case_number.trim(),
          children_names_years_of_birth:
            addModalData.children_names_years_of_birth.trim(),
          date_of_initial_order: addModalData.date_of_initial_order,
          time_of_initial_order: addModalData.time_of_initial_order,
          preexisting_child_support_paid_monthly1:
            addModalData.preexisting_child_support_paid_monthly1?.trim(),
          preexisting_child_support_paid_monthly2:
            addModalData.preexisting_child_support_paid_monthly2?.trim(),
        },
      };

      dispatch(updateArrayInfo(newData));
      setModalDataEmpty();
    }
  };
  // update Preexisting Order function
  const editPreexistingDataHandler = (item: PreexistingSupport) => {
    setIsEdit(item.id!);
    setAddModalData((prev) => {
      return {
        ...prev,
        court_name: item.court_name,
        civil_action_case_number: item.civil_action_case_number,
        children_names_years_of_birth: item.children_names_years_of_birth,
        date_of_initial_order: item.date_of_initial_order,
        time_of_initial_order: item.time_of_initial_order,
        preexisting_child_support_paid_monthly1:
          item.preexisting_child_support_paid_monthly1,
        preexisting_child_support_paid_monthly2:
          item.preexisting_child_support_paid_monthly2,
      };
    });
    handleFirstModalOpen();
  };
  /// Second Modal's Handlers
  const handleProposedAdjustmentIncomeOther = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
  ) => {
    const { name, value } = e.target;
    setAddProposedAdjustmentIncomeOther((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (proposedAdjustmentIncomeOtherErrors[name]) {
      setProposedAdjustmentIncomeOtherErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };
  /// second Modal Data Empty
  const setSecondModalDataEmpty = () => {
    handleSecondModalClose();
    setAddProposedAdjustmentIncomeOther((prev) => {
      return {
        ...prev,
        legally_responsible_for_child: "",
        child_lives_in_parents_home: "",
        parent_actually_supporting_child: "",
        child_not_before_court: "",
        child_not_subject_to_support_order: "",
        child_name: "",
        year_of_birth: "",
        adjustment_requested_by: "",
      };
    });
    setIsEdit("");
    setProposedAdjustmentIncomeOtherErrors({});
  };

  ////add Proposed Adjustment Income Other Handler
  const addProposedAdjustmentIncomeOtherHandler = () => {
    const formErrors = ProposedAdjustmentIncomeValidation(
      addProposedAdjustmentIncomeOther,
      (message) => {
        setSecondValidationErrorModalOpen(true);
        setInputValidationMessage(message); // Set the validation message
      }
    );
    setProposedAdjustmentIncomeOtherErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });
    const isFormValid = Object.keys(formErrors).length === 0;
    if (isFormValid) {
      const newData = {
        arrayKey: "proposed_adjustment_for_qualified_child",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          child_name: addProposedAdjustmentIncomeOther.child_name,
          year_of_birth: addProposedAdjustmentIncomeOther.year_of_birth,
          adjustment_requested_by:
            addProposedAdjustmentIncomeOther.adjustment_requested_by,
          legally_responsible_for_child:
            addProposedAdjustmentIncomeOther.legally_responsible_for_child ===
              "yes"
              ? true
              : false,
          child_lives_in_parents_home:
            addProposedAdjustmentIncomeOther.child_lives_in_parents_home ===
              "yes"
              ? true
              : false,
          parent_actually_supporting_child:
            addProposedAdjustmentIncomeOther.parent_actually_supporting_child ===
              "yes"
              ? true
              : false,
          child_not_before_court:
            addProposedAdjustmentIncomeOther.child_not_before_court === "yes"
              ? true
              : false,
          child_not_subject_to_support_order:
            addProposedAdjustmentIncomeOther.child_not_subject_to_support_order ===
              "yes"
              ? true
              : false,
          parentName1:
            addProposedAdjustmentIncomeOther.adjustment_requested_by ===
              workSheetStates.parent_name_1
              ? "1"
              : "",
          parentName2:
            addProposedAdjustmentIncomeOther.adjustment_requested_by ===
              workSheetStates.parent_name_2
              ? "1"
              : "",
        },
      };
      dispatch(updateArrayInfo(newData));
      setSecondModalDataEmpty();
    }
  };
  ///edit Proposed Adjustment Income Handler
  const editProposedAdjustmentIncomeHandler = (item: Adjustment) => {
    setIsEdit(item.id!);
    let adjustmentRequested = "";
    if (item.parentName1 === "1") {
      adjustmentRequested = workSheetStates.parent_name_1;
    } else if (item.parentName2 === "1") {
      adjustmentRequested = workSheetStates.parent_name_2;
    } else {
      adjustmentRequested = "";
    }
    setAddProposedAdjustmentIncomeOther((prev) => {
      return {
        ...prev,
        legally_responsible_for_child: "yes",
        child_lives_in_parents_home: "yes",
        parent_actually_supporting_child: "yes",
        child_not_before_court: "yes",
        child_not_subject_to_support_order: "yes",
        child_name: item.child_name,
        year_of_birth: item.year_of_birth ? item.year_of_birth.toString() : "",
        adjustment_requested_by: adjustmentRequested,
      };
    });
    handleSecondModalOpen();
  };

  /// delete Adjusted Income modal open
  const deletChildDataModalOpen = (id: string, tableTab: string) => {
    if (tableTab === "first") {
      setIsFirstTblDelete(true);
    } else {
      setIsFirstTblDelete(false);
    }
    setDeleteId(id);
    setDeleteModal(true);
  };
  // delete Preexisting Order function
  const deletePreexistingChildDataHandler = () => {
    if (isFirstTblDelete) {
      dispatch(
        deleteFromArray({
          arrayKey: "pre_existing_child_support",
          itemId: deleteId,
        })
      );
    } else {
      dispatch(
        deleteFromArray({
          arrayKey: "proposed_adjustment_for_qualified_child",
          itemId: deleteId,
        })
      );
    }
    setDeleteModal(false);
  };

  /// handle modal close
  const handleDeleteMdlClose = () => {
    setDeleteModal(false);
  };
  const handleSaveData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };
  /// API calling for Technical Report
  const handleSubmitTechnicalReport = async (
    reportInputValues: TechnicalReportInput
  ) => {
    const inputData = {
      worksheet_id: Number(reportInputValues.worksheet_id),
      name: reportInputValues.name !== "" ? reportInputValues.name : null,
      email: reportInputValues.email,
      phone:
        reportInputValues.phone !== "" ? Number(reportInputValues.phone) : null,
      browser:
        reportInputValues.browser !== "" ? reportInputValues.browser : null,
      technical_issue: reportInputValues.technical_issue,
    };
    await technicalReportService
      .reportTechnicalIssueAPI(inputData)
      .then((response) => {
        if (response && response.data) {
          showToast(t(response.data.message), "success");
          setReportTechnicalModal(false);
        } else {
          showToast(t(response?.data?.message), "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };

  /// show Input validation messages
  const handleInputValidationErrorModal = () => {
    setInputValidationErrorModal((prev) => !prev);
    dispatch(updateBasicInfo("activeTab", "1")); // This changes the tab
  };
  const handleInputValidationErrorModalOnClose = () => {
    setInputValidationErrorModal((prev) => !prev);
  };

  // Handle second validation error modal
  const handleSecondValidationErrorModal = () => {
    setSecondValidationErrorModalOpen((prev) => !prev);
    // No tab change here
  };

  const RedAsteriskTypography = styled(Typography)({
    "& span": {
      color: "#FF0000",
    },
  });

  const RadioGroupInput: React.FC<RadioGroupInputProps> = ({
    label,
    name,
    value,
    onChange,
    error,
    id,
  }) => {
    return (
      <FormControl>
        <Typography
          variant="h6"
          component="h2"
          fontSize={11}
          color="#022E4C"
          fontWeight="bold"
          sx={{ mt: 1 }}
        >
          {label}
        </Typography>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name={name}
          value={value}
          onChange={onChange}
          sx={{ marginX: "10px" }}
          id={id}
        >
          <FormControlLabel value="yes" control={<Radio />} label="Yes" />
          <FormControlLabel value="no" control={<Radio />} label="No" />
        </RadioGroup>
        {error && (
          <Typography
            variant="h6"
            component="h2"
            fontSize={11}
            color="red"
            fontWeight="bold"
            sx={{ marginBottom: "10px" }}
          >
            {error}
          </Typography>
        )}
      </FormControl>
    );
  };

  return (
    <>
      <Box>
        <Card
          elevation={0}
          sx={{
            bgcolor: "#E6F5FF",
            border: "1px solid #0464AB80",
            borderRadius: "20px 20px",
            opacity: 1,
          }}
        >
          <Typography
            sx={{ px: 1, pt: 1, display: "flex", alignItems: "center" }}
            color="#0464AB"
            fontSize={14}
            fontWeight="bold"
          >
            <InfoIcon style={{ height: "17px" }} />
            <Box sx={{ p: "4px" }}>
              {t("B. This section concerns Adjusted Gross Income.")}
            </Box>
          </Typography>
          <RedAsteriskTypography
            sx={{ px: 1, pb: 1 }}
            color="#404040"
            fontSize={14}
          >
            <Box sx={{ pl: "30px" }}>
              {t("Adjustments may be made in this section for Self-Employment Taxes Paid, Preexisting Child Support Orders Being Paid, and Other Qualified Children Living in a Parent&apos;s Home. Enter information that applies to the parents. Follow instructions provided at numbers 42, 43, 44, and 45 below. Information entered in each section, including calculations, will display on Schedule B.")}
            </Box>
          </RedAsteriskTypography>
        </Card>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography className={style.contentHeading}>
          {t("Self-Employment Tax Adjustment")}
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 900 }}
            className="custom-table"
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}></TableCell>
                <TableCell align="center" className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_1 || t("ParentName")}
                  </Box>
                </TableCell>
                <TableCell align="center" className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_2 || t("ParentName")}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={style.firstColumnStyle}
                >
                  <Box>
                    {t("42. Enter monthly Self-Employment Income on which a parent paid Self-Employment Taxes for FICA and Medicare")}
                    <CustomTooltip title={t("Enter monthly self-employment income on which the parent paid self-employment taxes for FICA and Medicare. This amount can be obtained from line 4 of Schedule SE of the parent?s federal income tax return for the previous year. If the parent reported less than $400 self-employment income that tax year, do not enter amount here.")}>
                      <img
                        height={13}
                        src={interrogation}
                        alt="Info"
                        style={{ marginLeft: "5px", verticalAlign: "middle" }}
                      />
                    </CustomTooltip>
                  </Box>
                </TableCell>
                <TableCell align="center" className={style.cellStyle}>
                  <CurrencyValue
                    value={workSheetStates.monthly_self_employment_income1}
                    onChange={handleDropdownChange}
                    type={"number"}
                    name="monthly_self_employment_income1"
                    backgroundColor="#FDFFA6"
                    onBlur={uninsuredInputHandle}
                  />
                </TableCell>
                <TableCell align="center" className={style.cellStyle}>
                  <CurrencyValue
                    value={workSheetStates.monthly_self_employment_income2}
                    onChange={handleDropdownChange}
                    type={"number"}
                    name="monthly_self_employment_income2"
                    backgroundColor="#FDFFA6"
                    onBlur={uninsuredInputHandle}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography className={style.contentHeading}>
          {t("Adjustment for Preexisting Child Support Orders Being Paid for Other Children")}
        </Typography>
      </Box>
      <Box>
        <Typography
          mt={2}
          p={2}
          sx={{ borderRadius: "5px", border: "1px solid #DFDFDF" }}
        >
          <Box display={"flex"} fontWeight={"bold"}>
            <input
              type="checkbox"
              name="isAdjustmentAcknowledgeCheck"
              onChange={handleCheckboxChange}
              style={{ marginRight: "5px" }}
              checked={workSheetStates.isAdjustmentAcknowledgeCheck}
            />
            {t("Acknowledge that you understand the requirements of a preexisting child support order by reviewing and clicking the checkbox:")}
          </Box>
          <Box px={2} mt={1}>
            <ul>
              <li>
                {t("A Preexisting Order is a child support order in a different case that requires a parent to make child support payments.")}
              </li>
              <li>
                {t("The parent must actually be making those child support payments.")}
              </li>
              <li>
                {t("The date of filing of that order must be earlier than the date of filing of the order in this case. See O.C.G.A. § 19-6-15(a)(18).")}
              </li>
            </ul>
          </Box>
          <Box mt={1}>
            {t("Enter information on Preexisting Orders, the child(ren) included in those orders, and the average monthly child support actually paid, excluding arrears payments. Select 43(a) to add a Preexisting Child Support Order or 43(b) to Edit/Remove a Preexisting Child Support Order.")}
            <CustomTooltip title={t("Current support actually paid must be averaged over the time period from the entry of the order, if entered within the last 12 months. If the preexisting order has been in effect more than 12 months, the amount paid over the past 12 months must be averaged. The averaged sum is entered as the amount paid by a parent.")}>
              <img
                height={13}
                src={interrogation}
                alt="Info"
                style={{ marginLeft: "5px", verticalAlign: "middle" }}
              />
            </CustomTooltip>
          </Box>
        </Typography>
      </Box>
      <Box mt={2} textAlign={"right"}>
        <Button
          className={style.globalButtonStyle}
          onClick={handleFirstModalOpen}
          variant="outlined"
          disabled={!workSheetStates.isAdjustmentAcknowledgeCheck}
          sx={{
            color: !workSheetStates.isAdjustmentAcknowledgeCheck
              ? "#DFDFDF!important"
              : "",
            borderColor: !workSheetStates.isAdjustmentAcknowledgeCheck
              ? "#DFDFDF!important"
              : "",
            cursor: !workSheetStates.isAdjustmentAcknowledgeCheck
              ? "not-allowed!important"
              : "",
          }}
        >
          {t("43(a).Add A Preexisting Child Support Order")}
        </Button>
        <CustomModal
          open={firstModalOpen}
          onClose={setModalDataEmpty}
          actionButtonLabel={t("Save")}
          title={
            isEdit
              ? t("43(a). Edit a Preexisting Order")
              : t("43(a). Add a Preexisting Order")
          }
          save={addPreexistingOrder}
        >
          <Typography
            variant="h6"
            component="h2"
            color="#022E4C"
            fontWeight="bold"
          >
            {t("SECTION 1. Preexisting Child Support Order")}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {t("A Preexisting Order is a child support order in another case that requires a parent to make child support payments. The parent must actually be making those current support payments. Also, the date of filing of that order, when filed with the clerk of court, must be earlier than the date of filing of the order in the case immediately before the court. See O.C.G.A. § 19-6-15(a)(18). Any order entered here that does not meet this definition may not be allowed by the court.")}
          </Typography>

          <Grid container spacing={2} mb={2} mt={0.5}>
            <Grid item xs={12} md={6}>
              <Box>
                <CustomInput
                  onChange={handleAddModalDataChange}
                  value={addModalData.court_name}
                  label={t("Court Name")}
                  name="court_name"
                  type="text"
                  placeholder={t("Enter Court Name")}
                  error={t(formPreExistingErrors.court_name)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box>
                <CustomInput
                  onChange={handleAddModalDataChange}
                  value={addModalData.civil_action_case_number}
                  label={t("Civil Action Case Number")}
                  name="civil_action_case_number"
                  type="text"
                  placeholder={t("Enter Civil Action Case Number")}
                  error={t(formPreExistingErrors.civil_action_case_number)}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <CustomInput
                  onChange={handleAddModalDataChange}
                  value={addModalData.children_names_years_of_birth}
                  label={t("Children's Names/Years of Birth Use name, 2006; name, 2013")}
                  name="children_names_years_of_birth"
                  type="text"
                  placeholder={t("Enter Children's Names")}
                  error={t(formPreExistingErrors.children_names_years_of_birth)}
                  required
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <CustomInput
                  onChange={handleAddModalDataChange}
                  value={addModalData.date_of_initial_order}
                  label={t("Date of Initial Order Use Date Format mm/dd/yyyy")}
                  name="date_of_initial_order"
                  type="text"
                  placeholder={t("Enter Date of Initial Order")}
                  error={t(formPreExistingErrors.date_of_initial_order)}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box>
                <CustomInput
                  onChange={handleAddModalDataChange}
                  value={addModalData.time_of_initial_order}
                  label={t("Time of Initial Order Use Time Format hh:mmam or hh:mmpm")}
                  name="time_of_initial_order"
                  type="text"
                  placeholder={t("Enter Time of Initial Order")}
                  error={t(formPreExistingErrors.time_of_initial_order)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <CustomInput
                  onChange={handleAddModalDataChange}
                  value={addModalData.preexisting_child_support_paid_monthly1}
                  label={t("Preexisting Current Child Support Paid Monthly by {{parentName}}", {
                    parentName: workSheetStates.parent_name_1,
                  })}
                  name="preexisting_child_support_paid_monthly1"
                  type="number"
                  placeholder=" 0.00"
                  startAdornment={<Box paddingTop={"1px"}>$</Box>}
                  error={
                    t(formPreExistingErrors.preexisting_child_support_paid_monthly1)
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <CustomInput
                  onChange={handleAddModalDataChange}
                  value={addModalData.preexisting_child_support_paid_monthly2}
                  label={t("Preexisting Current Child Support Paid Monthly by {{parentName}}", {
                    parentName: workSheetStates.parent_name_2,
                  })}
                  name="preexisting_child_support_paid_monthly2"
                  type="number"
                  placeholder=" 0.00"
                  startAdornment={<Box paddingTop={"1px"}>$</Box>}
                  error={
                    t(formPreExistingErrors.preexisting_child_support_paid_monthly1)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </CustomModal>
      </Box>

      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <div className={style.tableWrapper}>
            <Table
              sx={{ minWidth: 900 }}
              className={style.customTable}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={style.headerStyle}
                    style={{ minWidth: "90px" }}
                  >
                    <Box>{t("Court Name")}</Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={style.headerStyle}
                    style={{ minWidth: "90px" }}
                  >
                    <Box>{t("Civil Action Case Number")}</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>{t("Child Name/Birth Year")}</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>{t("Date/Time of Initial Order")}</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>{t("Preexisting Child Support Amount Paid by")}</Box>
                    <Box>{workSheetStates.parent_name_1}</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>{t("Preexisting Child Support Amount Paid by")}</Box>
                    <Box>{workSheetStates.parent_name_2}</Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={style.headerStyle}
                    style={{ minWidth: "90px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {t("Action")}
                      <CustomTooltip title={t("You Must Add At Least One Preexisting Child Support Order Before You Can Edit An Order")}>
                        <Box
                          component="img"
                          height={13}
                          src={interrogation}
                          alt="Info"
                          sx={{ marginLeft: "5px", verticalAlign: "middle" }}
                        />
                      </CustomTooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              {workSheetStates.pre_existing_child_support &&
                workSheetStates.pre_existing_child_support.map(
                  (item: PreexistingSupport, i: number) => (
                    <TableBody key={i}>
                      <TableRow className="WorkFormB">
                        <TableCell
                          component="th"
                          className={style.cellStyle}
                          scope="row"
                          width={"10%"}
                        >
                          {item.court_name}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {item.civil_action_case_number}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={style.cellStyle}
                          style={{
                            minWidth: "130px",
                            maxWidth: "130px!important",
                            width: "130px!important",

                            wordBreak: "break-word",
                          }}
                        >
                          {item.children_names_years_of_birth}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {item.date_of_initial_order}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {item.preexisting_child_support_paid_monthly1}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {item.preexisting_child_support_paid_monthly2}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <IconButton
                              onClick={() => editPreexistingDataHandler(item)}
                            >
                              <EditIcon height={13} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                deletChildDataModalOpen(item.id!, "first")
                              }
                            >
                              <DeleteIcon height={13} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                )}
              <TableBody>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600" }}
                  className={style.headerStyle2}
                  colSpan={4}
                >
                  {t("Total:")}
                </TableCell>
                <TableCell
                  align="center"
                  className={style.headerStyle2}
                  style={{ maxWidth: "50px", background: "#7FDB75" }}
                >
                  <CurrencyValue
                    value={
                      workSheetStates.pre_existing_child_support?.reduce(
                        (acc, item) => {
                          return (
                            acc +
                            Number(item.preexisting_child_support_paid_monthly1)
                          );
                        },
                        0
                      ) || 0
                    }
                    name=""
                    onChange={() => { }}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className={style.headerStyle2}
                  style={{ maxWidth: "50px", background: "#7FDB75" }}
                >
                  <CurrencyValue
                    value={
                      workSheetStates.pre_existing_child_support?.reduce(
                        (acc, item) => {
                          return (
                            acc +
                            Number(item.preexisting_child_support_paid_monthly2)
                          );
                        },
                        0
                      ) || 0
                    }
                    name=""
                    onChange={() => { }}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className={style.headerStyle2}
                ></TableCell>
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography className={style.contentHeading}>
          {t("Discretionary Adjustment to Income for Other Qualified Children Living in Parent&apos;s Home")}
        </Typography>
      </Box>
      <Box
        fontSize={12}
        sx={{
          mt: 2,
          background: "#F8F8F8",
          padding: "13px",
          border: "1px solid #DFDFDF",
          fontSize: "14px",
          borderRadius: "5px",
        }}
      >
        <Typography>
          {t("The Court has the discretion to consider an Adjustment to Income for Other Qualified Children to reduce a parent&apos;s gross income, if failure to consider an adjustment would cause substantial hardship to the parent. If the Court considers this Adjustment to Income, the Court must also consider whether this adjustment is in the best interest of the child(ren) in the case. An adjustment may be considered only for children who meet ALL FIVE of the following requirements:")}
        </Typography>
        <Typography mt={2}>
          {t("A. The parent is legally responsible for the qualified child (Stepchildren do not qualify);")}
        </Typography>
        <Typography>
          {" "}
          {t("B. The qualified child lives in the parent&apos;s home;")}
        </Typography>
        <Typography>
          {t("C. The parent is actually supporting the qualified child;")}
        </Typography>
        <Typography>
          {t("D. The qualified child is not subject to a preexisting child support order; and")}{" "}
        </Typography>
        <Typography>
          {t("E. The qualified child is not currently before the court to set, modify or enforce child support.")}
        </Typography>
      </Box>

      <Box>
        <Typography
          mt={2}
          p={2}
          sx={{ borderRadius: "5px", border: "1px solid #DFDFDF" }}
        >
          {t("Enter information on Other Qualified Children Living in a Parent&apos;s Home. Select 44(a) to add Other Qualified Children or 44(b) to Edit/Remove Other Qualified Children.")}
        </Typography>
      </Box>
      <Box mt={2} textAlign={"right"}>
        <Button
          className={style.globalButtonStyle}
          onClick={handleSecondModalOpen}
          variant="outlined"
        >
          {t("44(a). Add a Proposed Adjustment to Income for Other Qualified Children Living in the Parent&apos;s Home")}
        </Button>
        <CustomModal
          open={secondModalOpen}
          onClose={setSecondModalDataEmpty}
          actionButtonLabel={t("Save")}
          save={addProposedAdjustmentIncomeOtherHandler}
          title={
            isEdit
              ? t("44(a). Edit a Proposed Adjustment to Income for Other Qualified Children Living in Parent’s Home")
              : t("44(a). Add a Proposed Adjustment to Income for Other Qualified Children Living in Parent’s Home")
          }
        >
          <Typography
            variant="h6"
            component="h2"
            color="#022E4C"
            fontWeight="bold"
          >
            {t("SECTION 1. Required Responses")}
          </Typography>
          <Typography sx={{ mt: 1 }}>
            {t("The Court has the discretion to consider an Adjustment to Income for Other Qualified Children to reduce a parent’s gross income, if failure to consider an adjustment would cause substantial hardship to the parent. If the Court considers this Adjustment to Income, the Court must also consider whether this adjustment is in the best interest of the child(ren) in the case. An adjustment may be considered only for children who meet ALL FIVE of the following requirements:")}
          </Typography>
          <Box>
            <Typography mt={2}>
              {t("A. The parent is legally responsible for the qualified child (Stepchildren do not qualify);")}
            </Typography>
            <Typography>
              {t("B. The qualified child lives in the parent&apos;s home;")}
            </Typography>
            <Typography>
              {t("C. The parent is actually supporting the qualified child;")}
            </Typography>
            <Typography>
              {t("D. The qualified child is not subject to a preexisting child support order; and")}
            </Typography>
            <Typography>
              {t("E. The qualified child is not currently before the court to set, modify or enforce child support.")}
            </Typography>
          </Box>
          <Typography sx={{ mt: 2 }}>
            {t("Respond to the five requirements in Section 1 by selecting the “yes” or “no” radio button and the response that applies to the child under consideration as a qualified child. See O.C.G.A. § 19-6-15(a)(20). If the adjustment is not allowed, based on the responses to the five requirements set forth in the statute, an error message will display. Any child who does not meet the definition may not be included by the court in this adjustment. Complete the three fields in Section 2 and Save.")}
          </Typography>
          {/* Radio button Section  */}
          <Box sx={{ mt: 1 }}>
            <Box>
              <RadioGroupInput
                label={t("The parent is legally responsible for the qualified child (Stepchildren do not qualify)")}
                name="legally_responsible_for_child"
                value={
                  addProposedAdjustmentIncomeOther.legally_responsible_for_child
                }
                onChange={(e) => {
                  handleProposedAdjustmentIncomeOther(e);
                }}
                error={
                  t(proposedAdjustmentIncomeOtherErrors.legally_responsible_for_child)
                }
                id="radio"
              />
            </Box>
            <Box>
              <RadioGroupInput
                label={t("The qualified child lives in the parent's home")}
                name="child_lives_in_parents_home"
                value={
                  addProposedAdjustmentIncomeOther.child_lives_in_parents_home
                }
                error={
                  t(proposedAdjustmentIncomeOtherErrors.child_lives_in_parents_home)
                }
                onChange={handleProposedAdjustmentIncomeOther}
              />
            </Box>
            <Box>
              <RadioGroupInput
                label={t("The parent is actually supporting the qualified child")}
                name="parent_actually_supporting_child"
                value={
                  addProposedAdjustmentIncomeOther.parent_actually_supporting_child
                }
                error={
                  t(proposedAdjustmentIncomeOtherErrors.parent_actually_supporting_child)
                }
                onChange={handleProposedAdjustmentIncomeOther}
              />
            </Box>
            <Box>
              <RadioGroupInput
                label={t("The qualified child is not currently before the court to set, modify or enforce child support")}
                name="child_not_before_court"
                value={addProposedAdjustmentIncomeOther.child_not_before_court}
                error={
                  t(proposedAdjustmentIncomeOtherErrors.child_not_before_court)
                }
                onChange={handleProposedAdjustmentIncomeOther}
              />
            </Box>
            <Box>
              <RadioGroupInput
                label={t("The qualified child is not subject to a pre-existing child support order")}
                name="child_not_subject_to_support_order"
                value={
                  addProposedAdjustmentIncomeOther.child_not_subject_to_support_order
                }
                error={
                  t(proposedAdjustmentIncomeOtherErrors.child_not_subject_to_support_order)
                }
                onChange={handleProposedAdjustmentIncomeOther}
              />
            </Box>
          </Box>
          <Typography
            variant="h6"
            component="h2"
            color="#022E4C"
            fontWeight="bold"
            sx={{ mt: 1 }}
          >
            {t("SECTION 2. Request Adjustment to Income")}
          </Typography>
          <Grid container spacing={2} mb={2} mt={2}>
            <Grid item xs={12} md={6} sx={{ paddingTop: "0px!important" }}>
              <Box mb={2}>
                <CustomInput
                  onChange={handleProposedAdjustmentIncomeOther}
                  value={addProposedAdjustmentIncomeOther.child_name}
                  label={t("Child Name ")}
                  required={true}
                  name="child_name"
                  type="textarea"
                  placeholder={t("Enter Child Name")}
                  error={t(proposedAdjustmentIncomeOtherErrors.child_name)}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              paddingTop={0}
              sx={{ paddingTop: "0px!important" }}
            >
              <Box mb={2}>
                <CustomDropdown
                  name="year_of_birth"
                  label={t("Year of Birth")}
                  required={true}
                  value={addProposedAdjustmentIncomeOther.year_of_birth}
                  onChange={handleProposedAdjustmentIncomeOther}
                  options={years}
                  placeholder={t("- Choose Year of Birth -")}
                  error={t(proposedAdjustmentIncomeOtherErrors.year_of_birth)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} sx={{ paddingTop: "0px!important" }}>
              <Box>
                <CustomDropdown
                  name="adjustment_requested_by"
                  label={t("Adjustment Requested By")}
                  required={true}
                  value={
                    addProposedAdjustmentIncomeOther.adjustment_requested_by
                  }
                  onChange={handleProposedAdjustmentIncomeOther}
                  options={adjustmentRequestedByDropDownData}
                  placeholder={t("- Choose Who Requested -")}
                  error={
                    t(proposedAdjustmentIncomeOtherErrors.adjustment_requested_by)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </CustomModal>
      </Box>
      {/*--------------- Table 3------------- */}
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <div className={style.tableWrapper}>
            <Table
              sx={{ minWidth: 900 }}
              className={style.customTable}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>{t("Child Name")}</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>{t("Birth Year")}</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>
                      {workSheetStates.parent_name_1
                        ? workSheetStates.parent_name_1
                        : "Parent Name 1"}
                    </Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>
                      {workSheetStates.parent_name_2
                        ? workSheetStates.parent_name_2
                        : "Parent Name 2"}
                    </Box>
                  </TableCell>

                  <TableCell
                    align="center"
                    className={style.headerStyle}
                    sx={{ minWidth: "70px", width: "90px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Action
                      <CustomTooltip title={t("You Must Add At Least One Adjustment Before You Can Edit An Adjustment")}>
                        <Box
                          component="img"
                          height={13}
                          src={interrogation}
                          alt="Info"
                          sx={{ marginLeft: "5px", verticalAlign: "middle" }}
                        />
                      </CustomTooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workSheetStates.proposed_adjustment_for_qualified_child &&
                  workSheetStates.proposed_adjustment_for_qualified_child.map(
                    (item: Adjustment, i: number) => (
                      <TableRow className="WorkFormB" key={i}>
                        <TableCell
                          component="th"
                          className={style.cellStyle}
                          scope="row"
                        >
                          {item.child_name}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {item.year_of_birth}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {item.adjustment_requested_by ===
                            workSheetStates.parent_name_1
                            ? "1"
                            : ""}
                        </TableCell>

                        <TableCell align="center" className={style.cellStyle}>
                          {item.adjustment_requested_by ===
                            workSheetStates.parent_name_2
                            ? "1"
                            : ""}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <IconButton
                              onClick={() =>
                                editProposedAdjustmentIncomeHandler(item)
                              }
                            >
                              <EditIcon height={13} />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon
                                height={13}
                                onClick={() =>
                                  deletChildDataModalOpen(item.id!, "second")
                                }
                              />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
              <TableBody>
                <TableCell
                  align="center"
                  style={{ fontWeight: "600" }}
                  className={style.headerStyle2}
                  colSpan={2}
                >
                  {t("Total:")}
                </TableCell>
                <TableCell
                  align="center"
                  className={style.headerStyle2}
                  style={{ maxWidth: "50px", background: "#7FDB75" }}
                >
                  <CustomInput
                    value={
                      workSheetStates.proposed_adjustment_for_qualified_child?.reduce(
                        (acc, item) => {
                          return acc + Number(item.parentName1);
                        },
                        0
                      ) || 0
                    }
                    name=""
                    placeholder=""
                    onChange={() => { }}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>
                <TableCell
                  align="center"
                  className={style.headerStyle2}
                  style={{ maxWidth: "50px", background: "#7FDB75" }}
                >
                  <CustomInput
                    value={
                      workSheetStates.proposed_adjustment_for_qualified_child?.reduce(
                        (acc, item) => {
                          return acc + Number(item.parentName2);
                        },
                        0
                      ) || 0
                    }
                    name=""
                    placeholder=""
                    onChange={() => { }}
                    backgroundColor="#CFFAC9"
                    disabled
                  />
                </TableCell>

                <TableCell
                  align="center"
                  className={style.headerStyle2}
                ></TableCell>
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Box>

      <Box>
        <Typography
          mt={2}
          p={2}
          sx={{ borderRadius: "5px", border: "1px solid #DFDFDF" }}
        >
          {t("45. Each parent claiming a qualified child must enter a comment in the appropriate box below explaining why the parent has included an Other Qualified Child in the worksheet.")}
        </Typography>
      </Box>
      <Box mt={2} sx={{ minHeight: "165px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box>
              <CustomInput
                onChange={() => { }}
                value={workSheetStates.parent_name_1}
                label={"Parent Name"}
                name="ParentName"
                type="textarea"
                placeholder={t("ParentName")}
                disabled
              />
            </Box>
            <Box mt={2}>
              <TextareaField
                name="reason_for_including_other_child1"
                minRows={2}
                value={workSheetStates.reason_for_including_other_child1}
                onChange={handleDropdownChange}
                placeholder=""
                backgroundColor="#FDFFA6"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <CustomInput
                onChange={() => { }}
                value={workSheetStates.parent_name_2}
                label="Parent Name"
                name="ParentName"
                type="textarea"
                placeholder={t("ParentName")}
                disabled
              />
            </Box>
            <Box mt={2}>
              <TextareaField
                name="reason_for_including_other_child2"
                minRows={2}
                value={workSheetStates.reason_for_including_other_child2}
                onChange={handleDropdownChange}
                placeholder=""
                backgroundColor="#FDFFA6"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorksheetButtons />
      </Box>
      <Grid item xs={12} md={12}>
        <Grid
          sx={{
            mb: 0,
            borderTop: "2px solid #DFDFDF",
            py: 2,
            px: 1,
            textDecoration: "underline",
            cursor: "pointer",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <img src={SettingIcons} alt="img" style={{ height: "15px" }} />
            <Link
              onClick={handleReportModalOpen}
              sx={{
                marginLeft: "5px",
                color: "#147AFF",
                textDecoration: "underline",
              }}
            >
              {t("Report Technical Issue")}
            </Link>

            <CustomModal
              open={reportTechnicalModal}
              onClose={handleReportModalClose}
              actionButtonLabel={t("Save")}
              title="Report Technical Issue"
              save={handleSaveData}
              borderBottom="0px"
              pb="0px"
            >
              <ReportTechnicalForm
                ref={formRef}
                inputCaptcha={inputCaptcha}
                handleSubmitTechnicalReport={handleSubmitTechnicalReport}
                states={workSheetStates}
              />
            </CustomModal>
          </Box>
          <Box sx={{ p: { xs: 2, md: 1 } }}>
            <Button
              className={style.globalButtonStyle}
              variant="outlined"
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "2"));
              }}
              sx={{ marginX: 2 }}
            >
              <span style={{ padding: "0 4px" }}>{"<"}</span> {t("Back Step")}
            </Button>
            <Button
              variant="contained"
              className={style.globalContainedButton}
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "4"));
              }}
            >
              {t("Next Step")} <span style={{ padding: "0 4px" }}>{">"}</span>
            </Button>
          </Box>
        </Grid>

        <ValidationErrorModal
          onClose={handleSecondValidationErrorModal}
          onCancel={handleSecondValidationErrorModal}
          open={secondValidationErrorModalOpen}
          title={t("octestphp.georgiacourts.gov says")}
          descriptionMessage={inputValidationMessage}
        />
        <ValidationErrorModal
          onCancel={handleInputValidationErrorModalOnClose}
          onClose={handleInputValidationErrorModal}
          open={inputValidationErrorModal}
          title={t("octestphp.georgiacourts.gov says")}
          descriptionMessage={inputValidationMessage}
        />
        <DeleteModal
          onClose={handleDeleteMdlClose}
          open={deleteModal}
          delete={deletePreexistingChildDataHandler}
          title={t("Delete Adjustment Records")}
          deleteMessage={t("Are you sure you want permanently remove this preexisting record from the system?")}
        />
      </Grid>
    </>
  );
};

export default AdjustedIncomeTab;
