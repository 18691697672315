import axios from "axios";
import { showToast } from "../../components/toastNotification";
import i18next from "i18next";



///API Base Url
const baseUrl = window.location.hostname.includes("uat")
  ? "https://childsp-api-uat.brainvire.net/"
  : "https://childsp-api-dev.brainvire.net/";

export const httpCommon = axios.create({
  baseURL: baseUrl,
});

export const httpCommonFileDownload = axios.create({
  baseURL: baseUrl,
  responseType: "blob",
});
export const httpTokenFileDownload = axios.create({
  baseURL: baseUrl,
  responseType: "blob",
});

export const httpTokenCommon = axios.create({
  baseURL: baseUrl,
});


httpTokenCommon.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
httpTokenFileDownload.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/// Interceptors for without token API
httpCommon.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 400) {
      if (error.response.data.message) {
        showToast(error.response.data.message, "error");
      }
      if (error.response.data.error) {
        showToast(error.response.data.error, "error");
      }
    }
    if (error.response && error.response.status === 401) {
      showToast(error.response.data.message, "error");
    }
    if (error.response && error.response.status === 404) {
      showToast(error.response.data.message, "error");
    }
    if (error.response && error.response.status === 500) {
      showToast(
        i18next.t("Server is currently unavailable. Please try again later."),
        "error"
      );
    }
    return Promise.reject(error);
  }
);
/// Interceptors for with token API
httpTokenCommon.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 400) {
      if (error.response.data.message) {
        showToast(error.response.data.message, "error");
      }
      if (error.response.data.error) {
        showToast(error.response.data.error, "error");
      }
    }
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    if (error.response && error.response.status === 403) {
      if (error.response.data.message) {
        showToast(error.response.data.message, "error");
        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/";
        }, 500);
      }
    }
    if (error.response && error.response.status === 404) {
      showToast(error.response.data.message, "error");
    }
    if (error.response && error.response.status === 500) {
      showToast(
        i18next.t("Server is currently unavailable. Please try again later."),
        "error"
      );
    }
    return Promise.reject(error);
  }
);
