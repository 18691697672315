import React, { ChangeEvent } from "react";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
} from "@mui/material";
import CustomTooltip from "../tooltip";
import interrogation from "../../assets/images/interrogation.svg";


interface RadioGroupProps {
  name: string;
  label?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: { value: string; label: string }[];
  required?: boolean;
  tooltip?: string;
  color?: string;
}

const CustomRadioGroup: React.FC<RadioGroupProps> = ({
  name,
  label,
  value,
  onChange,
  options,
  required,
  tooltip,
  color
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          mb: 1,
          fontWeight: "medium",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",

        }}
      >
        {label} {required && <span style={{ color: "red" }}>*</span>}{" "}
        {tooltip && (
          <CustomTooltip title={tooltip}>
            <img
              src={interrogation}
              alt="help icon"
              height={13}
              style={{ marginLeft: "5px" }}
            />
          </CustomTooltip>
        )}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-labelledby={label}
          name={name}
          value={value}
          onChange={onChange}
          sx={{ color: color, ml: 0.5 }}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={
                <Radio
                  sx={{
                    color: "#9C9BAA",
                    "&.Mui-checked": {
                      color: "blue",
                    },
                  }}
                />
              }
              label={option.label}
              sx={{ fontSize: 13, mt: 1, marginLeft: "-15px" }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CustomRadioGroup;
