import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import footerLogo from "../../assets/images/jcaoc-logo@2x.png";
import styles from "./layout.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.footerSection}>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          bottom: 0,
          bgcolor: "#404040",
          color: "white",
          textAlign: "center",
          paddingY: { md: 3, sm: 2, xs: 3 },
        }}
      >
        <Box className={styles.max_width_container}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={footerLogo}
                alt="Logo"
                style={{ height: "40px", marginRight: "10px" }}
              />
            </Box>
            <Typography
              className={styles.footerLinks}
              variant="body2"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 2,
                textAlign: "center",
              }}
            >
              <Link to="/about" className={styles.footerLink}>
                {t("About")}
              </Link>

              <Link to="/" className={styles.footerLink}>
                {t("User Guide")}
              </Link>
              <Link to="/faq" className={styles.footerLink}>
                {t("FAQ")}
              </Link>
              <Link to="/contact" className={styles.footerLink}>
                {t("Contact")}
              </Link>
              <a
                href="https://childsp-api-uat.brainvire.net/user_guide_api/child_support_statute/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footerLink}
                style={{ textDecoration: "none", borderBottom: "none" }}
              >
                {t("Child Support Statute")}
              </a>
              <Link to="/basicChildSupport" className={styles.footerLink}>
                {t("Basic Child Support Obligation Table")}
              </Link>
            </Typography>
          </Box>

          <Box
            className={styles.border_bottom}
            sx={{
              width: { lg: "80%", md: "80%", sm: "80%", xs: "90%" },
              margin: "auto",
              mt: 2,
            }}
          ></Box>
          <Typography
            variant="body2"
            fontSize={11}
            sx={{ mt: 2, color: "#F2F2F2" }}
          >
            {t("© Georgia Commission on Child Support")} {new Date().getFullYear()}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Footer;
