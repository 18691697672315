import {
  SET_HEADERLINK,
  SET_MULTILINGUAL_LANGUAGE,
} from "../actions-Types/authActionTypes";

export const headerActiveLink = (value: string) => ({
  type: SET_HEADERLINK,
  payload: value,
});
export const headerNullData = () => ({
  type: SET_HEADERLINK,
  payload: "",
});

export const setMultiLingual = (value: string) => ({
  type: SET_MULTILINGUAL_LANGUAGE,
  payload: value,
});
