import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Paper,
  Button,
  Grid,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CustomModal from "../../components/customModal";
import TextareaField from "../../components/text-area";
import styles from "./sysMessage.module.css";
import { styled } from "@mui/material/styles";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import ExportFileDropdown from "../../components/exportFileDropDown";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ExportOptionData from "../../components/exportDataSelection";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
// import DeleteModal from "../../components/globalDeleteModal";
import CustomInput from "../../components/inputField";
import systemMessageService from "../../services/system-message-service";
import DeleteModal from "../../components/globalDeleteModal";
import { showToast } from "../../components/toastNotification";
import { getFileName } from "../../utils/fileNameUtil";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import { useTranslation } from "react-i18next";

interface TableSearchData {
  description: string;
  spanMessage: string;
}

interface Column {
  id: keyof Data;
  label: string;
}

interface Data {
  id: number;
  description: string;
  spanishDescription: string;
  action: string | JSX.Element;
}
interface SystemMessageData {
  id: number;
  description: string;
}
interface formInputState {
  description: string;
}
const SystemMessage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [systemMessageData, setSystemMessageData] = useState<
    SystemMessageData[]
  >([]);
  const [tableSearchData, setTableSearchData] = useState<TableSearchData>({
    description: "",
    spanMessage: "",
  });
  const [addModalData, setAddModalData] = useState<formInputState>({
    description: "",
  });
  const [errors, setErrors] = useState<Partial<formInputState>>({});
  const [addUpdateModal, setAddUpdateModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [totalTableRecords, setTotalTableRecords] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [updateDeleteID, setUpdateDeleteID] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  }>({
    key: "number",
    direction: "ascending",
  });
  const [deleteSystemModal, setDeleteSystemModal] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const searchTime = setTimeout(() => {
      handleGetSystemMessageListData();
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [pageIndex, perPage, tableSearchData, sortConfig]);

  const handleClose = () => {
    setAddUpdateModal(false);
    setErrors({});
  };

  ///* APi calling Start */
  const handleGetSystemMessageListData = () => {
    setLoading(true);
    const tableOrdering =
      sortConfig.direction === "ascending"
        ? sortConfig.key
        : `-${sortConfig.key}`;
    const inputData = {
      pageIndex,
      perPage,
      tableSearchData,
      tableOrdering,
    };
    systemMessageService
      .getAdminSystemMessageDataAPI(inputData)
      .then(
        (response: {
          data: {
            count(count: number): number;
            results: { data: SystemMessageData[] };
          };
        }) => {
          if (response?.data.results.data?.length > 0) {
            setSystemMessageData(response?.data.results.data);
            setTotalTableRecords(response?.data.count);
          } else {
            setSystemMessageData([]);
            setTotalTableRecords(0);
          }
          setLoading(false);
        }
      )
      .catch((error) => {
        setLoading(false);
        console.log(error, "FAQs Error");
      });
  };
  const handleDeleteSystemData = () => {
    systemMessageService
      .deleteSystemDataAPI(updateDeleteID)
      .then((response) => {
        if (response && response?.data.message) {
          showToast(t("System Message deleted successfully"), "success");
          setDeleteSystemModal(false);
          handleGetSystemMessageListData();
        } else {
          showToast(t("Failed to Delete System Message"), "error");
        }
      })
      .catch((error) => {
        console.log(error, "FAQs Error");
        showToast(t("Failed to Delete System Message"), "error");
      });
  };
  const handleAddUpdateSystemModalOpen = (
    rowData: SystemMessageData | null,
    isUpdate: string
  ) => {
    if (isUpdate === "update") {
      setAddModalData({
        description: rowData?.description || "",
      });
      setUpdateDeleteID(rowData?.id || 0);
      setIsUpdateData(false);
    } else {
      setAddModalData({
        description: "",
      });
      setIsUpdateData(true);
    }

    setAddUpdateModal(true);
  };
  const formValidate = (): boolean => {
    const newErrors: Partial<formInputState> = {};

    if (!addModalData.description.trim()) {
      newErrors.description = t("English description cannot be blank");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleAddUpdateSystemMessageData = async () => {
    if (formValidate()) {
      const inputData = {
        description: addModalData.description,
      };
      try {
        let response;
        if (isUpdateData) {
          response = await systemMessageService.addAdminSystemMessageAPI(
            inputData
          );
        } else {
          response = await systemMessageService.updateAdminSystemMessageAPI(
            inputData,
            updateDeleteID
          );
        }

        if (response && response.data) {
          showToast(
            t(`System Messages ${isUpdateData ? "created" : "updated"} successfully`),
            "success"
          );
          setAddUpdateModal(false);
          handleGetSystemMessageListData();
        } else {
          showToast(
            t(`Failed to ${isUpdateData ? "add" : "update"} data`),
            "error"
          );
        }
      } catch (error) {
        console.error(error, "System Messages Operation Error");
      }
    }
  };
  const handleDownloadFAQsData = (key: string) => {
    const arrayIDs = systemMessageData.map((item) => item.id);
    systemMessageService
      .getDownloadSystemDataAPI(key, arrayIDs)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", getFileName(key, "systemMessage"));
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("FAQs Error", error);
      });
  };
  ///* APi calling End */
  const handleModalInputChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | SelectChangeEvent
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    let formattedValue = value;
    formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setAddModalData((prev) => {
      return {
        ...prev,
        [name]: formattedValue,
      };
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const TableColumns: Column[] = [
    { id: "id", label: "#" },
    { id: "description", label: t("English Description") },
    { id: "spanishDescription", label: t("Spanish Description") },
    { id: "action", label: t("Action") },
  ];

  const DropdownChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    handleDownloadFAQsData(event.target.value);
  };

  const OptionData = ExportOptionData(selectedValue);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(Number(event.target.value));
    setPageIndex(1);
  };
  const searchInputOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTableSearchData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPageIndex(1);
    setPerPage(10);
  };
  const handlePageResetGrid = () => {
    setTableSearchData({ description: "", spanMessage: "" });
    setSelectedValue("");
    setPageIndex(1);
    setPerPage(10);
  };

  const handleSort = (columnId: keyof Data) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: columnId, direction });
  };
  const handleDeleteSystemMdlOpen = (id: number) => {
    setUpdateDeleteID(id);
    setDeleteSystemModal(true);
  };
  const handleDeleteSystemMdlClose = () => {
    setDeleteSystemModal(false);
  };
  const sortedRows = React.useMemo(() => {
    if (
      sortConfig !== null &&
      sortConfig.key !== null &&
      sortConfig.key !== undefined
    ) {
      const key = sortConfig.key as keyof SystemMessageData;
      return [...systemMessageData].sort(
        (a: SystemMessageData, b: SystemMessageData) => {
          const aValue = a[key];
          const bValue = b[key];

          if (aValue == null && bValue != null)
            return sortConfig.direction === "ascending" ? -1 : 1;
          if (aValue != null && bValue == null)
            return sortConfig.direction === "ascending" ? 1 : -1;
          if (aValue == null && bValue == null) return 0;

          if (aValue !== null && bValue !== null) {
            if (aValue < bValue) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (aValue > bValue) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        }
      );
    }
    return systemMessageData;
  }, [systemMessageData, sortConfig]);

  const totalPages = Math.ceil(totalTableRecords / perPage);
  const startIndex = (pageIndex - 1) * perPage + 1;
  const endIndex = Math.min(pageIndex * perPage, totalTableRecords);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#404040",
      color: theme.palette.common.white,
      fontSize: "12px !important",
      fontFamily: "Inter !important",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "12px !important",
      fontFamily: "Inter !important",
    },
    border: "1px solid #DFDFDF",
  }));
  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
        className="contain__area"
      >
        <Box
          className="breadcrumbs"
          sx={{
            margin: {
              xs: "10px 0px 0px 1px",
              sm: "10px 56px 0px 1px",
              md: "30px 56px 0px 56px",
            },
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handleHomeRedirection}
              >
                {t("Home")}
              </Link>
              <Link to="system-administration/systemMessage" className="LinkColorDark">
                {t("System Message")}
              </Link>

            </Breadcrumbs>
          </div>
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "5px",
              border: "1px solid #DFDFDF",
              margin: { md: "10px 56px" },
            }}
          >
            <Box
              display={{ md: "flex" }}
              justifyContent={{
                xs: "center",
                sm: "space-between",
                md: "space-between",
                lg: "space-between",
              }}
              flexWrap="wrap"
            >
              <Box>
                <Typography
                  m={2}
                  variant="h5"
                  className="h5"
                  color="#0464AB;"
                  gutterBottom
                  sx={{
                    fontSize: 25,
                    letterSpacing: "1px",
                    marginBottom: "1px",
                  }}
                >
                  {t("System Message")}
                </Typography>
              </Box>
              <Box m={2} textAlign={"center"}>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: "20px",
                    width: { lg: "fit-content", md: 150 },
                    fontWeight: "600",
                    textTransform: "none",
                    backgroundColor: "#0464AB",
                    "&:hover": {
                      backgroundColor: "#FBB522",
                      color: "#022E4C",
                      fontWeight: "600",
                    },
                  }}
                  onClick={() => handleAddUpdateSystemModalOpen(null, "Add")}
                >
                  {t("Create Message")}
                </Button>
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent={{
                md: "space-between",
                sm: "space-between",
                xs: "center",
              }}
              flexWrap="wrap"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                mx={2}
                marginLeft={{ xs: "30%", sm: "2%", md: "1.5%" }}
                flexWrap="wrap"
              >
                <Grid
                  container
                  alignItems={{ xs: "center" }}
                  justifyContent={{ xs: "center" }}
                  spacing={1}
                >
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography variant="body1">
                      {t("Showing")}{" "}
                      <strong>
                        {startIndex}-{endIndex}
                      </strong>{" "}
                      {t("of")} <strong>{totalTableRecords}</strong> {t("items.")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" mr={1}>
                        {t("Show")}
                      </Typography>
                      <Select
                        value={perPage}
                        onChange={handleRowsPerPageChange}
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "50px" }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <Typography variant="body1" ml={1}>
                        {t("Entries")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box mt={1} mr={3.8} ml={1}>
                <Grid
                  display={"flex"}
                  alignItems="center"
                  justifyContent="end"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="outlined"
                      className={styles.resetButtoncss}
                      onClick={handlePageResetGrid}
                      sx={{
                        height: "35px",
                        width: {
                          lg: "max-content",
                          md: "135px",
                          sm: "135px",
                          xs: "fit-content",
                        },

                        fontSize: {
                          md: "12px",
                          sm: "12px",
                          xs: "9px",
                        },
                        textTransform: "none",
                      }}
                    >
                      <ReplayOutlinedIcon sx={{ marginRight: "2px" }} />
                      {t("Reset Grid")}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    paddingLeft={{ xs: "5px!important", md: "10px!important" }}
                  >
                    <Box>
                      <ExportFileDropdown
                        name="dropdown"
                        label=""
                        value={selectedValue}
                        onChange={DropdownChange}
                        options={OptionData.slice(1)}
                        placeholder={
                          <div style={{ display: "flex", alignItems: "left" }}>
                            <FileExporticon
                              className={
                                selectedValue === "Export"
                                  ? styles.activeSVG
                                  : styles.inactiveSVG
                              }
                            />
                            <Typography
                              sx={{
                                marginLeft: { md: "6px", sm: "6px", xs: "0px" },
                              }}
                            >
                              {OptionData[0].label}
                            </Typography>
                          </div>
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box mt={1}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 700 }}
                  stickyHeader
                  aria-label="sticky table"
                  className={styles.systemTable}
                >
                  <TableHead>
                    <TableRow>
                      {TableColumns.map((data, index) => (
                        <StyledTableCell
                          key={data.id}
                          className={
                            index === TableColumns.length - 1
                              ? styles.sticky
                              : ""
                          }
                        >
                          <Box display="flex" alignItems="center">
                            {data.label}
                            {index !== 0 && data.id !== "action" && (
                              <IconButton
                                onClick={() => handleSort(data.id)}
                                size="small"
                                color="inherit"
                              >
                                {sortConfig.key === data.id ? (
                                  sortConfig.direction === "ascending" ? (
                                    <ArrowUpwardIcon fontSize="inherit" />
                                  ) : (
                                    <ArrowDownwardIcon fontSize="inherit" />
                                  )
                                ) : (
                                  <ArrowUpwardIcon fontSize="inherit" />
                                )}
                              </IconButton>
                            )}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {TableColumns.map((item, index) => (
                        <TableCell
                          sx={{ border: "1px solid #DFDFDF" }}
                          key={item.id}
                          className={
                            index === TableColumns.length - 1
                              ? styles.sticky
                              : ""
                          }
                        >
                          {index !== 0 && index !== TableColumns.length - 1 && (
                            <CustomInput
                              key={`search-${item.id}`}
                              name={item.id}
                              disabled={
                                index === 2 || index === 4 ? true : false
                              }
                              value={
                                tableSearchData[
                                item.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputOnchange}
                              placeholder={t("Search")}
                              endAdornment={<SearchOutlinedIcon />}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={TableColumns.length}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            padding={2}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {sortedRows.map((row, i) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            {(pageIndex - 1) * perPage + i + 1}
                          </TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell>{"(Not Create configured)"}</TableCell>
                          <TableCell
                            sx={{
                              position: "sticky",
                              zIndex: 1,
                              right: 0,
                              backgroundColor: "#fff",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <IconButton>
                                <EditIcon
                                  onClick={() =>
                                    handleAddUpdateSystemModalOpen(
                                      row,
                                      "update"
                                    )
                                  }
                                  height={13}
                                />
                              </IconButton>
                              <IconButton>
                                <DeleteIcon
                                  onClick={() =>
                                    handleDeleteSystemMdlOpen(row.id)
                                  }
                                  height={13}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Box
                my={1}
                display="flex"
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "center",
                }}
                flexWrap="wrap"
              >
                <Box>
                  <Typography sx={{ marginX: "20px" }} variant="body1">
                    {t("Showing")}{" "}
                    <strong>
                      {startIndex}-{endIndex}
                    </strong>{" "}
                    {t("of")} <strong>{totalTableRecords}</strong> {t("items.")}
                  </Typography>
                </Box>
                <Box>
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={pageIndex}
                    onChange={handlePageChange}
                  />
                </Box>
              </Box>
            </Box>
            <CustomModal
              open={addUpdateModal}
              onClose={handleClose}
              actionButtonLabel={isUpdateData ? t("Create") : t("Update")}
              title={`${isUpdateData ? t("Create") : t("Update")} ${t("System Message")}`}
              save={handleAddUpdateSystemMessageData}
            >
              <Grid container spacing={2} mb={2} mt={1}>
                <Grid item xs={12} md={12}>
                  <Typography fontWeight="bold" color="#022E4C">
                    {t("Section 1: English System Message")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box mb={2}>
                    <TextareaField
                      name="description"
                      label={t("Description")}
                      placeholder={t("Write Description")}
                      value={addModalData.description}
                      onChange={handleModalInputChange}
                      minRows={2}
                      required={true}
                      error={errors.description}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography fontWeight="bold" color="#022E4C">
                    {t("Section 2: Spanish System Message")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box mb={2}>
                    <TextareaField
                      name="description"
                      label={t("Description")}
                      placeholder={t("Write Description")}
                      value={""}
                      onChange={() => { }}
                      minRows={2}
                      disabled={true}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CustomModal>
          </Paper>
          <DeleteModal
            onClose={handleDeleteSystemMdlClose}
            open={deleteSystemModal}
            delete={handleDeleteSystemData}
            title={t("Delete System Message")}
          />
        </Box>
      </Box>
    </>
  );
};

export default SystemMessage;
