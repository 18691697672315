import {
  getTotalJudicialDeviation,
  getTotalProposedDeviation,
} from "../pages/worksheet/DeviationsTab/DeviationsValidationTabs";
import {
  CreateWorksheetInitialState,
  JudicialDiscretionForLowIncome,
  ProposedLowIncomeDeviation,
} from "../types/create-worksheet";
import { calculateIncomeDeviation } from "./calculateIncomeDeviation";
import { CalculateNarestChildValues } from "./calculateNarestChildValues";
import { finalCalculationForGrossAndAdjustedTabDeviation } from "./calculationForGrossAdjustedValueForDeviation";

export const CalculateFinalValue = (
  totalGrossIncome: number | undefined,
  monthlySelfEmploymentIncome: number | undefined,
  preexistingChildSupportAmount: number | undefined,
  numberOfChildren: number | undefined
): number => {
  const ficaValue = monthlySelfEmploymentIncome
    ? (Number(monthlySelfEmploymentIncome) * 0.062).toFixed(2)
    : "0";

  const line4Value = monthlySelfEmploymentIncome
    ? (Number(monthlySelfEmploymentIncome) * 0.0145).toFixed(2)
    : "0";

  const line5Value = Number(ficaValue) + Number(line4Value);

  const line6Value = totalGrossIncome
    ? Number(totalGrossIncome) - line5Value
    : 0;

  const line9Value =
    Number(line6Value) - Number(preexistingChildSupportAmount || 0);

  if (Number(numberOfChildren) > 0) {
    if (line6Value && line6Value > 0) {
      const line12Value = CalculateNarestChildValues(
        Number(line6Value),
        Number(numberOfChildren)
      );

      const line13Value = (line12Value * 75) / 100;

      const line14Value = line13Value ? line9Value - line13Value : 0;

      return line14Value;
    } else {
      return line9Value;
    }
  } else {
    return line9Value;
  }
};

/// get the final basic value for line 2 in 1st tab
export const getBasicFinalValueFor2Line = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  let FinalValue1 = 0;
  let FinalValue2 = 0;

  const ChildValues = WorkSheetData.adjusted_income_schedule_b
    ?.proposed_adjustment_for_qualified_child
    ? WorkSheetData.adjusted_income_schedule_b.proposed_adjustment_for_qualified_child.reduce(
        (
          acc: { parent1: number; parent2: number },
          item: { adjustment_requested_by: string }
        ) => {
          if (item.adjustment_requested_by === WorkSheetData.parent_name_1) {
            acc.parent1 = (acc.parent1 || 0) + 1;
          }

          if (item.adjustment_requested_by === WorkSheetData.parent_name_2) {
            acc.parent2 = (acc.parent2 || 0) + 1;
          }

          return acc;
        },
        { parent1: 0, parent2: 0 }
      )
    : { parent1: 0, parent2: 0 };

  if (
    WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income1 ||
    WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income2 ||
    WorkSheetData.adjusted_income_schedule_b
      ?.preexisting_child_support_amount1 ||
    WorkSheetData.adjusted_income_schedule_b?.preexisting_child_support_amount2
  ) {
    FinalValue1 = CalculateFinalValue(
      WorkSheetData.gross_income_schedule_a?.total_gross_income1,
      WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income1,
      WorkSheetData.adjusted_income_schedule_b
        ?.preexisting_child_support_amount1,
      ChildValues.parent1
    );

    FinalValue2 = CalculateFinalValue(
      WorkSheetData.gross_income_schedule_a?.total_gross_income2,
      WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income2,
      WorkSheetData.adjusted_income_schedule_b
        ?.preexisting_child_support_amount2,
      ChildValues.parent2
    );
  } else {
    FinalValue1 = WorkSheetData.gross_income_schedule_a?.total_gross_income1
      ? WorkSheetData.gross_income_schedule_a?.total_gross_income1
      : 0;
    FinalValue2 = WorkSheetData.gross_income_schedule_a?.total_gross_income2
      ? WorkSheetData.gross_income_schedule_a?.total_gross_income2
      : 0;
  }

  return { FinalValue1, FinalValue2 };
};

/// get the final basic value for line 6 in 1st tab
export const getBasicFinalValueFor6Line = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(WorkSheetData);

  const line1Total =
    (WorkSheetData.health_insurance_child_care_schedule_d
      ?.health_insurance_premiums_parent1 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent2 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent3) &&
    (
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          ?.health_insurance_premiums_parent1
      ) /
        12 +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          ?.health_insurance_premiums_parent2
      ) /
        12 +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          ?.health_insurance_premiums_parent3
      ) /
        12
    ).toFixed(2);

  const line2Total =
    (WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total1 ||
      WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total2 ||
      WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total3) &&
    Number(
      WorkSheetData.health_insurance_child_care_schedule_d.monthly_total1 || 0
    ) +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d.monthly_total2 || 0
      ) +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d.monthly_total3 || 0
      );
  const fetchValueTotal = (Number(FinalValue1) + Number(FinalValue2)).toFixed(
    2
  );

  const line3TotalValue = Number(line1Total) + Number(line2Total);

  const line4Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const line4Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const finalLine6Value1 = (Number(line4Value1) * line3TotalValue) / 100;
  const finalLine6Value2 = (Number(line4Value2) * line3TotalValue) / 100;

  return { finalLine6Value1, finalLine6Value2 };
};

/// get the final basic value for line 10 in 1st tab
export const getBasicFinalValueFor10Line = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(WorkSheetData);

  //// Code for Deviation Tab calculation start
  const proposedFinalResultDeviation = getProposedLowIncomeDeviationAmount(
    WorkSheetData.deviations_schedule_e?.proposed_low_income_deviation,
    WorkSheetData.parent_name_1,
    WorkSheetData.parent_name_2
  );

  const judicialFinalResultDeviation = getJudicialDiscretionAppliedAmount(
    WorkSheetData.deviations_schedule_e?.judicial_discretion_for_low_income,
    WorkSheetData.parent_name_1,
    WorkSheetData.parent_name_2
  );

  /// handle get Proposed Low Income Deviations Amount
  function getProposedLowIncomeDeviationAmount(
    arrayList: ProposedLowIncomeDeviation[],
    parent1: string,
    parent2: string
  ) {
    const resultP1 =
      arrayList?.find((item) => item.deviation_is_for === parent1)
        ?.low_income_deviation ?? 0;
    const resultP2 =
      arrayList?.find((item) => item.deviation_is_for === parent2)
        ?.low_income_deviation ?? 0;

    return { parent1: resultP1, parent2: resultP2 };
  }

  /// handle get Judicial Discretion Applied Amount
  function getJudicialDiscretionAppliedAmount(
    arrayList: JudicialDiscretionForLowIncome[],
    parent1: string,
    parent2: string
  ) {
    const resultP1 =
      arrayList?.find((item) => item.deviation_is_for === parent1)
        ?.low_income_deviation ?? 0;
    const resultP2 =
      arrayList?.find((item) => item.deviation_is_for === parent2)
        ?.low_income_deviation ?? 0;

    return { parent1: resultP1, parent2: resultP2 };
  }
  const line1AValue1 = proposedFinalResultDeviation;
  const lineABValue1 = judicialFinalResultDeviation;

  //// -----------Calculation for 1B code Ended-----------------
  let tempDeviationCalculation = {
    lineDeviationValue1: 0,
    lineDeviationValue2: 0,
  };
  let PValue1 = "";
  let PValue2 = "";
  const totalIncome =
    Number(WorkSheetData.gross_income_schedule_a?.total_gross_income1) +
    Number(WorkSheetData.gross_income_schedule_a?.total_gross_income2);

  const numberOfChildren =
    WorkSheetData.add_child_to_worksheet.length > 0
      ? WorkSheetData.add_child_to_worksheet?.filter(
          (item) => item.child_status === "included"
        ).length
      : 0;
  tempDeviationCalculation =
    finalCalculationForGrossAndAdjustedTabDeviation(WorkSheetData);

  //// parents 1 calculation code
  if (tempDeviationCalculation.lineDeviationValue1) {
    PValue1 = tempDeviationCalculation.lineDeviationValue1.toString();
  } else {
    PValue1 = calculateIncomeDeviation(
      Number(WorkSheetData.gross_income_schedule_a?.total_gross_income1),
      numberOfChildren,
      totalIncome
    )?.toString();
  }
  //// parents 2 calculation code
  if (tempDeviationCalculation.lineDeviationValue2) {
    PValue2 = tempDeviationCalculation.lineDeviationValue2.toString();
  } else {
    PValue2 = calculateIncomeDeviation(
      Number(WorkSheetData.gross_income_schedule_a?.total_gross_income2),
      numberOfChildren,
      totalIncome
    )?.toString();
  }
  const line14TempValue1 =
    Number(PValue1) > Number(line1AValue1.parent1)
      ? Number(PValue1)
      : Number(line1AValue1.parent1);
  const line14TempValue2 =
    Number(PValue2) > Number(line1AValue1.parent2)
      ? Number(PValue2)
      : Number(line1AValue1.parent2);

  const tempABValue1 =
    Number(PValue1) > Number(lineABValue1.parent1)
      ? Number(PValue1)
      : Number(lineABValue1.parent1);

  const lineABValueFinal1 = lineABValue1.parent1
    ? tempABValue1
    : Number(PValue1);

  const tempABValue2 =
    Number(PValue2) > Number(lineABValue1.parent2)
      ? Number(PValue2)
      : Number(lineABValue1.parent2);

  const lineABValueFinal2 = lineABValue1.parent2
    ? tempABValue2
    : Number(PValue2);

  let lineMinMaxValue1 = 0;
  let lineMinMaxValue2 = 0;

  if (lineABValue1.parent1) {
    lineMinMaxValue1 = Math.max(
      lineABValueFinal1,
      Number(lineABValue1.parent1)
    );
  } else {
    lineMinMaxValue1 = Math.max(lineABValueFinal1, line14TempValue1);
  }

  if (lineABValue1.parent2) {
    lineMinMaxValue2 = Math.max(
      lineABValueFinal2,
      Number(lineABValue1.parent2)
    );
  } else {
    lineMinMaxValue2 = Math.max(lineABValueFinal2, line14TempValue2);
  }

  const line14Value1 = lineMinMaxValue1;
  const line14Value2 = lineMinMaxValue2;
  //// line 14 calculation code end

  //// Code for Deviation Tab calculation end
  const JudicialParentsTotal = getTotalJudicialDeviation(
    WorkSheetData.deviations_schedule_e?.specific_nonspecific_deviation || []
  );
  const parentsTotal = getTotalProposedDeviation(
    WorkSheetData.deviations_schedule_e?.specific_nonspecific_deviation || []
  );
  const line1BValue1 = line14Value1;
  const line1BValue2 = line14Value2;

  const ProposedParent1Total =
    Object.keys(parentsTotal).length > 0
      ? parentsTotal[WorkSheetData.parent_name_1]
      : 0;
  const ProposedParent2Total =
    Object.keys(parentsTotal).length > 0
      ? parentsTotal[WorkSheetData.parent_name_2]
      : 0;

  const JudicialParent1Total =
    Object.keys(JudicialParentsTotal).length > 0
      ? JudicialParentsTotal[WorkSheetData.parent_name_1]
      : 0;
  const JudicialParent2Total =
    Object.keys(JudicialParentsTotal).length > 0
      ? JudicialParentsTotal[WorkSheetData.parent_name_2]
      : 0;

  const line2AValueCheckP1 = JudicialParent1Total
    ? JudicialParent1Total
    : ProposedParent1Total;
  const line2AValueCheckP2 = JudicialParent2Total
    ? JudicialParent2Total
    : ProposedParent2Total;

  const lineATotalValue =
    Number(
      WorkSheetData.deviations_schedule_e?.special_expenses_child_rearing_total1
    ) +
    Number(
      WorkSheetData.deviations_schedule_e?.special_expenses_child_rearing_total2
    ) +
    Number(
      WorkSheetData.deviations_schedule_e?.special_expenses_child_rearing_total3
    );
  const lineBTotalValue = Number(lineATotalValue) / 12;
  const line2ValueTotal = (Number(FinalValue1) + Number(FinalValue2)).toFixed(
    2
  );

  const ChildCountNo = WorkSheetData?.add_child_to_worksheet
    ? WorkSheetData?.add_child_to_worksheet?.filter(
        (child) => child.child_status === "included"
      ).length
    : 0;

  const lineCTotalValue = CalculateNarestChildValues(
    Number(line2ValueTotal),
    ChildCountNo
  );

  const lineDTotalValue = (Number(lineCTotalValue) * 7) / 100;
  const lineETotalValue =
    Number(lineBTotalValue) > Number(lineDTotalValue)
      ? Number(lineBTotalValue) - Number(lineDTotalValue)
      : "";

  const checkValue1 = WorkSheetData.deviations_schedule_e
    ?.special_expenses_child_rearing_total1
    ? WorkSheetData.deviations_schedule_e?.special_expenses_child_rearing_total1
    : 0;
  const checkValue2 = WorkSheetData.deviations_schedule_e
    ?.special_expenses_child_rearing_total2
    ? WorkSheetData.deviations_schedule_e?.special_expenses_child_rearing_total2
    : 0;
  const checkValue3 = WorkSheetData.deviations_schedule_e
    ?.special_expenses_child_rearing_total3
    ? WorkSheetData.deviations_schedule_e?.special_expenses_child_rearing_total3
    : 0;
  const lineFTotalValue =
    (Number(checkValue1) / Number(lineATotalValue)) * Number(lineETotalValue);

  const lineGTotalValue =
    (Number(checkValue2) / Number(lineATotalValue)) * Number(lineETotalValue);

  const lineHTotalValue =
    (Number(checkValue3) / Number(lineATotalValue)) * Number(lineETotalValue);

  const checkEducationValue1 = WorkSheetData.deviations_schedule_e
    ?.extraordinary_educational_expenses_total1
    ? WorkSheetData.deviations_schedule_e
        ?.extraordinary_educational_expenses_total1
    : 0;
  const checkEducationValue2 = WorkSheetData.deviations_schedule_e
    ?.extraordinary_educational_expenses_total2
    ? WorkSheetData.deviations_schedule_e
        ?.extraordinary_educational_expenses_total2
    : 0;
  const checkEducationValue3 = WorkSheetData.deviations_schedule_e
    ?.extraordinary_educational_expenses_total3
    ? WorkSheetData.deviations_schedule_e
        ?.extraordinary_educational_expenses_total3
    : 0;

  const checkMedicalValue1 = WorkSheetData.deviations_schedule_e
    ?.extraordinary_medical_expenses_total1
    ? WorkSheetData.deviations_schedule_e?.extraordinary_medical_expenses_total1
    : 0;
  const checkMedicalValue2 = WorkSheetData.deviations_schedule_e
    ?.extraordinary_medical_expenses_total2
    ? WorkSheetData.deviations_schedule_e?.extraordinary_medical_expenses_total2
    : 0;
  const checkMedicalValue3 = WorkSheetData.deviations_schedule_e
    ?.extraordinary_medical_expenses_total3
    ? WorkSheetData.deviations_schedule_e?.extraordinary_medical_expenses_total3
    : 0;

  const line12DTotal1 =
    Number(checkEducationValue1) / 12 +
    Number(checkMedicalValue1 / 12) +
    (isNaN(lineFTotalValue) ? 0 : lineFTotalValue);

  const line12DTotal2 =
    Number(checkEducationValue2) / 12 +
    Number(checkMedicalValue2 / 12) +
    (isNaN(lineGTotalValue) ? 0 : lineGTotalValue);

  const line12DTotal3 =
    Number(checkEducationValue3) / 12 +
    Number(checkMedicalValue3 / 12) +
    (isNaN(lineHTotalValue) ? 0 : lineHTotalValue);

  const line12DConbine = line12DTotal1 + line12DTotal2 + line12DTotal3;

  const line3Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line3Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line12FValue1 = (line12DConbine * Number(line3Value1)) / 100;
  const line12FValue2 = (line12DConbine * Number(line3Value2)) / 100;

  const line12GValue1 = line12FValue1 - line12DTotal1;
  const line12GValue2 = line12FValue2 - line12DTotal2;

  const tempValue1 = line2AValueCheckP1 ? Number(line2AValueCheckP1) : 0;

  const finalLine10Value1 =
    Number(line1BValue1) +
    Number(tempValue1) +
    Number(line12GValue1) +
    Number(WorkSheetData.deviations_schedule_e?.parenting_time_deviation1);

  const tempValue2 = line2AValueCheckP2 ? Number(line2AValueCheckP2) : 0;
  const finalLine10Value2 =
    Number(line1BValue2) +
    Number(tempValue2) +
    Number(line12GValue2) +
    Number(WorkSheetData.deviations_schedule_e?.parenting_time_deviation2);

  return { finalLine10Value1, finalLine10Value2 };
};
