import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  SelectChangeEvent,
} from "@mui/material";
import CustomTooltip from "../tooltip";
import interrogation from "../../assets/images/interrogation.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

interface DropdownProps {
  name: string;
  label?: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  options: { value: string; label: string; icon?: React.ReactElement }[];
  error?: string;
  required?: boolean;
  tooltip?: string;
  secondTooltip?: string; // Second tooltip prop
  placeholder: string;
  backgroundColor?: string;
  disabled?: boolean;
  height?: number;
  mbHeading?: string;
  width?: string;
  minWidth?: string;
}

const CustomDropdown: React.FC<DropdownProps> = ({
  name,
  label,
  value,
  onChange,
  options,
  error,
  required,
  tooltip,
  secondTooltip, // Second tooltip prop
  placeholder,
  backgroundColor,
  height,
  disabled,
  mbHeading,
  width,
  minWidth,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Typography
        variant="subtitle1"
        sx={{
          mb: mbHeading || 1,
          fontWeight: "medium",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          {label} {required && <span style={{ color: "red" }}>*</span>}
          {tooltip && (
            <CustomTooltip title={tooltip}>
              <img
                src={interrogation}
                alt="help icon"
                height={13}
                style={{ marginLeft: "5px" }}
              />
            </CustomTooltip>
          )}
        </div>

        {secondTooltip && (
          <CustomTooltip title={secondTooltip}>
            <img
              src={interrogation}
              alt="second help icon"
              height={13}
              style={{ marginLeft: "5px" }}
            />
          </CustomTooltip>
        )}
      </Typography>
      <FormControl sx={{}}>
        <Select
          value={value}
          onChange={onChange}
          name={name}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          placeholder={placeholder}
          IconComponent={ExpandMoreIcon}
          disabled={disabled}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: "300px",

                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "scrollbar-width": "none", // For Firefox
              },
            },
          }}
          sx={{
            borderRadius: "28px",
            height: height || "35px",
            color: "#9C9BAA",
            borderColor: error ? "#d32f2f !important" : "#E2E0E0",
            width: { width },
            minWidth: { minWidth },
            fontSize: "14px",
            padding: "0px",
            margin: "0px",
            backgroundColor: backgroundColor || "#fff",
            "& .MuiInputLabel-root": {
              top: "-5px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f !important" : "#E2E0E0",
            },
          }}
          renderValue={(selected) => {
            if (selected) {
              const selectedOption = options.find(
                (option) => option.value === selected
              );
              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {selectedOption?.icon}
                  <Typography
                    sx={{
                      fontSize: "14px",
                      marginLeft: "8px",
                      color: "#6E6E6E",
                      fontWeight: "600",
                    }}
                  >
                    {selectedOption?.label}
                  </Typography>
                </Box>
              );
            }
            return <em>{placeholder}</em>;
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                backgroundColor:
                  value === option.value ? "#f0f0f0" : "transparent",
                color: value === option.value ? "#0464AB" : "#9C9BAA",
              }}
            >
              {option.icon && (
                <span style={{ marginRight: "8px" }}>{option.icon}</span>
              )}
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <Typography
            variant="subtitle1"
            sx={{ mt: 1, fontSize: "1rem", color: "error.main" }}
          >
            {error}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};

export default CustomDropdown;
