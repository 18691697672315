import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import style from "../searchWorksheet.module.css";
import SearchWorksheetButtons from "../../../components/searchWorksheetButtons";
import { useAppSelector } from "../../../globalState/hooks";
import FootnoteImage from "../../../assets/images/FootnoteImg.png";
import { formatNumber } from "../../../utils/formatNumber";
import CustomInput from "../../../components/inputField";
import { useTranslation } from "react-i18next";

const SelfEmploymentTab: React.FC = () => {
  const { t } = useTranslation();
  const WorkSheetData = useAppSelector((state) => state.createWorksheet);

  const businessInformation = [
    {
      label: t("1) Description of Business"),
      label1: WorkSheetData.self_employment_calculator.business_description1
        ? WorkSheetData.self_employment_calculator.business_description1
        : "",
      label2: WorkSheetData.self_employment_calculator.business_description2
        ? WorkSheetData.self_employment_calculator.business_description2
        : "",
    },
    {
      label: t("2) Name of Business"),
      label1: WorkSheetData.self_employment_calculator.business_name1
        ? WorkSheetData.self_employment_calculator.business_name1
        : "",
      label2: WorkSheetData.self_employment_calculator.business_name2
        ? WorkSheetData.self_employment_calculator.business_name2
        : "",
    },
    {
      label: t("3) Type of Business"),
      label1: WorkSheetData.self_employment_calculator.business_type1
        ? WorkSheetData.self_employment_calculator.business_type1
        : "",
      label2: WorkSheetData.self_employment_calculator.business_type2
        ? WorkSheetData.self_employment_calculator.business_type2
        : "",
    },
  ];
  const selfEmpTableIncome = [
    {
      label: t("4) Gross Receipts (A)"),
      label1: WorkSheetData.self_employment_calculator.gross_receipts1
        ? "$" +
          formatNumber(
            Number(WorkSheetData.self_employment_calculator.gross_receipts1)
          )
        : "",
      label2: WorkSheetData.self_employment_calculator.gross_receipts2
        ? "$" +
          formatNumber(
            Number(WorkSheetData.self_employment_calculator.gross_receipts2)
          )
        : "",
    },
    {
      label: t("5) Cost of Sales (B)"),
      label1: WorkSheetData.self_employment_calculator.cost_of_sales1
        ? "$" +
          formatNumber(
            Number(WorkSheetData.self_employment_calculator.cost_of_sales1)
          )
        : "",
      label2: WorkSheetData.self_employment_calculator.cost_of_sales2
        ? "$" +
          formatNumber(
            Number(WorkSheetData.self_employment_calculator.cost_of_sales2)
          )
        : "",
    },
    {
      label: t("6) Equals Gross Profit (A-B=C)"),
      label1: WorkSheetData.self_employment_calculator.equals_gross_profit1
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator.equals_gross_profit1
            )
          )
        : "",
      label2: WorkSheetData.self_employment_calculator.equals_gross_profit2
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator.equals_gross_profit2
            )
          )
        : "",
    },
  ];
  const selfEmpTableExp = [
    ...(WorkSheetData.self_employment_calculator.a_compensation_to_owner1
      ? [
          {
            label: t("7(a) Compensation to owner"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .a_compensation_to_owner2
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .a_compensation_to_owner2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.other_salaries_wages1
      ? [
          {
            label: t("7(b) Other salaries and wages"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.other_salaries_wages1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.other_salaries_wages2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.advertising_promotion1
      ? [
          {
            label: t("7(c) Advertising/promotion"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .advertising_promotion1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .advertising_promotion2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.car_truck_expenses1
      ? [
          {
            label: t("7(d) Car and truck expenses"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.car_truck_expenses1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.car_truck_expenses2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.depreciation1
      ? [
          {
            label: t("7(e) Depreciation"),
            label1:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.depreciation1)
              ),
            label2:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.depreciation2)
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.employee_benefits1
      ? [
          {
            label: t("7(f) Employee benefits (including medical insurance)"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.employee_benefits1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.employee_benefits2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.insurance_business1
      ? [
          {
            label: t("7(g) Insurance - business"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.insurance_business1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.insurance_business2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.interest1
      ? [
          {
            label: t("7(h) Interest"),
            label1:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.interest1)
              ),
            label2:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.interest2)
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.office_supplies_expenses1
      ? [
          {
            label: t("7(i) Office supplies and expense"),
            label1: WorkSheetData.self_employment_calculator
              .office_supplies_expenses1
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .office_supplies_expenses1
                  )
                )
              : "",
            label2: WorkSheetData.self_employment_calculator
              .office_supplies_expenses2
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .office_supplies_expenses2
                  )
                )
              : "",
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.rent_or_lease_building1
      ? [
          {
            label: t("7(j) Rent or lease - building"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .rent_or_lease_building1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .rent_or_lease_building2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.rent_or_lease_equipment1
      ? [
          {
            label: t("7(k) Rent or lease - equipment"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .rent_or_lease_equipment1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator
                    .rent_or_lease_equipment2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.taxes_licenses1
      ? [
          {
            label: t("7(l) Taxes and licenses (including payroll taxes)"),
            label1:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.taxes_licenses1)
              ),
            label2:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.taxes_licenses2)
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.travel_entertainment1
      ? [
          {
            label: t("7(m) Travel and entertainment"),
            label1:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.travel_entertainment1
                )
              ),
            label2:
              "$" +
              formatNumber(
                Number(
                  WorkSheetData.self_employment_calculator.travel_entertainment2
                )
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.utilities1
      ? [
          {
            label: t("7(n) Utilities"),
            label1:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.utilities1)
              ),
            label2:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.utilities2)
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.other_expenses1
      ? [
          {
            label: t("7(o) Other"),
            label1:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.other_expenses1)
              ),
            label2:
              "$" +
              formatNumber(
                Number(WorkSheetData.self_employment_calculator.other_expenses2)
              ),
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.explanation_other_expenses
      ? [
          {
            label: t("7(p) Explanation for Other Business Expenses"),
            inputField: true,
          },
        ]
      : []),
    {
      label: t("8) Total Business Expenses (D)"),
      label1: WorkSheetData.self_employment_calculator.total_business_expenses1
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator.total_business_expenses1
            )
          )
        : "",
      label2: WorkSheetData.self_employment_calculator.total_business_expenses2
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator.total_business_expenses2
            )
          )
        : "",
    },
    {
      label: t("9) Net Income (C - D = E)"),
      label1: WorkSheetData.self_employment_calculator.net_income1
        ? "$" +
          formatNumber(
            Number(WorkSheetData.self_employment_calculator.net_income1)
          )
        : "",
      label2: WorkSheetData.self_employment_calculator.net_income2
        ? "$" +
          formatNumber(
            Number(WorkSheetData.self_employment_calculator.net_income2)
          )
        : "",
    },
  ];
  const selfEmpTableExpIncluded = [
    ...(WorkSheetData.self_employment_calculator.compensation_to_owner1 ||
    WorkSheetData.self_employment_calculator.compensation_to_owner2
      ? [
          {
            label:
              t("Compensation to owner (Do not include this amount on Schedule A as it is included with self-employment income)"),
            label1: WorkSheetData.self_employment_calculator
              .compensation_to_owner1
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .compensation_to_owner1
                  )
                )
              : "",
            label2: WorkSheetData.self_employment_calculator
              .compensation_to_owner2
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .compensation_to_owner2
                  )
                )
              : "",
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.excessive_expenses1 ||
    WorkSheetData.self_employment_calculator.excessive_expenses2
      ? [
          {
            label:
              t("Excessive promotional, travel, vehicle or personal living expenses"),
            label1: WorkSheetData.self_employment_calculator.excessive_expenses1
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator.excessive_expenses1
                  )
                )
              : "",
            label2: WorkSheetData.self_employment_calculator.excessive_expenses2
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator.excessive_expenses2
                  )
                )
              : "",
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.home_office_expenses1 ||
    WorkSheetData.self_employment_calculator.home_office_expenses2
      ? [
          {
            label: t("Home office expenses"),
            label1: WorkSheetData.self_employment_calculator
              .home_office_expenses1
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .home_office_expenses1
                  )
                )
              : "",
            label2: WorkSheetData.self_employment_calculator
              .home_office_expenses2
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .home_office_expenses2
                  )
                )
              : "",
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.equipment_depreciation1 ||
    WorkSheetData.self_employment_calculator.equipment_depreciation2
      ? [
          {
            label:
              t("Equipment depreciation, accelerated depreciation, tax credits"),
            label1: WorkSheetData.self_employment_calculator
              .equipment_depreciation1
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .equipment_depreciation1
                  )
                )
              : "",
            label2: WorkSheetData.self_employment_calculator
              .equipment_depreciation2
              ? "$" +
                formatNumber(
                  Number(
                    WorkSheetData.self_employment_calculator
                      .equipment_depreciation2
                  )
                )
              : "",
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator.other1 ||
    WorkSheetData.self_employment_calculator.other2
      ? [
          {
            label: t("Other"),
            label1: WorkSheetData.self_employment_calculator.other1
              ? "$" +
                formatNumber(
                  Number(WorkSheetData.self_employment_calculator.other1)
                )
              : "",
            label2: WorkSheetData.self_employment_calculator.other2
              ? "$" +
                formatNumber(
                  Number(WorkSheetData.self_employment_calculator.other2)
                )
              : "",
          },
        ]
      : []),
    ...(WorkSheetData.self_employment_calculator
      .explanation_non_deductible_expenses
      ? [
          {
            label: t("Explanation for Other Non-Deductible Expenses"),
            inputField: true,
          },
        ]
      : []),
    {
      label: t("11) Total Non-Deductible Expenses (F)"),
      label1: WorkSheetData.self_employment_calculator
        .total_non_deductible_expenses1
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator
                .total_non_deductible_expenses1
            )
          )
        : "",
      label2: WorkSheetData.self_employment_calculator
        .total_non_deductible_expenses2
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator
                .total_non_deductible_expenses2
            )
          )
        : "",
    },
    {
      label: t("12) Total Self-Employment Income (E+F)"),
      label1: WorkSheetData.self_employment_calculator
        .total_self_employment_income1
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator
                .total_self_employment_income1
            )
          )
        : "",
      label2: WorkSheetData.self_employment_calculator
        .total_self_employment_income2
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator
                .total_self_employment_income2
            )
          )
        : "",
    },
    {
      label:
        t("13) Amount to Display on Line 21 Income from Self-Employment Schedule A"),
      label1: WorkSheetData.self_employment_calculator
        .total_self_employment_income1
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator
                .total_self_employment_income1
            )
          )
        : "",
      label2: WorkSheetData.self_employment_calculator
        .total_self_employment_income2
        ? "$" +
          formatNumber(
            Number(
              WorkSheetData.self_employment_calculator
                .total_self_employment_income2
            )
          )
        : "",
    },
  ];
  return (
    <>
      {WorkSheetData.self_employment_calculator.business_description1 ||
      WorkSheetData.self_employment_calculator.business_description2 ||
      WorkSheetData.self_employment_calculator.business_name1 ||
      WorkSheetData.self_employment_calculator.business_name2 ||
      WorkSheetData.self_employment_calculator.business_type1 ||
      WorkSheetData.self_employment_calculator.business_type2 ||
      WorkSheetData.self_employment_calculator.explanation_other_expenses ||
      WorkSheetData.self_employment_calculator
        .explanation_non_deductible_expenses ||
      WorkSheetData.self_employment_calculator.total_non_deductible_expenses1 ||
      WorkSheetData.self_employment_calculator.total_non_deductible_expenses2 ||
      WorkSheetData.self_employment_calculator.total_self_employment_income1 ||
      WorkSheetData.self_employment_calculator.total_self_employment_income2 ||
      WorkSheetData.self_employment_calculator.notes_self_employment_income ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              className={style.contentHeading}
              marginTop={"15px !important"}
            >
              {t("SELF-EMPLOYMENT CALCULATOR")}
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography
              p={2}
              sx={{
                background: "#F8F8F8",
                border: "1px solid #DFDFDF",
                color: "#404040",
              }}
            >
              <span style={{ fontWeight: 600 }}>
                {t("Self-Employment Calculator")}-
              </span>{" "}
              O.C.G.A. 19-6-15(f)(1)(B) states, &quot;Income from
              self-employment includes income from, but not limited to, business
              operations, work as an independent contractor or consultant, sales
              of goods or services, and rental properties, less ordinary and
              reasonable expenses necessary to produce such income. Income from
              self-employment, rent, royalties, proprietorship of a business, or
              joint ownership of a partnership, limited liability company, or
              closely held corporation is defined as gross receipts minus
              ordinary and reasonable expenses required for self-employment or
              business operations.&quot;
            </Typography>
            <Typography
              p={2}
              sx={{
                background: "#F8F8F8",
                border: "1px solid #DFDFDF",
                color: "#404040",
              }}
            >
              <span style={{ fontWeight: 600 }}>{t("Guidance:")}</span> {t("Amounts entered and resulting calculations display below, and the final amounts display on Schedule A, Line 21. If updates must be made, click the")}
              &quot;{t("Open this Worksheet")}&quot; 
              {t("button and return to the Self-Employment Calculator. Make the updates and click the")}
              &quot;{t("Transfer")}&quot; 
              {t("button to update Schedule A, Line 21.")}
            </Typography>
          </Box>
          <Box mt={2}>
            <TableContainer sx={{ borderRadius: "0px" }}>
              <Table
                className="custom-table"
                aria-label="customized table"
                sx={{ minWidth: 900 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_1}</Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_2}</Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businessInformation.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyleSelfEmp}
                      >
                        <Box>{row.label}</Box>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchSelfEmpStyle}
                      >
                        {row.label1}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchSelfEmpStyle}
                      >
                        {row.label2}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="right"
                      className={style.searchSelfEmpStyle}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      className={style.searchSelfEmpStyle}
                      sx={{ fontWeight: "600", color: "#404040" }}
                    >
                      {t("Business Income (per month)")}
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableBody>
                  {selfEmpTableIncome.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyleSelfEmp}
                      >
                        <Box>{row.label}</Box>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchSelfEmpStyle}
                      >
                        {row.label1}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchSelfEmpStyle}
                      >
                        {row.label2}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="right"
                      className={style.searchSelfEmpStyle}
                    ></TableCell>
                    <TableCell
                      align="center"
                      colSpan={2}
                      className={style.searchSelfEmpStyle}
                      sx={{ fontWeight: "600", color: "#404040" }}
                    >
                      {t("Business Expenses")}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {selfEmpTableExp.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyleSelfEmp}
                      >
                        <Box>{row.label}</Box>
                      </TableCell>
                      {row.inputField ? (
                        <>
                          {WorkSheetData.self_employment_calculator
                            .explanation_other_expenses && (
                            <TableCell
                              align="right"
                              className={style.searchSelfEmpStyle}
                              colSpan={2}
                            >
                              <CustomInput
                                type="text"
                                name=""
                                placeholder=""
                                value={
                                  WorkSheetData.self_employment_calculator
                                    .explanation_other_expenses
                                }
                                onChange={() => {}}
                                disabled
                              />
                            </TableCell>
                          )}
                        </>
                      ) : (
                        <>
                          <TableCell
                            align="right"
                            className={style.searchSelfEmpStyle}
                          >
                            {row.label1}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={style.searchSelfEmpStyle}
                          >
                            {row.label2}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>

                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={3}
                      className={style.searchSelfEmpStyle}
                      sx={{ fontWeight: "600", color: "#404040" }}
                    >
                      {t("10) Expenses included above that are not deductible for child support See O.C.G.A.§19-6-15(f)(1)(B)")}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  {selfEmpTableExpIncluded.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyleSelfEmp}
                        sx={
                          index === selfEmpTableExpIncluded.length - 1
                            ? { fontWeight: 600 }
                            : {}
                        }
                      >
                        <Box>{row.label}</Box>
                      </TableCell>

                      {row.inputField ? (
                        <>
                          {WorkSheetData.self_employment_calculator
                            .explanation_non_deductible_expenses && (
                            <TableCell
                              align="right"
                              className={style.searchSelfEmpStyle}
                              colSpan={2}
                              sx={
                                index === selfEmpTableExpIncluded.length - 1
                                  ? { fontWeight: 600 }
                                  : {}
                              }
                            >
                              <CustomInput
                                type="text"
                                name=""
                                placeholder=""
                                value={
                                  WorkSheetData.self_employment_calculator
                                    .explanation_non_deductible_expenses
                                }
                                onChange={() => {}}
                                disabled
                              />
                            </TableCell>
                          )}
                        </>
                      ) : (
                        <>
                          <TableCell
                            align="right"
                            className={style.searchSelfEmpStyle}
                            sx={
                              index === selfEmpTableExpIncluded.length - 1
                                ? { fontWeight: 600 }
                                : {}
                            }
                          >
                            {row.label1}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={style.searchSelfEmpStyle}
                            sx={
                              index === selfEmpTableExpIncluded.length - 1
                                ? { fontWeight: 600 }
                                : {}
                            }
                          >
                            {row.label2}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableBody>
                  <TableCell colSpan={3} sx={{ p: 0 }}>
                    <Typography
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        background: "#F8F8F8",
                        border: "1px solid #DFDFDF",
                        fontWeight: "600",
                        color: "#404040",
                      }}
                    >
                      {t("Answers from the Self-Employment Calculator will display on Schedule A, Line 21 as a positive number for a profit or as zero for a loss.")}
                    </Typography>
                  </TableCell>
                </TableBody>
                {WorkSheetData.self_employment_calculator
                  .notes_self_employment_income && (
                  <TableBody>
                    <TableCell className={style.searchSelfEmpStyle}>
                      <Typography
                        sx={{
                          fontFamily: "Inter, sans-serif !important",
                        }}
                      >
                        {t("Self Employment Income Comment")}
                      </Typography>
                    </TableCell>
                    <TableCell className={style.searchSelfEmpStyle} colSpan={2}>
                      <CustomInput
                        type="text"
                        name=""
                        placeholder=""
                        value={
                          WorkSheetData.self_employment_calculator
                            .notes_self_employment_income
                        }
                        onChange={() => {}}
                        disabled
                      />
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box textAlign={"center"} my={"4rem"}>
          <Box
            component="img"
            src={FootnoteImage}
            alt="Footnotes"
            sx={{
              width: { xs: "70%", md: "25%" },
              height: "auto",
            }}
          />
          <Typography className={style.contentHeading}>
            {t("No Information To Display")}
          </Typography>
        </Box>
      )}

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SearchWorksheetButtons />
      </Box>
    </>
  );
};

export default SelfEmploymentTab;
