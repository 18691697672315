import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { Typography, Box, GlobalStyles, IconButton } from "@mui/material";
import CustomTooltip from "../tooltip";
import interrogation from "../../assets/images/interrogation.svg";
import calenderIcon from "../../assets/images/calendar.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface DatePickerFieldProps {
  label?: string;
  tooltip?: string;
  backgroundColor?: string;
  value: string | number;
  required?: boolean;
  onChange: (date: Dayjs | null) => void;
  isDateDisabled?: boolean;
  error?: string;
}

export default function DatePickerField({
  label,
  tooltip,
  backgroundColor,
  onChange,
  required,
  value,
  isDateDisabled,
  error,
}: DatePickerFieldProps) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const CustomDatePicker = isXs ? MobileDatePicker : DesktopDatePicker;
  const [open, setOpen] = useState(false);

  const CustomCalendarIcon = () => (
    <IconButton>
      <img src={calenderIcon} alt="Calendar Icon" height={16} width={16} />
    </IconButton>
  );

  const handleScroll = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleOpen = () => setOpen(true);

  return (
    <>
      <GlobalStyles
        styles={{
          "input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
            WebkitTextFillColor: "#000",
          },
          "textarea:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "select:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "input:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "textarea:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "select:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "input:-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "textarea:-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "select:-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPickersPopper-paper":
          {
            marginLeft: isXs ? "25px" : "15px",
          },
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {label && (
          <Typography
            variant="subtitle1"
            sx={{
              mb: 1,
              fontWeight: "medium",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {label} {required && <span style={{ color: "red" }}>*</span>}{" "}
            {tooltip && (
              <CustomTooltip title={tooltip}>
                <img
                  src={interrogation}
                  alt="Logo"
                  height={13}
                  style={{ marginLeft: "5px" }}
                />
              </CustomTooltip>
            )}
          </Typography>
        )}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDatePicker
            value={value}
            open={open}
            onChange={onChange}
            onOpen={handleOpen}
            maxDate={isDateDisabled ? dayjs() : undefined}
            components={{
              OpenPickerIcon: CustomCalendarIcon,
            }}
            PopperProps={{
              disablePortal: true,
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -120],
                  },
                },
              ],
            }}
            onClose={handleScroll}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                {...params}
                required={required}
                error={!!error}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "28px",
                    height: "35px",
                    fontSize: "14px",
                    borderColor: error ? "#d32f2f " : "#E2E0E0 ",
                    color:  "#6E6E6E", ///// Old Color - "#9C9BAA"
                    fontWeight:  "600",
                    backgroundColor: backgroundColor || "#FDFFA6",
                    "& fieldset": {
                      borderColor: error ? "#d32f2f" : "#E2E0E0",
                    },
                    "&:hover": {
                      backgroundColor: backgroundColor || "#FDFFA6",
                    },

                    "&.Mui-focused": {
                      backgroundColor: backgroundColor || "#FDFFA6",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#d32f2f",
                    },
                  },
                }}
              // onClick={handleOpen}
              />
            )}
          />
        </LocalizationProvider>
        {error && (
          <Typography
            variant="caption"
            color="error"
            sx={{ mt: 1, fontSize: '12px', }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </>
  );
}
