import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

interface TooltipProps {
  title: string;
  children: React.ReactElement;
  fontSize?: string;
  placement?:
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";
}

const CustomTooltip: React.FC<TooltipProps> = ({
  title,
  fontSize = "12px", // Default fontSize
  children,
  placement = "left-end",
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#fff",
            color: "#000",
            fontSize: fontSize,
            borderRadius: "20px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            padding: "15px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={title}
        placement={isMobile ? "top" : placement}
        open={open}
        onClose={handleTooltipClose}
        onOpen={handleTooltipOpen}
        disableHoverListener={isMobile}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        <IconButton
          onTouchStart={isMobile ? handleTooltipOpen : undefined}
          onClick={isMobile ? undefined : handleTooltipOpen}
          sx={{
            padding: 0,
          }}
        >
          {children}
        </IconButton>
      </Tooltip>
    </ThemeProvider>
  );
};

export default CustomTooltip;
