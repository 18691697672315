import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import style from "../searchWorksheet.module.css";
import FootnoteImage from "../../../assets/images/FootnoteImg.png";
import SearchWorksheetButtons from "../../../components/searchWorksheetButtons";
import { useAppSelector } from "../../../globalState/hooks";
import { useTranslation } from "react-i18next";

const FootnotesTab: React.FC = () => {
  const WorkSheetData = useAppSelector((state) => state.createWorksheet);
  const { t } = useTranslation();

  return (
    <>
      <Box>
        {WorkSheetData?.foot_note?.length > 0 ? (
          <>
            <Typography
              className={style.contentHeading}
              marginTop={"15px !important"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {t("CHILD SUPPORT FOOTNOTES")}
            </Typography>
            <Box sx={{ pt: 2 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={style.headerStyle}
                        sx={{ bgcolor: "#e6f5ff", minWidth: "5px" }}
                      >
                        {t("Row")}
                      </TableCell>
                      <TableCell
                        className={style.headerStyle}
                        sx={{ bgcolor: "#e6f5ff" }}
                        align="left"
                      >
                        {t("Schedule")}
                      </TableCell>
                      <TableCell
                        className={style.headerStyle}
                        sx={{ bgcolor: "#e6f5ff" }}
                        align="left"
                      >
                        {t("Line Number")}
                      </TableCell>
                      <TableCell
                        className={style.headerStyle}
                        sx={{ bgcolor: "#e6f5ff" }}
                        align="left"
                      >
                        {t("Footnote")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {WorkSheetData?.foot_note &&
                      WorkSheetData?.foot_note.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell align="left" className={style.cellStyle}>
                            <Box
                              style={{
                                backgroundColor: "white",
                                display: "flex",
                              }}
                            >
                              {index + 1}
                            </Box>
                          </TableCell>
                          <TableCell
                            className={style.cellStyle}
                            component="th"
                            scope="row"
                          >
                            {row.schedule}
                          </TableCell>
                          <TableCell className={style.cellStyle} align="left">
                            {row.line_no}
                          </TableCell>
                          <TableCell
                            className={style.cellStyle}
                            align="left"
                            sx={{ minWidth: "300px" }}
                          >
                            {row.footnote}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        ) : (
          <Box textAlign={"center"} my={"4rem"}>
            <Box
              component="img"
              src={FootnoteImage}
              alt="Footnotes"
              sx={{
                width: { xs: "70%", md: "25%" },
                height: "auto",
              }}
            />
            <Typography className={style.contentHeading}>
              {t("No Information To Display")}
            </Typography>
          </Box>
        )}
      </Box>

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SearchWorksheetButtons />
      </Box>
    </>
  );
};

export default FootnotesTab;
