export const sortParentsAlphabetically = (
  parent1: string,
  parent2: string
): string[] => {
  const parents = [parent1, parent2];

  parents.sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "base" })
  );

  return parents;
};
