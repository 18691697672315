import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Link,
  CircularProgress,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReCAPTCHA from "react-google-recaptcha";
import CustomInput from "../../components/inputField";
import TextareaField from "../../components/text-area";
import { showToast } from "../../components/toastNotification";
import reCAPTCHAkey from "../../utils/reCAPTCHAkey";
import { Link as LinkUrl, useNavigate } from "react-router-dom";
import contactFormService from "../../services/contact-form-service";
import { useTranslation } from "react-i18next";


interface ContactData {
  name: string;
  email: string;
  phone: string;
  assistance: string;
}
const Contact: React.FC = () => {
  const navigate = useNavigate();

  const [contactData, setContactData] = useState<ContactData>({
    name: "",
    email: "",
    phone: "",
    assistance: "",
  });

  const [hover, setHover] = useState<boolean>(false);
  const [errors, setErrors] = useState<Partial<ContactData>>({});
  const [recaptchaCode, setRecaptchaCode] = useState<string | null>(null);
  const [isRecaptchaValide, setIsRecaptchaValide] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleTextData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "name") {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
    setContactData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  //// handle recaptch code
  const handleRecaptcha = (value: string | null) => {
    setRecaptchaCode(value);
    setIsRecaptchaValide("");
  };
  //// form validation code
  const formValidate = (): boolean => {
    const newErrors: Partial<ContactData> = {};
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const alphabetRegex = /^[A-Za-z\s]+$/;

    if (!contactData.name.trim()) {
      newErrors.name = t("First Name cannot be blank");
    } else if (contactData.name.length > 255) {
      newErrors.name = t("First Name cannot exceed 255 characters");
    } else if (specialCharRegex.test(contactData.name.trim())) {
      newErrors.name = t("First Name cannot contain special characters");
    } else if (!alphabetRegex.test(contactData.name.trim())) {
      newErrors.name = t("First Name can only contain alphabets");
    } else if (contactData.name.length > 50) {
      newErrors.name = t("Ensure this field has not more than 50 characters");
    }

    if (!contactData.email.trim()) {
      newErrors.email = t("Email Address cannot be blank");
    } else if (!/\S+@\S+\.\S+/.test(contactData.email)) {
      newErrors.email = t("Email Address is invalid");
    }

    if (!contactData.phone.trim()) {
      newErrors.phone = t("Phone Number cannot be blank");
    } else if (!/^\d{10}$/.test(contactData.phone)) {
      newErrors.phone = t("Phone Number must be 10 digits");
    }

    if (!contactData.assistance.trim()) {
      newErrors.assistance = t("Message cannot be blank");
    } else if (contactData.assistance.length > 255) {
      newErrors.assistance = t("Message cannot exceed 255 characters");
    }
    if (recaptchaCode === null) {
      setIsRecaptchaValide(t("Please confirm that you are not a bot."));
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /// handle Send contact form code
  const handleSendContactForm = async () => {
    if (formValidate()) {
      if (recaptchaCode === null) {
        setIsRecaptchaValide(t("Please confirm that you are not a bot."));
      } else {
        setIsLoading(true);
        await contactFormService
          .contactFormAPI(contactData)
          .then((response) => {
            if (response && Object.keys(response.data.data).length > 0) {
              navigate("/");
              showToast(t(response.data.message), "success");
            } else {
              showToast(t(response?.data?.message), "error");
            }
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error, "API Error");
          });
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="contain__area"
      >
        <Card
          elevation={0}
          sx={{
            width: "100%",
            minWidth: "200px",
            maxWidth: "750px",
            bgcolor: "#fff",
            border: "1px solid #DFDFDF",
            borderRadius: "40px 40px",
            opacity: 1,
            margin: "30px 8px 8px 8px",
          }}
        >
          <Box m={2} sx={{ marginTop: "1rem" }}>
            <Box
              m={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #DFDFDF",
                p: 2,
              }}
            >
              <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                <Typography
                  variant="h4"
                  color="#0464AB"
                  className="h6 main_heading"
                  gutterBottom
                  sx={{
                    fontWeight: "500",
                    // fontFamily: "DM Serif Display",
                    marginTop: "10px",
                  }}
                >
                  {t("Contact Form")}
                </Typography>
              </Box>
              <Box
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={() => setHover(true)}
                sx={{ position: "relative" }}
              >
                <InfoOutlinedIcon
                  sx={{
                    color: "#FBB522",
                    cursor: "pointer",
                    fontSize: 25,
                  }}
                />
                {hover && (
                  <Card
                    sx={{
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      width: { md: "400px", sm: "400px", xs: "280px" },
                      height: { md: "350px", sm: "350px", xs: "300px" },
                      boxShadow: 3,
                      zIndex: 1,
                      mt: 1,
                      borderRadius: "20px",
                      overflow: "auto",
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <CardContent>
                      <Typography
                        variant="subtitle1"
                        color="#000000"
                        component="p"
                      >
                        {t("Questions on the Child Support Calculator, or where to find information in the Child Support Guidelines statute, O.C.G.A. § 19-6-15, may be addressed by completing the Contact Form below. A member of the Child Support Commission staff will respond.")}
                        <br />
                        <br />
                        {t("The Child Support Commission staff cannot provide legal advice. If you have specific questions on your own case, please consult an attorney or your local Legal Aid or Georgia Legal Services office.")}
                        <br />
                        <br />
                        {t("If you have a case with the Georgia Division of Child Support Services (DCSS), contact that agency directly at 844-694-2347 or visit them online at")}{" "}
                        <Link
                          href="https://childsupport.georgia.gov/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://childsupport.georgia.gov/
                        </Link>
                        {t(". The Georgia Child Support Commission has no authority over the DCSS or involvement with any case managed by that agency and cannot assist you.")}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Box>
          </Box>
          <Box m={3}>
            <Typography
              m={2}
              fontWeight="bold"
              gutterBottom
              sx={{ color: "#022E4C", fontSize: "14px" }}
            >
              {t("Please fill out the form if you need assistance from our team:")}
            </Typography>
            <Box m={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    name="name"
                    label={t("Name ")}
                    value={contactData.name}
                    onChange={handleTextData}
                    placeholder={t("Enter Name")}
                    required={true}
                    type="text"
                    error={errors.name}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <CustomInput
                    onChange={handleTextData}
                    value={contactData.email}
                    label={t("Email")}
                    name="email"
                    type="text"
                    required={true}
                    placeholder={t("Enter Email")}
                    error={errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    onChange={handleTextData}
                    value={contactData.phone}
                    label={t("Phone Number ")}
                    name="phone"
                    type="number"
                    placeholder={t("Enter Phone Number")}
                    required={true}
                    error={errors.phone}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box m={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextareaField
                    name="assistance"
                    label={t("How Can We Assist You")}
                    placeholder={t("Write Something...")}
                    value={contactData.assistance}
                    onChange={handleTextData}
                    minRows={4}
                    required={true}
                    error={errors.assistance}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: {
                        xs: "center",
                        sm: "flex-start",
                        md: "flex-start",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: {
                          xs: "center",
                          sm: "flex-start",
                          md: "flex-start",
                        },
                        padding: { xs: "0 16px", sm: "0px", md: "0px" },
                        boxSizing: "border-box",
                        "& .g-recaptcha": {
                          transform: "scale(0.8)",
                          transformOrigin: "0 0",
                        },
                        "@media (max-width: 320px)": {
                          padding: "0 8px",
                          "& .g-recaptcha": {
                            transform: "scale(0.65)",
                          },
                        },
                      }}
                    >
                      <ReCAPTCHA
                        sitekey={
                          window.location.host === "localhost:3000"
                            ? reCAPTCHAkey.localhostSitekey
                            : window.location.host ===
                              "childsupport-dev.brainvire.net"
                            ? reCAPTCHAkey.devSitekey
                            : reCAPTCHAkey.UATSitekey
                        }
                        onChange={handleRecaptcha}
                      />
                    </Box>
                  </Box>
                  {isRecaptchaValide !== "" && (
                    <span style={{ color: "#d32f2f" }}>
                      {isRecaptchaValide}
                    </span>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Box display="flex" justifyContent="flex-start" mb={5}>
              <Button
                variant="contained"
                disableElevation
                disabled={isLoading}
                onClick={handleSendContactForm}
                sx={{
                  borderRadius: "20px",
                  width: 100,
                  mx: 2,
                  fontWeight: "600",
                  backgroundColor: "#0464AB",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#FBB522",
                    color: "#022E4C",
                    fontWeight: "600",
                  },
                }}
              >
                {t("Send")}
                {isLoading && (
                  <CircularProgress size={18} sx={{ padding: "0px 8px" }} />
                )}
              </Button>
              <LinkUrl to="/">
                <Button
                  variant="outlined"
                  disableElevation
                  sx={{
                    borderRadius: "20px",
                    width: 100,
                    fontWeight: "600",
                    textTransform: "none",
                    backgroundColor: "#fff",
                    border: "1px solid #0464AB",
                    color: "#0464AB",
                    textDecoration: "none",
                    "&:hover": {
                      backgroundColor: "#FBB522",
                      color: "#022E4C",
                      borderColor: "#FBB522",
                      fontWeight: "600",
                    },
                  }}
                >
                  {t("Cancel")}
                </Button>
              </LinkUrl>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};
export default Contact;
