import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import LoginForm from "../pages/login";
import RegisterForm from "../pages/register";
import Home from "../pages/home/index";
import { useSelector } from "react-redux";
import CreateWorkSheet from "../pages/worksheet";
import SearchWorkSheet from "../pages/searchWorksheet";
import Layout from "../pages/layout/layout";
import Contact from "../pages/contactForm";
import ForgetForm from "../pages/forgetPassword";
import FAQPage from "../pages/faq/index";
import SystemMessage from "../pages/systemMessage";
import SearchFolderWorksheet from "../pages/searchFolderWorksheet";
import MakeAvailableToCourt from "../pages/searchFolderWorksheet/MakeAvailableToCourt";
import NewPasswordForm from "../pages/newPassword";
import ToastNotification from "../components/toastNotification";
import About from "../pages/about";
import UserInformation from "../pages/userInformation";
import BasicChildSupport from "../pages/basicChildSupportObligation";
import ReportMonthlyNum from "../pages/reportMonthlyNum/reportMonthlyNum";
import SearchArchivedFolderWorksheet from "../pages/searchArchivedFolder";
import UpdateUserInformation from "../pages/updateUserInformation";
import AdminFAQ from "../pages/adminFAQ";
import BecomeOrganizationMember from "../pages/becomeOrganizationMember";
import ViewOrganizationName from "../pages/viewOrganizationName";
import WorkSheets from "../pages/systemAdminWorksheet";
import { HeaderActiveState, RootState } from "../utils/userDetailsCommonType";
import ViewOrganizationMember from "../pages/viewOrganizationMembers";
import OtpVerification from "../pages/OTP/OTPVerification";
import PDFViewPage from "../pages/pdfView";
import { useAppSelector } from "../globalState/hooks";
import i18n from "../i18n";
import SignUpOTP from "../pages/OTP/SignUpOTP";

const PrivateRoute: React.FC = () => {
  const loginDetails = useSelector((state: RootState) => state.authReducer);
  const isLoginSucceeded = loginDetails.loginDetials || false;

  return isLoginSucceeded ? <Outlet /> : <Navigate replace to="/" />;
};
///Admin Private Routes
const AdminPrivateRoute: React.FC = () => {
  const loginDetails = useSelector((state: RootState) => state.authReducer);
  const isAdminUser = loginDetails.loginDetials.is_superuser || false;

  return isAdminUser ? <Outlet /> : <Navigate replace to="/" />;
};

const RouteStart: React.FC = () => {
  const languageStates = useAppSelector(
    (state: HeaderActiveState) => state.headerURLData.selectedLanguage
  );

  useEffect(() => {
    if (languageStates) {
      i18n.changeLanguage(languageStates);
    }
  }, [languageStates, i18n]);

  return (
    <Router>
      <Layout>
        <React.Fragment>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/resetPassword" element={<ForgetForm />} />
            <Route path="/otpVerification" element={<OtpVerification />} />
            <Route path="/signUpOTP" element={<SignUpOTP />} />
            <Route path="/createPassword/" element={<NewPasswordForm />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/basicChildSupport" element={<BasicChildSupport />} />
            <Route element={<PrivateRoute />}>
              <Route
                path="/WorkSheets/createWorksheet"
                element={<CreateWorkSheet />}
              />
              <Route
                path="/WorkSheets/updateWorksheet/:id"
                element={<CreateWorkSheet />}
              />
              <Route
                path="/makeAvailableToCourt/:id"
                element={<MakeAvailableToCourt />}
              />
              <Route
                path="/searchFolderWorksheet"
                element={<SearchFolderWorksheet />}
              />
              <Route
                path="/searchWorksheet/:id"
                element={<SearchWorkSheet />}
              />
              <Route
                path="/WorkSheets/searchArchivedWorksheet"
                element={<SearchArchivedFolderWorksheet />}
              />
              <Route
                path="/administration/updateUserInformation"
                element={<UpdateUserInformation />}
              />
              <Route
                path="/system-administration/worksheet"
                element={<WorkSheets />}
              />
              <Route
                path="/administration/becomeOrganizationMember"
                element={<BecomeOrganizationMember />}
              />
              <Route
                path="/administration/viewOrganizationName"
                element={<ViewOrganizationName />}
              />
              <Route
                path="/administration/viewOrganizationMember"
                element={<ViewOrganizationMember />}
              />
            </Route>
            {/* Admin Private Route code */}
            <Route element={<AdminPrivateRoute />}>
              <Route
                path="/system-administration/userInformation"
                element={<UserInformation />}
              />
              <Route
                path="/system-administration/updateFAQ"
                element={<AdminFAQ />}
              />
              <Route
                path="/system-administration/systemMessage"
                element={<SystemMessage />}
              />
              <Route
                path="/system-administration/AdminFAQ"
                element={<AdminFAQ />}
              />
              <Route
                path="/system-administration/reportMonthlyNumber"
                element={<ReportMonthlyNum />}
              />
              <Route path="/pdfViewPage" element={<PDFViewPage />} />
            </Route>
          </Routes>
        </React.Fragment>
      </Layout>
      <ToastNotification />
    </Router>
  );
};

export default RouteStart;
