import {
  httpCommon,
  httpTokenCommon,
  httpTokenFileDownload,
} from "../interceptor";

interface tableList {
  pageIndex: number;
  perPage: number;
  tableSearchData: {
    plaintiff_name: string;
    defendant_name: string;
    county_id__county_name: string;
    court_id__court_name: string;
    civil_action_case_number: string;
    dhsc_no: string;
    prepared_by__username: string;
  };
  tableOrdering: string;
}

class SearchArchiveWorksheetService {
  /// get Court list API
  getCourtListAPI() {
    return httpCommon.get("users/court-list");
  }

  /// get country list API
  getCountyListAPI() {
    return httpCommon.get("users/county-list");
  }

  //// get search Folder worksheet API endpoint
  getSearchArchiveWorksheetTblListAPI(inputdata: tableList) {
    const { pageIndex, perPage, tableSearchData, tableOrdering } = inputdata;
    return httpTokenCommon.get(
      `worksheet/user_archive_worksheet_list/?limit=${perPage}&page=${pageIndex}&plaintiff_name=${tableSearchData.plaintiff_name}&defendant_name=${tableSearchData.defendant_name}
      &county_id__county_name=${tableSearchData.county_id__county_name}&court_id__court_name=${tableSearchData.court_id__court_name}&civil_action_case_number=${tableSearchData.civil_action_case_number}
      &dhsc_no=${tableSearchData.dhsc_no}&prepared_by__username=${tableSearchData.prepared_by__username}&ordering=${tableOrdering}`
    );
  }

  //// get search Folder worksheet Sub table data by ID API endpoint
  getSearchArchiveWorksheetSubTblListAPI(id: number | string) {
    return httpTokenCommon.get(
      `worksheet/archive_worksheet_version_list/?worksheet_ids=${id}`
    );
  }

  //// get search Folder worksheet Sub table data by ID API endpoint
  getDownloadWorkSheetAPI(key: string, download_ids: number[]) {
    return httpTokenFileDownload.get(
      `worksheet/download_worksheet/?download_format=${key}&download_ids=${download_ids}`
    );
  }

  //// delete Worksheet API endpoint
  deleteDeleteWorksheetAPI(worksheetId: number) {
    return httpTokenCommon.delete(`worksheet/basic_info/${worksheetId}/`);
  }

  //// patch Restore Worksheet API endpoint
  patchRestoreWorksheetAPI(worksheetId: number) {
    return httpTokenCommon.patch(`worksheet/restore/${worksheetId}/`);
  }
}

const searchArchiveWorksheet = new SearchArchiveWorksheetService();
export default searchArchiveWorksheet;
