import {
  getTotalJudicialDeviation,
  getTotalProposedDeviation,
} from "../pages/worksheet/DeviationsTab/DeviationsValidationTabs";
import moment from "moment";
import { formatNumberWithDigit } from "./formatNumber";
import {
  getBasicFinalValueFor10Line,
  getBasicFinalValueFor2Line,
  getBasicFinalValueFor6Line,
} from "./getBasicInfoCalculate";
import {
  ChildDetail,
  CreateWorksheetInitialState,
  JudicialDiscretionForLowIncome,
  ProposedLowIncomeDeviation,
} from "../types/create-worksheet";
import { CalculateNarestChildValues } from "./calculateNarestChildValues";
import { formatNumber } from "../utils/formatNumber";
import { finalCalculationForGrossAndAdjustedTabDeviation } from "./calculationForGrossAdjustedValueForDeviation";
import { calculateIncomeDeviation } from "./calculateIncomeDeviation";

// Utility function to check the value
function getValidValue(value: string | null | undefined) {
  return value !== null &&
    value !== undefined &&
    value !== "" &&
    value !== "NaN"
    ? "$" + formatNumberWithDigit(Number(value))
    : "";
}

// Function to replace null values with "" in Child Data
const replaceNullsWithEmptyString = (data: ChildDetail[]) => {
  return data.map((child) => {
    return Object.fromEntries(
      Object.entries(child).map(([key, value]) => [
        key,
        value === null ? "" : value,
      ])
    ) as ChildDetail;
  });
};
// Function to replace null values with "" in work related child
const workRelatedChildReplaceNullsWithEmptyString = <
  T extends Record<string, unknown>
>(
  data: T[]
): T[] => {
  return data.map((item) => {
    return Object.fromEntries(
      Object.entries(item).map(([key, value]) => [
        key,
        value === null || value === undefined ? "" : value,
      ])
    ) as T;
  });
};

export function setWorksheetDataForPrintWorkSheet(
  worksheetData: CreateWorksheetInitialState,
  submitedByValue: string
) {
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(worksheetData);

  const { finalLine6Value1, finalLine6Value2 } =
    getBasicFinalValueFor6Line(worksheetData);

  const { finalLine10Value1, finalLine10Value2 } =
    getBasicFinalValueFor10Line(worksheetData);
  //// Code for Deviation Tab calculation start
  const proposedFinalResultDeviation = getProposedLowIncomeDeviationAmount(
    worksheetData.deviations_schedule_e.proposed_low_income_deviation,
    worksheetData.parent_name_1,
    worksheetData.parent_name_2
  );
  const judicialFinalResultDeviation = getJudicialDiscretionAppliedAmount(
    worksheetData.deviations_schedule_e.judicial_discretion_for_low_income,
    worksheetData.parent_name_1,
    worksheetData.parent_name_2
  );

  const totalGrossIncome = formatNumber(
    Number(worksheetData.gross_income_schedule_a?.total_gross_income1 || 0) +
      Number(worksheetData.gross_income_schedule_a?.total_gross_income2 || 0)
  );

  const line2ValueTotal =
    Number(FinalValue1) || Number(FinalValue2)
      ? (Number(FinalValue1) + Number(FinalValue2)).toFixed(2)
      : "";
  ///get Explanation data start
  //// code for Proposed explanation
  const ExPropoValue1 = worksheetData.deviations_schedule_e
    .proposed_low_income_deviation
    ? worksheetData.deviations_schedule_e.proposed_low_income_deviation[0]
        ?.low_income_deviation_explanation
    : "";
  const ExPropoValue2 =
    worksheetData.deviations_schedule_e.proposed_low_income_deviation.length ===
    2
      ? worksheetData.deviations_schedule_e.proposed_low_income_deviation[1]
          ?.low_income_deviation_explanation
      : "";

  //// code for judicial explanation
  const ExJudiValue1 = worksheetData.deviations_schedule_e
    .judicial_discretion_for_low_income
    ? worksheetData.deviations_schedule_e.judicial_discretion_for_low_income[0]
        ?.low_income_deviation_explanation
    : "";
  const ExJudiValue2 =
    worksheetData.deviations_schedule_e.judicial_discretion_for_low_income
      .length === 2
      ? worksheetData.deviations_schedule_e
          ?.judicial_discretion_for_low_income[1]
          ?.low_income_deviation_explanation
      : "";

  const line1AValueExplanation = ExPropoValue1 || ExJudiValue1;
  const lineABValueExplanation = ExPropoValue2 || ExJudiValue2;
  //// Code for Deviation Tab calculation end
  const ChildCountNo = worksheetData?.add_child_to_worksheet
    ? worksheetData?.add_child_to_worksheet?.filter(
        (child) => child.child_status === "included"
      ).length
    : 0;

  ////Code for Basic Information tab code start
  const line3Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line3Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line4NarestValue = CalculateNarestChildValues(
    line2ValueTotal ? Number(line2ValueTotal) : 0,
    ChildCountNo
  ).toFixed(2);

  const line5Value1 = (Number(line4NarestValue) * Number(line3Value1)) / 100;
  const line5Value2 = (Number(line4NarestValue) * Number(line3Value2)) / 100;

  const line7Value1 =
    line5Value1 || finalLine6Value1
      ? Number(Number(line5Value1) + Number(finalLine6Value1))
      : "";
  const line7Value2 =
    line5Value2 || finalLine6Value2
      ? Number(Number(line5Value2) + Number(finalLine6Value2))
      : "";

  const Line8Value1 = (
    Number(
      worksheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent1
    ) /
      12 +
    Number(
      worksheetData?.health_insurance_child_care_schedule_d?.monthly_total1
    )
  ).toFixed(2);
  const Line8Value2 = (
    Number(
      worksheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent2
    ) /
      12 +
    Number(
      worksheetData?.health_insurance_child_care_schedule_d?.monthly_total2
    )
  ).toFixed(2);

  const line9Value1 = Number(line7Value1) - Number(Line8Value1);
  const line9Value2 = Number(line7Value2) - Number(Line8Value2);

  const finalLineNo10Value1 = isNaN(Number(finalLine10Value1))
    ? 0
    : Number(finalLine10Value1);
  const finalLineNo10Value2 = isNaN(Number(finalLine10Value2))
    ? 0
    : Number(finalLine10Value2);

  const line11Value1 = Number(line9Value1) + finalLineNo10Value1;
  const line11Value2 = Number(line9Value2) + finalLineNo10Value2;

  const line14Value1 =
    Number(line11Value1) -
    Number(worksheetData.social_security_payment_1) -
    Number(worksheetData.veterans_affairs_disability_payment_1);
  const line14Value2 =
    Number(line11Value2) -
    Number(worksheetData.social_security_payment_2) -
    Number(worksheetData.veterans_affairs_disability_payment_2);

  //// Check box code
  const isGrossIncomeValue =
    worksheetData.gross_income_schedule_a?.total_gross_income1 ||
    worksheetData.gross_income_schedule_a?.total_gross_income2
      ? true
      : false;

  const isAdjustedIncomeValue =
    Number(
      worksheetData.adjusted_income_schedule_b?.monthly_self_employment_income1
    ) > 0 ||
    Number(
      worksheetData.adjusted_income_schedule_b?.monthly_self_employment_income2
    ) > 0 ||
    worksheetData.adjusted_income_schedule_b?.pre_existing_child_support
      ?.length > 0 ||
    worksheetData.adjusted_income_schedule_b
      ?.proposed_adjustment_for_qualified_child?.length > 0
      ? true
      : false;

  const isAdditionalExpenses =
    worksheetData.health_insurance_child_care_schedule_d
      ?.health_insurance_premiums_parent1 ||
    worksheetData.health_insurance_child_care_schedule_d
      ?.health_insurance_premiums_parent2 ||
    worksheetData.health_insurance_child_care_schedule_d
      ?.health_insurance_premiums_parent3 ||
    worksheetData.health_insurance_child_care_schedule_d?.monthly_total1 !==
      null ||
    worksheetData.health_insurance_child_care_schedule_d?.monthly_total2 !==
      null ||
    worksheetData.health_insurance_child_care_schedule_d?.monthly_total3 !==
      null
      ? true
      : false;

  const isDeviationsFromPresumptive =
    worksheetData.deviations_schedule_e?.proposed_low_income_deviation
      ?.length ||
    worksheetData.deviations_schedule_e?.judicial_discretion_for_low_income
      ?.length ||
    worksheetData.deviations_schedule_e?.specific_nonspecific_deviation
      ?.length ||
    worksheetData.deviations_schedule_e?.extra_ordinary_educational_expenses
      ?.length ||
    worksheetData.deviations_schedule_e?.extra_ordinary_medical_expenses
      ?.length ||
    worksheetData.deviations_schedule_e?.special_expenses_child_rearing
      ?.length ||
    worksheetData.deviations_schedule_e?.parenting_time_deviation1 ||
    worksheetData.deviations_schedule_e?.parenting_time_deviation2 ||
    worksheetData.deviations_schedule_e?.unjust_or_inappropriate ||
    worksheetData.deviations_schedule_e?.best_interests_of_children ||
    worksheetData.deviations_schedule_e?.impair_ability_to_maintain
      ? true
      : false;

  ////Code for Basic Information tab code end

  //// Adjusted tab code start
  const ficaValue1 = worksheetData.adjusted_income_schedule_b
    .monthly_self_employment_income1
    ? Number(
        worksheetData.adjusted_income_schedule_b.monthly_self_employment_income1
      ) * 0.062
    : 0;
  const ficaValue2 = worksheetData.adjusted_income_schedule_b
    .monthly_self_employment_income2
    ? Number(
        worksheetData.adjusted_income_schedule_b.monthly_self_employment_income2
      ) * 0.062
    : 0;

  const adjustedLine4Value1 = worksheetData.adjusted_income_schedule_b
    .monthly_self_employment_income1
    ? Number(
        worksheetData.adjusted_income_schedule_b.monthly_self_employment_income1
      ) * 0.0145
    : 0;
  const adjustedLine4Value2 = worksheetData.adjusted_income_schedule_b
    .monthly_self_employment_income2
    ? Number(
        worksheetData.adjusted_income_schedule_b.monthly_self_employment_income2
      ) * 0.0145
    : 0;

  const adjustedLine5Value1 = Number(ficaValue1) + Number(adjustedLine4Value1);
  const adjustedLine5Value2 = Number(ficaValue2) + Number(adjustedLine4Value2);

  const adjustedLine6Value1 = worksheetData.gross_income_schedule_a
    .total_gross_income1
    ? Number(worksheetData.gross_income_schedule_a.total_gross_income1) -
      adjustedLine5Value1
    : 0;
  const adjustedLine6Value2 = worksheetData.gross_income_schedule_a
    .total_gross_income2
    ? Number(worksheetData.gross_income_schedule_a.total_gross_income2) -
      adjustedLine5Value2
    : 0;

  const adjustedLine9Value1 =
    Number(adjustedLine6Value1) -
    Number(
      worksheetData?.adjusted_income_schedule_b
        ?.preexisting_child_support_amount1
    );
  const adjustedLine9Value2 =
    Number(adjustedLine6Value2) -
    Number(
      worksheetData?.adjusted_income_schedule_b
        ?.preexisting_child_support_amount2
    );
  const adjustedLine10AValues = worksheetData.adjusted_income_schedule_b
    .proposed_adjustment_for_qualified_child
    ? worksheetData.adjusted_income_schedule_b.proposed_adjustment_for_qualified_child.reduce(
        (
          acc: { parent1: number; parent2: number },
          item: { adjustment_requested_by: string }
        ) => {
          if (item.adjustment_requested_by === worksheetData.parent_name_1) {
            acc.parent1 = (acc.parent1 || 0) + 1;
          }

          if (item.adjustment_requested_by === worksheetData.parent_name_2) {
            acc.parent2 = (acc.parent2 || 0) + 1;
          }

          return acc;
        },
        { parent1: 0, parent2: 0 }
      )
    : { parent1: 0, parent2: 0 };

  const adjustedLine12Value1 =
    adjustedLine6Value1 > 0
      ? CalculateNarestChildValues(
          Number(adjustedLine6Value1),
          adjustedLine10AValues.parent1
        )
      : 0;
  const adjustedLine12Value2 =
    adjustedLine6Value2 > 0
      ? CalculateNarestChildValues(
          Number(adjustedLine6Value2),
          adjustedLine10AValues.parent2
        )
      : 0;

  const adjustedLine13Value1 = (adjustedLine12Value1 * 75) / 100;
  const adjustedLine13Value2 = (adjustedLine12Value2 * 75) / 100;

  const adjustedLne14Value1 = adjustedLine13Value1
    ? adjustedLine9Value1 - adjustedLine13Value1
    : "";
  const adjustedLne14Value2 = adjustedLine13Value2
    ? adjustedLine9Value2 - adjustedLine13Value2
    : "";
  //// Adjusted tab code end

  //// health Insurenace Tab code start
  const healthLine1Total =
    (worksheetData.health_insurance_child_care_schedule_d
      .health_insurance_premiums_parent1 ||
      worksheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2 ||
      worksheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3) &&
    Number(
      worksheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent1
    ) /
      12 +
      Number(
        worksheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent2
      ) /
        12 +
      Number(
        worksheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent3
      ) /
        12;

  const healthLine2Total =
    (worksheetData?.health_insurance_child_care_schedule_d?.monthly_total1 ||
      worksheetData?.health_insurance_child_care_schedule_d?.monthly_total2 ||
      worksheetData?.health_insurance_child_care_schedule_d?.monthly_total3) &&
    Number(
      worksheetData.health_insurance_child_care_schedule_d.monthly_total1 || 0
    ) +
      Number(
        worksheetData.health_insurance_child_care_schedule_d.monthly_total2 || 0
      ) +
      Number(
        worksheetData.health_insurance_child_care_schedule_d.monthly_total3 || 0
      );
  const healthLine3Total = Number(healthLine1Total) + Number(healthLine2Total);

  const healthLine4Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
          100
        );
  const healthLine4Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
          100
        );

  const healthLine5TotalValue =
    (Number(healthLine4Value1) * healthLine3Total) / 100 +
    (Number(healthLine4Value2) * healthLine3Total) / 100;
  //// health Insurenace Tab code end

  //// Deviation calculation code start
  let tempDeviationCalculation = {
    lineDeviationValue1: 0,
    lineDeviationValue2: 0,
  };
  let PValue1 = "";
  let PValue2 = "";
  const deviationLine1AValue1 = proposedFinalResultDeviation;
  const deviationLineABValue1 = judicialFinalResultDeviation;

  const totalIncome =
    Number(worksheetData.gross_income_schedule_a.total_gross_income1) +
    Number(worksheetData.gross_income_schedule_a.total_gross_income2);
  tempDeviationCalculation =
    finalCalculationForGrossAndAdjustedTabDeviation(worksheetData);

  //// parents 1 calculation code
  if (tempDeviationCalculation.lineDeviationValue1) {
    PValue1 = tempDeviationCalculation.lineDeviationValue1.toString();
  } else {
    PValue1 = calculateIncomeDeviation(
      Number(worksheetData.gross_income_schedule_a.total_gross_income1),
      ChildCountNo,
      totalIncome
    ).toString();
  }
  //// parents 2 calculation code
  if (tempDeviationCalculation.lineDeviationValue2) {
    PValue2 = tempDeviationCalculation.lineDeviationValue2.toString();
  } else {
    PValue2 = calculateIncomeDeviation(
      Number(worksheetData.gross_income_schedule_a.total_gross_income2),
      ChildCountNo,
      totalIncome
    ).toString();
  }

  const line14TempValue1 =
    Number(PValue1) > Number(deviationLine1AValue1.parent1)
      ? Number(PValue1)
      : Number(deviationLine1AValue1.parent1);
  const line14TempValue2 =
    Number(PValue2) > Number(deviationLine1AValue1.parent2)
      ? Number(PValue2)
      : Number(deviationLine1AValue1.parent2);

  const tempABValue1 =
    Number(PValue1) > Number(deviationLineABValue1.parent1)
      ? Number(PValue1)
      : Number(deviationLineABValue1.parent1);

  const lineABValueFinal1 = deviationLineABValue1.parent1
    ? tempABValue1
    : Number(PValue1);

  const tempABValue2 =
    Number(PValue2) > Number(deviationLineABValue1.parent2)
      ? Number(PValue2)
      : Number(deviationLineABValue1.parent2);

  const lineABValueFinal2 = deviationLineABValue1.parent2
    ? tempABValue2
    : Number(PValue2);

  const lineMaxValue1 = Math.max(lineABValueFinal1, line14TempValue1);
  const lineMaxValue2 = Math.max(lineABValueFinal2, line14TempValue2);
  const deviationLine14Value1 = lineMaxValue1;

  const deviationLine14Value2 = lineMaxValue2;

  const parentsTotal = getTotalProposedDeviation(
    worksheetData.deviations_schedule_e.specific_nonspecific_deviation || []
  );
  const ProposedParent1Total = parentsTotal[worksheetData.parent_name_1];
  const ProposedParent2Total = parentsTotal[worksheetData.parent_name_2];

  const JudicialParentsTotal = getTotalJudicialDeviation(
    worksheetData.deviations_schedule_e.specific_nonspecific_deviation || []
  );
  const JudicialParent1Total =
    JudicialParentsTotal[worksheetData.parent_name_1];
  const JudicialParent2Total =
    JudicialParentsTotal[worksheetData.parent_name_2];

  const deviationLine12ATotal =
    (worksheetData.deviations_schedule_e
      .extraordinary_educational_expenses_total1 ||
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total2 ||
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total3) &&
    Number(
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total1
    ) /
      12 +
      Number(
        worksheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total2
      ) /
        12 +
      Number(
        worksheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total3
      ) /
        12;

  const deviationLine12BTotal =
    (worksheetData.deviations_schedule_e
      .extraordinary_medical_expenses_total1 ||
      worksheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total2 ||
      worksheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total3) &&
    Number(
      worksheetData.deviations_schedule_e.extraordinary_medical_expenses_total1
    ) /
      12 +
      Number(
        worksheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total2
      ) /
        12 +
      Number(
        worksheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total3
      ) /
        12;
  const lineATotalValue =
    Number(
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total1
    ) +
    Number(
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total2
    ) +
    Number(
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total3
    );

  const lineBTotalValue = Number(lineATotalValue) / 12;
  const lineCTotalValue = CalculateNarestChildValues(
    Number(line2ValueTotal),
    worksheetData?.add_child_to_worksheet?.length
  );

  const lineDTotalValue = (Number(lineCTotalValue) * 7) / 100;

  const lineETotalValue =
    Number(lineBTotalValue) > Number(lineDTotalValue)
      ? Number(lineBTotalValue) - Number(lineDTotalValue)
      : "";
  const lineFTotalValue =
    (Number(
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total1
    ) /
      Number(lineATotalValue)) *
    Number(lineETotalValue);

  const lineGTotalValue =
    (Number(
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total2
    ) /
      Number(lineATotalValue)) *
    Number(lineETotalValue);

  const lineHTotalValue =
    (Number(
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total3
    ) /
      Number(lineATotalValue)) *
    Number(lineETotalValue);

  const line12CTotal =
    Number(lineFTotalValue) + Number(lineGTotalValue) + Number(lineHTotalValue);

  const line12DTotal1 =
    Number(
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total1
    ) /
      12 +
    Number(
      worksheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total1 / 12
    ) +
    lineFTotalValue;
  const line12DTotal2 =
    Number(
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total2
    ) /
      12 +
    Number(
      worksheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total2 / 12
    ) +
    lineGTotalValue;
  const line12DTotal3 =
    Number(
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total3
    ) /
      12 +
    Number(
      worksheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total3 / 12
    ) +
    lineHTotalValue;

  const line12DConbine = line12DTotal1 + line12DTotal2 + line12DTotal3;

  const deviationLine3Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
          100
        );

  const deviationLine3Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
          100
        );

  const line12FValue1 = (line12DConbine * Number(line3Value1)) / 100;
  const line12FValue2 = (line12DConbine * Number(line3Value2)) / 100;

  const line12FConbine = line12FValue1 + line12FValue2;

  const line12GValue1 = line12FValue1 - line12DTotal1;
  const line12GValue2 = line12FValue2 - line12DTotal2;
  //// Deviation calculation code end

  const worksheetResult = {
    /// Basic Information Worksheet
    worksheet_created_at: moment(worksheetData?.created_at).format(
      "YYYY-MM-DD"
    ),
    plaintiff_name: worksheetData.plaintiff_name,
    defendant_name: worksheetData.defendant_name,
    county_name: worksheetData.county_name,
    court_name: worksheetData.court_name,
    non_parent_custodian_name: worksheetData.non_parent_custodian_name
      ? worksheetData.non_parent_custodian_name
      : "",
    civil_action_case_number: worksheetData.civil_action_case_number
      ? worksheetData.civil_action_case_number
      : "",
    dhsc_no: worksheetData.dhsc_no ? worksheetData.dhsc_no : "",
    comments_for_the_court: worksheetData.comments_for_the_court
      ? worksheetData.comments_for_the_court
      : "",
    type_of_action: worksheetData.type_of_action
      ? worksheetData.type_of_action
      : "",
    initial_support_order_date: worksheetData?.initial_support_order_date
      ? moment(worksheetData?.initial_support_order_date).format("MM-DD-YYYY")
      : "",
    parent1: worksheetData.parent_name_1,
    parent2: worksheetData.parent_name_2,
    non_custodial_parent: worksheetData.non_custodial_parent,
    submitted_by: submitedByValue,
    add_child_to_worksheet: replaceNullsWithEmptyString(
      worksheetData.add_child_to_worksheet
    ),
    number_of_included_children: worksheetData.number_of_included_children,
    name_person_preparing_worksheet:
      worksheetData.name_person_preparing_worksheet,
    monthly_gross_income1: worksheetData?.gross_income_schedule_a
      ?.total_gross_income1
      ? "$" +
        formatNumber(
          Number(worksheetData.gross_income_schedule_a.total_gross_income1)
        )
      : "",
    monthly_gross_income2: worksheetData?.gross_income_schedule_a
      ?.total_gross_income2
      ? "$" +
        formatNumber(
          Number(worksheetData.gross_income_schedule_a.total_gross_income2)
        )
      : "",
    monthly_gross_income3: "$" + totalGrossIncome,
    monthly_adjusted_income1: FinalValue1
      ? "$" + formatNumberWithDigit(FinalValue1)
      : "",
    monthly_adjusted_income2: FinalValue2
      ? "$" + formatNumberWithDigit(FinalValue2)
      : "",
    monthly_adjusted_income3:
      "$" + formatNumberWithDigit(Number(line2ValueTotal)),
    pro_rata_shares_of_combined_income1: isNaN(Number(line3Value1))
      ? ""
      : Number(line3Value1).toFixed(2) + "%",
    pro_rata_shares_of_combined_income2: isNaN(Number(line3Value2))
      ? ""
      : Number(line3Value2).toFixed(2) + "%",
    pro_rata_shares_of_combined_income3:
      isNaN(Number(line3Value1)) || isNaN(Number(line3Value2)) ? "" : "100.00%",
    basic_child_support_obligation1:
      "$" + formatNumber(Number(line4NarestValue)),
    pro_rata_shares_basic_child_support_obligation1: isNaN(Number(line5Value1))
      ? ""
      : "$" + formatNumberWithDigit(line5Value1),
    pro_rata_shares_basic_child_support_obligation2: isNaN(Number(line5Value2))
      ? ""
      : "$" + formatNumberWithDigit(line5Value2),
    adjustment_for_work_related_child_care_and_health_insurance_expenses1:
      finalLine6Value1 ? "$" + formatNumberWithDigit(finalLine6Value1) : "",
    adjustment_for_work_related_child_care_and_health_insurance_expenses2:
      finalLine6Value2 ? "$" + formatNumberWithDigit(finalLine6Value2) : "",
    anddjusted_child_support_obligation1: isNaN(Number(line7Value1))
      ? ""
      : "$" + formatNumberWithDigit(Number(line7Value1)),
    anddjusted_child_support_obligation2: isNaN(Number(line7Value2))
      ? ""
      : "$" + formatNumberWithDigit(Number(line7Value2)),
    adjustment_for_additional_expenses_paid1:
      Number(Line8Value1) > 0 ? "$" + formatNumber(Number(Line8Value1)) : "",
    adjustment_for_additional_expenses_paid2:
      Number(Line8Value2) > 0 ? "$" + formatNumber(Number(Line8Value2)) : "",
    presumptive_amount_of_child_support1: line9Value1
      ? "$" + formatNumberWithDigit(line9Value1)
      : "",
    presumptive_amount_of_child_support2: line9Value2
      ? "$" + formatNumberWithDigit(line9Value2)
      : "",
    deviations_from_presumptive_child_support_amount1: finalLine10Value1
      ? "$" + formatNumberWithDigit(finalLine10Value1)
      : "",
    deviations_from_presumptive_child_support_amount2: finalLine10Value2
      ? "$" + formatNumberWithDigit(finalLine10Value2)
      : "",
    subtotal1: line11Value1 ? "$" + formatNumberWithDigit(line11Value1) : "",
    subtotal2: line11Value2 ? "$" + formatNumberWithDigit(line11Value2) : "",
    social_security_payments1: worksheetData.social_security_payment_1
      ? "$" + worksheetData.social_security_payment_1
      : "",
    social_security_payments2: worksheetData.social_security_payment_2
      ? "$" + worksheetData.social_security_payment_2
      : "",
    veterans_affairs_disability_payments1: getValidValue(
      worksheetData.veterans_affairs_disability_payment_1
    ),
    veterans_affairs_disability_payments2: getValidValue(
      worksheetData.veterans_affairs_disability_payment_2
    ),
    final_monthly_child_support_amount1:
      Number(line14Value1) > 0
        ? "$" + formatNumberWithDigit(Math.round(line14Value1))
        : "",
    final_monthly_child_support_amount2:
      Number(line14Value2) > 0
        ? "$" + formatNumberWithDigit(Math.round(line14Value2))
        : "",
    percentages_for_each_parent1: worksheetData.uninsured_health_expenses_1
      ? worksheetData.uninsured_health_expenses_1 + "%"
      : "",
    percentages_for_each_parent2: worksheetData.uninsured_health_expenses_2
      ? worksheetData.uninsured_health_expenses_2 + "%"
      : "",

    //// Check box payload
    gross_income: isGrossIncomeValue,
    adjusted_income: isAdjustedIncomeValue,
    additional_expenses: isAdditionalExpenses,
    deviations_from_presumptive_amount: isDeviationsFromPresumptive,

    /// Gross Income Schedule A
    gross_income_schedule_a: {
      tanf1: worksheetData.gross_income_schedule_a.tanf1,
      tanf2: worksheetData.gross_income_schedule_a.tanf2,
      salary_and_wages1: getValidValue(
        worksheetData.gross_income_schedule_a.gross_salary1
      ),
      salary_and_wages2: getValidValue(
        worksheetData.gross_income_schedule_a.gross_salary2
      ),
      commissions1: getValidValue(
        worksheetData.gross_income_schedule_a.commissions1
      ),
      commissions2: getValidValue(
        worksheetData.gross_income_schedule_a.commissions2
      ),
      self_employment_income1: getValidValue(
        worksheetData.gross_income_schedule_a.self_employment_income1
      ),
      self_employment_income2: getValidValue(
        worksheetData.gross_income_schedule_a.self_employment_income2
      ),
      bonuses1: getValidValue(worksheetData.gross_income_schedule_a.bonuses1),
      bonuses2: getValidValue(worksheetData.gross_income_schedule_a.bonuses2),
      overtime_payment1: getValidValue(
        worksheetData.gross_income_schedule_a.overtime_payment1
      ),
      overtime_payment2: getValidValue(
        worksheetData.gross_income_schedule_a.overtime_payment2
      ),
      severence_pay1: getValidValue(
        worksheetData.gross_income_schedule_a.severence_pay1
      ),
      severence_pay2: getValidValue(
        worksheetData.gross_income_schedule_a.severence_pay2
      ),
      recurring_income1: getValidValue(
        worksheetData.gross_income_schedule_a.recurring_income1
      ),
      recurring_income2: getValidValue(
        worksheetData.gross_income_schedule_a.recurring_income2
      ),
      interest_income1: getValidValue(
        worksheetData.gross_income_schedule_a.interest_income1
      ),
      interest_income2: getValidValue(
        worksheetData.gross_income_schedule_a.interest_income2
      ),
      income_from_dividends1: getValidValue(
        worksheetData.gross_income_schedule_a.income_from_dividends1
      ),
      income_from_dividends2: getValidValue(
        worksheetData.gross_income_schedule_a.income_from_dividends2
      ),
      trust_income1: getValidValue(
        worksheetData.gross_income_schedule_a.trust_income1
      ),
      trust_income2: getValidValue(
        worksheetData.gross_income_schedule_a.trust_income2
      ),
      income_from_annuities1: getValidValue(
        worksheetData.gross_income_schedule_a.income_from_annuities1
      ),
      income_from_annuities2: getValidValue(
        worksheetData.gross_income_schedule_a.income_from_annuities2
      ),
      capital_gains1: getValidValue(
        worksheetData.gross_income_schedule_a.capital_gains1
      ),
      capital_gains2: getValidValue(
        worksheetData.gross_income_schedule_a.capital_gains2
      ),
      social_security_disablity_benefits1: getValidValue(
        worksheetData.gross_income_schedule_a
          .social_security_disablity_benefits1
      ),
      social_security_disablity_benefits2: getValidValue(
        worksheetData.gross_income_schedule_a
          .social_security_disablity_benefits2
      ),
      federal_veterans_disability_benefits1: getValidValue(
        worksheetData.gross_income_schedule_a
          .federal_veterans_disability_benefits1
      ),
      federal_veterans_disability_benefits2: getValidValue(
        worksheetData.gross_income_schedule_a
          .federal_veterans_disability_benefits2
      ),
      workers_compensation_benefits1: getValidValue(
        worksheetData.gross_income_schedule_a.workers_compensation_benefits1
      ),
      workers_compensation_benefits2: getValidValue(
        worksheetData.gross_income_schedule_a.workers_compensation_benefits2
      ),
      unemployment_benefits1: getValidValue(
        worksheetData.gross_income_schedule_a.unemployment_benefits1
      ),
      unemployment_benefits2: getValidValue(
        worksheetData.gross_income_schedule_a.unemployment_benefits2
      ),
      judgements1: getValidValue(
        worksheetData.gross_income_schedule_a.judgements1
      ),
      judgements2: getValidValue(
        worksheetData.gross_income_schedule_a.judgements2
      ),
      gifts1: getValidValue(worksheetData.gross_income_schedule_a.gifts1),
      gifts2: getValidValue(worksheetData.gross_income_schedule_a.gifts2),
      prizes1: getValidValue(worksheetData.gross_income_schedule_a.prizes1),
      prizes2: getValidValue(worksheetData.gross_income_schedule_a.prizes2),
      alimony1: getValidValue(worksheetData.gross_income_schedule_a.alimony1),
      alimony2: getValidValue(worksheetData.gross_income_schedule_a.alimony2),
      assets1: getValidValue(worksheetData.gross_income_schedule_a.assets1),
      assets2: getValidValue(worksheetData.gross_income_schedule_a.assets2),
      fringe_benefits1: getValidValue(
        worksheetData.gross_income_schedule_a.fringe_benefits1
      ),
      fringe_benefits2: getValidValue(
        worksheetData.gross_income_schedule_a.fringe_benefits2
      ),
      other_income1: getValidValue(
        worksheetData.gross_income_schedule_a.other_income1
      ),
      other_income2: getValidValue(
        worksheetData.gross_income_schedule_a.other_income2
      ),
      total_gross_income1: getValidValue(
        worksheetData.gross_income_schedule_a.total_gross_income1
      ),
      total_gross_income2: getValidValue(
        worksheetData.gross_income_schedule_a.total_gross_income2
      ),
      total_gross_income3: "$" + totalGrossIncome,
      comment1: worksheetData.gross_income_schedule_a.comment1,
      comment2: worksheetData.gross_income_schedule_a.comment2,
    },

    /// Adjusted Income Schedule B
    adjusted_income_schedule_b: {
      total_gross_income1: worksheetData?.gross_income_schedule_a
        ?.total_gross_income1
        ? "$" +
          formatNumber(
            Number(worksheetData.gross_income_schedule_a.total_gross_income1)
          )
        : "",
      total_gross_income2: worksheetData?.gross_income_schedule_a
        ?.total_gross_income2
        ? "$" +
          formatNumber(
            Number(worksheetData.gross_income_schedule_a.total_gross_income2)
          )
        : "",
      monthly_self_employment_income1: worksheetData.adjusted_income_schedule_b
        .monthly_self_employment_income1
        ? "$" +
          worksheetData.adjusted_income_schedule_b
            .monthly_self_employment_income1
        : "",
      monthly_self_employment_income2: worksheetData.adjusted_income_schedule_b
        .monthly_self_employment_income2
        ? "$" +
          worksheetData.adjusted_income_schedule_b
            .monthly_self_employment_income2
        : "",
      fica_calculation1: ficaValue1
        ? "$" + formatNumberWithDigit(Number(ficaValue1))
        : "",
      fica_calculation2: ficaValue2
        ? "$" + formatNumberWithDigit(Number(ficaValue2))
        : "",
      medicare_tax_calculation1: adjustedLine4Value1
        ? "$" + formatNumberWithDigit(Number(adjustedLine4Value1))
        : "",
      medicare_tax_calculation2: adjustedLine4Value2
        ? "$" + formatNumberWithDigit(Number(adjustedLine4Value2))
        : "",
      total_of_3_4_1: adjustedLine5Value1
        ? "$" + formatNumberWithDigit(adjustedLine5Value1)
        : "",
      total_of_3_4_2: adjustedLine5Value2
        ? "$" + formatNumberWithDigit(adjustedLine5Value2)
        : "",
      line5_subtracted_from_line1: adjustedLine6Value1
        ? "$" + formatNumber(Number(adjustedLine6Value1))
        : "",
      line5_subtracted_from_line2: adjustedLine6Value2
        ? "$" + formatNumber(Number(adjustedLine6Value2))
        : "",
      total_adjustment_for_preexisting_child1: worksheetData
        ?.adjusted_income_schedule_b?.preexisting_child_support_amount1
        ? "$" +
          formatNumber(
            Number(
              worksheetData?.adjusted_income_schedule_b
                ?.preexisting_child_support_amount1
            )
          )
        : "",
      total_adjustment_for_preexisting_child2: worksheetData
        ?.adjusted_income_schedule_b?.preexisting_child_support_amount2
        ? "$" +
          formatNumber(
            Number(
              worksheetData?.adjusted_income_schedule_b
                ?.preexisting_child_support_amount2
            )
          )
        : "",
      line8_subtracted_from_line_6_1: adjustedLine9Value1
        ? "$" + formatNumber(adjustedLine9Value1)
        : "",
      line8_subtracted_from_line_6_2: adjustedLine9Value2
        ? "$" + formatNumber(adjustedLine9Value2)
        : "",
      line10_a_1: true,
      line10_a_2: adjustedLine10AValues ? adjustedLine10AValues.parent1 : 0,
      line10_a_3: adjustedLine10AValues ? adjustedLine10AValues.parent2 : 0,
      explanation_for_parent1:
        worksheetData.adjusted_income_schedule_b
          .reason_for_including_other_child1,
      explanation_for_parent2:
        worksheetData.adjusted_income_schedule_b
          .reason_for_including_other_child2,
      line11_for_parent1:
        adjustedLine9Value1 && adjustedLine10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(adjustedLine9Value1)
          : "",
      line11_for_parent2:
        adjustedLine9Value2 && adjustedLine10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(adjustedLine9Value2)
          : "",
      line12_for_parent1:
        adjustedLine12Value1 && adjustedLine10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(adjustedLine12Value1)
          : "",
      line12_for_parent2:
        adjustedLine12Value2 && adjustedLine10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(adjustedLine12Value2)
          : "",
      line13_for_parent1:
        adjustedLine13Value1 && adjustedLine10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(adjustedLine13Value1)
          : "",
      line13_for_parent2:
        adjustedLine13Value2 && adjustedLine10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(adjustedLine13Value2)
          : "",
      line14_for_parent1:
        adjustedLne14Value1 && adjustedLine10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(adjustedLne14Value1)
          : "",
      line14_for_parent2:
        adjustedLne14Value2 && adjustedLine10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(adjustedLne14Value2)
          : "",
      isAdjustmentAcknowledgeCheck: worksheetData.adjusted_income_schedule_b
        ?.pre_existing_child_support
        ? true
        : false,
      pre_existing_child_support: worksheetData.adjusted_income_schedule_b
        ?.pre_existing_child_support
        ? worksheetData.adjusted_income_schedule_b?.pre_existing_child_support?.map(
            (item: {
              date_of_initial_order: string;
              time_of_initial_order: string;
              preexisting_child_support_paid_monthly1: string | null;
              preexisting_child_support_paid_monthly2: string | null;
            }) => {
              if (item.time_of_initial_order) {
                item.time_of_initial_order = moment(
                  item.time_of_initial_order,
                  "HH:mm:ss"
                ).format("hh:mma");
              } else {
                item.time_of_initial_order = "";
              }
              if (item.date_of_initial_order) {
                item.date_of_initial_order = moment(
                  item.date_of_initial_order
                ).format("MM/DD/YYYY");
              } else {
                item.date_of_initial_order = "";
              }
              item.preexisting_child_support_paid_monthly1 =
                item.preexisting_child_support_paid_monthly1 !== null
                  ? item.preexisting_child_support_paid_monthly1
                  : "";

              item.preexisting_child_support_paid_monthly2 =
                item.preexisting_child_support_paid_monthly2 !== null
                  ? item.preexisting_child_support_paid_monthly2
                  : "";
              return item;
            }
          )
        : [],
      proposed_adjustment_for_qualified_child: worksheetData
        .adjusted_income_schedule_b.proposed_adjustment_for_qualified_child
        ? worksheetData.adjusted_income_schedule_b.proposed_adjustment_for_qualified_child.map(
            (item: { adjustment_requested_by: string }) => {
              return {
                ...item,
                parentName1:
                  item.adjustment_requested_by === worksheetData.parent_name_1
                    ? "1"
                    : "",
                parentName2:
                  item.adjustment_requested_by === worksheetData.parent_name_2
                    ? "1"
                    : "",
              };
            }
          )
        : [],
    },

    /// Health Insurance/Child Care Schedule D
    health_insurance_schedule_d: {
      work_related_childcare_expenses1: worksheetData
        .health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent1
        ? "$" +
          formatNumberWithDigit(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent1 / 12
          )
        : "",
      work_related_childcare_expenses2: worksheetData
        .health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2
        ? "$" +
          formatNumberWithDigit(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent2 / 12
          )
        : "",
      work_related_childcare_expenses3: worksheetData
        .health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3
        ? "$" +
          formatNumberWithDigit(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent3 / 12
          )
        : "",
      work_related_childcare_expenses4: healthLine1Total
        ? "$" + formatNumberWithDigit(Number(healthLine1Total))
        : "",
      health_insurance_premiums_paid1: worksheetData
        ?.health_insurance_child_care_schedule_d?.monthly_total1
        ? "$" +
          formatNumber(
            worksheetData?.health_insurance_child_care_schedule_d
              ?.monthly_total1
          )
        : "",
      health_insurance_premiums_paid2: worksheetData
        ?.health_insurance_child_care_schedule_d?.monthly_total2
        ? "$" +
          formatNumber(
            worksheetData?.health_insurance_child_care_schedule_d
              ?.monthly_total2
          )
        : "",
      health_insurance_premiums_paid3: worksheetData
        ?.health_insurance_child_care_schedule_d?.monthly_total3
        ? "$" +
          formatNumber(
            worksheetData?.health_insurance_child_care_schedule_d
              ?.monthly_total3
          )
        : "",
      health_insurance_premiums_paid4: healthLine2Total
        ? "$" + formatNumberWithDigit(Number(healthLine2Total))
        : "",
      total_monthly_additional_expenses1:
        "$" +
        formatNumberWithDigit(
          Number(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent1
          ) /
            12 +
            Number(
              worksheetData?.health_insurance_child_care_schedule_d
                ?.monthly_total1
            )
        ),
      total_monthly_additional_expenses2:
        "$" +
        formatNumberWithDigit(
          Number(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent2
          ) /
            12 +
            Number(
              worksheetData?.health_insurance_child_care_schedule_d
                ?.monthly_total2
            )
        ),
      total_monthly_additional_expenses3:
        "$" +
        formatNumberWithDigit(
          Number(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent3
          ) /
            12 +
            Number(
              worksheetData?.health_insurance_child_care_schedule_d
                ?.monthly_total3
            )
        ),
      total_monthly_additional_expenses4:
        "$" + formatNumber(Number(healthLine3Total)),
      pro_rata_share_of_parent_income1: healthLine4Value1
        ? formatNumberWithDigit(Number(healthLine4Value1)) + "%"
        : "",
      pro_rata_share_of_parent_income2: healthLine4Value2
        ? formatNumberWithDigit(Number(healthLine4Value2)) + "%"
        : "",
      pro_rata_share_of_parent_income3: "100.00%",
      pro_rata_share_of_additional_expenses1:
        "$" +
        formatNumberWithDigit(
          (Number(healthLine4Value1) * healthLine3Total) / 100
        ),
      pro_rata_share_of_additional_expenses2:
        "$" +
        formatNumberWithDigit(
          (Number(healthLine4Value2) * healthLine3Total) / 100
        ),
      pro_rata_share_of_additional_expenses3:
        "$" + formatNumberWithDigit(healthLine5TotalValue),
      work_related_child_care: workRelatedChildReplaceNullsWithEmptyString(
        worksheetData.health_insurance_child_care_schedule_d
          .work_related_child_care
      ),
      work_related_child_care_paid_by_parent1:
        "$" +
        worksheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent1,
      work_related_child_care_paid_by_parent2:
        "$" +
        worksheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent2,
      work_related_child_care_paid_by_parent3:
        "$" +
        worksheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent3,
      work_related_child_care_monthly_average_for_parent1: worksheetData
        .health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent1
        ? "$" +
          formatNumberWithDigit(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent1 / 12
          )
        : "",
      work_related_child_care_monthly_average_for_parent2: worksheetData
        .health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2
        ? "$" +
          formatNumberWithDigit(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent2 / 12
          )
        : "",
      work_related_child_care_monthly_average_for_parent3: worksheetData
        .health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3
        ? "$" +
          formatNumberWithDigit(
            worksheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent3 / 12
          )
        : "",
    },

    /// Deviations Schedule E
    deviation_schedule_e: {
      proposed_low_income_deviation1: proposedFinalResultDeviation.parent1
        ? "$" + proposedFinalResultDeviation.parent1
        : "",
      proposed_low_income_deviation2: proposedFinalResultDeviation.parent2
        ? "$" + proposedFinalResultDeviation.parent2
        : "",
      proposed_low_income_deviation_by_court1:
        judicialFinalResultDeviation.parent1
          ? "$" + judicialFinalResultDeviation.parent1
          : "",
      proposed_low_income_deviation_by_court2:
        judicialFinalResultDeviation.parent2
          ? "$" + judicialFinalResultDeviation.parent2
          : "",
      judicial_discretion_for_low_income1: deviationLine14Value1
        ? "$" + Number(deviationLine14Value1).toFixed(2)
        : "",
      judicial_discretion_for_low_income2: deviationLine14Value2
        ? "$" + Number(deviationLine14Value2).toFixed(2)
        : "",
      explanation_for_requesting1: line1AValueExplanation,
      explanation_for_requesting2: lineABValueExplanation,
      high_income: "",
      specific_non_specific_deviation:
        worksheetData.deviations_schedule_e.specific_nonspecific_deviation,
      proposed_deviation_total_by_parent1: ProposedParent1Total
        ? "$" + ProposedParent1Total
        : "",
      proposed_deviation_total_by_parent2: ProposedParent2Total
        ? "$" + ProposedParent2Total
        : "",
      judicial_discretion_for_parent1: JudicialParent1Total
        ? "$" + JudicialParent1Total
        : "",
      judicial_discretion_for_parent2: JudicialParent2Total
        ? "$" + JudicialParent2Total
        : "",
      extraordinary_educational_expenses_total1:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total1 / 12
        ),
      extraordinary_educational_expenses_total2:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total2 / 12
        ),
      extraordinary_educational_expenses_total3:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total3 / 12
        ),
      extraordinary_educational_expenses_total4: deviationLine12ATotal
        ? "$" + formatNumberWithDigit(Number(deviationLine12ATotal))
        : "",
      extraordinary_medical_expenses_total1:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total1 / 12
        ),
      extraordinary_medical_expenses_total2:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total2 / 12
        ),
      extraordinary_medical_expenses_total3:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total3 / 12
        ),
      extraordinary_medical_expenses_total4: deviationLine12BTotal
        ? "$" + formatNumberWithDigit(Number(deviationLine12BTotal))
        : "",
      allowable_special_expenses1: lineFTotalValue
        ? "$" + formatNumberWithDigit(lineFTotalValue)
        : "",
      allowable_special_expenses2: lineGTotalValue
        ? "$" + formatNumberWithDigit(lineGTotalValue)
        : "",
      allowable_special_expenses3: lineHTotalValue
        ? "$" + formatNumberWithDigit(lineHTotalValue)
        : "",
      allowable_special_expenses4: line12CTotal
        ? "$" + formatNumberWithDigit(line12CTotal)
        : "",
      total_extraordinary_and_allowable_special_expenses1: line12DTotal1
        ? "$" + formatNumberWithDigit(line12DTotal1)
        : "",
      total_extraordinary_and_allowable_special_expenses2: line12DTotal2
        ? "$" + formatNumberWithDigit(line12DTotal2)
        : "",
      total_extraordinary_and_allowable_special_expenses3: line12DTotal3
        ? "$" + formatNumberWithDigit(line12DTotal3)
        : "",
      total_extraordinary_and_allowable_special_expenses4: line12DConbine
        ? "$" + formatNumberWithDigit(line12DConbine)
        : "",
      parents_pro_rata_share_of_income1: deviationLine3Value1
        ? formatNumberWithDigit(Number(deviationLine3Value1)) + "%"
        : "",
      parents_pro_rata_share_of_income2: deviationLine3Value2
        ? formatNumberWithDigit(Number(deviationLine3Value2)) + "%"
        : "",
      parents_pro_rata_share_of_income3: "100%",
      parnets_share_of_extraordinary_special_expenses1: line12FValue1
        ? "$" + formatNumberWithDigit(line12FValue1)
        : "",
      parnets_share_of_extraordinary_special_expenses2: line12FValue2
        ? "$" + formatNumberWithDigit(line12FValue2)
        : "",
      parnets_share_of_extraordinary_special_expenses3: line12FConbine
        ? "$" + formatNumberWithDigit(line12FConbine)
        : "",
      deviation_for_extraordinary_special_expenses1: line12GValue1
        ? "$" + formatNumberWithDigit(line12GValue1)
        : "",
      deviation_for_extraordinary_special_expenses2: line12GValue2
        ? "$" + formatNumberWithDigit(line12GValue2)
        : "",
      proposed_low_income_deviation:
        worksheetData.deviations_schedule_e.proposed_low_income_deviation,
      parenting_time_deviation1: worksheetData.deviations_schedule_e
        .parenting_time_deviation1
        ? "$" + worksheetData.deviations_schedule_e.parenting_time_deviation1
        : "",
      parenting_time_deviation2: worksheetData.deviations_schedule_e
        .parenting_time_deviation2
        ? "$" + worksheetData.deviations_schedule_e.parenting_time_deviation2
        : "",
      important_requirement_about_deviations1: finalLine10Value1
        ? "$" + formatNumberWithDigit(finalLine10Value1)
        : "",
      important_requirement_about_deviations2: finalLine10Value2
        ? "$" + formatNumberWithDigit(finalLine10Value2)
        : "",
      comment1: worksheetData.deviations_schedule_e.unjust_or_inappropriate,
      comment2: worksheetData.deviations_schedule_e.best_interests_of_children,
      comment3: worksheetData.deviations_schedule_e.impair_ability_to_maintain,
      judicial_discretion_for_low_income:
        worksheetData.deviations_schedule_e.judicial_discretion_for_low_income,
      extra_ordinary_educational_expenses: worksheetData.deviations_schedule_e
        .extra_ordinary_educational_expenses
        ? worksheetData.deviations_schedule_e.extra_ordinary_educational_expenses.map(
            (expense: {
              id: number;
              child_name: string;
              tuition_room_board_books_yearly: string;
              other_extraordinary_expenses_yearly: string;
              paid_by: string;
            }) => ({
              id: expense.id,
              child_name: expense.child_name,
              tuition_room_board_books_yearly:
                expense.tuition_room_board_books_yearly
                  ? expense.tuition_room_board_books_yearly
                  : "",
              other_extraordinary_expenses_yearly:
                expense.other_extraordinary_expenses_yearly
                  ? expense.other_extraordinary_expenses_yearly
                  : "",
              paid_by: expense.paid_by,
            })
          )
        : "",
      yearly_total_for_education_by_parent1:
        "$" +
        worksheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total1,
      yearly_total_for_education_by_parent2:
        "$" +
        worksheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total2,
      yearly_total_for_education_by_parent3:
        "$" +
        worksheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total3,
      monthly_total_for_education_by_parent1:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total1 / 12
        ),
      monthly_total_for_education_by_parent2:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total2 / 12
        ),
      monthly_total_for_education_by_parent3:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total3 / 12
        ),
      extra_ordinary_medical_expenses: worksheetData.deviations_schedule_e
        .extra_ordinary_medical_expenses
        ? worksheetData.deviations_schedule_e.extra_ordinary_medical_expenses.map(
            (expense: {
              id: number;
              child_name: string;
              extraordinary_medical_expenses_yearly: string;
              paid_by: string;
            }) => ({
              id: expense.id,
              child_name: expense.child_name,
              extraordinary_medical_expenses_yearly:
                expense.extraordinary_medical_expenses_yearly
                  ? expense.extraordinary_medical_expenses_yearly
                  : "",
              paid_by: expense.paid_by,
            })
          )
        : "",
      yearly_total_for_medical_by_parent1:
        "$" +
        worksheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total1,
      yearly_total_for_medical_by_parent2:
        "$" +
        worksheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total2,
      yearly_total_for_medical_by_parent3:
        "$" +
        worksheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total3,
      monthly_total_for_medical_by_parent1:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total1 / 12
        ),
      monthly_total_for_medical_by_parent2:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total2 / 12
        ),
      monthly_total_for_medical_by_parent3:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total3 / 12
        ),
      extra_ordinary_spaecial_expenses_expenses: worksheetData
        .deviations_schedule_e.special_expenses_child_rearing
        ? worksheetData.deviations_schedule_e.special_expenses_child_rearing.map(
            (expense: {
              id: number;
              child_name: string;
              explanation_of_expense: string;
              amount_paid_yearly: string;
              paid_by: string;
            }) => ({
              id: expense.id,
              child_name: expense.child_name,
              explanation_of_expense: expense.explanation_of_expense,
              amount_paid_yearly: expense.amount_paid_yearly
                ? expense.amount_paid_yearly
                : "",
              paid_by: expense.paid_by,
            })
          )
        : "",
      yearly_total_for_special_expenses_by_parent1:
        "$" +
        worksheetData.deviations_schedule_e
          .special_expenses_child_rearing_total1,
      yearly_total_for_special_expenses_by_parent2:
        "$" +
        worksheetData.deviations_schedule_e
          .special_expenses_child_rearing_total2,
      yearly_total_for_special_expenses_by_parent3:
        "$" +
        worksheetData.deviations_schedule_e
          .special_expenses_child_rearing_total3,
      monthly_total_for_special_expenses_by_parent1:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .special_expenses_child_rearing_total1 / 12
        ),
      monthly_total_for_special_expenses_by_parent2:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .special_expenses_child_rearing_total2 / 12
        ),
      monthly_total_for_special_expenses_by_parent3:
        "$" +
        formatNumberWithDigit(
          worksheetData.deviations_schedule_e
            .special_expenses_child_rearing_total3 / 12
        ),
      total_yearly_special_expenses: lineATotalValue
        ? "$" + formatNumberWithDigit(lineATotalValue)
        : "",
      monthly_average_of_special_expenses: lineBTotalValue
        ? "$" + formatNumberWithDigit(lineBTotalValue)
        : "",
      basic_child_support_obligation: lineCTotalValue
        ? "$" + lineCTotalValue
        : "",
      special_expenses_limitation: lineDTotalValue ? "$" + lineDTotalValue : "",
      line_e: lineETotalValue
        ? "$" + formatNumberWithDigit(lineETotalValue)
        : "",
      allowable_special_expenses_for_child_rearing1: lineFTotalValue
        ? "$" + formatNumberWithDigit(lineFTotalValue)
        : "",
      allowable_special_expenses_for_child_rearing2: lineGTotalValue
        ? "$" + formatNumberWithDigit(lineGTotalValue)
        : "",
      allowable_special_expenses_for_child_rearing3: lineHTotalValue
        ? "$" + formatNumberWithDigit(lineHTotalValue)
        : "",
    },
    /// Selft-Employment Calculator
    self_employment_calculator: {
      business_description1:
        worksheetData.self_employment_calculator.business_description1,
      business_description2:
        worksheetData.self_employment_calculator.business_description2,
      business_name1: worksheetData.self_employment_calculator.business_name1,
      business_name2: worksheetData.self_employment_calculator.business_name2,
      business_type1: worksheetData.self_employment_calculator.business_type1,
      business_type2: worksheetData.self_employment_calculator.business_type2,
      gross_receipts1: getValidValue(
        worksheetData.self_employment_calculator.gross_receipts1
      ),
      gross_receipts2: getValidValue(
        worksheetData.self_employment_calculator.gross_receipts2
      ),
      cost_of_sales1: getValidValue(
        worksheetData.self_employment_calculator.cost_of_sales1
      ),
      cost_of_sales2: getValidValue(
        worksheetData.self_employment_calculator.cost_of_sales2
      ),
      equals_gross_profit1: getValidValue(
        worksheetData.self_employment_calculator.equals_gross_profit1
      ),
      equals_gross_profit2: getValidValue(
        worksheetData.self_employment_calculator.equals_gross_profit2
      ),
      a_compensation_to_owner1: getValidValue(
        worksheetData.self_employment_calculator.a_compensation_to_owner1
      ),
      a_compensation_to_owner2: getValidValue(
        worksheetData.self_employment_calculator.a_compensation_to_owner2
      ),
      other_salaries_wages1: getValidValue(
        worksheetData.self_employment_calculator.other_salaries_wages1
      ),
      other_salaries_wages2: getValidValue(
        worksheetData.self_employment_calculator.other_salaries_wages2
      ),
      advertising_promotion1: getValidValue(
        worksheetData.self_employment_calculator.advertising_promotion1
      ),
      advertising_promotion2: getValidValue(
        worksheetData.self_employment_calculator.advertising_promotion2
      ),
      car_truck_expenses1: getValidValue(
        worksheetData.self_employment_calculator.car_truck_expenses1
      ),
      car_truck_expenses2: getValidValue(
        worksheetData.self_employment_calculator.car_truck_expenses2
      ),
      depreciation1: getValidValue(
        worksheetData.self_employment_calculator.depreciation1
      ),
      depreciation2: getValidValue(
        worksheetData.self_employment_calculator.depreciation2
      ),
      employee_benefits1: getValidValue(
        worksheetData.self_employment_calculator.employee_benefits1
      ),
      employee_benefits2: getValidValue(
        worksheetData.self_employment_calculator.employee_benefits2
      ),
      insurance_business1: getValidValue(
        worksheetData.self_employment_calculator.insurance_business1
      ),
      insurance_business2: getValidValue(
        worksheetData.self_employment_calculator.insurance_business2
      ),
      interest1: getValidValue(
        worksheetData.self_employment_calculator.interest1
      ),
      interest2: getValidValue(
        worksheetData.self_employment_calculator.interest2
      ),
      office_supplies_expenses1: getValidValue(
        worksheetData.self_employment_calculator.office_supplies_expenses1
      ),
      office_supplies_expenses2: getValidValue(
        worksheetData.self_employment_calculator.office_supplies_expenses2
      ),
      rent_or_lease_building1: getValidValue(
        worksheetData.self_employment_calculator.rent_or_lease_building1
      ),
      rent_or_lease_building2: getValidValue(
        worksheetData.self_employment_calculator.rent_or_lease_building2
      ),
      rent_or_lease_equipment1: getValidValue(
        worksheetData.self_employment_calculator.rent_or_lease_equipment1
      ),
      rent_or_lease_equipment2: getValidValue(
        worksheetData.self_employment_calculator.rent_or_lease_equipment2
      ),
      taxes_licenses1: getValidValue(
        worksheetData.self_employment_calculator.taxes_licenses1
      ),
      taxes_licenses2: getValidValue(
        worksheetData.self_employment_calculator.taxes_licenses2
      ),
      travel_entertainment1: getValidValue(
        worksheetData.self_employment_calculator.travel_entertainment1
      ),
      travel_entertainment2: getValidValue(
        worksheetData.self_employment_calculator.travel_entertainment2
      ),
      utilities1: getValidValue(
        worksheetData.self_employment_calculator.utilities1
      ),
      utilities2: getValidValue(
        worksheetData.self_employment_calculator.utilities2
      ),
      other_expenses1: getValidValue(
        worksheetData.self_employment_calculator.other_expenses1
      ),
      other_expenses2: getValidValue(
        worksheetData.self_employment_calculator.other_expenses2
      ),
      explanation_other_expenses: worksheetData.self_employment_calculator
        .explanation_other_expenses
        ? worksheetData.self_employment_calculator.explanation_other_expenses
        : "",
      notes_self_employment_income: worksheetData.self_employment_calculator
        .notes_self_employment_income
        ? worksheetData.self_employment_calculator.notes_self_employment_income
        : "",
      total_business_expenses1: getValidValue(
        worksheetData.self_employment_calculator.total_business_expenses1
      ),
      total_business_expenses2: getValidValue(
        worksheetData.self_employment_calculator.total_business_expenses2
      ),
      net_income1: getValidValue(
        worksheetData.self_employment_calculator.net_income1
      ),
      net_income2: getValidValue(
        worksheetData.self_employment_calculator.net_income2
      ),
      compensation_to_owner1: getValidValue(
        worksheetData.self_employment_calculator.compensation_to_owner1
      ),
      compensation_to_owner2: getValidValue(
        worksheetData.self_employment_calculator.compensation_to_owner2
      ),
      excessive_expenses1: getValidValue(
        worksheetData.self_employment_calculator.excessive_expenses1
      ),
      excessive_expenses2: getValidValue(
        worksheetData.self_employment_calculator.excessive_expenses2
      ),
      home_office_expenses1: getValidValue(
        worksheetData.self_employment_calculator.home_office_expenses1
      ),
      home_office_expenses2: getValidValue(
        worksheetData.self_employment_calculator.home_office_expenses2
      ),
      equipment_depreciation1: getValidValue(
        worksheetData.self_employment_calculator.equipment_depreciation1
      ),
      equipment_depreciation2: getValidValue(
        worksheetData.self_employment_calculator.equipment_depreciation2
      ),
      other1: getValidValue(worksheetData.self_employment_calculator.other1),
      other2: getValidValue(worksheetData.self_employment_calculator.other2),
      explanation_non_deductible_expenses:
        worksheetData.self_employment_calculator
          .explanation_non_deductible_expenses,
      total_non_deductible_expenses1: getValidValue(
        worksheetData.self_employment_calculator.total_non_deductible_expenses1
      ),
      total_non_deductible_expenses2: getValidValue(
        worksheetData.self_employment_calculator.total_non_deductible_expenses2
      ),
      total_self_employment_income1: getValidValue(
        worksheetData.self_employment_calculator.total_self_employment_income1
      ),
      total_self_employment_income2: getValidValue(
        worksheetData.self_employment_calculator.total_self_employment_income2
      ),
      amount_to_display_on_line_21_1: "",
      amount_to_display_on_line_21_2: "",
    },
    /// Footnotes
    foot_note: worksheetData.foot_note,
  };

  return worksheetResult;
}

/// handle get Proposed Low Income Deviations Amount
function getProposedLowIncomeDeviationAmount(
  arrayList: ProposedLowIncomeDeviation[],
  parent1: string,
  parent2: string
) {
  const resultP1 = arrayList.find(
    (item) => item.deviation_is_for === parent1
  )?.low_income_deviation;
  const resultP2 = arrayList.find(
    (item) => item.deviation_is_for === parent2
  )?.low_income_deviation;

  return { parent1: resultP1, parent2: resultP2 };
}

/// handle get Judicial Discretion Applied Amount
function getJudicialDiscretionAppliedAmount(
  arrayList: JudicialDiscretionForLowIncome[],
  parent1: string,
  parent2: string
) {
  const resultP1 = arrayList.find(
    (item) => item.deviation_is_for === parent1
  )?.low_income_deviation;
  const resultP2 = arrayList.find(
    (item) => item.deviation_is_for === parent2
  )?.low_income_deviation;

  return { parent1: resultP1, parent2: resultP2 };
}
