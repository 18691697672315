import React from "react";
import {
  Box,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Card
} from "@mui/material";
import style from "../searchWorksheet.module.css";
import SearchWorksheetButtons from "../../../components/searchWorksheetButtons";
import { useAppSelector } from "../../../globalState/hooks";
import moment from "moment";
import {
  getBasicFinalValueFor10Line,
  getBasicFinalValueFor2Line,
  getBasicFinalValueFor6Line,
} from "../../../utils/getBasicInfoCalculate";
import { CalculateNarestChildValues } from "../../../utils/calculateNarestChildValues";
import {
  formatNumber,
  formatNumberWithDigit,
} from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";

interface TableRow2 {
  id?: string;
  schedules: string;
  attached: boolean;
  notApplicable: boolean;
}

const BasicInformationTab: React.FC = () => {
  const WorkSheetData = useAppSelector((state) => state.createWorksheet);
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(WorkSheetData);
  const { finalLine6Value1, finalLine6Value2 } =
    getBasicFinalValueFor6Line(WorkSheetData);
  const { finalLine10Value1, finalLine10Value2 } =
    getBasicFinalValueFor10Line(WorkSheetData);
  const { t } = useTranslation();

  const cellStyleHeading = {
    border: "1px solid #ccc",
    textAlign: "center",
    padding: "15px 20px 15px 10px",
    background: "#F8F8F8",
  };

  const tableData2 = (
    id: string,
    schedules: string,
    attached: boolean,
    notApplicable: boolean
  ): TableRow2 => ({ id, schedules, attached, notApplicable });

  const isGrossIncomeValue =
    WorkSheetData.gross_income_schedule_a?.total_gross_income1 ||
      WorkSheetData.gross_income_schedule_a?.total_gross_income2
      ? true
      : false;

  const isAdjustedIncomeValue =
    Number(
      WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income1
    ) > 0 ||
      Number(
        WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income2
      ) > 0 ||
      WorkSheetData.adjusted_income_schedule_b?.pre_existing_child_support
        ?.length > 0 ||
      WorkSheetData.adjusted_income_schedule_b
        ?.proposed_adjustment_for_qualified_child?.length > 0
      ? true
      : false;

  const isAdditionalExpenses =
    WorkSheetData.health_insurance_child_care_schedule_d
      ?.health_insurance_premiums_parent1 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent2 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent3 ||
      WorkSheetData.health_insurance_child_care_schedule_d?.monthly_total1 !==
      null ||
      WorkSheetData.health_insurance_child_care_schedule_d?.monthly_total2 !==
      null ||
      WorkSheetData.health_insurance_child_care_schedule_d?.monthly_total3 !==
      null
      ? true
      : false;

  const isDeviationsFromPresumptive =
    WorkSheetData.deviations_schedule_e?.proposed_low_income_deviation
      ?.length ||
      WorkSheetData.deviations_schedule_e?.judicial_discretion_for_low_income
        ?.length ||
      WorkSheetData.deviations_schedule_e?.specific_nonspecific_deviation
        ?.length ||
      WorkSheetData.deviations_schedule_e?.extra_ordinary_educational_expenses
        ?.length ||
      WorkSheetData.deviations_schedule_e?.extra_ordinary_medical_expenses
        ?.length ||
      WorkSheetData.deviations_schedule_e?.special_expenses_child_rearing
        ?.length ||
      WorkSheetData.deviations_schedule_e?.parenting_time_deviation1 ||
      WorkSheetData.deviations_schedule_e?.parenting_time_deviation2 ||
      WorkSheetData.deviations_schedule_e?.unjust_or_inappropriate ||
      WorkSheetData.deviations_schedule_e?.best_interests_of_children ||
      WorkSheetData.deviations_schedule_e?.impair_ability_to_maintain
      ? true
      : false;
  const scheduleTableData = [
    tableData2("A", t("Gross Income"), isGrossIncomeValue, !isGrossIncomeValue),
    tableData2(
      "B",
      t("Adjusted Income"),
      isAdjustedIncomeValue,
      !isAdjustedIncomeValue
    ),
    tableData2(
      "D",
      t("Additional Expenses"),
      isAdditionalExpenses,
      !isAdditionalExpenses
    ),
    tableData2(
      "E",
      t("Deviations From Presumptive Amount"),
      isDeviationsFromPresumptive,
      !isDeviationsFromPresumptive
    ),
  ];

  const line2ValueTotal =
    Number(FinalValue1) || Number(FinalValue2)
      ? Number(FinalValue1) + Number(FinalValue2)
      : "";

  const line3Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
        Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
        100
      ).toFixed(2);

  const line3Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
        Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
        100
      ).toFixed(2);

  const ChildCountNo = WorkSheetData?.add_child_to_worksheet
    ? WorkSheetData?.add_child_to_worksheet?.filter(
      (child) => child.child_status === "included"
    ).length
    : 0;

  const line4NarestValue = CalculateNarestChildValues(
    line2ValueTotal ? Number(line2ValueTotal) : 0,
    ChildCountNo
  ).toFixed(2);

  const line5Value1 = (
    (Number(line4NarestValue) * Number(line3Value1)) /
    100
  ).toFixed(2);
  const line5Value2 = (
    (Number(line4NarestValue) * Number(line3Value2)) /
    100
  ).toFixed(2);

  const line7Value1 =
    line5Value1 || finalLine6Value1
      ? Number(Number(line5Value1) + Number(finalLine6Value1))
      : "";
  const line7Value2 =
    line5Value2 || finalLine6Value2
      ? Number(Number(line5Value2) + Number(finalLine6Value2))
      : "";

  const Line8Value1 = (
    Number(WorkSheetData?.health_insurance_premiums_parent1) / 12 +
    Number(WorkSheetData?.monthly_total1)
  ).toFixed(2);
  const Line8Value2 = (
    Number(WorkSheetData?.health_insurance_premiums_parent2) / 12 +
    Number(WorkSheetData?.monthly_total2)
  ).toFixed(2);

  const line9Value1 = Number(line7Value1) - Number(Line8Value1);
  const line9Value2 = Number(line7Value2) - Number(Line8Value2);

  const finalLineNo10Value1 = isNaN(Number(finalLine10Value1))
    ? 0
    : Number(finalLine10Value1);
  const finalLineNo10Value2 = isNaN(Number(finalLine10Value2))
    ? 0
    : Number(finalLine10Value2);

  const line11Value1 = Number(line9Value1) + finalLineNo10Value1;
  const line11Value2 = Number(line9Value2) + finalLineNo10Value2;

  const line12Value1 = WorkSheetData.social_security_payment_1;
  const line12Value2 = WorkSheetData.social_security_payment_2;

  const line13Value1 = WorkSheetData.veterans_affairs_disability_payment_1;
  const line13Value2 = WorkSheetData.veterans_affairs_disability_payment_2;

  const line14Value1 =
    Number(line11Value1) - Number(line12Value1) - Number(line13Value1);
  const line14Value2 =
    Number(line11Value2) - Number(line12Value2) - Number(line13Value2);

  return (
    <>
      <Box>
        {WorkSheetData.court_name && WorkSheetData.county_name ? (
          <Typography
            className={style.contentHeading}
            marginTop={"10px !important"}
            style={{ textTransform: "uppercase" }}
          >
            {t(
              "GEORGIA CHILD SUPPORT WORKSHEET {{courtName}} COURT OF {{countyName}} COUNTY STATE OF GEORGIA",
              {
                courtName: WorkSheetData.court_name,
                countyName: WorkSheetData.county_name,
              }
            )}
          </Typography>
        ) : null}
        <Grid
          container
          alignItems={{ xs: "start", sm: "center", md: "center" }}
          sx={{
            marginBottom: 2,
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
        >
          <Grid item sx={{ pr: 1 }}>
            <Typography variant="body1">
              <strong>{t("Plaintiff Name :")}</strong> {WorkSheetData?.plaintiff_name}
            </Typography>
          </Grid>
          <Grid item sx={{ p: { md: 1, sm: 1, xs: 0 } }}>
            <Typography variant="body1">
              <strong>Vs.</strong>
            </Typography>
          </Grid>
          <Grid item sx={{ p: { md: 1, sm: 1, xs: 0 } }}>
            <Typography variant="body1">
              <strong>{t("Defendant Name :")}</strong> {WorkSheetData?.defendant_name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#F8F8F8",
          border: "1px solid #DFDFDF",
          borderRadius: "5px 5px",
          opacity: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ px: 2, pt: 2 }}
              color="#404040"
              fontSize={14}
              variant="body2"
            >
              {t("Civil Action Case No.:")} {WorkSheetData?.civil_action_case_number}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{ px: 2, pt: 2 }}
              color="#404040"
              fontSize={14}
              variant="body2"
            >
              {t("DHS/DCSS Case No.:")} {WorkSheetData?.dhsc_no}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          sx={{ px: 2, pt: 2 }}
          color="#404040"
          fontSize={14}
          variant="body2"
        >
          {t("Comments for the Court:")}
        </Typography>
        <Typography
          sx={{ px: 2, pt: 1, pb: 2 }}
          color="#404040"
          fontSize={14}
          variant="body2"
        >
          {WorkSheetData?.comments_for_the_court}
        </Typography>
      </Card>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              color="#404040"
              fontSize={14}
              variant="body2"
              textTransform={"capitalize"}
            >
              {t("Type Of Action:")}{" "}
              {WorkSheetData?.type_of_action
                ? WorkSheetData?.type_of_action
                : "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="#404040" fontSize={14} variant="body2">
              {t("Initial Order Date:")}{" "}
              {WorkSheetData?.initial_support_order_date
                ? moment(WorkSheetData?.initial_support_order_date).format(
                  "MM-DD-YYYY"
                )
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 350 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    fontSize: 14,
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    fontSize: 14,
                  }}
                >
                  {t("Child Full Name")}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    fontSize: 14,
                  }}
                >
                  {t("Year Of Birth")}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    fontSize: 14,
                  }}
                >
                  {t("Child Status")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {WorkSheetData.add_child_to_worksheet &&
                WorkSheetData.add_child_to_worksheet.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      className={style.cellStyle2}
                      component="th"
                      scope="row"
                      sx={{ backgroundColor: "#CEFBC9" }}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      className={style.cellStyle2}
                      component="th"
                      scope="row"
                      sx={{ backgroundColor: "#CEFBC9" }}
                    >
                      {row.child_name}
                    </TableCell>
                    <TableCell
                      className={style.cellStyle2}
                      component="th"
                      scope="row"
                      sx={{ backgroundColor: "#CEFBC9" }}
                    >
                      {row.date_of_birth
                        ? row.date_of_birth
                        : row.year_of_birth}
                    </TableCell>
                    <TableCell
                      className={style.cellStyle2}
                      component="th"
                      scope="row"
                      sx={{
                        backgroundColor: "#CEFBC9",
                      }}
                    >
                      {row.child_status === "included"
                        ? "Included"
                        : "Excluded"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ pt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography color="#404040" fontSize={14} variant="body2">
              {t("Number Of Included Children:")}{" "}
              {WorkSheetData?.number_of_included_children}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="#404040" fontSize={14} variant="body2">
              {t("Noncustodial Parent:")} {WorkSheetData?.non_custodial_parent}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography color="#404040" fontSize={14} variant="body2">
              {t("Submitted By:")} {WorkSheetData?.make_available_to_court_by}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="#404040" fontSize={14} variant="body2">
              {t("Nonparent Custodian:")} {WorkSheetData?.non_parent_custodian_name}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <TableCell className={style.headerStyle}></TableCell>
                <TableCell className={style.headerStyle}>
                  {WorkSheetData?.parent_name_1}
                </TableCell>
                <TableCell className={style.headerStyle}>
                  {WorkSheetData?.parent_name_2}
                </TableCell>
                <TableCell className={style.headerStyle}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("1. Monthly Gross Income")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {WorkSheetData?.gross_income_schedule_a?.total_gross_income1
                    ? "$" +
                    formatNumber(
                      Number(
                        WorkSheetData.gross_income_schedule_a
                          .total_gross_income1
                      )
                    )
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {WorkSheetData?.gross_income_schedule_a?.total_gross_income2
                    ? "$" +
                    formatNumber(
                      Number(
                        WorkSheetData.gross_income_schedule_a
                          .total_gross_income2
                      )
                    )
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {(WorkSheetData?.gross_income_schedule_a
                    ?.total_gross_income1 ||
                    WorkSheetData?.gross_income_schedule_a
                      ?.total_gross_income2) &&
                    "$" +
                    formatNumber(
                      Number(
                        WorkSheetData.gross_income_schedule_a
                          ?.total_gross_income1 || 0
                      ) +
                      Number(
                        WorkSheetData.gross_income_schedule_a
                          ?.total_gross_income2 || 0
                      )
                    )}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("2. Monthly Adjusted Income")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {FinalValue1 ? "$" + formatNumberWithDigit(FinalValue1) : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {FinalValue2 ? "$" + formatNumberWithDigit(FinalValue2) : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line2ValueTotal
                    ? "$" + formatNumberWithDigit(Number(line2ValueTotal))
                    : ""}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("3. Pro Rata Shares of Combined Income")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {isNaN(Number(line3Value1))
                    ? ""
                    : formatNumberWithDigit(Number(line3Value1)) + "%"}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {isNaN(Number(line3Value2))
                    ? ""
                    : formatNumberWithDigit(Number(line3Value2)) + "%"}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {isNaN(Number(line3Value1)) || isNaN(Number(line3Value2))
                    ? ""
                    : "100.00%"}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("4. Basic Child Support Obligation (from the Table)")}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
                <TableCell className={style.cellStyle}>
                  ${formatNumber(Number(line4NarestValue))}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("5. Pro rata shares of Basic Child Support Obligation")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {isNaN(Number(line5Value1))
                    ? ""
                    : "$" + formatNumberWithDigit(Number(line5Value1))}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {isNaN(Number(line5Value2))
                    ? ""
                    : "$" + formatNumberWithDigit(Number(line5Value2))}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("6. Adjustment for Work Related Child Care and Health Insurance Expenses")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {finalLine6Value1
                    ? "$" + formatNumberWithDigit(finalLine6Value1)
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {finalLine6Value2
                    ? "$" + formatNumberWithDigit(finalLine6Value2)
                    : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("7. Adjusted Child Support Obligation")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {isNaN(Number(line7Value1))
                    ? ""
                    : "$" + formatNumberWithDigit(Number(line7Value1))}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {isNaN(Number(line7Value2))
                    ? ""
                    : "$" + formatNumberWithDigit(Number(line7Value2))}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("8. Adjustment for Additional Expenses Paid")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {Number(Line8Value1) > 0
                    ? "$" + formatNumberWithDigit(Number(Line8Value1))
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {Number(Line8Value2) > 0
                    ? "$" + formatNumberWithDigit(Number(Line8Value2))
                    : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("9. Presumptive Amount of Child Support")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line9Value1 ? "$" + formatNumberWithDigit(line9Value1) : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line9Value2 ? "$" + formatNumberWithDigit(line9Value2) : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  className="custom-table-cell-head"
                  colSpan={4}
                  sx={cellStyleHeading}
                >
                  <Typography fontWeight="bold" align="center" color="#404040">
                    {t("The Amount on Line 9 is the Presumptive Child Support Amount")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("10. Deviations From Presumptive Child Support Amount")}:
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {finalLine10Value1
                    ? "$" + formatNumberWithDigit(finalLine10Value1)
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {finalLine10Value2
                    ? "$" + formatNumberWithDigit(finalLine10Value2)
                    : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("11. Subtotal")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line11Value1
                    ? "$" + formatNumberWithDigit(line11Value1)
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line11Value2
                    ? "$" + formatNumberWithDigit(line11Value2)
                    : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("12. Social Security Payments to Children (excludes Supplemental Security Income (SSI))")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line12Value1 ? "$" + formatNumber(Number(line12Value1)) : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line12Value2 ? "$" + formatNumber(Number(line12Value2)) : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("13. Veterans Affairs Disability Payments to Children")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line13Value1 ? "$" + formatNumber(Number(line13Value1)) : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {line13Value2 ? "$" + formatNumber(Number(line13Value2)) : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("14. Final Monthly Child Support Amount (rounded to whole number)")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {Number(line14Value1) > 0
                    ? "$" + formatNumberWithDigit(Math.round(line14Value1))
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {Number(line14Value2) > 0
                    ? "$" + formatNumberWithDigit(Math.round(line14Value2))
                    : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell
                  className="custom-table-cell-head"
                  colSpan={4}
                  sx={cellStyleHeading}
                >
                  <Typography fontWeight="bold" align="center" color="#404040">
                    {t("The Amount on Line 13 is the Final Child Support Amount")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStyle}>
                  {t("15. Percentages for each parent for future Uninsured Health Expenses")}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {WorkSheetData.uninsured_health_expenses_1
                    ? Number(WorkSheetData.uninsured_health_expenses_1).toFixed(
                      2
                    ) + "%"
                    : ""}
                </TableCell>
                <TableCell className={style.cellStyle}>
                  {WorkSheetData.uninsured_health_expenses_2
                    ? Number(WorkSheetData.uninsured_health_expenses_2).toFixed(
                      2
                    ) + "%"
                    : ""}
                </TableCell>
                <TableCell
                  className={style.cellStyle}
                  sx={{ backgroundColor: "#F8F8F8" }}
                ></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ pt: 4 }}>
        <TableContainer>
          <Table sx={{ minWidth: 450 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    border: "1px solid #DFDFDF",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    border: "1px solid #DFDFDF",
                  }}
                >
                  {t("Schedules")}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    border: "1px solid #DFDFDF",
                  }}
                >
                  {t("Attached")}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#404040",
                    color: "#F8F8F8",
                    border: "1px solid #DFDFDF",
                  }}
                >
                  {t("Not Applicable")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduleTableData.map((row) => (
                <TableRow key={row.id}>
                  <TableCell className={style.cellStyle}>{row.id}</TableCell>
                  <TableCell className={style.cellStyle}>
                    {row.schedules}
                  </TableCell>
                  <TableCell className={style.cellStyle}>
                    <Checkbox checked={row.attached} disabled />
                  </TableCell>
                  <TableCell className={style.cellStyle}>
                    <Checkbox checked={row.notApplicable} disabled />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SearchWorksheetButtons />
      </Box>
    </>
  );
};

export default BasicInformationTab;
