import {
  SET_HEADERLINK,
  SET_HEADERNULL,
  SET_MULTILINGUAL_LANGUAGE,
} from "../../actions-Types/authActionTypes";

const initialState = {
  headerActiveURL: null,
  selectedLanguage: "en",
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload: string }
) => {
  switch (type) {
    case SET_HEADERLINK:
      return { ...state, headerActiveURL: payload };
    case SET_HEADERNULL:
      return { ...state, headerActiveURL: "" };
    case SET_MULTILINGUAL_LANGUAGE:
      return { ...state, selectedLanguage: payload };
    default:
      return state;
  }
};
