import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  Button,
  useMediaQuery,
} from "@mui/material";
import Header from "../layout/header";
import styles from "./home.module.css";
// import HomeImage from "../../assets/images/HomeImage.png";
import HomeComputerImage from "../../assets/images/homeComputerImg.webp";
import judicialLogo from "../../assets/images/judicialLogo.png";
// import signupImg from "../../assets/images/Component81.svg";
// import logInImg from "../../assets/images/Component82.svg";
import workSheetImg from "../../assets/images/Component83.svg";
import BlueBgImg from "../../assets/images/BgBlueImg.png";
import BlueBgImgSM from "../../assets/images/bgBlueSM.png";
import Announcement from "../../assets/images/annauHome.svg";
import AnnouncementHover from "../../assets/images/annaHover2.svg";
// import signupImgHover from "../../assets/images/ComponentHover81.svg";
// import logInImgHover from "../../assets/images/ComponentHover82.svg";
import workSheetImgHover from "../../assets/images/ComponentHover83.svg";
import CustomModal from "../../components/customModal";
import homeService from "../../services/home-service";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../utils/userDetailsCommonType";

interface ApiResponse<T> {
  data: T;
}
interface AnnouncementData {
  id: number;
  description: string;
}
const Home: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userDetails = useSelector((state: RootState) => state.authReducer);
  const isUserLoggedIn: boolean = !!userDetails?.loginDetials?.isUserLoggedIn;
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [hoveredImage, setHoveredImage] = useState<number | null>(null);
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const [modalContentIndex, setModalContentIndex] = useState<number>(0);
  const [announcementData, setAnnouncementData] = useState<AnnouncementData[]>(
    []
  );

  useEffect(() => {
    handleGetAnnouncementsData();
  }, []);
  const handleFirstModalOpen = (index: number) => {
    setModalContentIndex(index);
    setFirstModalOpen(true);
  };
  const handleFirstModalClose = () => setFirstModalOpen(false);

  const handleGetAnnouncementsData = () => {
    homeService
      .getAnnouncementsListAPI()
      .then(
        (response: { data: { results: ApiResponse<AnnouncementData[]> } }) => {
          if (response?.data?.results.data?.length > 0) {
            setAnnouncementData(response?.data?.results.data);
          } else {
            setAnnouncementData([]);
          }
        }
      )
      .catch((error) => {
        console.log(error, "Error");
      });
  };

  const isMediumScreen = useMediaQuery(
    "(min-width:900px) and (max-width:1200px)"
  );

  const gettingStartedItems = [
    // {
    //   img: signupImg,
    //   hoverImg: signupImgHover,
    //   title: "Create Account",
    //   text: "Please <strong style='font-size: 14px;'>Create Account</strong> to use the Child Support Calculator.",
    // },
    // {
    //   img: logInImg,
    //   hoverImg: logInImgHover,
    //   title: "Login",
    //   text: "After creating  your <strong style='font-size: 14px;'>Account</strong>, click <strong style='font-size: 14px;'>Login</strong>.",
    // },
    {
      img: workSheetImg,
      hoverImg: workSheetImgHover,
      style: { cursor: "pointer" },
      title: t("Create Worksheet"),
      text: `${t("Then click the")} <strong style='font-size: 14px;'>${t(
        "Worksheet"
      )}</strong> ${t(
        "drop-down, and select"
      )} <strong style='font-size: 14px;'>${t("Create")}</strong> ${t(
        "to begin a new worksheet."
      )}`,
      onClick: (isLoggedIn: boolean) => {
        if (isLoggedIn) {
          navigate("/WorkSheets/createWorksheet");
        } else {
          navigate("/login");
        }
      },
    },
  ];

  return (
    <>
      <Header />
      <div className={styles.homeBody}>
        <div className={styles.homeContent}>
          <Box sx={{ background: "#F8F8F8" }}>
            <Grid container spacing={2} className={styles.homePage}>
              <Grid item xs={12} md={6} style={{ paddingTop: "0%" }}>
                <Box
                  component="img"
                  // src={HomeImage}
                  src={HomeComputerImage}
                  alt="Example"
                  sx={{
                    width: "100%",
                    height: "auto",
                    paddingTop: "0px",
                    borderRadius: "0px 0px 22.5% 0px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  minHeight: { md: "275px", sm: "275px", xs: "350px" },
                }}
                style={{ paddingTop: "0%" }}
              >
                <Box my={2} sx={{ margin: " 0 25% 0 8%;" }}>
                  <Typography variant="h5" component="h1" gutterBottom>
                    {t("Welcome to")}
                  </Typography>
                  <Typography variant="h4" className="main_heading">
                    {t("Georgia Child Support Calculator")}
                  </Typography>
                  <Typography
                    variant="body1"
                    my={1}
                    sx={{ fontSize: { xs: 14, md: 13 }, textAlign: "justify" }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: isMediumScreen ? "16px" : "inherit",
                      }}
                    >
                      {t("Georgia Child Support Commission:")}
                    </span>{" "}
                    {t(
                      "The Georgia Child Support Calculator has been developed and made available by the Georgia Child Support Commission as the official calculator for Georgia&apos;s Child Support Guidelines statute found at O.C.G.A. §19-6-15. Information entered in the calculator is used to determine a presumptive amount of child support that may be deviated from to reach a final child support amount. The PDF forms may be printed for filing with the court and consist of a Worksheet and Schedules. Begin by entering information for your Worksheet on the Basic Information Worksheet tab. Helpful instructions are included to assist as you navigate the calculator."
                    )}
                    {t(
                      "On January 3, 2024, Georgia's Child Support Calculator,"
                    )}
                    {t(
                      "Version 1.4, was released to update the self-employment"
                    )}
                    {t(
                      "taxes paid formula on Schedule B as required by O.C.G.A. §"
                    )}
                    {t("19-6-15 (f)(5)(A).")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ background: "#fff" }}>
            <Box sx={{ margin: { md: "1% 15% 0% 15%", xs: "1% 12% 0% 12%" } }}>
              <Grid container spacing={2}>
                <Grid item xs={12} marginY={4}>
                  <Typography
                    variant="h4"
                    style={{ textAlign: "center" }}
                    className="main_heading"
                  >
                    {t("Child Support Calculator - Getting Started")}
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                  >
                    {gettingStartedItems.map((item, index) => (
                      <Box
                        key={index}
                        flex={1}
                        p={1}
                        minWidth="250px"
                        textAlign="center"
                        mb={1}
                      >
                        <Box
                          component="img"
                          src={
                            hoveredImage === index ? item.hoverImg : item.img
                          }
                          style={item.style}
                          alt={`Image ${index + 1}`}
                          sx={{
                            minHeight: {
                              md: "240px",
                              sm: "224px",
                              xs: "190px",
                            },
                            width: hoveredImage === index ? "30%" : "28%",
                            height: "auto",
                            transition: "transform 0.3s ease-in-out",
                          }}
                          onMouseEnter={() => setHoveredImage(index)}
                          onMouseLeave={() => setHoveredImage(null)}
                          onClick={() =>
                            item.onClick && item.onClick(isUserLoggedIn)
                          }
                        />
                        <Typography
                          variant="h6"
                          className={styles.blockHeading}
                          marginBottom={1}
                          fontSize={14}
                          onClick={() =>
                            item.onClick && item.onClick(isUserLoggedIn)
                          }
                          style={item.style}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          className="gettingSatartedText"
                          variant="h6"
                          fontSize={14}
                          sx={{ mx: 3 }}
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        ></Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box textAlign="center" marginY={{ md: 3 }}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: 5,
                        mx: 1,
                        background: "#0464AB",
                        textTransform: "none",
                        minWidth: { xs: 170 },
                      }}
                      disabled
                    >
                      {t("Getting Started Manual")}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        borderRadius: 5,
                        mx: 1,
                        color: "#0464AB",
                        borderColor: "#0464AB",
                        textTransform: "none",
                        minWidth: { xs: 170 },
                        mt: { md: 0, xs: 2 },
                      }}
                      color="inherit"
                    >
                      {t("Scheduled Trainings")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box sx={{ background: "#F8F8F8" }}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sx={{
                  margin: { md: "3rem 150px", xs: "1rem 3rem" },
                  paddingLeft: { xs: "20px!important" },
                }}
              >
                <Typography
                  variant="h4"
                  marginBottom={1}
                  textAlign="center"
                  className="main_heading"
                >
                  {t("Announcements")}
                </Typography>
                <Box display="flex" justifyContent="center" flexWrap="wrap">
                  {announcementData.map((item, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      sm={12}
                      md={index < 3 ? 4 : 4}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                        fontSize: "14px!important",
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          padding: "3rem 2rem",
                          borderRadius: "35px",
                          minWidth: isMediumScreen
                            ? "175px"
                            : { md: "250px", sm: "250px", xs: "200px" },
                          maxWidth: isMediumScreen ? "175px" : "300px",
                          minHeight: "200px",
                          position: "relative",
                          overflow: "hidden",
                          "&::before": {
                            content: '""',
                            position: "absolute",
                            top: "100%",
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "#0464AB",
                            transition: "top 0.3s ease-in-out",
                            zIndex: 0,
                          },
                          "&:hover::before": {
                            top: 0,
                          },
                          "&:hover .hoverText": {
                            color: "#fff",
                          },
                          "&:hover .hoverButton": {
                            backgroundColor: "#FBB522",
                            color: "#000",
                          },
                        }}
                      >
                        <Box sx={{ position: "relative", zIndex: 1 }}>
                          <Typography
                            className="hoverText"
                            marginBottom={2}
                            textAlign="center"
                          >
                            <img
                              src={
                                hoveredIndex === index
                                  ? AnnouncementHover
                                  : Announcement
                              }
                              alt="img"
                              style={{
                                width: "22%",
                                height: "auto",
                                transition: "filter 0.3s ease-in-out",
                              }}
                            />
                          </Typography>
                          <Typography
                            className="hoverText"
                            variant="body1"
                            textAlign="center"
                            fontSize={14}
                          >
                            {item.description?.length > 150
                              ? item.description.substr(0, 150).concat("...")
                              : item.description}
                          </Typography>
                          <Typography textAlign="center" marginTop={3}>
                            <Button
                              className="hoverButton"
                              variant="outlined"
                              onClick={() => handleFirstModalOpen(index)}
                              sx={{
                                borderRadius: 5,
                                mx: 1,
                                color: "#0464AB",
                                borderColor: "#0464AB",
                                textTransform: "none",
                              }}
                              color="inherit"
                            >
                              {t("Read More")}
                            </Button>
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              backgroundImage: {
                xs: `url(${BlueBgImgSM})`,
                sm: `url(${BlueBgImgSM})`,
                md: `url(${BlueBgImg})`,
              },
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: {
                md: "40vh",
                sm: "100vh",
                xs: "100vh,",
              },
              width: "100%",
              display: "flex",
            }}
          >
            <Grid
              container
              spacing={2}
              className={styles.homePage}
              display={"flex"}
              alignItems={"center"}
            >
              <Grid
                item
                xs={12}
                md={3}
                sx={{
                  borderRight: "1px solid #DFDFDF",
                  paddingTop: "0px!important",
                  paddingLeft: "0px!important",
                  textAlign: { md: "right", sm: "center" },
                  display: { md: "block", xs: "flex" },
                  justifyContent: { md: "inherit", xs: "center" },
                }}
              >
                <Box
                  component="img"
                  src={judicialLogo}
                  alt="img"
                  sx={{
                    width: { xs: "45%", sm: "27%", md: "40%" },
                    height: "auto",
                    pr: { md: "5rem", xs: "0rem" },
                    mt: { md: "0", xs: "5rem", sm: "2rem" },
                  }}
                />
              </Grid>
              <Grid md={9}>
                <Typography
                  variant="body1"
                  color="white"
                  fontWeight={300}
                  fontSize={{ md: 16 }}
                  sx={{
                    textAlign: "justify",
                    margin: {
                      md: "0 6rem 0 3rem",
                      sm: "0 5rem 3rem 5rem",
                      xs: "3rem 2rem 6rem 3rem",
                    },
                  }}
                >
                  {t("On January 3, 2024, Georgia's Child Support Calculator,")}
                  {t("Version 1.4, was released to update the self-employment")}
                  {t(
                    "taxes paid formula on Schedule B as required by O.C.G.A. §"
                  )}
                  {t("19-6-15 (f)(5)(A).")}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
        <CustomModal
          open={firstModalOpen}
          onClose={handleFirstModalClose}
          actionButtonLabel=""
          title=""
          boxStyle={{ width: { md: "30%", sm: "50%", xs: "90%" } }}
        >
          <Typography sx={{ mt: 1 }}>
            {firstModalOpen && announcementData[modalContentIndex].description}
          </Typography>
        </CustomModal>
      </div>
    </>
  );
};

export default Home;
