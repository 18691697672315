import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  IconButton,
  SelectChangeEvent,
  Button,
  Breadcrumbs,
  Link
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CustomDropdown from "../../../components/drop-down";
import { useDispatch } from "react-redux";
import { headerActiveLink } from "../../../globalState/actions/headerAction";
import searchFolderWorksheet from "../../../services/searchFolderWorksheet";
import { showToast } from "../../../components/toastNotification";
import { useTranslation } from "react-i18next";

const MakeAvailableToCourt = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectSubmittedBy, setSelectSubmittedBy] = useState("");
  const { t } = useTranslation();

  const handleBackClick = () => {
    dispatch(headerActiveLink("/searchFolderWorksheet"));
    navigate("/searchFolderWorksheet");
  };
  const handleDropdownSubmittedBy = (event: SelectChangeEvent) => {
    setSelectSubmittedBy(event.target.value);
  };
  ///-------------API calling Function start-------------------
  /// API called for Make Available To Court By Id
  const handleMakeAvailableToCourtById = () => {
    if (selectSubmittedBy) {
      const tempeUrl = window.location.pathname;
      const worksheetId = tempeUrl.substring(tempeUrl.lastIndexOf("/") + 1);
      const inputData = {
        make_available_to_court_by: selectSubmittedBy
      };
      searchFolderWorksheet
        .postMakeAvailableToCourtAPI(inputData, Number(worksheetId))
        .then((response) => {
          if (
            response?.data?.message === "Made available to court successfully"
          ) {
            showToast(t(response?.data?.message), "success");

            navigate("/pdfViewPage", { state: { worksheetId: Number(worksheetId) } });
            console.log(worksheetId);

          } else {
            showToast(t(response?.data?.message), "error");
          }
        })
        .catch((error) => {
          console.log(error, "API Error");
        });
    } else {
      showToast("Submitted By cannot be blank", "error");
    }
  };
  ///-------------API calling Function end-------------------
  const handleCancelCourt = () => {
    dispatch(headerActiveLink("/"));
    navigate("/");
  };
  return (
    <>
      <Box className="contain__area" sx={{
        display: "flex",
        flexDirection: "column",
      }}
      >
        <Box
          className="breadcrumbs"
          sx={{
            mt: { md: "2rem", sm: "1rem", xs: "1rem" },
            mb: { md: "1.5rem", sm: "1rem", xs: "1rem" },
            marginX: { md: "56px", sm: "0px", xs: "0px" },
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="#147AFF" href="/">
                {t("Home")}
              </Link>
              <Link underline="hover" color="#147AFF" href="/searchFolderWorksheet">
                {t("Search Folders-Worksheets")}
              </Link>
              <Typography color="#000000">{t("Make Available To Court")}</Typography>
            </Breadcrumbs>
          </div>
        </Box>
        <Paper
          elevation={0}
          sx={{
            borderRadius: "5px",
            border: "1px solid #DFDFDF",
            margin: { md: "0px 56px", sm: " 0px", xs: "0px" }
            // width: "90%"
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box>
                <Box
                  m={2}
                  sx={{
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <IconButton
                    aria-label="back"
                    onClick={handleBackClick}
                    sx={{
                      color: "#404040",
                      backgroundColor: "#DFDFDF",
                      marginRight: "30px",
                      "&:hover": {
                        backgroundColor: "#0464AB",
                        color: "#FFFFFF" // Change to your desired hover color
                      }
                    }}
                  >
                    <ArrowBackIosNewOutlinedIcon />
                  </IconButton>
                  <Typography
                    variant="h5"
                    color="#0464AB;"
                    gutterBottom
                    sx={{
                      fontSize: 28,
                      letterSpacing: "1px",
                      marginBottom: "0px"
                    }}
                    className="h5"
                  >
                    {t("Make Available To Court")}
                  </Typography>
                </Box>
                <Box
                  borderRadius="5px"
                  m={2}
                  sx={{
                    backgroundColor: "#F8F8F8"
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      sx={{
                        mx: 1,
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "left",
                        fontWeight: "600",
                        textAlign: "left",
                        fontFamily: "Inter",

                        px: 1,
                        py: 2
                      }}
                    >
                      {t("Please read all information in this dialog box before proceeding. A worksheet should only be made available to the court after a hearing date has been scheduled. By selecting “Make Available to Court” you will give the presiding judge in your hearing the ability to review, edit or print this worksheet. A printed copy of this child support worksheet needs to be filed with the court prior to the hearing.")}
                      <br />
                      <br />
                      {t("To make this worksheet available for the court, click in the “Submitted By” field and use the drop-down to select the person type/entity making the worksheet available to the court, i.e., plaintiff, defendant, etc.")}
                      <br />
                      <br />
                      {t("Click the “Make Available To Court” button and a PDF “Certificate of Service” form will open in a different window. If the form does not display, check your computer for a pop-up blocker that may prevent the PDF form from loading. If a pop-up blocker is found, change the option to allow the pop-up of the PDF form. The PDF “Certificate of Service” form will display.")}
                      <br />
                      <br />
                      {t("Instructions for PDF form: The PDF “Certificate of Service” form will auto-populate the court and county, parties’ names, case number, if already known, date of the Child Support Worksheet (see bottom margin), today’s date, and the name of the party making the worksheet available for the court. Print the form. In the other blank address fields, fill in the name and address of the opposing party in the middle section of the form, and then fill in your address and phone number in the remaining section at the bottom left part of the form. Attach the form to a printed copy of the Child Support Worksheet when you file it with the court and when you mail it to the opposing party.")}
                    </Typography>
                    <Grid container spacing={1} mx={1}>
                      <Grid item xs={10} md={4} >
                        <CustomDropdown
                          name="submittedBy"
                          label={t("Submitted By")}
                          value={selectSubmittedBy}
                          onChange={handleDropdownSubmittedBy}
                          options={[
                            { value: "Plaintiff", label: "Plaintiff" },
                            { value: "Defendant", label: "Defendant" },
                            { value: "DHS/DCSS", label: "DHS/DCSS" },
                            { value: "Joint", label: "Joint" },
                            { value: "3rd Party", label: "3rd Party" }
                          ]}
                          placeholder={t("- Choose Who Submitted -")}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}></Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                          mb: 2,
                          mt: 0,
                          paddingRight: { xs: '15px' }
                        }}
                      >
                        <Button
                          variant="contained"
                          disableElevation
                          sx={{
                            borderRadius: "21px",
                            backgroundColor: "#0464AB",
                            width: "fit-content",
                            height: 30,
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#FBB522",
                              color: "#022E4C",
                              fontWeight: "600"
                            }
                          }}
                          onClick={handleMakeAvailableToCourtById}
                        >
                          {t("Make Available To Court")}
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          sx={{
                            borderRadius: "20px",
                            p: 1,
                            m: 2,
                            width: 100,
                            height: 30,
                            fontSize: "14px",
                            fontWeight: "600",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#FBB522",
                              color: "#022E4C",
                              fontWeight: "600",
                              border: "none"
                            }
                          }}
                          onClick={handleCancelCourt}
                        >
                          {t("Cancel")}
                        </Button>{" "}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box >
    </>
  );
};

export default MakeAvailableToCourt;
