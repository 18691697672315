import React, { useState, ChangeEvent, useEffect } from "react";
import styles from "../../pages/worksheet/workSheet.module.css";
import styles1 from "./adminfaq.module.css";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import CustomInput from "../../components/inputField";
import CustomModal from "../../components/customModal";
import TextareaField from "../../components/text-area";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import ExportFileDropdown from "../../components/exportFileDropDown";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
import ExportOptionData from "../../components/exportDataSelection";
import fAQService from "../../services/faq-service";
import { showToast } from "../../components/toastNotification";
import DeleteModal from "../../components/globalDeleteModal";
import { getFileName } from "../../utils/fileNameUtil";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import { useTranslation } from "react-i18next";

interface Column {
  id: keyof Data;
  label: string;
}

interface Data {
  id: number;
  term: string;
  spanishTitle: string;
  explanation: string;
  spanishDescription: string;
  action: string | JSX.Element;
}
interface AdminFAQData {
  id: number;
  term: string;
  explanation: string;
}
interface formInputState {
  term: string;
  explanation: string;
  spanishTitle: string;
  spanishExplanation: string;
}
interface TableSearchData {
  term: string;
  explanation: string;
}
const AdminFAQ = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createFAQ, setCreateFAQ] = useState(false);
  const [addModalData, setAddModalData] = useState<formInputState>({
    term: "",
    explanation: "",
    spanishTitle: "",
    spanishExplanation: "",
  });
  const [errors, setErrors] = useState<Partial<formInputState>>({});
  const [adminFAQData, setAdminFAQData] = useState<AdminFAQData[]>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [totalTableRecords, setTotalTableRecords] = useState(0);
  const [updateDeleteID, setUpdateDeleteID] = useState(0);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  }>({
    key: "number",
    direction: "ascending",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isUpdateData, setIsUpdateData] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [tableSearchData, setTableSearchData] = useState<TableSearchData>({
    term: "",
    explanation: "",
  });
  const [deleteFAQModal, setDeleteFAQModal] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    const searchTime = setTimeout(() => {
      handleGetFAQListData();
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [pageIndex, perPage, tableSearchData, sortConfig]);

  const handleGetFAQListData = () => {
    setLoading(true);
    const tableOrdering =
      sortConfig.direction === "ascending"
        ? sortConfig.key
        : `-${sortConfig.key}`;
    const inputData = {
      pageIndex,
      perPage,
      tableSearchData,
      tableOrdering,
    };
    fAQService
      .getAdminFAQsDataAPI(inputData)
      .then(
        (response: {
          data: {
            count(count: number): number;
            results: { data: AdminFAQData[] };
          };
        }) => {
          if (response?.data.results.data?.length > 0) {
            setAdminFAQData(response?.data.results.data);
            setTotalTableRecords(response?.data.count);
          } else {
            setAdminFAQData([]);
            setTotalTableRecords(0);
          }
          setLoading(false);
        }
      )
      .catch((error) => {
        setLoading(false);
        console.log(error, "FAQs Error");
      });
  };
  const handleAddUpdateFAQmodalOpen = (
    rowData: AdminFAQData | null,
    isUpdate: string
  ) => {
    if (isUpdate === "update") {
      setAddModalData({
        term: rowData?.term || "",
        explanation: rowData?.explanation || "",
        spanishTitle: "",
        spanishExplanation: "",
      });
      setUpdateDeleteID(rowData?.id || 0);
      setIsUpdateData(false);
    } else {
      setAddModalData({
        term: "",
        explanation: "",
        spanishTitle: "",
        spanishExplanation: "",
      });
      setIsUpdateData(true);
    }

    setCreateFAQ(true);
  };

  const handleCreateFAQmodalClose = () => {
    setCreateFAQ(false);
    setErrors({});
  };
  const handleDeleteFAQMdlOpen = (id: number) => {
    setUpdateDeleteID(id);
    setDeleteFAQModal(true);
  };
  const handleDeleteFAQMdlClose = () => {
    setDeleteFAQModal(false);
  };

  const searchInputOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTableSearchData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPageIndex(1);
    setPerPage(10);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(Number(event.target.value));
    setPageIndex(1);
  };

  const handleModalInputChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | SelectChangeEvent
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    let formattedValue = value;
    formattedValue = value.charAt(0).toUpperCase() + value.slice(1);

    setAddModalData((prev) => {
      return {
        ...prev,
        [name]: formattedValue,
      };
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const DropdownChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    handleDownloadFAQsData(event.target.value);
  };

  const handleSort = (columnId: keyof Data) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: columnId, direction });
  };

  const sortedRows = React.useMemo(() => {
    if (
      sortConfig !== null &&
      sortConfig.key !== null &&
      sortConfig.key !== undefined
    ) {
      const key = sortConfig.key as keyof AdminFAQData;
      return [...adminFAQData].sort((a: AdminFAQData, b: AdminFAQData) => {
        const aValue = a[key];
        const bValue = b[key];

        if (aValue == null && bValue != null)
          return sortConfig.direction === "ascending" ? -1 : 1;
        if (aValue != null && bValue == null)
          return sortConfig.direction === "ascending" ? 1 : -1;
        if (aValue == null && bValue == null) return 0;

        if (aValue !== null && bValue !== null) {
          if (aValue < bValue) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }

        return 0;
      });
    }
    return adminFAQData;
  }, [adminFAQData, sortConfig]);

  const totalPages = Math.ceil(totalTableRecords / perPage);
  const startIndex = (pageIndex - 1) * perPage + 1;
  const endIndex = Math.min(pageIndex * perPage, totalTableRecords);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#404040",
      color: theme.palette.common.white,
      fontFamily: "Inter !important",
    },
    [`&.${tableCellClasses.body}`]: {
      fontFamily: "Inter !important",
    },
    border: "1px solid #DFDFDF",
  }));

  const OptionData = ExportOptionData(selectedValue);

  const TableColumns: Column[] = [
    { id: "id", label: "#" },
    { id: "term", label: t("English Title") },
    { id: "spanishTitle", label: t("Spanish Title") },
    { id: "explanation", label: t("English Description") },
    { id: "spanishDescription", label: t("Spanish Description") },
    { id: "action", label: t("Action") },
  ];

  /// handle table search reset data
  const handlePageResetGrid = () => {
    setTableSearchData({ term: "", explanation: "" });
    setSortConfig({ key: "number", direction: "ascending" });
    setSelectedValue("");
    setPageIndex(1);
    setPerPage(10);
  };
  const formValidate = (): boolean => {
    const newErrors: Partial<formInputState> = {};

    if (!addModalData.term.trim()) {
      newErrors.term = t("Term cannot be blank");
    }
    if (!addModalData.explanation.trim()) {
      newErrors.explanation = t("Explanation cannot be blank");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // updateAdminFAQAPI
  const handleAddUpdateFAQData = async () => {
    if (formValidate()) {
      setBtnLoading(true);
      const inputData = {
        term: addModalData.term,
        explanation: addModalData.explanation,
      };
      try {
        let response;
        if (isUpdateData) {
          response = await fAQService.addAdminFAQAPI(inputData);
        } else {
          response = await fAQService.updateAdminFAQAPI(
            inputData,
            updateDeleteID
          );
        }

        if (response && response.data) {
          showToast(
            t(`FAQ ${isUpdateData ? "created" : "updated"} successfully`),
            "success"
          );
          setCreateFAQ(false);
          handleGetFAQListData();
        } else {
          showToast(
            t(`Failed to ${isUpdateData ? "add" : "update"} data`),
            "error"
          );
        }
        setBtnLoading(false);
      } catch (error) {
        setBtnLoading(false);
        console.error(error, t("FAQ Operation Error"));
      }
    }
  };
  const handleDeleteFAQData = () => {
    setBtnLoading(true);
    fAQService
      .deleteFAQDataAPI(updateDeleteID)
      .then((response) => {
        if (response && response?.data.message) {
          showToast(t("FAQ deleted successfully"), "success");
          if (adminFAQData.length === 1) {
            setPageIndex(1);
          } else {
            handleGetFAQListData();
          }
          setDeleteFAQModal(false);
        } else {
          showToast(t("Failed to Delete FAQ"), "error");
        }
        setBtnLoading(false);
      })
      .catch((error) => {
        setBtnLoading(false);
        console.log(error, "FAQs Error");
        showToast(t("Failed to Delete FAQ"), "error");
      });
  };
  const handleDownloadFAQsData = (key: string) => {
    const arrayIDs = adminFAQData.map((item) => item.id);
    fAQService
      .getDownloadFAQsDataAPI(key, arrayIDs)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", getFileName(key, "faqs"));
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error(t("FAQs Error"), error);
      });
  };
  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };

  return (
    <>
      <Box
        className="contain__area"
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Box
          className="breadcrumbs"
          sx={{
            margin: {
              xs: "0px 20px 0px 1px",
              sm: "0px 56px 0px 1px",
              md: "30px 56px 0px 56px",
            },
          }}
        >
          <Box role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handleHomeRedirection}
              >
                {t("Home")}
              </Link>
              <Link
                to="/system-administration/updateFAQ"
                className="LinkColorDark"
              >
                {t("Frequently Asked Questions")}
              </Link>
            </Breadcrumbs>
          </Box>
        </Box>
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Paper
            sx={{
              borderRadius: "5px",
              border: "1px solid #DFDFDF",
              margin: { md: "10px 56px" },
            }}
          >
            <Box
              display="flex"
              justifyContent={{
                xs: "center",
                sm: "space-between!important",
                md: "space-between",
                lg: "space-between",
              }}
              flexWrap="wrap"
            >
              <Grid container spacing={2} padding={{ md: 2, sm: 1, xs: 1 }}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={{
                      md: "space-between",
                      sm: "space-between",
                      xs: "center",
                    }}
                    spacing={2}
                  >
                    <Grid item xs={12} sm={6}>
                      <Typography
                        className="h4 main_heading"
                        fontSize={22}
                        sx={{
                          color: "#0464AB",
                          marginLeft: { xs: "10px", sm: "5px", md: 0 },
                        }}
                      >
                        {t("Frequently Asked Questions")}
                      </Typography>
                    </Grid>
                    <Box sx={{ pt: 1, pr: 1 }}>
                      <Button
                        onClick={() => handleAddUpdateFAQmodalOpen(null, "Add")}
                        sx={{
                          borderRadius: "20px",
                          px: 2,
                          m: 1,
                          width: { xs: "100%", md: "100%" },
                          background: "#0464AB",
                          color: "#fff",
                          fontWeight: 600,
                          border: "1px solid #0464AB",
                          textTransform: "none",
                          "&:hover": {
                            backgroundColor: "#FBB522",
                            border: "1px solid #FBB522",
                            color: "#022E4C",
                          },
                        }}
                      >
                        {t("Create FAQ")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box
              display="flex"
              justifyContent={{
                md: "space-between",
                sm: "space-between",
                xs: "center",
              }}
              flexWrap="wrap"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                mx={2}
                marginLeft={{ xs: "30%", sm: "2%", md: "1.5%" }}
                flexWrap="wrap"
              >
                <Grid
                  container
                  alignItems={{ xs: "center" }}
                  justifyContent={{ xs: "center" }}
                  spacing={1}
                >
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography variant="body1">
                      {t("Showing")}{" "}
                      <strong>
                        {startIndex}-{endIndex}
                      </strong>{" "}
                      {t("of")} <strong>{totalTableRecords}</strong> {t("items.")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" mr={1}>
                        {t("Show")}
                      </Typography>
                      <Select
                        value={perPage}
                        onChange={handleRowsPerPageChange}
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "50px" }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <Typography variant="body1" ml={1}>
                        {t("Entries")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box> 
              <Box mt={1} mr={{ sm: 1, md: 3.5 }} ml={1}>
                <Grid
                  display={"flex"}
                  alignItems="center"
                  justifyContent="end"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="outlined"
                      className={styles1.resetButtoncss}
                      onClick={handlePageResetGrid}
                      sx={{
                        height: "35px",
                        width: {
                          xl: "max-content",
                          lg: "fit-content",
                          md: "135px",
                          sm: "135px",
                          xs: "fit-content",
                        },
                        fontSize: {
                          md: "12px",
                          sm: "12px",
                          xs: "9px",
                        },
                        textTransform: "none",
                      }}
                    >
                      <ReplayOutlinedIcon sx={{ marginRight: "2px" }} />
                      {t("Reset Grid")}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    paddingLeft={{ xs: "5px!important", md: "10px!important" }}
                  >
                    <Box>
                      <ExportFileDropdown
                        name="dropdown"
                        label=""
                        value={selectedValue}
                        onChange={DropdownChange}
                        options={OptionData.slice(1)}
                        placeholder={
                          <div style={{ display: "flex", alignItems: "left" }}>
                            <FileExporticon
                              className={
                                selectedValue === "Export"
                                  ? styles.activeSVG
                                  : styles.inactiveSVG
                              }
                            />
                            <Typography
                              sx={{
                                marginLeft: { md: "6px", sm: "6px", xs: "6px" },
                              }}
                            >
                              {OptionData[0].label}
                            </Typography>
                          </div>
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box mt={1}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 800 }}
                  stickyHeader
                  aria-label="sticky table"
                  className={styles1.faqTable}
                >
                  <TableHead>
                    <TableRow>
                      {TableColumns.map((data, index) => (
                        <StyledTableCell
                          key={data.id}
                          className={
                            index === TableColumns.length - 1
                              ? styles1.sticky
                              : ""
                          }
                        >
                          <Box display="flex" alignItems="center">
                            {data.label}
                            {index !== 0 && data.id !== "action" && (
                              <IconButton
                                onClick={() => handleSort(data.id)}
                                size="small"
                                color="inherit"
                              >
                                {sortConfig.key === data.id ? (
                                  sortConfig.direction === "ascending" ? (
                                    <ArrowUpwardIcon fontSize="inherit" />
                                  ) : (
                                    <ArrowDownwardIcon fontSize="inherit" />
                                  )
                                ) : (
                                  <ArrowUpwardIcon fontSize="inherit" />
                                )}
                              </IconButton>
                            )}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {TableColumns.map((item, index) => (
                        <TableCell
                          sx={{ border: "1px solid #DFDFDF" }}
                          key={item.id}
                          className={
                            index === TableColumns.length - 1
                              ? styles1.sticky
                              : ""
                          }
                        >
                          {index !== 0 && index !== TableColumns.length - 1 && (
                            <CustomInput
                              key={`search-${item.id}`}
                              name={item.id}
                              disabled={
                                index === 2 || index === 4 ? true : false
                              }
                              value={
                                tableSearchData[
                                item.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputOnchange}
                              placeholder={t("Search")}
                              endAdornment={<SearchOutlinedIcon />}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={TableColumns.length}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            padding={2}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {sortedRows.map((row, i) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            {(pageIndex - 1) * perPage + i + 1}
                          </TableCell>
                          <TableCell>{row.term}</TableCell>
                          <TableCell>{"(not set)"}</TableCell>
                          <TableCell>{row.explanation}</TableCell>
                          <TableCell>{"(not set)"}</TableCell>
                          <TableCell
                            sx={{
                              position: "sticky",
                              zIndex: 1,
                              right: 0,
                              backgroundColor: "#fff",
                            }}
                          >
                            <Box display="flex" justifyContent="space-between">
                              <IconButton>
                                <EditIcon
                                  onClick={() =>
                                    handleAddUpdateFAQmodalOpen(row, "update")
                                  }
                                  height={13}
                                />
                              </IconButton>
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => handleDeleteFAQMdlOpen(row.id)}
                                  height={13}
                                />
                              </IconButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              <Box
                my={1}
                display="flex"
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "center",
                }}
                flexWrap="wrap"
              >
                <Box>
                  <Typography sx={{ marginX: "20px" }} variant="body1">
                    {t("Showing")}{" "}
                    <strong>
                      {startIndex}-{endIndex}
                    </strong>{" "}
                    {t("of")} <strong>{totalTableRecords}</strong> {t("items.")}
                  </Typography>
                </Box>
                <Box>
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={pageIndex}
                    onChange={handlePageChange}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
          <CustomModal
            open={createFAQ}
            onClose={handleCreateFAQmodalClose}
            actionButtonLabel={isUpdateData ? t("Create") : t("Update")}
            title={`${isUpdateData ? t("Create") : t("Update")} ${t("Frequently Asked Question")}`}
            save={handleAddUpdateFAQData}
            btnLoader={btnLoading}
          >
            <Grid container spacing={2} mb={2} mt={1}>
              <Grid item xs={12} md={12}>
                <Typography fontWeight="bold" color="#022E4C">
                  {t("Section1: English Frequently Asked Question")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <CustomInput
                    onChange={handleModalInputChange}
                    value={addModalData.term}
                    label={t("Title")}
                    name="term"
                    type="textarea"
                    placeholder={t("Enter Title")}
                    required={true}
                    error={errors.term}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <TextareaField
                    name="explanation"
                    label={t("Description")}
                    value={addModalData.explanation}
                    onChange={handleModalInputChange}
                    placeholder={t("Write Description")}
                    required={true}
                    error={errors.explanation}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography fontWeight="bold" color="#022E4C">
                  {t("Section2: Spanish Frequently Asked Question")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <CustomInput
                    onChange={handleModalInputChange}
                    value={addModalData.spanishTitle}
                    label={t("Title")}
                    name="title"
                    type="textarea"
                    placeholder={t("Enter Title")}
                    disabled={true}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box>
                  <TextareaField
                    name="description"
                    label={t("Description")}
                    value={addModalData.spanishExplanation}
                    onChange={handleModalInputChange}
                    placeholder={t("Write Description")}
                    disabled={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </CustomModal>
          <DeleteModal
            onClose={handleDeleteFAQMdlClose}
            open={deleteFAQModal}
            delete={handleDeleteFAQData}
            title={t("Delete FAQ")}
            btnLoader={btnLoading}
          />
        </Box>
      </Box>
    </>
  );
};

export default AdminFAQ;
