import React, {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  FocusEvent,
} from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  SelectChangeEvent,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Link,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import CustomDropdown from "../../../components/drop-down/index";
import CustomInput from "../../../components/inputField";
import CustomRadioGroup from "../../../components/radio-button";
import TextareaField from "../../../components/text-area";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/info.svg";
import { ReactComponent as Interrogation } from "../../../assets/images/interrogation.svg";
import CustomTooltip from "../../../components/tooltip";
import { useAppDispatch, useAppSelector } from "../../../globalState/hooks";
import {
  deleteFromArray,
  // emptyBasicInfoFields,
  updateArrayInfo,
  updateBasicInfo,
} from "../../../globalState/actions/create-worksheet-action";
import interrogation from "../../../assets/images/interrogation.svg";
import { ChildDetail } from "../../../types/create-worksheet";
import { generateYearDropdown, isFutureDate } from "../../../utils/constants";
// import AlertDialog from "../../../components/alertDialog";
import WorksheetButtons from "../../../components/worksheetButtons";
import SettingIcons from "../../../assets/images/SettingIcon.svg";
import style from "../workSheet.module.css";
import CustomModal from "../../../components/customModal";
import ReportTechnicalForm, {
  ReportTechnicalFormHandle,
} from "../../../components/reportTechnicalIssue";
import CurrencyValue from "../../../components/currencyInputWithoutSelect";
import { GenerateCaptcha } from "../../../utils/generateCaptcha";
import { showToast } from "../../../components/toastNotification";
import technicalReportService from "../../../services/technicalReport-service";
import createWorksheetService from "../../../services/worksheet/create-worksheet-service";
import { RootState } from "../../../utils/userDetailsCommonType";
import ValidationErrorModal from "../../../components/validationErrorModal";
import DatePickerField from "../../../components/datePickerField";
import dayjs, { Dayjs } from "dayjs";
import {
  ApiResponse,
  CountryData,
  CourtData,
  DropDown,
} from "../../../types/global";
import DeleteModal from "../../../components/globalDeleteModal";
import moment from "moment";
import { getBrowserName } from "../../../utils/getBrowserName";
import SafariDatePickerField from "../../../components/safariDatePickerField";
import { useTranslation } from "react-i18next";
import { sortParentsAlphabetically } from "../../../utils/sortParentsAlphabetically";

const RedAsteriskTypography = styled(Typography)({
  "& span": {
    color: "#FF0000",
  },
});
interface TechnicalReportInput {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
}
interface ChildFormErrors {
  childName?: string;
  year_of_birth?: string;
  date_of_birth?: string;
}
const BasicInformationWorksheet: React.FC = () => {
  const dispatch = useAppDispatch();
  const states = useAppSelector((state) => state.createWorksheet);

  const userDetails = useAppSelector((state: RootState) => state.authReducer);
  const formRef = useRef<ReportTechnicalFormHandle>(null);

  const [addChildInputValues, setAddChildInputValues] = useState({
    childName: "",
    year_of_birth: "",
    date_of_birth: "",
    child_status: "included",
    sex_of_child: "",
    file_number: "",
    case_number: "",
  });
  const [openChildInfo, setOpenChildInfo] = useState<boolean>(false);
  // const [customAlert, setCustomAlert] = useState<boolean>(false);
  // const [customAlertCancel, setCustomAlertCancel] = useState<boolean>(true);
  // const [alertMessage, setAlertMessage] = useState<string>("");
  const [isEdit, setIsEdit] = useState("");
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [reportTechnicalModal, setReportTechnicalModal] =
    useState<boolean>(false);
  const [courtAPIResData, setCourtAPIResData] = useState<CourtData[]>([]);
  const [courtData, setCourtData] = useState<DropDown[]>([]);
  const [countyData, setCountyData] = useState<DropDown[]>([]);
  const [noncustodialData, setNoncustodialData] = useState<DropDown[]>([]);
  const [inputValidationErrorModal, setInputValidationErrorModal] =
    useState<boolean>(false);
  const [inputValidationMessage, setInputValidationMessage] = useState("");
  const [isAddUserShow, setIsAddUserShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<ChildFormErrors>({});
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleGetCourtListData();
    handleGetCountyListData();
    dispatch(
      updateBasicInfo(
        "name_person_preparing_worksheet",
        userDetails.loginDetials.username
      )
    );
  }, []);

  useEffect(() => {
    if (states.parent_name_1.trim() || states.parent_name_2.trim()) {
      const optionsData = createOptionsValue(
        states.parent_name_1.trim(),
        states.parent_name_2.trim()
      );
      setNoncustodialData(optionsData);
    } else {
      setNoncustodialData([]);
    }
  }, [states.parent_name_1, states.parent_name_2]);

  ///-------------API calling Function start-------------------
  /// fetch Court List data
  const handleGetCourtListData = () => {
    createWorksheetService
      .getCourtListAPI()
      .then((response: { data: ApiResponse<CourtData[]> }) => {
        if (response?.data?.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (court: CourtData) => ({
              value: court.id.toString(),
              label: court.court_name,
            })
          );
          if (Number(states.court_id)) {
            const courtRecord = response.data.data.find(
              (x) => x.id === Number(states.court_id)
            );
            const isJuvenileExist: string | undefined = courtRecord?.court_name;
            if (isJuvenileExist === "Juvenile") {
              setIsAddUserShow(true);
            } else {
              setIsAddUserShow(false);
            }
          }
          if (userDetails.loginDetials.court_id) {
            dispatch(
              updateBasicInfo(
                "court_id",
                userDetails.loginDetials.court_id.toString()
              )
            );
          }
          setCourtAPIResData(response?.data?.data);
          setCourtData(finalData);
        } else {
          setCourtData([]);
          setCourtAPIResData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch County List data
  const handleGetCountyListData = () => {
    createWorksheetService
      .getCountyListAPI()
      .then((response: { data: ApiResponse<CountryData[]> }) => {
        if (response?.data.data?.length > 0) {
          const finalData: DropDown[] = response.data.data.map(
            (item: CountryData) => ({
              value: item.id.toString(),
              label: item.county_name,
            })
          );
          setCountyData(finalData);
          dispatch(
            updateBasicInfo(
              "county_id",
              userDetails.loginDetials.county_id.toString()
            )
          );
        } else {
          setCountyData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  ///-------------API calling Function end-------------------
  const courtRecords = courtAPIResData.find(
    (x) => x.id === Number(states.court_id)
  );
  const isJuvenileData: string | undefined = courtRecords?.court_name;

  const isJuvenilExist =
    isJuvenileData === "Juvenile" && states.add_child_to_worksheet.length > 0
      ? true
      : false;

  const handleReportModalOpen = () => {
    setInputCaptcha(GenerateCaptcha);
    setReportTechnicalModal(true);
  };
  const handleReportModalClose = () => setReportTechnicalModal(false);

  /// show Input validation messages
  const handleInputValidationErrorModal = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
  };

  const handleChildInfoOpen = () => {
    if (states.parent_name_1 === "" || states.parent_name_2 === "") {
      setInputValidationErrorModal(true);
      setInputValidationMessage(
        t("Please Enter the Names For Both Parents First")
      );
    } else {
      // parentsNameFields
      setOpenChildInfo(true);
    }
  };
  const handleChildInfoClose = () => {
    setOpenChildInfo(false);
  };
  const yearDropdownOptions = generateYearDropdown(2004);

  const handleDropdownChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    if (name === "court_id") {
      const courtRecord = courtAPIResData.find((x) => x.id === Number(value));
      const isJuvenileExist: string | undefined = courtRecord?.court_name;
      if (isJuvenileExist === "Juvenile") {
        if (states.add_child_to_worksheet.length > 0) {
          setInputValidationMessage(
            t(
              "You have to remove all children data in order to change to Juvenile court."
            )
          );
          setInputValidationErrorModal(true);
        } else {
          setIsAddUserShow(true);
          dispatch(updateBasicInfo(name, value));
        }
      } else {
        setIsAddUserShow(false);
        dispatch(updateBasicInfo(name, value));
      }
    } else {
      dispatch(updateBasicInfo(name, value));
    }
  };

  /// Input onChange code
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const validNumberRegex = /^\d*\.?\d*$/;
    const validTwoDecimalRegex = /^\d*\.?\d{0,2}$/;

    if (name === "dhsc_no") {
      if (value.length <= 9) {
        dispatch(updateBasicInfo(name, value));
      }
    } else if (
      (name === "social_security_payment_1" ||
        name === "social_security_payment_2" ||
        name === "veterans_affairs_disability_payment_1" ||
        name === "veterans_affairs_disability_payment_2") &&
      value !== ""
    ) {
      if (validNumberRegex.test(value) && parseFloat(value) >= 0) {
        if (validTwoDecimalRegex.test(value)) {
          dispatch(updateBasicInfo(name, value));
        }
      } else if (parseFloat(value) < 0) {
        setInputValidationMessage(t("Please Enter a Positive Number"));
        setInputValidationErrorModal(true);
        dispatch(updateBasicInfo(name, ""));
      }
    } else {
      dispatch(updateBasicInfo(name, value));
    }
  };
  /// 17.Uninsured health Input onChange
  const handleUninsuredInputOnchange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    let getValue = 0;
    if (Number(value) > 100) {
      setInputValidationErrorModal(true);
      setInputValidationMessage(
        t("Number should be less than or equal to 100%")
      );
      return;
    }
    if (name === "uninsured_health_expenses_1") {
      getValue = 100 - Number(value);
      dispatch(updateBasicInfo(name, value));
      dispatch(
        updateBasicInfo("uninsured_health_expenses_2", getValue.toFixed(2))
      );
    } else {
      getValue = 100 - Number(value);
      dispatch(updateBasicInfo(name, value));
      dispatch(
        updateBasicInfo("uninsured_health_expenses_1", getValue.toFixed(2))
      );
    }
  };

  /// 17.Uninsured health onBlur Input onChange
  // const uninsuredInputHandle = (event: FocusEvent<HTMLInputElement>) => {
  const uninsuredInputHandle = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numValue = parseFloat(value);
    let formattedValue = value;
    if (!isNaN(numValue)) {
      if (value.includes(".")) {
        formattedValue = numValue.toFixed(2);
      }
    }
    dispatch(updateBasicInfo(name, formattedValue));
  };
  /// handle date picker onchange
  const handleDateChange = (date: Dayjs | null, name: string) => {
    const formattedDate = date ? date.format("MM-DD-YYYY") : "";

    setAddChildInputValues((prev) => {
      return {
        ...prev,
        [name]: formattedDate,
      };
    });
    dispatch(updateBasicInfo(name, formattedDate));
  };
  const handleSafariDateChange = (date: Date | null, name: string) => {
    const dayjsDate: Dayjs | null = date ? dayjs(date) : null;
    const formattedDate = dayjsDate ? dayjsDate.format("MM-DD-YYYY") : "";

    // Update state with the formatted date
    setAddChildInputValues((prev) => ({
      ...prev,
      [name]: formattedDate,
    }));

    // Dispatch to Redux or other state management
    dispatch(updateBasicInfo(name, formattedDate));
  };

  /// create a Child drop-down data for options
  function createOptionsValue(firstName: string, lastName: string): DropDown[] {
    return [
      { value: firstName, label: firstName },
      { value: lastName, label: lastName },
      {
        value: `${firstName} and ${lastName}`,
        label: `${firstName} ${t("and")} ${lastName}`,
      },
    ];
  }

  const parentNameHandler = () => {
    if (states.parent_name_1 !== "" && states.parent_name_2 !== "") {
      const SortParentName = sortParentsAlphabetically(
        states.parent_name_1,
        states.parent_name_2
      );
      dispatch(updateBasicInfo("parent_name_1", SortParentName[0]));
      dispatch(updateBasicInfo("parent_name_2", SortParentName[1]));
    }
  };

  // const parentNameHandler = () => {
  //   if (states.parent_name_1 !== "" && states.parent_name_2 !== "") {
  //     if (
  //       states.parent_name_1.toLocaleLowerCase() ===
  //       states.parent_name_2.toLocaleLowerCase()
  //     ) {
  //       setCustomAlertCancel(false);
  //       setAlertMessage(
  //         "You have entered the exact same name for each parent (11.Parent's Name and 12. Parent's Name).The parent names must differ by at least by one letter or the calculator cannot alphabetize the names for"
  //       );
  //       setCustomAlert(true);
  //     } else {
  //       const message = `Please confirm that you entered the Parent Names correctly.\nPlease review and correct now as there will be no other opportunity to do so. \nSelect OK to accept Parent Names or Cancel to re-enter Parent Names.\n11. Parent's Name - ${states.parent_name_1} \n12. Parent's Name - ${states.parent_name_2}`;
  //       setAlertMessage(message);
  //       const optionsData = createOptionsValue(
  //         states.parent_name_1,
  //         states.parent_name_2
  //       );
  //       setNoncustodialData(optionsData);
  //       setCustomAlertCancel(true);
  //       setCustomAlert(true);
  //     }
  //   }
  // };

  // const handleConfirmAction = () => {
  //   if (
  //     states.parent_name_1.toLocaleLowerCase() ===
  //     states.parent_name_2.toLocaleLowerCase()
  //   ) {
  //     dispatch(
  //       emptyBasicInfoFields({
  //         fields: {
  //           parent_name_1: "",
  //           parent_name_2: ""
  //         }
  //       })
  //     );
  //   } else {
  //     dispatch(updateBasicInfo("parentsNameFields", true));
  //   }
  // };
  /// handle Modal input onchange
  const handleModalInputChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | SelectChangeEvent
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setAddChildInputValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error message
    }));
  };
  /// handle select date of birth change
  const handleBODDateChange = (date: Dayjs | null, name: string) => {
    const formattedDate = date ? date.format("MM-DD-YYYY") : "";

    setAddChildInputValues((prev) => {
      return {
        ...prev,
        [name]: formattedDate,
      };
    });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleBODDateChangeSafari = (date: Date | null, name: string) => {
    const formattedDate = date ? dayjs(date).format("MM-DD-YYYY") : "";

    setAddChildInputValues((prev) => ({
      ...prev,
      [name]: formattedDate,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  //// handle Input comment changes
  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    dispatch(updateBasicInfo(name, value));
  };

  const setModalDataEmpty = () => {
    handleChildInfoClose();
    setAddChildInputValues((prev) => {
      return {
        ...prev,
        childName: "",
        year_of_birth: "",
        date_of_birth: "",
        sex_of_child: "",
        file_number: "",
        case_number: "",
      };
    });
    setIsEdit("");
  };
  /// validation code
  const validateChildInfo = (values: typeof addChildInputValues) => {
    const errors: ChildFormErrors = {};

    if (!values.childName.trim()) {
      errors.childName = t("Child Name is required");
    }
    if (!values.year_of_birth && !values.date_of_birth) {
      // If both are missing, trigger the error
      errors.year_of_birth = t("Year of Birth  is required");
      errors.date_of_birth = t("Date of Birth is required");
    }

    return errors;
  };
  /// add new child function
  const addChildToWorksheet = () => {
    const errors = validateChildInfo(addChildInputValues);
    setFormErrors(errors);
    const isFormValid = Object.keys(errors).length === 0;
    let formattedDate = "";

    if (isFormValid) {
      if (addChildInputValues.date_of_birth) {
        formattedDate = moment(addChildInputValues.date_of_birth).format(
          "YYYY-MM-DD"
        );
      }
      if (isFutureDate(formattedDate)) {
        errors.date_of_birth = t("Future dates are not allowed.");
        setFormErrors(errors);
      } else {
        const newchiildData = {
          arrayKey: "add_child_to_worksheet",
          itemId: isEdit ? isEdit : "",
          newItemValues: {
            child_name: addChildInputValues.childName.trim(),
            year_of_birth: addChildInputValues.year_of_birth
              ? addChildInputValues.year_of_birth
              : null,
            date_of_birth: addChildInputValues.date_of_birth
              ? formattedDate
              : null,
            child_status: addChildInputValues.child_status,
            sex_of_child: addChildInputValues.sex_of_child,
            file_number: addChildInputValues.file_number,
            case_number: addChildInputValues.case_number,
          },
        };
        dispatch(updateArrayInfo(newchiildData));
        setModalDataEmpty();
      }
    }
  };
  /// handle modal close
  const handleDeleteMdlClose = () => {
    setDeleteModal(false);
  };

  /// delete childrens modal open
  const deletChildDataModalOpen = (id: string) => {
    setDeleteId(id);
    setDeleteModal(true);
  };

  ///delete childrens data
  const deletChildDataHandler = () => {
    dispatch(
      deleteFromArray({
        arrayKey: "add_child_to_worksheet",
        itemId: deleteId,
      })
    );
    setDeleteModal(false);
  };

  const editChildDataHandler = (item: ChildDetail) => {
    setIsEdit(item.id!);
    setAddChildInputValues((prev) => ({
      ...prev,
      childName: item.child_name.trim(),
      year_of_birth: item.year_of_birth || "",
      date_of_birth: item.date_of_birth || "",
      child_status: item.child_status,
      sex_of_child: item.sex_of_child,
      file_number: item.file_number,
      case_number: item.case_number,
    }));
    handleChildInfoOpen();
  };
  const handleSaveData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };
  /// API called for Technical Report
  const handleSubmitTechnicalReport = async (
    reportInputValues: TechnicalReportInput
  ) => {
    const inputData = {
      worksheet_id: Number(reportInputValues.worksheet_id),
      name: reportInputValues.name !== "" ? reportInputValues.name : null,
      email: reportInputValues.email,
      phone:
        reportInputValues.phone !== "" ? Number(reportInputValues.phone) : null,
      browser:
        reportInputValues.browser !== "" ? reportInputValues.browser : null,
      technical_issue: reportInputValues.technical_issue,
    };
    await technicalReportService
      .reportTechnicalIssueAPI(inputData)
      .then((response) => {
        if (response && response.data) {
          showToast(t(response.data.message), "success");
          setReportTechnicalModal(false);
        } else {
          showToast(t(response?.data?.message), "error");
        }
      })
      .catch((error) => {
        console.error(t(error), "API Error");
      });
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#404040",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    border: "1px solid #DFDFDF",
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    border: "1px solid #DFDFDF",
  }));

  return (
    <>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#E6F5FF",
          border: "1px solid #0464AB80",
          borderRadius: "20px 20px",
          opacity: 1,
        }}
      >
        <Typography
          sx={{
            px: { xs: 1, sm: 1.5, md: 2 },
            pt: { xs: 0.5, sm: 0.75, md: 1 },
            display: "flex",
            flexDirection: { xs: "row", sm: "row", md: "row" },
            alignItems: { xs: "flex-start", sm: "flex-start", md: "center" },
          }}
          color="#0464AB"
          fontSize={{ xs: 12, sm: 13, md: 14 }}
          fontWeight="bold"
        >
          <InfoIcon style={{ height: "17px" }} />
          <Box sx={{ px: { xs: "2px", sm: "3px", md: "4px" } }}>
            {t(
              "This section concerns Basic Information to be included in the Worksheet. Calculations from the Schedules will be used in the Worksheet."
            )}
          </Box>
        </Typography>
        <RedAsteriskTypography
          sx={{
            px: { xs: 1, sm: 1.5, md: 2 },
            pb: { xs: 0.5, sm: 0.75, md: 1 },
            color: "#404040",
            fontSize: { xs: 12, sm: 13, md: 14 },
          }}
        >
          <Box sx={{ pl: { xs: "15px", sm: "22px", md: "30px" } }}>
            {t("For help hover the")}{" "}
            <Interrogation
              style={{ height: "14px", verticalAlign: "middle" }}
            />{" "}
            {t("button. All required fields are denoted with an asterisk")}{" "}
            <span>*</span>
            <br />
            {t(
              "You may enter information in yellow fields only. Fields with green or white backgrounds are read only."
            )}
          </Box>
        </RedAsteriskTypography>
      </Card>
      <Box sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomDropdown
              name="court_id"
              label={t("1. Court Name")}
              value={states.court_id.toString()}
              onChange={handleDropdownChange}
              options={courtData}
              placeholder={t("- Choose Court -")}
              backgroundColor={isJuvenilExist ? "#CEFBC9" : "#FDFFA6"}
              tooltip={t(
                "Court in Georgia where current action is filed and heard and for which Child Support worksheet is created. "
              )}
              required
              disabled={isJuvenilExist}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomDropdown
              name="county_id"
              label={t("2. County Name")}
              value={states.county_id.toString()}
              onChange={handleDropdownChange}
              options={countyData}
              placeholder={t("- Choose County -")}
              backgroundColor="#FDFFA6"
              tooltip={t(
                "County in Georgia where current action has been or will be filed and heard for which Child Support Worksheet is created. "
              )}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="plaintiff_name"
              label={t("3. Plaintiff Name")}
              value={states.plaintiff_name}
              onChange={handleInputChange}
              placeholder={t("Enter Plaintiff Name")}
              backgroundColor="#FDFFA6"
              tooltip={t(
                "Person who  files or intends to file current case with Court."
              )}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="defendant_name"
              label={t("4. Defendant Name")}
              value={states.defendant_name}
              onChange={handleInputChange}
              placeholder={t("Enter Defendant Name")}
              backgroundColor="#FDFFA6"
              tooltip={t(
                "Person against whom you are filing the current case."
              )}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="non_parent_custodian_name"
              label={t("5. Nonparent Custodian Name")}
              value={states.non_parent_custodian_name}
              onChange={handleInputChange}
              placeholder={t("Enter Nonparent Custodian Name")}
              backgroundColor="#FDFFA6"
              tooltip={t(
                "Individual with legal custody of child, or who has legal right to seek, modify, or enforce child support."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="civil_action_case_number"
              label={t("6. Civil Action Case No")}
              value={states.civil_action_case_number}
              onChange={handleInputChange}
              placeholder={t("Enter Civil Action Case No")}
              backgroundColor={isJuvenilExist ? "#CEFBC9" : "#FDFFA6"}
              tooltip={t(
                "The Civil Action Case Number (CACN) is a unique number assigned by a Clerk of Court to each case filed in that court.  All legal pleadings filed in the same case will bear this CACN."
              )}
              disabled={isJuvenilExist}
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              type="number"
              name="dhsc_no"
              label={t("7. DHS/DCSS IV-D Case No. (Only used for DCSS cases)")}
              value={states.dhsc_no}
              onChange={handleInputChange}
              placeholder={t("Enter DHS/DCSS IV-D Case No.")}
              backgroundColor={isJuvenilExist ? "#CEFBC9" : "#FDFFA6"}
              tooltip={t(
                "Division of Child Support Services (DCSS) internal case number assigned to cases maintained on their Support Tracking and Reporting System ($TARS) database. Enter this 9-digit number in field provided, if applicable."
              )}
              disabled={isJuvenilExist}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomRadioGroup
              name="type_of_action"
              label={t("8. Type Of Action")}
              color="#9C9BAA"
              value={states.type_of_action}
              onChange={handleInputChange}
              options={[
                { value: "initial", label: t("Initial Action") },
                { value: "modification", label: t("Modification") },
              ]}
              tooltip={t(
                "Indicate whether this is a current action or a modification action."
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4}>
            {/* <CustomInput
              type="date"
              name="initial_support_order_date"
              label="9. Initial Child Support Order Date"
              value={states.initial_support_order_date}
              onChange={handleInputChange}
              placeholder="Enter Date"
              backgroundColor="#FDFFA6"
              tooltip="Enter the required information."
            />  */}
            {getBrowserName() === "Safari" ? (
              <SafariDatePickerField
                label={t("9. Initial Child Support Order Date")}
                value={states.initial_support_order_date}
                onChange={(date) =>
                  handleSafariDateChange(date, "initial_support_order_date")
                }
                tooltip={t(
                  "If this is a modification action, enter the date the first child support order was entered between the parties to this case that will be considered by the court for modification.  Subsequent orders resulting from modification are not considered."
                )}
                required={true}
                backgroundColor="#FDFFA6"
              />
            ) : (
              <DatePickerField
                label={t("9. Initial Child Support Order Date")}
                value={states.initial_support_order_date}
                onChange={(e) =>
                  handleDateChange(e, "initial_support_order_date")
                }
                tooltip={t(
                  "If this is a modification action, enter the date the first child support order was entered between the parties to this case that will be considered by the court for modification.  Subsequent orders resulting from modification are not considered."
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={8}>
            <TextareaField
              name="comments_for_the_court"
              label={t("10. Comments for the court")}
              value={states.comments_for_the_court}
              onChange={handleCommentChange}
              placeholder={t("Enter comments for the court")}
              backgroundColor="#FDFFA6"
              tooltip={t(
                "Box used only for purpose of communicating appropriate information to the judge or clerk of court concerning this worksheet.  Personal comments written about a party are not allowed.  Appropriate use may include: 1) version of worksheet, 2) if worksheet is for temporary or final hearing."
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Typography fontWeight="bold" sx={{ py: 2 }}>
        {t("Parent Name Instructions")}
      </Typography>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#E6F5FF",
          border: "1px solid #0464AB80",
          borderRadius: "20px 20px",
          opacity: 1,
          py: 2,
        }}
      >
        <Typography
          sx={{ px: 2, display: "flex" }}
          color="#0464AB"
          fontSize={14}
        >
          <InfoIcon width={20} />
          <Box sx={{ p: "4px" }}>
            {t("Parent&apos;s names entered on Lines")}{" "}
            <span style={{ fontWeight: "bold" }}>11</span> {t("and")}{" "}
            <span style={{ fontWeight: "bold" }}>12</span>{" "}
            {t(
              "are alphabetized and used as column headings in the calculator and on the printed forms."
            )}
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="parent_name_1"
              label={t("11. Parents Name")}
              value={states.parent_name_1}
              onChange={handleInputChange}
              placeholder={t("Enter Parent's Name")}
              backgroundColor={states.parentsNameFields ? "#FDFFA6" : "#FDFFA6"}
              tooltip={t("Biological or legally adoptive parent of a child. ")}
              onBlur={parentNameHandler}
              disabled={states.parentsNameFields}
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomInput
              name="parent_name_2"
              label={t("12. Parents Name")}
              value={states.parent_name_2}
              onChange={handleInputChange}
              placeholder={t("Enter Parent's Name")}
              backgroundColor={states.parent_name_2 ? "#FDFFA6" : "#FDFFA6"}
              tooltip={t("Biological or legally adoptive parent of a child. ")}
              onBlur={parentNameHandler}
              disabled={states.parentsNameFields}
              required
            />
          </Grid>
          <Grid item xs={12} md={12}></Grid>
          <Grid item xs={12} md={4}>
            <CustomDropdown
              name="non_custodial_parent"
              label={t("13. Noncustodial Parent")}
              value={states.non_custodial_parent}
              onChange={handleDropdownChange}
              options={noncustodialData}
              placeholder={t("Enter Parent's Name")}
              backgroundColor="#FDFFA6"
              tooltip={t(
                "definitions.noncustodial_parent",
                `Definitions from OCGA §19-6-15 in subsection (a) address how to determine which parent is the Noncustodial parent in a case, including when there is 50/50 parenting time (a)(14) "Noncustodial parent" means the parent with whom the child resides less than 50 percent of the time or the parent who has the greater payment obligation for child support. When the child resides equally with both parents and neither parent can be determined as owing a lesser amount than the other, the court shall determine which parent to designate as the noncustodial parent for the purpose of this Code section. (a)(9) "Custodial parent" means the parent with whom the child resides more than 50 percent of the time. When a custodial parent has not been designated or when a child resides with both parents an equal amount of time, the court shall designate the custodial parent as the parent with the lesser support obligation and the other parent as the noncustodial parent. When the child resides equally with both parents and neither parent can be determined as owing a greater amount than the other, the court shall determine which parent to designate as the custodial parent for the purpose of this Code section.`
              )}
              secondTooltip={t(
                "definitions.both_parents_noncustodial",
                `Only select 'Both Parents' as Noncustodial parents when a Nonparent custodian is included in the case. (Nonparent custodian: individual, other than a Parent, with legal custody of a child, or who has a legal right to seek or modify child support for children in the current case.)
              
              If you include deviations in a worksheet, and then change the Noncustodial parent at #13, the calculator is programmed to remove those deviations. You may then enter deviations, if there are any, for the new Noncustodial parent.`
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-start">
              <Button
                onClick={handleChildInfoOpen}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                {t("14(a) Add Child to Worksheet")}
              </Button>
            </Box>
          </Grid>
          <CustomModal
            open={openChildInfo}
            onClose={() => {
              handleChildInfoClose();
              setModalDataEmpty();
              setFormErrors({});
            }}
            actionButtonLabel={t("Save")}
            title={
              isEdit ? t("Edit Child Information") : t("Add Child Information")
            }
            save={addChildToWorksheet}
            borderBottom="0px"
            pb="0px"
          >
            <Typography
              gutterBottom
              id="modal-modal-description"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                borderBottom: "1px solid #DFDFDF",
                p: 1,
                "@media (max-width: 600px)": {
                  fontSize: "0.875rem",
                },
              }}
            >
              {t(
                "To add a child to the Worksheet, enter the child&apos;s name and year of birth. The &apos;Child Status&apos; radio button will be set to &apos;Included,&apos; which will ensure the child&apos;s related expenses are included in the calculations."
              )}
            </Typography>
            <Box sx={{ pt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomInput
                    name="childName"
                    label={t("Child Name")}
                    value={addChildInputValues.childName}
                    onChange={handleModalInputChange}
                    placeholder={t("Enter Child's Name")}
                    error={t(formErrors.childName ?? "")}
                    required
                  />
                </Grid>
                {isAddUserShow ? (
                  <Grid item xs={12}>
                    {getBrowserName() === "Safari" ? (
                      <SafariDatePickerField
                        label={t("Date of Birth")}
                        value={
                          addChildInputValues.date_of_birth
                            ? dayjs(
                                addChildInputValues.date_of_birth,
                                "MM-DD-YYYY"
                              ).toDate()
                            : null
                        }
                        onChange={(date) =>
                          handleBODDateChangeSafari(date, "date_of_birth")
                        }
                        required={true}
                        backgroundColor="#fff"
                        error={t(formErrors.date_of_birth ?? "")}
                      />
                    ) : (
                      <DatePickerField
                        label={t("Date of Birth")}
                        value={addChildInputValues.date_of_birth}
                        backgroundColor={"#FFFF"}
                        onChange={(e) =>
                          handleBODDateChange(e, "date_of_birth")
                        }
                        isDateDisabled={true}
                        error={t(formErrors.date_of_birth ?? "")}
                        required
                      />
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <CustomDropdown
                      name="year_of_birth"
                      label={t("Year Of Birth")}
                      value={addChildInputValues.year_of_birth.toString()}
                      onChange={handleModalInputChange}
                      options={yearDropdownOptions}
                      placeholder={t("- Choose Year Of Birth -")}
                      error={t(formErrors.year_of_birth ?? "")}
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={6} mb={0}>
                  <CustomRadioGroup
                    name="child_status"
                    label={t("Child Status")}
                    value={addChildInputValues.child_status}
                    onChange={handleModalInputChange}
                    options={[
                      { value: "included", label: t("Included") },
                      { value: "excluded", label: t("Excluded") },
                    ]}
                  />
                </Grid>
                {isAddUserShow && (
                  <>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomRadioGroup
                        name="sex_of_child"
                        label={t("Sex of Child")}
                        value={addChildInputValues.sex_of_child}
                        onChange={handleModalInputChange}
                        options={[
                          { value: "female", label: t("Female") },
                          { value: "male", label: t("Male") },
                          { value: "unknown", label: t("Unknown") },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomInput
                        name="file_number"
                        label={t("File Number")}
                        value={addChildInputValues.file_number}
                        onChange={handleModalInputChange}
                        placeholder={t("Enter File Name")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <CustomInput
                        name="case_number"
                        label={t("Case Number")}
                        value={addChildInputValues.case_number}
                        onChange={handleModalInputChange}
                        placeholder={t("Enter Case Number")}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </CustomModal>
        </Grid>
      </Box>
      <Box sx={{ pt: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell align="left">
                  {t("Child Full Name")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {t("Year of Birth")}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {t("Child Status")}
                </StyledTableCell>
                <StyledTableCell align="left">{t("Action")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {states.add_child_to_worksheet.map((row, i) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell
                    style={{ width: "5%" }}
                    component="th"
                    scope="row"
                  >
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ width: "33%" }}>
                    {row.child_name}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.year_of_birth || row.date_of_birth}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.child_status === "included"
                      ? t("Included")
                      : t("Excluded")}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <EditIcon
                      height={13}
                      onClick={() => editChildDataHandler(row)}
                      style={{ cursor: "pointer", marginRight: "20px" }}
                    />
                    <DeleteIcon
                      height={13}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={() => deletChildDataModalOpen(row.id!)}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ pt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} sx={{ fontWeight: 800 }}>
              <CustomInput
                name="includedChildren"
                label=""
                value={t("Number of Included children: {{count}}", {
                  count: states.add_child_to_worksheet.filter(
                    (item) => item.child_status === "included"
                  ).length,
                })}
                onChange={() => {}}
                placeholder={t("No. Of Included Children : 02")}
                backgroundColor="#CEFBC9"
                color="#000"
                fontWeight="bold"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={12}></Grid>
            <Grid item xs={12} md={4}>
              <CustomInput
                name="name_person_preparing_worksheet"
                label={t("15(a). Name of Person Preparing Worksheet")}
                value={states.name_person_preparing_worksheet}
                onChange={handleInputChange}
                placeholder={t("Enter (a). Name of Person Preparing Worksheet")}
                backgroundColor="#FDFFA6"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <CustomDropdown
                name="make_available_to_court_by"
                label={t(
                  "15(b). Person Making Worksheet Available to the Court"
                )}
                value={states.make_available_to_court_by}
                onChange={handleDropdownChange}
                options={[
                  { value: "Plaintiff", label: "Plaintiff" },
                  { value: "Defendant", label: "Defendant" },
                  { value: "DHS/DCSS", label: "DHS/DCSS" },
                  { value: "Joint", label: "Joint" },
                  { value: "3rd Party", label: "3rd Party" },
                  { value: "Other", label: "Other" },
                ]}
                placeholder={t("- Choose Submitted By -")}
                backgroundColor="#FDFFA6"
                tooltip={t("Person Submitting Worksheet ....")}
                //required
                width="80%"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className={style.firstColumnStylebasicChild}
                  sx={{ bgcolor: "#E6F5FF" }}
                ></TableCell>
                <TableCell className={style.headerStyle} align="left">
                  {states.parent_name_1 || t("ParentName")}
                </TableCell>
                <TableCell className={style.headerStyle} align="left">
                  {states.parent_name_2 || t("ParentName")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={style.firstColumnStylebasicChild}>
                  {t("16(a). Social Security Retirement/Disability payments")}
                  <CustomTooltip
                    title={t(
                      "Enter disability benefits (RSDI or SSD) paid to child in current case who is either unmarried and not yet age 18, still in secondary (high) school and under 19 years of age, or under disability that began before age 22. Do not enter SSI payments. Child must be dependent of insured parent in current case. Payments made on behalf of a child are not income for a parent in this action."
                    )}
                  >
                    <img
                      style={{ marginLeft: "5px", verticalAlign: "middle" }}
                      height={13}
                      src={interrogation}
                      alt="Info"
                    />
                  </CustomTooltip>
                </TableCell>
                <TableCell className={style.cellStyle} align="right">
                  <CurrencyValue
                    type="number"
                    name="social_security_payment_1"
                    value={states.social_security_payment_1}
                    onChange={handleInputChange}
                    backgroundColor="#FDFFA6"
                    onBlur={uninsuredInputHandle}
                  />
                </TableCell>
                <TableCell className={style.cellStyle} align="right">
                  <CurrencyValue
                    type="number"
                    name="social_security_payment_2"
                    value={states.social_security_payment_2}
                    onChange={handleInputChange}
                    backgroundColor="#FDFFA6"
                    onBlur={uninsuredInputHandle}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={style.firstColumnStylebasicChild}>
                  {t("16(b). Veterans Affairs Disability Payments to Children")}
                  <CustomTooltip
                    title={t(
                      "Enter veterans affairs disability benefits paid to child in current case who is either unmarried and not yet age 18, still in secondary (high) school and under 19 years of age, or under disability that began before age 22. Do not enter SSI payments. Child must be dependent of insured parent in current case. Payments made on behalf of a child are not income for a parent in this action."
                    )}
                  >
                    <img
                      style={{ marginLeft: "5px", verticalAlign: "middle" }}
                      height={13}
                      src={interrogation}
                      alt="Info"
                    />
                  </CustomTooltip>
                </TableCell>
                <TableCell align="right">
                  <CurrencyValue
                    type="number"
                    name="veterans_affairs_disability_payment_1"
                    value={states.veterans_affairs_disability_payment_1}
                    onChange={handleInputChange}
                    backgroundColor="#FDFFA6"
                    onBlur={uninsuredInputHandle}
                  />
                </TableCell>
                <TableCell className={style.cellStyle} align="right">
                  <CurrencyValue
                    type="number"
                    name="veterans_affairs_disability_payment_2"
                    value={states.veterans_affairs_disability_payment_2}
                    onChange={handleInputChange}
                    backgroundColor="#FDFFA6"
                    onBlur={uninsuredInputHandle}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={style.firstColumnStylebasicChild}>
                  {t("17. Uninsured Health Care Expenses")}
                  <CustomTooltip
                    title={t(
                      "Enter the desired percentage for future Uninsured Health Expenses to be paid by each parent. (Example: expenses may include copayments, deductibles, reasonable necessities for dental, medical treatment, physical therapy, vision, etc., not covered by insurance.)"
                    )}
                  >
                    <img
                      style={{ marginLeft: "5px", verticalAlign: "middle" }}
                      height={13}
                      src={interrogation}
                      alt="Info"
                    />
                  </CustomTooltip>
                </TableCell>
                <TableCell className={style.cellstylelast} align="right">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomInput
                      type="number"
                      name="uninsured_health_expenses_1"
                      value={states.uninsured_health_expenses_1}
                      placeholder=""
                      backgroundColor="#FDFFA6"
                      onChange={handleUninsuredInputOnchange}
                      onBlur={uninsuredInputHandle}
                    />
                    <div style={{ margin: "0px 5px" }}>%</div>
                  </Box>
                </TableCell>
                <TableCell
                  className={style.cellstylelast}
                  style={{ borderRight: "1px solid #ccc" }}
                  align="right"
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomInput
                      type="number"
                      name="uninsured_health_expenses_2"
                      value={states.uninsured_health_expenses_2}
                      placeholder=""
                      backgroundColor="#FDFFA6"
                      onChange={handleUninsuredInputOnchange}
                      onBlur={uninsuredInputHandle}
                    />
                    <div style={{ margin: "0px 5px" }}>%</div>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorksheetButtons />
      </Box>
      <Grid item xs={12} md={12}>
        <Grid
          sx={{
            mb: 0,
            borderTop: "2px solid #DFDFDF",
            py: 2,
            px: 1,
            textDecoration: "underline",
            cursor: "pointer",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <img src={SettingIcons} alt="img" style={{ height: "15px" }} />
            <Link
              onClick={handleReportModalOpen}
              sx={{
                marginLeft: "5px",
                color: "#147AFF",
                textDecoration: "underline",
              }}
            >
              {t("Report Technical Issue")}
            </Link>
            <CustomModal
              open={reportTechnicalModal}
              onClose={handleReportModalClose}
              actionButtonLabel={t("Save")}
              title={t("Report Technical Issue")}
              save={handleSaveData}
              borderBottom="0px"
              pb="0px"
            >
              <ReportTechnicalForm
                ref={formRef}
                inputCaptcha={inputCaptcha}
                handleSubmitTechnicalReport={handleSubmitTechnicalReport}
                states={states}
              />
            </CustomModal>
          </Box>
          <Box sx={{ p: { xs: 2, md: 1 } }}>
            <Button
              variant="contained"
              className={style.globalContainedButton}
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "2"));
              }}
            >
              {t("Next Step")} <span style={{ padding: "0 4px" }}>{">"}</span>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ValidationErrorModal
        onClose={handleInputValidationErrorModal}
        onCancel={handleInputValidationErrorModal}
        open={inputValidationErrorModal}
        title={t("octestphp.georgiacourts.gov says")}
        descriptionMessage={inputValidationMessage}
      />
      <DeleteModal
        onClose={handleDeleteMdlClose}
        open={deleteModal}
        delete={deletChildDataHandler}
        title={t("Delete Child Records")}
        deleteMessage={t(
          "Are you sure you want permanently remove this child from the system?"
        )}
        deleteNote={t(
          "Note: Removing this child will cause all deviations and child care for this child to be removed."
        )}
      />
      {/* <AlertDialog
        title="octestphp.georgiacourts.gov says"
        open={customAlert}
        setOpen={setCustomAlert}
        description={alertMessage}
        onConfirm={handleConfirmAction}
        showCancelButton={customAlertCancel}
        onClose={() => {
          dispatch(
            emptyBasicInfoFields({
              fields: {
                parent_name_1: "",
                parent_name_2: ""
              }
            })
          );
        }}
      /> */}
    </>
  );
};

export default BasicInformationWorksheet;
