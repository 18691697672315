import { BasicChildSupportObligationTableData } from "./obligationTableData";

export const calculateIncomeDeviation = (
  parentIncome: number,
  numberOfChildren: number,
  totalIncome: number
) => {
  let nearestRecord;

  if (totalIncome > 40000) {
    // If totalIncome is more than 40,000, use the record with adjusted value "40,000.00"
    nearestRecord = BasicChildSupportObligationTableData.find(
      (record) => parseFloat(record.adjusted.replace(/,/g, "")) === 40000
    );
  } else {
    // If totalIncome is less than 40,000, use the logic to find the nearest value
    const lastTwoDigits = totalIncome % 100;
    const nearestValues = BasicChildSupportObligationTableData.filter(
      (record) => {
        const adjustedValue = parseFloat(record.adjusted.replace(/,/g, ""));
        return (
          adjustedValue <= totalIncome + 25 && adjustedValue >= totalIncome - 25
        );
      }
    );

    if (lastTwoDigits <= 75) {
      // Pick the lower value if last two digits <= 75
      nearestRecord =
        nearestValues.find(
          (record) =>
            parseFloat(record.adjusted.replace(/,/g, "")) <= totalIncome
        ) || nearestValues[0];
    } else {
      // Pick the higher value if last two digits > 75
      nearestRecord =
        nearestValues.find(
          (record) =>
            parseFloat(record.adjusted.replace(/,/g, "")) > totalIncome
        ) || nearestValues[nearestValues.length - 1];
    }
  }

  if (!nearestRecord) {
    return (nearestRecord = 0);
    // throw new Error("No matching record found for the given total income.");
  }

  let obligationValue = 0;
  switch (numberOfChildren) {
    case 1:
      obligationValue = parseFloat(nearestRecord.one_child.replace(/,/g, ""));
      break;
    case 2:
      obligationValue = parseFloat(
        nearestRecord.two_children.replace(/,/g, "")
      );
      break;
    case 3:
      obligationValue = parseFloat(
        nearestRecord.three_children.replace(/,/g, "")
      );
      break;
    case 4:
      obligationValue = parseFloat(
        nearestRecord.four_children.replace(/,/g, "")
      );
      break;
    case 5:
      obligationValue = parseFloat(
        nearestRecord.five_children.replace(/,/g, "")
      );
      break;
    case 6:
      obligationValue = parseFloat(
        nearestRecord.six_children.replace(/,/g, "")
      );
      break;
    default:
      obligationValue = parseFloat(nearestRecord.one_child.replace(/,/g, ""));
      break;
  }

  const parentPercentage = (parentIncome / totalIncome) * 100;
  const parentObligation = parentPercentage / 100;
  const parentsObligation = parentObligation * obligationValue;
  const deductionAmount = 50 * (1 + numberOfChildren);

  const finalParent1Obligation = parseFloat(
    adjustSign(parentsObligation - deductionAmount).toFixed(2)
  );

  return finalParent1Obligation;
};

export function adjustSign(value: number): number {
  return -value;
}
