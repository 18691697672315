import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Breadcrumbs,
  Paper,
  Button,
  SelectChangeEvent,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  Pagination,
  MenuItem,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styles from "./worksheet.module.css";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
import ExportFileDropdown from "../../components/exportFileDropDown";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ExportOptionData from "../../components/exportDataSelection";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import adminWorksheet from "../../services/adminWorksheet";
import CustomInput from "../../components/inputField";
import { getFileName } from "../../utils/fileNameUtil";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import { SetWorksheetDataToRedux } from "../../utils/setWorksheetDataToRedux";
import { setWorksheetAPIData } from "../../globalState/actions/create-worksheet-action";
import { useTranslation } from "react-i18next";


const WorkSheets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState("");
  const [systemAdminData, setSystemAdminData] = useState<Data[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({
    key: "",
    direction: "asc",
  });
  const OptionData = ExportOptionData(selectedValue);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalTableRecords, setTotalTableRecords] = useState(0);
  const [tableSearchData, setTableSearchData] = useState<TableSearchData>({
    description: "",
    created_at: "",
    version: null,
    worksheet_id: null,
  });

  const { t } = useTranslation();

  interface Column {
    id: keyof Data;
    label: string;
  }

  interface Data {
    number: number;
    worksheet_id: number;
    // caseId: string;
    created_at: Date;
    version: number;
    description: string;
    action?: string | JSX.Element;
  }
  interface TableSearchData {
    created_at: string;
    description: string;
    version: number | null;
    worksheet_id: number | null;
  }

  const columns: Column[] = [
    { id: "number", label: "#" },
    { id: "worksheet_id", label: t("Worksheet Id") },
    // { id: "caseId", label: "Case Id" },
    { id: "created_at", label: t("Created At") },
    { id: "version", label: t("Version") },
    { id: "description", label: t("Description") },
    { id: "action", label: t("Action") },
  ];

  useEffect(() => {
    const searchTime = setTimeout(() => {
      fetchWorksheetData();
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [page, rowsPerPage, tableSearchData, sortConfig]);

  //Fetch Worksheet Table Data
  const fetchWorksheetData = () => {
    setLoading(true);
    const tableOrdering =
      sortConfig.direction === "asc" ? sortConfig.key : `-${sortConfig.key}`;

    const inputData = {
      pageIndex: page,
      perPage: rowsPerPage,
      tableSearchData,
      tableOrdering,
    };

    adminWorksheet
      .getAdminWorksheetDataAPI(inputData)
      .then(
        (response: { data: { results: { data: Data[] }; count: number } }) => {
          if (response?.data?.results?.data?.length > 0) {
            setSystemAdminData(
              response.data.results.data.map((item: Data, index: number) => ({
                number: (page - 1) * rowsPerPage + index + 1,
                worksheet_id: item.worksheet_id,
                created_at: item.created_at,
                version: item.version,
                description: item.description,
              }))
            );
            setTotalTableRecords(response.data.count);
          } else {
            setSystemAdminData([]);
            setTotalTableRecords(0);
          }
          setLoading(false);
        }
      )
      .catch((error: unknown) => {
        setLoading(false);
        console.error("Worksheets Error", error);
      });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  // Reset Data on click of reset filter
  const handlePageResetGrid = () => {
    setTableSearchData({
      created_at: "",
      worksheet_id: null,
      version: null,
      description: "",
    });
    setSortConfig({ key: "number", direction: "asc" });
    setSelectedValue("");
    setPage(1);
    setRowsPerPage(10);
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  };

  const handleSort = (columnId: keyof Data) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnId, direction });
  };

  const sortedRows = React.useMemo(() => {
    if (sortConfig.key !== null && sortConfig.key !== undefined) {
      const key = sortConfig.key as keyof Data;
      return [...systemAdminData].sort((a: Data, b: Data) => {
        const aValue = a[key];
        const bValue = b[key];

        if (aValue == null && bValue != null)
          return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue != null && bValue == null)
          return sortConfig.direction === "asc" ? 1 : -1;
        if (aValue == null && bValue == null) return 0;

        if (aValue !== null && bValue !== null) {
          if (typeof aValue === "number" && typeof bValue === "number") {
            return sortConfig.direction === "asc"
              ? aValue - bValue
              : bValue - aValue;
          } else if (typeof aValue === "string" && typeof bValue === "string") {
            return sortConfig.direction === "asc"
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          }
        }

        return 0;
      });
    }
    return systemAdminData;
  }, [systemAdminData, sortConfig]);

  const searchInputSelectOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    setTableSearchData((prev) => ({
      ...prev,
      [name]: value === "" ? null : value,
    }));
  };

  const totalPages = Math.ceil(totalTableRecords / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage + 1;
  const endIndex = Math.min(page * rowsPerPage, totalTableRecords);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#404040",
      color: theme.palette.common.white,
      fontSize: "14px !important",
      fontFamily: "Inter !important",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "14px !important",
      fontFamily: "Inter !important",
    },
    border: "1px solid #DFDFDF",
  }));
  const DropdownChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    handleDownloadAdminWorksheetData(event.target.value);
  };

  // Download Data
  const handleDownloadAdminWorksheetData = (key: string) => {
    const arrayIDs = systemAdminData.map((item) => item.number);
    adminWorksheet
      .getDownloadAdminWorksheetDataAPI(key, arrayIDs)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", getFileName(key, "number"));
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("Admin Worksheet Error", error);
      });
  };
  /// handle get Worksheet data by Id
  const handleGetWorksheetDataById = (worksheetId: number) => {
    adminWorksheet
      .getWorksheetInfoDataAPI(Number(worksheetId))
      .then((response) => {
        if (response?.data.worksheet_id) {
          const finalWorksheetData = SetWorksheetDataToRedux(response.data);
          dispatch(setWorksheetAPIData(finalWorksheetData));
          setTimeout(() => {
            navigate(`/WorkSheets/updateWorksheet/${worksheetId}`);
            dispatch(headerActiveLink("/WorkSheets/updateWorksheet"));
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };

  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
        className="contain__area"
      >
        <Box
          className="breadcrumbs"
          sx={{
            margin: {
              xs: "30px 20px 0px 1px",
              sm: "20px 56px 0px 1px",
              md: "25px 56px 0px 56px",
            },
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handleHomeRedirection}
              >
                {t("Home")}
              </Link>
              <Link
                to="/system-administration/worksheet"
                className="LinkColorDark"
              >
                {t("Worksheets")}
              </Link>
            </Breadcrumbs>
          </div>
        </Box>
        <Box sx={{ marginTop: "1rem" }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "5px",
              border: "1px solid #DFDFDF",
              margin: { md: "10px 56px" },
            }}
          >
            <Box>
              <Typography
                m={2}
                variant="h5"
                className="h5"
                color="#0464AB;"
                gutterBottom
                sx={{
                  fontSize: 25,
                  letterSpacing: "1px",
                  marginBottom: "1px",
                }}
              >
                {t("Worksheets")}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent={{
                md: "space-between",
                sm: "space-between",
                xs: "center",
              }}
              flexWrap="wrap"
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                mx={2}
                marginLeft={{ xs: "30%", sm: "2%", md: "1.5%" }}
                flexWrap="wrap"
              >
                <Grid
                  container
                  alignItems={{ xs: "center" }}
                  justifyContent={{ xs: "center" }}
                  spacing={1}
                >
                  <Grid item xs={12} sm={6} md={7}>
                    <Typography variant="body1">
                      {t("Showing")}{" "}
                      <strong>
                        {startIndex}-{endIndex}
                      </strong>{" "}
                      of <strong>{totalTableRecords}</strong> {t("items.")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1" mr={1}>
                        {t("Show")}
                      </Typography>
                      <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "50px" }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                      <Typography variant="body1" ml={1}>
                        {t("entries")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1} mr={{ sm: 1, md: 3.5 }} ml={1}>
                <Grid
                  display={"flex"}
                  alignItems="center"
                  justifyContent="end"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6}>
                    <Button
                      variant="outlined"
                      onClick={handlePageResetGrid}
                      className={styles.resetButtoncss}
                      sx={{
                        height: "35px",
                        textTransform: "none",
                        width: {
                          lg: "max-content",
                          md: "135px",
                          sm: "135px",
                          xs: "fit-content",
                        },
                        fontSize: {
                          md: "12px",
                          sm: "12px",
                          xs: "9px",
                        },
                      }}
                    >
                      <ReplayOutlinedIcon sx={{ marginRight: "2px" }} />
                      {t("Reset Filter")}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    paddingLeft={{
                      xs: "0px!important",
                      sm: "4px!important",
                      md: "10px!important",
                      lg: "10px!important",
                    }}

                  >
                    <Box>
                      <ExportFileDropdown
                        name="dropdown"
                        label=""
                        value={selectedValue}
                        onChange={DropdownChange}
                        options={OptionData.slice(1)}
                        placeholder={
                          <div style={{ display: "flex", alignItems: "left" }}>
                            <FileExporticon
                              className={
                                selectedValue === "Export"
                                  ? styles.activeSVG
                                  : styles.inactiveSVG
                              }
                            />
                            <Typography
                              sx={{
                                marginLeft: { md: "6px", sm: "6px", xs: "6px" },
                              }}
                            >
                              {OptionData[0].label}
                            </Typography>
                          </div>
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box mt={1}>
              <TableContainer>
                <Table
                  sx={{ minWidth: 900 }}
                  stickyHeader
                  aria-label="sticky table"
                  className={styles.worksheetTable}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((data, index) => (
                        <StyledTableCell
                          key={data.id}
                          className={
                            index === columns.length - 1 ? styles.sticky : ""
                          }
                        >
                          <Box display="flex" alignItems="center">
                            {data.label}
                            {index !== 0 && data.id !== "action" && (
                              <IconButton
                                onClick={() => handleSort(data.id)}
                                size="small"
                                color="inherit"
                              >
                                {sortConfig.key === data.id ? (
                                  sortConfig.direction === "asc" ? (
                                    <ArrowUpwardIcon fontSize="inherit" />
                                  ) : (
                                    <ArrowDownwardIcon fontSize="inherit" />
                                  )
                                ) : (
                                  <ArrowUpwardIcon fontSize="inherit" />
                                )}
                              </IconButton>
                            )}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {columns.map((item, index) => (
                        <TableCell
                          sx={{ border: "1px solid #DFDFDF" }}
                          key={item.id}
                          className={
                            index === columns.length - 1 ? styles.sticky : ""
                          }
                        >
                          {index !== 0 && index !== columns.length - 1 && (
                            <CustomInput
                              key={`search-${item.id}`}
                              name={item.id}
                              value={
                                tableSearchData[
                                item.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputSelectOnchange}
                              placeholder={t("Search")}
                              endAdornment={<SearchOutlinedIcon />}
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={columns.length}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100%"
                            padding={2}
                          >
                            <CircularProgress />
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : (
                    <TableBody>
                      {sortedRows.map((row, i) => (
                        <TableRow key={row.worksheet_id}>
                          <TableCell>
                            {(page - 1) * rowsPerPage + i + 1}
                          </TableCell>
                          <TableCell>{row.worksheet_id}</TableCell>
                          {/* <TableCell>{"(Not Set)"}</TableCell> */}
                          <TableCell>{row.created_at}</TableCell>
                          <TableCell>{row.version}</TableCell>
                          <TableCell>{row.description}</TableCell>
                          <TableCell
                            sx={{
                              position: "sticky",
                              zIndex: 1,
                              right: 0,
                              backgroundColor: "#fff",
                              border: "1px solid #DFDFDF",
                            }}
                          >
                            {
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <IconButton
                                  sx={{ color: "#0464AB" }}
                                  onClick={() =>
                                    handleGetWorksheetDataById(row.worksheet_id)
                                  }
                                >
                                  <RemoveRedEyeOutlinedIcon />{" "}
                                </IconButton>
                              </Box>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <Box
                my={1}
                display="flex"
                justifyContent={{
                  md: "space-between",
                  sm: "space-between",
                  xs: "center",
                }}
                alignItems="center"
                flexDirection={{ md: "row", sm: "row", xs: "column" }}
                flexWrap="wrap"
              >
                <Box>
                  <Typography sx={{ marginX: "20px" }} variant="body1">
                    <Typography variant="body1">
                      {t("Showing")}{" "}
                      <strong>
                        {startIndex}-{endIndex}
                      </strong>{" "}
                      {t("of")} <strong>{totalTableRecords}</strong> {t("items.")}
                    </Typography>
                  </Typography>
                </Box>
                <Box>
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default WorkSheets;
