import React from "react";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import style from "../searchWorksheet.module.css";

import SearchWorksheetButtons from "../../../components/searchWorksheetButtons";
import { useAppSelector } from "../../../globalState/hooks";
import NoDataImage from "../../../assets/images/FootnoteImg.png";
import { formatNumber } from "../../../utils/formatNumber";
import TextareaField from "../../../components/text-area";
import { useTranslation } from "react-i18next";

const GrossIncomeTab: React.FC = () => {
  const WorkSheetData = useAppSelector((state) => state.createWorksheet);
  const { t } = useTranslation();

  return (
    <>
      {WorkSheetData.gross_income_schedule_a.total_gross_income1 ||
        WorkSheetData.gross_income_schedule_a.total_gross_income2 ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              className={style.contentHeading}
              marginTop={"15px !important"}
            >
              {t("CHILD SUPPORT SCHEDULE A GROSS INCOME")}
            </Typography>
          </Box>
          <Box sx={{ pt: { md: 3, sm: 4, xs: 2 } }}>
            <TableContainer>
              <Table sx={{ minWidth: 850 }}>
                <TableHead>
                  <TableRow>
                    <TableCell className={style.headerStyle}>
                      {t("Schedule A - All amounts/data that display on Schedule A were entered using the Online Child Support Calculator and can only be changed by selecting the button")} 
                      &quot;{t("Open This Worksheet.")}&quot; 
                      {t("All income on Schedule A is in monthly amounts. The totals from Line 24 of this schedule will display on Line 1 of the Worksheet.")}
                    </TableCell>
                    <TableCell className={style.headerStyle}>
                      {WorkSheetData.parent_name_1}
                    </TableCell>
                    <TableCell className={style.headerStyle}>
                      {WorkSheetData.parent_name_2}
                    </TableCell>
                    <TableCell className={style.headerStyle}>
                      {t("Combined")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {WorkSheetData.tanf1 || WorkSheetData.tanf2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("If either or both parents receive Temporary Assistance for Needy Families (TANF), a check mark will appear; otherwise, the boxes will be blank.")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        <Checkbox checked={WorkSheetData.tanf1} />
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        <Checkbox checked={WorkSheetData.tanf2} />
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.gross_salary1 ||
                  WorkSheetData.gross_income_schedule_a.gross_salary2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("1. Salary and Wages (Will not include means-tested public assistance, such as TANF or food stamps)")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.gross_salary1
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .gross_salary1)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.gross_salary2
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .gross_salary2)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.commissions1 ||
                  WorkSheetData.gross_income_schedule_a.commissions2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("2. Commissions, Fees, Tips")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.commissions1
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a.commissions1)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.commissions2
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a.commissions2)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a
                  .self_employment_income1 ||
                  WorkSheetData.gross_income_schedule_a
                    .self_employment_income2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("3. Income from Self-Employment")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .self_employment_income1
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .self_employment_income1)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .self_employment_income2
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .self_employment_income2)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.bonuses1 ||
                  WorkSheetData.gross_income_schedule_a.bonuses2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("4. Bonuses")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.bonuses1
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a.bonuses1)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.bonuses2
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a.bonuses2)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.overtime_payment1 ||
                  WorkSheetData.gross_income_schedule_a.overtime_payment2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("5. Overtime Payments")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.overtime_payment1
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .overtime_payment1)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.overtime_payment2
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .overtime_payment2)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.severence_pay1 ||
                  WorkSheetData.gross_income_schedule_a.severence_pay2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("6. Severance Pay")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.severence_pay1
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .severence_pay1)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.severence_pay2
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .severence_pay2)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.recurring_income1 ||
                  WorkSheetData.gross_income_schedule_a.recurring_income2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("7. Recurring Income from Pensions or Retirement Plans")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.recurring_income1
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .recurring_income1)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.recurring_income2
                          ? "$" +
                          formatNumber(
                            Number(WorkSheetData.gross_income_schedule_a
                              .recurring_income2)
                          )
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.interest_income1 ||
                  WorkSheetData.gross_income_schedule_a.interest_income2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("8. Interest Income")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.interest_income1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .interest_income1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.interest_income2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .interest_income2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.income_from_dividends1 ||
                  WorkSheetData.gross_income_schedule_a.income_from_dividends2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("9. Income from Dividends")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .income_from_dividends1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .income_from_dividends1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .income_from_dividends2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .income_from_dividends2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.trust_income1 ||
                  WorkSheetData.gross_income_schedule_a.trust_income2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("10. Trust Income")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.trust_income1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.trust_income1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.trust_income2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.trust_income2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.income_from_annuities1 ||
                  WorkSheetData.gross_income_schedule_a.income_from_annuities2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("11. Income from Annuities")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .income_from_annuities1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .income_from_annuities1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .income_from_annuities2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .income_from_annuities2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.capital_gains1 ||
                  WorkSheetData.gross_income_schedule_a.capital_gains2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("12. Capital Gains")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.capital_gains1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.capital_gains1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.capital_gains2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.capital_gains2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a
                  .social_security_disablity_benefits1 ||
                  WorkSheetData.gross_income_schedule_a
                    .social_security_disablity_benefits2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("13. Social Security Disability or Retirement payments (excludes SSI or payments for children)")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .social_security_disablity_benefits1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .social_security_disablity_benefits1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .social_security_disablity_benefits2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .social_security_disablity_benefits2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a
                  .federal_veterans_disability_benefits1 ||
                  WorkSheetData.gross_income_schedule_a
                    .federal_veterans_disability_benefits2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("14. Federal Veterans&apos; Disability Benefits")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .federal_veterans_disability_benefits1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .federal_veterans_disability_benefits1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .federal_veterans_disability_benefits2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .federal_veterans_disability_benefits2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a
                  .workers_compensation_benefits1 ||
                  WorkSheetData.gross_income_schedule_a
                    .workers_compensation_benefits2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("15. Worker&apos;s Compensation Benefits")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .workers_compensation_benefits1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .workers_compensation_benefits1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .workers_compensation_benefits2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .workers_compensation_benefits2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.unemployment_benefits1 ||
                  WorkSheetData.gross_income_schedule_a.unemployment_benefits2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("16. Unemployment Benefits")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .unemployment_benefits1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .unemployment_benefits1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a
                          .unemployment_benefits2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .unemployment_benefits2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.judgements1 ||
                  WorkSheetData.gross_income_schedule_a.judgements2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("17. Judgments from Personal Injury or Other Civil Cases")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.judgements1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.judgements1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.judgements2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.judgements2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.gifts1 ||
                  WorkSheetData.gross_income_schedule_a.gifts2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("18. Gifts (cash or other gifts that can be converted to cash)")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.gifts1
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.gifts1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.gifts2
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.gifts2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.prizes1 ||
                  WorkSheetData.gross_income_schedule_a.prizes2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("19. Prizes / Lottery Winnings")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.prizes1
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.prizes1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.prizes2
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.prizes2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.alimony1 ||
                  WorkSheetData.gross_income_schedule_a.alimony2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("20. Alimony & maintenance from persons not in this case")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.alimony1
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.alimony1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.alimony2
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.alimony2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.assets1 ||
                  WorkSheetData.gross_income_schedule_a.assets2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("21. Assets which are used for support of family")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.assets1
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.assets1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.assets2
                          ? "$" + formatNumber(Number(WorkSheetData.gross_income_schedule_a.assets2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.fringe_benefits1 ||
                  WorkSheetData.gross_income_schedule_a.fringe_benefits2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("22. Fringe Benefits (if significantly reduce living expenses)")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.fringe_benefits1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .fringe_benefits1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.fringe_benefits2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a
                            .fringe_benefits2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                {WorkSheetData.gross_income_schedule_a.other_income1 ||
                  WorkSheetData.gross_income_schedule_a.other_income2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("23. Any Other Income, including Imputed Income (Does not include means-tested public assistance)")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.other_income1
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.other_income1))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        {WorkSheetData.gross_income_schedule_a.other_income2
                          ? "$" +
                          formatNumber(Number(WorkSheetData.gross_income_schedule_a.other_income2))
                          : ""}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#F8F8F8" }}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
                <TableBody>
                  <TableRow>
                    <TableCell className={style.firstColumnStyle}>
                      {t("24. TOTAL GROSS MONTHLY INCOME Total will automatically display here, Line 1 of Worksheet and Line 1 of Schedule B")}
                    </TableCell>
                    <TableCell className={style.cellStyle}>
                      {WorkSheetData.gross_income_schedule_a.total_gross_income1
                        ? "$" +
                        formatNumber(Number(WorkSheetData.gross_income_schedule_a
                          .total_gross_income1))
                        : ""}
                    </TableCell>
                    <TableCell className={style.cellStyle}>
                      {WorkSheetData.gross_income_schedule_a.total_gross_income2
                        ? "$" +
                        formatNumber(Number(WorkSheetData.gross_income_schedule_a
                          .total_gross_income2))
                        : ""}
                    </TableCell>
                    <TableCell className={style.cellStyle}>
                      {(WorkSheetData.gross_income_schedule_a
                        .total_gross_income1 ||
                        WorkSheetData.gross_income_schedule_a
                          .total_gross_income2) &&
                        "$" +
                        formatNumber((Number(
                          WorkSheetData.gross_income_schedule_a
                            .total_gross_income1 || 0
                        ) +
                          Number(
                            WorkSheetData.gross_income_schedule_a
                              .total_gross_income2 || 0
                          )))}
                    </TableCell>
                  </TableRow>
                </TableBody>
                {WorkSheetData.gross_income_schedule_a.comment1 ||
                  WorkSheetData.gross_income_schedule_a.comment2 ? (
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.firstColumnStyle}>
                        {t("The explanations below explain the basis of any Other Income, including Imputed Income, for each parent")}
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >

                        <TextareaField
                          name=""
                          value={WorkSheetData.gross_income_schedule_a.comment1}
                          onChange={() => { }}
                          placeholder=""
                          tooltip=""
                          disabled
                        />
                      </TableCell>
                      <TableCell
                        className={style.cellStyle}
                        sx={{ backgroundColor: "#CEFBC9" }}
                      >
                        <TextareaField
                          name=""
                          value={WorkSheetData.gross_income_schedule_a.comment2}
                          onChange={() => { }}
                          placeholder=""
                          tooltip=""
                          disabled
                        />
                      </TableCell>
                      <TableCell className={style.cellStyle}></TableCell>
                    </TableRow>
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box textAlign={"center"} my={"4rem"}>
          <Box
            component="img"
            src={NoDataImage}
            alt="Footnotes"
            sx={{
              width: { xs: "70%", md: "25%" },
              height: "auto",
            }}
          />
          <Typography className={style.contentHeading}>
            {t("No Information To Display")}
          </Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SearchWorksheetButtons />
      </Box>
    </>
  );
};

export default GrossIncomeTab;
