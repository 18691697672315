// Admin Worksheet service
import { httpTokenFileDownload, httpTokenCommon } from "./interceptor";

interface tableList {
  pageIndex: number;
  perPage: number;
  tableSearchData: {
    created_at: string;
    description: string;
    version: number | null;
    worksheet_id: number | null;
  };
  tableOrdering: string;
}
class adminWorksheetService {
  // Get Admin Worksheet Table Data
  getAdminWorksheetDataAPI(inputdata: tableList) {
    const { pageIndex, perPage, tableSearchData, tableOrdering } = inputdata;

    return httpTokenCommon.get(
      `worksheet/admin_worksheet_list/?limit=${perPage}&page=${pageIndex}&created_at=${
        tableSearchData.created_at || ""
      }&description=${tableSearchData.description || ""}&worksheet_id=${
        tableSearchData.worksheet_id !== null
          ? tableSearchData.worksheet_id
          : ""
      }&version=${
        tableSearchData.version !== null ? tableSearchData.version : ""
      }&ordering=${tableOrdering}`
    );
  }

  /// fetch worksheet data by ID
  getWorksheetInfoDataAPI(id: number) {
    return httpTokenCommon.get(`worksheet/basic_info/${id}/`);
  }

  // download the table data in multiple formats
  getDownloadAdminWorksheetDataAPI(key: string, download_ids: number[]) {
    return httpTokenFileDownload.get(
      `worksheet/admin_worksheet_list_download/?download_format=${key}&download_ids=${download_ids}`
    );
  }
}
const adminWorksheet = new adminWorksheetService();
export default adminWorksheet;
