import React from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AboutBackground from "../../assets/images/About1.png";
import AboutBackgroundMR from "../../assets/images/Rectangle 3mr@3x.png";
import judicialCouncil from "../../assets/images/judicial-council@2x.png";
import childSupport from "../../assets/images/child-support-commission@2x.png";
import imgDHS from "../../assets/images/Georgia-Department-of-Human-Services-Information (1)@2x.png";
import purposeMission from "../../assets/images/About2.png";
import purposeMission2 from "../../assets/images/About3.png";
import { useTranslation } from "react-i18next";


const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <>
      <Box marginTop="-10px">
        <Box position="relative" width="100%" height="auto">
          <Box
            component="img"
            src={isMobile ? AboutBackgroundMR : AboutBackground}
            alt="About Background"
            sx={{ width: "100%", height: "auto" }}
          />
          <Box>
            <Grid
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
              position="absolute"
              left="10%"
              right="10%"
              top={isMobile ? "55%" : "70%"}
              marginBottom="50px"
            >
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ p: isMobile ? 2 : 2, pr: isMobile ? 2 : 6 }}
              >
                <Card
                  sx={{
                    borderRadius: isMobile ? "10px" : "30px",
                    width: "300px",
                    height: "120px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={judicialCouncil}
                      alt="Judicial Council Logo"
                      sx={{
                        width: "30%",
                        height: "auto",
                        p: 3,
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ p: isMobile ? 2 : 2, pr: isMobile ? 2 : 6 }}
              >
                <Card
                  sx={{
                    borderRadius: isMobile ? "10px" : "30px",
                    width: "300px",
                    height: "120px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={childSupport}
                      alt="Child Support Logo"
                      sx={{ width: "50%" }}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ p: 2 }}>
                <Card
                  sx={{
                    borderRadius: isMobile ? "10px" : "30px",
                    width: "300px",
                    height: "120px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      component="img"
                      src={imgDHS}
                      alt="DHS Logo"
                      sx={{
                        width: "30%",
                        height: "auto",
                        p: 3,
                      }}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box mt={isMobile ? 45 : 10}>
          <Grid container spacing={2}>
            <Grid item xs={12} my={4}>
              <Typography
                className="h4 main_heading"
                fontSize={28}
                mx={isMobile ? 4 : 0}
                sx={{ textAlign: "center", color: "#0464AB" }}
              >
                {t("The Georgia Commission on Child Support")}
              </Typography>
              <Typography
                fontSize={14}
                sx={{ textAlign: "center", p: 1 }}
                mx={isMobile ? 4 : 0}
              >
                {t("The Georgia Child Support Commission (“Commission”) was created by Executive Order of the Governor, dated May 9, 2005, in accordance with O.C.G.A. § 19-6-51.")}
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              mx={isMobile ? 2 : 15}
            >
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  src={purposeMission}
                  alt="Example"
                  sx={{
                    width: "100%", // Make the image take full width of its grid column
                    height: "auto",
                    // objectFit: "contain", // Ensure the image retains its aspect ratio
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <Typography
                  fontSize={14}
                  sx={{ color: "#404040", paddingLeft: { xs: 0, sm: 0, md: 2 }, }}
                  mt={isMobile ? 3 : 0}

                >
                  <Typography
                    className="h4 main_heading"
                    fontSize={28}
                    sx={{ textAlign: "left", color: "#0464AB", mb: 1 }}
                  >
                    {t("Purpose and Mission")}
                  </Typography>
                  {t("Pursuant to O.C.G.A. § 19-6-50, the Georgia Child Support Commission was created and charged with the purpose of studying and collecting information and data relating to awards of child support, and to create a child support obligation table. The Commission was also made responsible for conducting a comprehensive review of the child support guidelines, economic conditions, and all matters relevant to maintaining effective and efficient child support guidelines that will serve the best interest of Georgia’s children and take into account the changing dynamics of family life. The actual duties of the Child Support Commission include, but are not limited to: studying and evaluating the effectiveness and efficiency of Georgia’s child support guidelines; evaluating and considering the experiences and results in other states which utilize child support guidelines; creating and recommending to the Georgia General Assembly a child support obligation table consistent with O.C.G.A. § 19-6-15; developing or causing to be developed software and a calculator associated with the use of the child support obligation table and guidelines; developing and publishing the child support obligation table and worksheets; developing training manuals and information to educate judges, attorneys and litigants on the use of the guidelines; collaborating with the Institute of Continuing Legal Education and Judicial Legal Education for the purpose of training persons who will be utilizing the child support table and child support guidelines; and making recommendations for proposed legislation.")}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              mx={isMobile ? 2 : 15}
              marginTop={isMobile ? 2 : 5}
              marginBottom={isMobile ? 2 : 5}
            >
              <Grid
                item
                xs={12}
                md={6} // 6 columns on medium and larger screens
              >
                <Typography
                  fontSize={14}
                  pr={isMobile ? 0 : 3}
                  sx={{ color: "#404040" }}
                  mt={isMobile ? 3 : 0}
                >
                  {t("Other duties, per O.C.G.A. § 19-6-53, include: study the appellate courts’ acceptance of discretionary appeals in domestic relations cases and the formulation of case law in the area of domestic relations; study alternative programs such as mediation, collaborative practice and pro se assistance programs in order to reduce litigation in child support cases; and study the impact of parenting time (visitation) as a deviation to the presumptive amount of child support when calculating child support.")}
                  <br /> <br /> 
                  {t("In addition, federal regulations and statutes require each state to review child support guidelines every four years. See 42 U.S.C. 667 and 45 C.F.R. 302.56. Furthermore, it is the Child Support Commission that is charged with certifying the appropriateness and effectiveness of the state’s guidelines under 42 U.S.C. 667 and 45 C.F.R. 302.56. Pursuant to O.C.G.A. § 19-6-51, the Commission is staffed administratively to the Division of Child Support Services (DCSS), but through an interagency contract, the administrative work of the Commission is staffed and housed at the Judicial Council/Administrative Office of the Courts (“AOC”).")}
                  <br /><br /> 
                  {t("The Commission is staffed by Patricia Buonodono, Staff Attorney, Elaine Johnson, Executive Program Manager, and Bruce Shaw, Administrative Assistant.")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6} // 6 columns on medium and larger screens
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  src={purposeMission2}
                  alt="Example"
                  sx={{
                    width: "100%", // Make the image take the full width of its grid column
                    height: "auto",
                    objectFit: "contain", // Maintain the aspect ratio
                  }}
                />
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </Box >
    </>
  );
};

export default About;
