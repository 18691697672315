import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Tab } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import BasicInformationTab from "./BasicInformationTab";
import GrossIncomeTab from "./GrossIncomeTab";
import AdjustedIncomeTab from "./AdjustedIncomeTab";
import HealthInsuranceTab from "./HealthInsuranceTab";
import DeviationsTab from "./DeviationsTab";
import SelfEmploymentTab from "./SelfEmploymentTab";
import FootnotesTab from "./FootnotesTab";
import createWorksheetService from "../../services/worksheet/create-worksheet-service";
import { useAppDispatch } from "../../globalState/hooks";
import {
  setWorksheetAPIData,
  updateBasicInfo,
} from "../../globalState/actions/create-worksheet-action";
import { ApiResponse, CountryData, CourtData } from "../../types/global";
import { useTranslation } from "react-i18next";

const SearchWorkSheet: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const [activeSheet, setActiveSheet] = useState("1");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleChange = (event: React.SyntheticEvent, value: string) => {
    setActiveSheet(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetWorksheetDataById();
  }, []);

  ///-------------API calling Function start-------------------
  /// fetch Search Folder Worksheet Listinst data
  const handleGetWorksheetDataById = () => {
    const tempeUrl = window.location.pathname;
    const worksheetId = tempeUrl.substring(tempeUrl.lastIndexOf("/") + 1);
    createWorksheetService
      .getWorksheetInfoDataAPI(Number(worksheetId))
      .then((response) => {
        if (response?.data.worksheet_id) {
          handleGetCourtListData(response.data.court_id);
          handleGetCountyListData(response.data.county_id);
          dispatch(setWorksheetAPIData(response.data));
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch Court List data
  const handleGetCourtListData = (courtId: number) => {
    createWorksheetService
      .getCourtListAPI()
      .then((response: { data: ApiResponse<CourtData[]> }) => {
        const responseData = response?.data?.data;
        if (responseData?.length > 0) {
          const courtData = responseData.find((x) => x.id === courtId);
          if (courtData) {
            const courtName: string = courtData.court_name;
            dispatch(updateBasicInfo("court_name", courtName));
          }
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// fetch County List data
  const handleGetCountyListData = (countryId: number) => {
    createWorksheetService
      .getCountyListAPI()
      .then((response: { data: ApiResponse<CountryData[]> }) => {
        const responseData = response?.data?.data;
        if (responseData?.length > 0) {
          const countryDate = responseData.find((x) => x.id === countryId);
          if (countryDate) {
            const countryName: string = countryDate.county_name;
            dispatch(updateBasicInfo("county_name", countryName));
          }
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  ///-------------API calling Function end-------------------
  return (
    <>
      <Box
        className="contain__area"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            width: {
              xs: "100%",
              sm: "90%",
            },
            borderRadius: "2px",
            border: "1px solid #DFDFDF",
            marginTop: "20px",
            marginBottom: "40px",
          }}
        >
          <CardContent sx={{ padding: "0px!important" }}>
            <TabContext value={activeSheet}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "#404040",
                  background: "#404040",
                  width: "100%",
                  overflowX: "auto",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="Worksheet"
                  variant={isMobile ? "scrollable" : "fullWidth"}
                  sx={{
                    "& .MuiTabs-flexContainer": {
                      flexDirection: isMobile ? "row" : "row",
                      justifyContent: isMobile ? "flex-start" : "center",
                    },
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#F5B325",
                      border: "1px solid #F5B325",
                    },
                  }}
                >
                  {[
                    { label: t("Basic Information Worksheet"), value: "1" },
                    { label: t("Gross Income Schedule A"), value: "2" },
                    { label: t("Adjusted Income Schedule B"), value: "3" },
                    {
                      label: t("Health Insurance/Child Care Schedule D"),
                      value: "4",
                    },
                    { label: t("Deviations Schedule E"), value: "5" },
                    { label: t("Self-Employment Calculator"), value: "6" },
                    { label: t("Footnotes"), value: "7" },
                  ].map((tab, index, array) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "12px",
                        },
                        color: "#CECECE",
                        textTransform: "capitalize",
                        height: {
                          xs: "40px",
                          sm: "40px",
                          md: "60px",
                          lg: "40px",
                          xl: "70px",
                        },
                        padding: "10px 15px",
                        minWidth: "100px",
                        maxWidth: "170px",
                        ...(index !== array.length - 1 && {
                          borderRight: "1px solid #CECECE",
                        }),
                        "&.Mui-selected": {
                          color: "#FFF",
                          backgroundColor: "#0464AB",
                        },
                        "&:hover": {
                          backgroundColor: "#0464AB",
                          "&::after": {
                            content: '""',
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            width: "100%",
                            height: "2px",
                            backgroundColor: "#F5B325",
                            transform: "scaleX(1)",
                            transition: "transform 1s",
                            transformOrigin: "left",
                            border: "1px solid #F5B325",
                          },
                        },
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                          width: "100%",
                          height: "2px",
                          backgroundColor: "#F5B325",
                          transform: "scaleX(0)",
                          transition: "transform 1s",
                          transformOrigin: "left",
                          border: "1px solid #F5B325",
                        },

                        position: "relative",
                      }}
                    />
                  ))}
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ paddingY: 1, paddingX: 2 }}>
                <BasicInformationTab />
              </TabPanel>
              <TabPanel value="2" sx={{ paddingY: 1, paddingX: 2 }}>
                <GrossIncomeTab />
              </TabPanel>
              <TabPanel value="3" sx={{ paddingY: 1, paddingX: 2 }}>
                <AdjustedIncomeTab />
              </TabPanel>
              <TabPanel value="4" sx={{ paddingY: 1, paddingX: 2 }}>
                <HealthInsuranceTab />
              </TabPanel>
              <TabPanel value="5" sx={{ paddingY: 1, paddingX: 2 }}>
                <DeviationsTab />
              </TabPanel>
              <TabPanel value="6" sx={{ paddingY: 1, paddingX: 2 }}>
                <SelfEmploymentTab />
              </TabPanel>
              <TabPanel value="7" sx={{ paddingY: 1, paddingX: 2 }}>
                <FootnotesTab />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SearchWorkSheet;
