import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Link,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import { showToast } from "../../components/toastNotification/index";
import { useNavigate, useLocation } from "react-router-dom";
import signUpService from "../../services/signup-service";
import { useTranslation } from "react-i18next";

const SignUpOTP: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    first_name,
    last_name,
    password,
    email,
    language,
    court_id,
    county_id,
    bar_number,
    user_type_id,
    role_id,
  } = location.state as {
    first_name: string;
    last_name: string;
    password: string;
    email: string;
    language: string;
    court_id: string;
    county_id: string;
    bar_number: string;
    user_type_id: string;
    role_id: number;
  };
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState<number>(60);
  const [canResend, setCanResend] = useState<boolean>(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const handleChange = (value: string, index: number) => {
    if (!/^\d*$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      nextInput?.focus();
    }
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number
  ) => {
    const target = e.target as HTMLInputElement;
    if (e.key === "Backspace" && !target.value && index > 0) {
      const prevInput = document.getElementById(`otp-input-${index - 1}`);
      prevInput?.focus();
    }
  };
  const startTimer = () => {
    if (intervalId) clearInterval(intervalId);
    setCanResend(false);
    setTimer(60);

    const id = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(id);
          setCanResend(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    setIntervalId(id);
  };

  useEffect(() => {
    startTimer();

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, []);

  const handleSubmit = () => {
    const otpCode = otp.join("");

    const SignUpInputData = {
      first_name,
      last_name,
      email,
      password,
      language,
      court_id,
      county_id,
      bar_number,
      user_type_id,
      role_id,
      register_otp: otpCode,
    };

    signUpService
      .getSignUpAPI(SignUpInputData)
      .then((response) => {
        if (
          response &&
          response?.data.message === "User registered successfully"
        ) {
          showToast(t(response?.data.message), "success");
          navigate("/login");
        } else {
          showToast(t(response?.data.message), "error");
        }
      })
      .catch((error) => {
        console.error(t("OTP Verification Error:"), error);
      });
  };
  const handleResendCode = () => {
    const inputData = {
      email: email,
    };
    signUpService
      .postSignUpOTP(inputData)
      .then((response) => {
        if (response && response.status === 200) {
          showToast(t("A new OTP code has been sent."), "success");
          setTimer(60);
          setCanResend(false);
          startTimer();
        } else {
          showToast(t(response?.data.message), "error");
        }
      })
      .catch((error) => {
        showToast(t(error), "error");
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: 13,
            mt: 3,
          }}
        >
          <Card
            sx={{
              minWidth: { xs: 200, sm: 300, md: 400 },
              maxWidth: { xs: 350, sm: 500, md: 500 },
              m: 2,
              py: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "40px",
              border: "1px solid #DFDFDF",
              opacity: "1px",
              boxShadow: "none",
            }}
          >
            <CardContent sx={{ textAlign: "center", width: "80%" }}>
              <Typography
                variant="h4"
                color="#0464AB;"
                gutterBottom
                sx={{
                  fontSize: 24,
                  letterSpacing: "1px",
                  marginBottom: "0px",
                }}
                className="h4"
              >
                {t("Verification")}
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                fontSize={12}
                fontWeight={540}
                sx={{
                  borderBottom: "1px solid #DFDFDF",
                  p: 1,
                  color: "#000000",
                }}
              >
                {t("Enter your OTP code number")}
              </Typography>

              <Box sx={{ pt: 1 }}>
                <form>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                    sx={{ margin: "10px" }}
                  >
                    {otp.map((value, index) => (
                      <TextField
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        inputProps={{
                          maxLength: 1,
                          style: { textAlign: "center", fontSize: "24px" },
                        }}
                        value={value}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyPress(e, index)}
                        variant="outlined"
                        sx={{
                          width: 50,
                          height: 50,
                          mx: 0.5,
                          "& .MuiInputBase-input.MuiOutlinedInput-input": {
                            height: "0.4375em",
                            padding: {
                              xs: "16.5px 1px",
                              sm: "16.5px 14px",
                              md: "16.5px 14px",
                            },
                          },
                        }}
                      />
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    disableElevation
                    disabled={otp.join("").length !== 6 }
                    onClick={handleSubmit}
                    sx={{
                      borderRadius: "21px",
                      backgroundColor: "#0464AB",
                      width: 120,
                      height: 35,
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "none",
                      marginTop: "10px",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "#022E4C",
                        fontWeight: "600",
                        borderColor: "#FBB522",
                      },
                    }}
                  >
                    {t("Verify")}
                  </Button>
                  {canResend ? (
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      fontSize={12}
                      sx={{
                        pb: 2,
                        fontWeight: 600,
                        color: "#575757",
                        marginTop: "30px",
                        lineHeight: 2,
                      }}
                    >
                     {t("Didn’t you receive any code?")}{" "}
                      <Box
                        component="span"
                        sx={{ display: { xs: "block", sm: "inline" } }}
                      >
                        <Link
                          onClick={handleResendCode}
                          sx={{
                            color: "#1976d2",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {t("Resend New Code")}
                        </Link>
                      </Box>
                    </Typography>
                  ) : (
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      fontSize={12}
                      sx={{
                        pb: 2,
                        fontWeight: 600,
                        color: "#575757",
                        marginTop: "30px",
                        lineHeight: 2,
                      }}
                    >
                     {t("Request again in")} {timer} {t("seconds")}
                    </Typography>
                  )}
                </form>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default SignUpOTP;
