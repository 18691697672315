import { httpCommon } from "./interceptor";

class SignUpService {
  getCourtListAPI() {
    return httpCommon.get("users/court-list");
  }
  getCountyListAPI() {
    return httpCommon.get("users/county-list");
  }
  getUserRoleListAPI() {
    return httpCommon.get("users/role-list/");
  }
  getUserTypeListAPI() {
    return httpCommon.get("users/user-type-list/");
  }
  getSignUpAPI(inputdata: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    language: string;
    court_id: string;
    county_id: string;
    bar_number: string;
    user_type_id: string | number;
    role_id: string | number;
    register_otp: string;
  }) {
    return httpCommon.post("users/register/", inputdata);
  }

  postSignUpOTP(inputdata: { email: string }) {
    return httpCommon.post("users/send_otp_register/", inputdata);
  }
}

const signUpService = new SignUpService();
export default signUpService;
