import React, { ChangeEvent } from "react";
import { Typography, Box } from "@mui/material";
import CustomTooltip from "../tooltip";
import interrogation from "../../assets/images/interrogation.svg";

interface InputTextFieldProps {
  type?: string;
  name: string;
  placeholder: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  error?: string;
  required?: boolean;
  label?: string;
  tooltip?: string;
  minRows?: number;
  backgroundColor?: string;
  disabled?: boolean;
  marginBottomLabel?: string;
}

const TextareaField: React.FC<InputTextFieldProps> = ({
  name,
  label,
  value,
  onChange,
  placeholder,
  required,
  error,
  tooltip,
  minRows,
  backgroundColor,
  disabled,
  marginBottomLabel,
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", mb: 1, width: "100%" }}
    >
      {label && (
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: marginBottomLabel || "8px",
            fontWeight: "medium",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {label} {required && <span style={{ color: "red" }}>*</span>}{" "}
          {tooltip && (
            <CustomTooltip title={tooltip}>
              <img
                src={interrogation}
                alt="Logo"
                height={13}
                style={{ marginLeft: "5px" }}
              />
            </CustomTooltip>
          )}
        </Typography>
      )}

      <Box
        component="textarea"
        required={required}
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        rows={minRows}
        sx={{
          borderRadius: "20px",
          border: error ? "1px solid #d32f2f" : "1px solid #E2E0E0",
          color: "#6E6E6E",
          fontWeight: "600",
          fontSize: "14px",
          backgroundColor: backgroundColor || "#fff",
          padding: "10px",
          minHeight: "20px",
          resize: "vertical",
          outline: "none",
          fontStyle: ' "Inter", sans-serif',
          "&:focus-visible": {
            border: error ? "2px solid #d32f2f" : "2px solid #E2E0E0",
          },
          "& .Mui-error": {
            color: "#d32f2f",
          },
          "::placeholder": {
            fontSize: 14,
            color: error
              ? "rgba(211, 47, 47, 0.5)"
              : "rgba(156, 155, 170, 0.5)",
          },
        }}
        disabled={disabled}
      />
      {error && (
        <Typography
          variant="subtitle1"
          sx={{
            mb: 1,
            fontWeight: "medium",
            textAlign: "left",
            color: "#d32f2f",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default TextareaField;
