import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Paper,
  Button,
  Grid,
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Divider,
  CircularProgress,
} from "@mui/material";

import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import FAQcss from "./FAQ.module.css";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { SelectChangeEvent } from "@mui/material/Select";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
import ExportFileDropdown from "../../components/exportFileDropDown";
import fAQService from "../../services/faq-service";
import ExportOptionData from "../../components/exportDataSelection";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getFileName } from "../../utils/fileNameUtil";
import { useTranslation } from "react-i18next";


interface FAQ {
  id: number;
  term: string;
  explanation: string;
}

const FrequentlyAskedQuestions: React.FC = () => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const [fAQsData, setFAQsData] = useState<FAQ[]>([]);
  const [fAQsAllData, setFAQsAllData] = useState<FAQ[]>([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [searchableOptions, setSearchableOptions] = useState<string>("Terms");
  const [faqSearchInput, setFAQSearchInput] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const OptionData = ExportOptionData(selectedValue);
  const { t } = useTranslation();

  useEffect(() => {
    handleGetFAQListData();
  }, []);

  const handleChange =
    (panel: string) =>
    (event: React.ChangeEvent<object>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };

  const handleGetFAQListData = () => {
    setLoading(true);
    fAQService
      .getFAQsDataAPI()
      .then((response: { data: { results: { data: FAQ[] } } }) => {
        if (response?.data.results.data?.length > 0) {
          setFAQsData(response?.data.results.data);
          setFAQsAllData(response?.data.results.data);
        } else {
          setFAQsData([]);
          setFAQsAllData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "FAQs Error");
      });
  };

  /// handle File Download onchange
  const handleFileDownloadOnChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    handleDownloadFAQsData(event.target.value);
  };

  //// handle Download FAQ Files data
  const handleDownloadFAQsData = (key: string) => {
    const arrayIDs = fAQsData.map((item) => item.id);
    fAQService
      .getDownloadFAQsDataAPI(key,arrayIDs)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", getFileName(key, "faqs"));
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("FAQs Error", error);
      });
  };

  const handleSearchableOptionsChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSearchableOptions(value as string);
    handleInputSearchData({
      target: { value: faqSearchInput },
    } as ChangeEvent<HTMLInputElement>);
  };

  const handleInputSearchData = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFAQSearchInput(value as string);
    if (value === "") {
      setFAQsData(fAQsAllData);
    } else {
      const searchField =
        searchableOptions === "Terms" ? "term" : "explanation";
      const lowerCaseValue = value.toLowerCase();
      const filteredResults = fAQsAllData.filter((item) =>
        item[searchField].toLowerCase().includes(lowerCaseValue)
      );
      setFAQsData(filteredResults);
    }
  };

  const handlePageResetField = () => {
    setSearchableOptions("Terms");
    setFAQSearchInput("");
    handleGetFAQListData();
  };
  return (
    <>
      <Paper sx={{ m: { md: "2.5% 10%", sm: "2% 10%", xs: "2% 5%" }, p: "1%" }}>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12} container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <Typography
                className="h4"
                fontSize={22}
                align="left"
                color="#0464AB"
                gutterBottom
              >
                {t("Frequently Asked Questions")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              sx={{
                display: "flex",
                justifyContent: { md: "end", sm: "center", xs: "center" },
              }}
            >
              <Grid container alignItems="end" spacing={2}>
                <Grid item xs={12} sm={6} md={6} sx={{ width: "100%" }}>
                  <TextField
                    variant="outlined"
                    placeholder={t("Search for {{option}}", { option: t(searchableOptions) })}
                    onChange={handleInputSearchData}
                    value={faqSearchInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Select
                              value={searchableOptions}
                              onChange={handleSearchableOptionsChange}
                              variant="standard"
                              IconComponent={ArrowDropDownIcon}
                              disableUnderline
                              sx={{
                                minWidth: 80,
                                "& .MuiSelect-select": {
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                },
                                "& .MuiSelect-icon": {
                                  marginRight: "4px",
                                },
                              }}
                            >
                              <MenuItem value="Terms">{t("Terms")}</MenuItem>
                              <MenuItem value="Explanation">
                                {t("Explanation")}
                              </MenuItem>
                            </Select>
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{ mx: 1 }}
                            />
                          </Box>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon sx={{ cursor: "pointer" }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "50px",
                        paddingRight: 3,
                        paddingLeft: 3,
                        height: "35px!important",
                      },
                      "& .MuiInputAdornment-root": {
                        color: "inherit",
                      },
                      "& .MuiSelect-select:focus": {
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <Button
                    variant="outlined"
                    className={FAQcss.resetButtoncss}
                    sx={{
                      height: "35px",
                      width: {
                        xl: "max-content",
                        lg: "fit-content",
                        md: "135px",
                        sm: "135px",
                        xs: "fit-content",
                      },
                      fontSize: {
                        md: "12px",
                        sm: "12px",
                        xs: "9px",
                      },
                      textTransform: "none",
                    }}
                    onClick={handlePageResetField}
                  >
                    <ReplayOutlinedIcon sx={{ marginRight: "2px" }} />
                    {t("Reset Grid")}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  sx={{
                    ml: { md: -2, sm: 0 },
                    justifyContent: {
                      md: "space-between",
                      xs: "end",
                      sm: "end",
                    },
                    display: { xs: "flex" },
                  }}
                >
                  <Box sx={{ textAlign: "right" }}>
                    <ExportFileDropdown
                      name="dropdown"
                      label=""
                      value={selectedValue}
                      onChange={handleFileDownloadOnChange}
                      options={OptionData.slice(1)}
                      placeholder={
                        <div style={{ display: "flex", alignItems: "left" }}>
                          <FileExporticon />
                          <span style={{ marginLeft: "6px", color: "#9C9BAA" }}>
                            {OptionData[0].label}
                          </span>
                        </div>
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <Box display="flex" justifyContent="center" padding={2}>
            <CircularProgress />
          </Box>
        ) : (
          <div>
            {fAQsData &&
              fAQsData.map((item: FAQ, i: number) => (
                <Accordion
                  key={i}
                  expanded={expanded === `panel${i}`}
                  onChange={handleChange(`panel${i}`)}
                  sx={{
                    marginBottom: "1.5%",
                    "&::before": {
                      height: "0px",
                    },
                  }}
                  className={
                    expanded === `panel${i}`
                      ? FAQcss.activeAccordionSummary
                      : FAQcss.inactiveAccordian
                  }
                >
                  <AccordionSummary
                    sx={{ minHeight: { md: "0px !important", sm: "64px" } }}
                    expandIcon={
                      expanded === `panel${i}` ? (
                        <RemoveCircleOutlineIcon sx={{ color: "#0464AB" }} />
                      ) : (
                        <AddCircleOutlineIcon />
                      )
                    }
                    aria-controls={`panel${i}-content`}
                    id={`panel${i}-header`}
                    className={
                      expanded === `panel${i}` ? FAQcss.activeAccordian : ""
                    }
                  >
                    <Typography className={FAQcss.boldText}>
                      {item.term}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0px 16px 6px 16px" }}>
                    {item.explanation}
                  </AccordionDetails>
                </Accordion>
              ))}
          </div>
        )}
      </Paper>
    </>
  );
};

export default FrequentlyAskedQuestions;
