import React, { ChangeEvent, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import CustomInput from "../../components/inputField";
import { Card, CardContent, Typography, Button } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

import reCAPTCHAkey from "../../utils/reCAPTCHAkey";
import "./forget.module.css";
import { useNavigate, Link } from "react-router-dom";
import { showToast } from "../../components/toastNotification/index";
import forgetPasswordService from "../../services/forget-password-service";
import { useTranslation } from "react-i18next";

const ForgetForm: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [emailValidation, setEmailValidation] = useState<string>("");
  const [recaptchaCode, setRecaptchaCode] = useState<string | null>(null);
  const [isRecaptchaValide, setIsRecaptchaValide] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailValidation("");
  };

  const handleRecaptcha = (value: string | null) => {
    setRecaptchaCode(value);
    setIsRecaptchaValide("");
  };
  //// input form validation
  const formValidation = () => {
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidation;
    if (email === "") {
      setEmailValidation(t("Email Address cannot be blank"));
      isValidation = true;
    } else {
      if (!emailReg.test(email)) {
        setEmailValidation(t("Email Address is invalid"));
        isValidation = true;
      }
    }
    if (recaptchaCode === null) {
      setIsRecaptchaValide(t("Please confirm that you are not a bot."));
      isValidation = true;
    }
    return isValidation;
  };
  //// handle reset password API calling
  const handleSubmitForm = () => {
    if (!formValidation()) {
      setIsLoading(true);
      const inputData = {
        email: email.trim(),
      };
      forgetPasswordService
        .resetPasswordAPI(inputData)
        .then((response) => {
          if (
            response &&
            response?.data.message === t("Reset password email sent")
          ) {
            showToast(t(response?.data.message), "success");
            navigate("/otpVerification", {
              state: { email: inputData.email },
            });
          } else {
            showToast(t(response?.data.message), "error");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error, "API Error");
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          sx={{
            minWidth: 200,
            maxWidth: 310,
            my: 5,
            mx: 2,
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "40px",
            border: "1px solid #DFDFDF",
            opacity: "1px",
            boxShadow: "none",
          }}
        >
          <CardContent sx={{ textAlign: "center", padding: "16px 25px" }}>
            <Typography
              variant="h4"
              color="#0464AB;"
              gutterBottom
              className="h5"
            >
              {t("Reset Password")}
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              fontSize={12}
              sx={{ borderBottom: "1px solid #DFDFDF", p: 2 }}
            >
              {t("Please enter your email address in the Email field, and then click the Send Email button. The calculator will send an email that includes the password reset Instructions.")}
            </Typography>
            <Box sx={{ pt: 3 }}>
              <form>
                <CustomInput
                  onChange={handleEmailChange}
                  value={email}
                  label={t("Email")}
                  name="email"
                  type="textarea"
                  required={true}
                  placeholder={t("Email")}
                  error={emailValidation !== "" ? emailValidation : ""}
                />
                <Box sx={{ my: 3 }}>
                  <ReCAPTCHA
                    sitekey={
                      window.location.host === "localhost:3000"
                        ? reCAPTCHAkey.localhostSitekey
                        : window.location.host ===
                          "childsupport-dev.brainvire.net"
                        ? reCAPTCHAkey.devSitekey
                        : reCAPTCHAkey.UATSitekey
                    }
                    onChange={handleRecaptcha}
                  />
                  {isRecaptchaValide !== "" && (
                    <Box
                      sx={{
                        color: "#d32f2f",
                        mt: 1,
                        textAlign: "left",
                        fontWeight: "medium",
                      }}
                    >
                      <span>{isRecaptchaValide}</span>
                    </Box>
                  )}
                </Box>

                <Button
                  variant="contained"
                  disableElevation
                  disabled={isLoading}
                  onClick={handleSubmitForm}
                  sx={{
                    borderRadius: "21px",
                    width: 120,
                    mx: 2,
                    backgroundColor: "#0464AB",
                    fontWeight: "600",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#FBB522",
                      color: "black",
                      fontWeight: "600",
                    },
                  }}
                >
                  {t("Send")}{" "}
                  {isLoading && (
                    <CircularProgress size={18} sx={{ padding: "0px 8px" }} />
                  )}
                </Button>
                <Link to="/">
                  <Button
                    variant="outlined"
                    disableElevation
                    sx={{
                      borderRadius: "21px",
                      border: "1px solid #0464AB",
                      width: 100,
                      color: "#0464AB",
                      textTransform: "none",
                      fontWeight: "600",
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "black",
                        borderColor: "#FBB522",
                        fontWeight: "600",
                      },
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                </Link>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default ForgetForm;
