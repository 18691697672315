import React, { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Card,
  Grid,
  Button,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import organizationService from "../../services/organizationService/organization-service";
import { showToast } from "../../components/toastNotification/index";
import { RootState } from "../../utils/userDetailsCommonType";
import { loginDetails } from "../../globalState/actions/authAction";

interface DataState {
  invOrganizationName: string;
  invitedOrgOwnerName: string;
}

const BecomeOrganizationMember = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.authReducer);

  const [inviteOrgData, setInviteOrgData] = useState<DataState>({
    invOrganizationName: "",
    invitedOrgOwnerName: ""
  });
  const [invitationNotFound, setInvitationNotFound] = useState<boolean>(false);
  const [acceptedLoading, setAcceptedLoading] = useState<boolean>(false);
  const [rejectedLoading, setRejectedLoading] = useState<boolean>(false);

  useEffect(() => {
    handleInviteOrganizationData();
  }, []);

  /// APi calling function start
  // APi called for Invite a Org User
  const handleInviteOrganizationData = async () => {
    try {
      const response = await organizationService.getInviteFromOrganization();
      const { data } = response.data;

      if (data && data.inv_organization_name && data.invited_org_owner_name) {
        setInviteOrgData({
          invOrganizationName: data.inv_organization_name,
          invitedOrgOwnerName: data.invited_org_owner_name
        });

        setInvitationNotFound(false);
      } else {
        setInvitationNotFound(true);
      }
    } catch (error) {
      console.log(error, "API Error");
      setInvitationNotFound(true);
    }
  };
  // APi called for Org accept request
  const handleAcceptRequest = async () => {
    setAcceptedLoading(true);
    try {
      await organizationService.updateOrganizationInvitation({
        accepted: true
      });
      showToast("Invitation Accepted successfully!", "success");
      const userData = {
        ...userDetails?.loginDetials,
        is_organization_admin: false,
        invite_from_org: false,
        organization: true
      };
      dispatch(loginDetails(userData));
      navigate("/");
    } catch (error) {
      console.log(error, "API Error");
      showToast("An error occurred while accepting the invitation.", "error");
    } finally {
      setAcceptedLoading(false);
    }
  };
  // APi called for Org reject request
  const handleRejectRequest = async () => {
    setRejectedLoading(true);
    try {
      await organizationService.updateOrganizationInvitation({
        accepted: false
      });
      showToast("Invitation Rejected", "success");
      const userData = {
        ...userDetails?.loginDetials,
        invite_from_org: false
      };
      dispatch(loginDetails(userData));
      navigate("/");
    } catch (error) {
      console.log("API Error:", error);
      showToast("An error occurred while rejecting the invitation.", "error");
    } finally {
      setRejectedLoading(false);
    }
  };
  /// APi calling function end
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}
        className="contain__area"
      >
        <Box
          className="breadcrumbs"
          sx={{
            margin: {
              xs: "10px 0px 0px 1px",
              sm: "10px 56px 0px 1px",
              md: "30px 56px 0px 56px"
            }
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="#147AFF" href="/">
                Home
              </Link>
              <Typography color="#000000">
                Become An Organization Member
              </Typography>
            </Breadcrumbs>
          </div>
        </Box>
        <Box>
          <Box
            sx={{
              margin: { md: "1rem 10rem", sm: "1rem 0rem", xs: "1rem 0rem" }
            }}
          >
            <Card
              elevation={0}
              sx={{
                bgcolor: "#fff",
                border: "1px solid #DFDFDF",
                borderRadius: "30px 30px",
                minHeight: "200px",
                opacity: 1,
                m: { md: 4, sm: 1, xs: 0 }
              }}
            >
              <Box sx={{ marginTop: { md: "3rem", sm: "3rem", xs: "1rem" } }}>
                <Box>
                  <Typography
                    variant="h4"
                    mx={{ md: 4, sm: 3, xs: 1 }}
                    color="#0464AB;"
                    textAlign="center"
                    gutterBottom
                    sx={{
                      fontFamily: "DM Serif Display",
                      borderBottom: "1px solid #DFDFDF",
                      pb: 2,
                      px: 2
                    }}
                    className="h4 main_heading"
                  >
                    Become An Organization Member
                  </Typography>
                </Box>
              </Box>
              {invitationNotFound ? (
                <Box>
                  <Typography
                    variant="subtitle1"
                    mx={2}
                    fontWeight="bold"
                    gutterBottom
                    sx={{
                      color: "#022E4C",
                      fontSize: "14px",
                      textAlign: "center",
                      mt: 4
                    }}
                  >
                    No invitation found for this email.
                  </Typography>
                </Box>
              ) : (
                <Box m={{ md: 3, sm: 3, xs: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant="subtitle1"
                        mx={2}
                        fontWeight="bold"
                        gutterBottom
                        sx={{
                          color: "#022E4C",
                          fontSize: "14px"
                        }}
                      >
                        SECTION 1. Become An Organization Member
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        mx={2}
                        textAlign={{ xs: "center", sm: "left", md: "left" }}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          You have been selected by{" "}
                          {inviteOrgData.invitedOrgOwnerName} to be added to the{" "}
                          {inviteOrgData.invOrganizationName} Organization.
                          <br />
                          <br />
                          Press the Accept Request Button to be Added to the Organization or Press the Reject Request Button to not be Added to the Organization.
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={8} md={12}>
                      <Box display="flex" justifyContent="flex-start" mb={3}>
                        <Button
                          variant="contained"
                          onClick={handleAcceptRequest}
                          disableElevation
                          sx={{
                            minWidth: "fit-content",
                            borderRadius: "20px",
                            width: 150,
                            mx: 2,
                            fontWeight: "600",
                            backgroundColor: "#0464AB",
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: "#FBB522",
                              color: "#022E4C",
                              fontWeight: "600"
                            }
                          }}
                        >
                          Accept Request
                          {acceptedLoading && (
                            <CircularProgress
                              size={16}
                              sx={{ padding: "0px 8px", color: "#fff" }}
                            />
                          )}
                        </Button>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={handleRejectRequest}
                          sx={{
                            minWidth: "fit-content",
                            borderRadius: "20px",
                            width: 150,
                            textTransform: "none",
                            backgroundColor: "#fff",
                            border: "1px solid #0464AB",
                            color: "#0464AB",
                            fontWeight: "600",
                            "&:hover": {
                              backgroundColor: "#FBB522",
                              color: "#022E4C",
                              border: "none",
                              fontWeight: "600"
                            }
                          }}
                        >
                          Reject Request
                          {rejectedLoading && (
                            <CircularProgress
                              size={16}
                              sx={{ padding: "0px 8px" }}
                            />
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BecomeOrganizationMember;
