import React from "react";
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import style from "../searchWorksheet.module.css";
import SearchWorksheetButtons from "../../../components/searchWorksheetButtons";
import { useAppSelector } from "../../../globalState/hooks";
import TextareaField from "../../../components/text-area";
import { CalculateNarestChildValues } from "../../../utils/calculateNarestChildValues";
import NoDataImage from "../../../assets/images/FootnoteImg.png";
import {
  formatNumber,
  formatNumberWithDigit,
} from "../../../utils/formatNumber";
import { useTranslation } from "react-i18next";

const AdjustedIncomeTab: React.FC = () => {
  const WorkSheetData = useAppSelector((state) => state.createWorksheet);
  const { t } = useTranslation();

  ////---------Design code start---------
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 0 0 1px rgb(16 22 26 / 40%)"
        : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
    backgroundImage:
      theme.palette.mode === "dark"
        ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
        : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background:
        theme.palette.mode === "dark"
          ? "rgba(57,75,89,.5)"
          : "rgba(206,217,224,.5)",
    },
  }));
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&::before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  });
  ////---------Design code end---------
  const ficaValue1 = WorkSheetData.adjusted_income_schedule_b
    .monthly_self_employment_income1
    ? Number(
        WorkSheetData.adjusted_income_schedule_b.monthly_self_employment_income1
      ) * 0.062
    : 0;
  const ficaValue2 = WorkSheetData.adjusted_income_schedule_b
    .monthly_self_employment_income2
    ? Number(
        WorkSheetData.adjusted_income_schedule_b.monthly_self_employment_income2
      ) * 0.062
    : 0;

  const line4Value1 = WorkSheetData.adjusted_income_schedule_b
    .monthly_self_employment_income1
    ? Number(
        WorkSheetData.adjusted_income_schedule_b.monthly_self_employment_income1
      ) * 0.0145
    : 0;
  const line4Value2 = WorkSheetData.adjusted_income_schedule_b
    .monthly_self_employment_income2
    ? Number(
        WorkSheetData.adjusted_income_schedule_b.monthly_self_employment_income2
      ) * 0.0145
    : 0;

  const line5Value1 = Number(ficaValue1) + Number(line4Value1);
  const line5Value2 = Number(ficaValue2) + Number(line4Value2);

  const line6Value1 = WorkSheetData.gross_income_schedule_a.total_gross_income1
    ? Number(WorkSheetData.gross_income_schedule_a.total_gross_income1) -
      line5Value1
    : 0;
  const line6Value2 = WorkSheetData.gross_income_schedule_a.total_gross_income2
    ? Number(WorkSheetData.gross_income_schedule_a.total_gross_income2) -
      line5Value2
    : 0;

  const line9Value1 =
    Number(line6Value1) -
    Number(
      WorkSheetData?.adjusted_income_schedule_b
        ?.preexisting_child_support_amount1
    );
  const line9Value2 =
    Number(line6Value2) -
    Number(
      WorkSheetData?.adjusted_income_schedule_b
        ?.preexisting_child_support_amount2
    );

  const line10AValues = WorkSheetData.adjusted_income_schedule_b
    .proposed_adjustment_for_qualified_child
    ? WorkSheetData.adjusted_income_schedule_b.proposed_adjustment_for_qualified_child.reduce(
        (
          acc: { parent1: number; parent2: number },
          item: { adjustment_requested_by: string }
        ) => {
          if (item.adjustment_requested_by === WorkSheetData.parent_name_1) {
            acc.parent1 = (acc.parent1 || 0) + 1;
          }

          if (item.adjustment_requested_by === WorkSheetData.parent_name_2) {
            acc.parent2 = (acc.parent2 || 0) + 1;
          }

          return acc;
        },
        { parent1: 0, parent2: 0 }
      )
    : { parent1: 0, parent2: 0 };

  const selfEmpTaxAdj = [
    {
      label:
        t("2. Monthly Self-Employment Income on which a parent paid Self-Employment Taxes for FICA & Medicare"),
      label1: WorkSheetData.adjusted_income_schedule_b
        .monthly_self_employment_income1
        ? "$" +
          formatNumber(
            WorkSheetData.adjusted_income_schedule_b
              .monthly_self_employment_income1
          )
        : "",
      label2: WorkSheetData.adjusted_income_schedule_b
        .monthly_self_employment_income2
        ? "$" +
          formatNumber(
            WorkSheetData.adjusted_income_schedule_b
              .monthly_self_employment_income2
          )
        : "",
    },
    {
      label: t("3. FICA calculation (Line 2 multiplied by .062)"),
      label1: ficaValue1 ? "$" + formatNumberWithDigit(Number(ficaValue1)) : "",
      label2: ficaValue2 ? "$" + formatNumberWithDigit(Number(ficaValue2)) : "",
    },
    {
      label: t("4. Medicare tax calculation (Line 2 multiplied by 0.0145)"),
      label1: line4Value1
        ? "$" + formatNumberWithDigit(Number(line4Value1))
        : "",
      label2: line4Value2
        ? "$" + formatNumberWithDigit(Number(line4Value2))
        : "",
    },
    {
      label: t("5. Total of Lines 3 & 4"),
      label1: line5Value1 ? "$" + formatNumberWithDigit(line5Value1) : "",
      label2: line5Value2 ? "$" + formatNumberWithDigit(line5Value2) : "",
    },
    {
      label: t("6. Line 5 subtracted from Line 1"),
      label1: line6Value1 ? "$" + formatNumber(Number(line6Value1)) : "",
      label2: line6Value2 ? "$" + formatNumber(Number(line6Value2)) : "",
    },
    ...(WorkSheetData.adjusted_income_schedule_b.pre_existing_child_support
      ?.length === 0 &&
    WorkSheetData.adjusted_income_schedule_b
      .proposed_adjustment_for_qualified_child?.length === 0
      ? [
          {
            label:
              t("9. Line 8 subtracted from Line 6 If a discretionary adjustment is being claimed for other qualified children living in the home, information will appear on Line 10 below. Otherwise, the adjusted income amount on Line 9 will automatically display on line 2 of the worksheet."),
            label1: line6Value1 ? "$" + formatNumber(Number(line6Value1)) : "",
            label2: line6Value2 ? "$" + formatNumber(Number(line6Value2)) : "",
          },
        ]
      : []),
  ];

  const line12Value1 =
    line6Value1 > 0
      ? CalculateNarestChildValues(Number(line6Value1), line10AValues.parent1)
      : 0;
  const line12Value2 =
    line6Value2 > 0
      ? CalculateNarestChildValues(Number(line6Value2), line10AValues.parent2)
      : 0;

  const line13Value1 = (line12Value1 * 75) / 100;
  const line13Value2 = (line12Value2 * 75) / 100;

  const line14Value1 = line13Value1 ? line9Value1 - line13Value1 : "";
  const line14Value2 = line13Value2 ? line9Value2 - line13Value2 : "";
 
  const tableData = [
    {
      description:
        t("11. Amounts from Line 6 for parent(s) seeking a Theoretical Child Support order adjustment"),
      p1:
        line9Value1 && line10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(line9Value1)
          : "",
      p2:
        line9Value2 && line10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(line9Value2)
          : "",
    },
    {
      description:
        t("12. Basic Child Support Obligation (from table) displays for number of children on Line 10 and income on Line 11, for parent(s) seeking the adjustment"),
      p1:
        line12Value1 && line10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(line12Value1)
          : "",
      p2:
        line12Value2 && line10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(line12Value2)
          : "",
    },
    {
      description:
        t("13. 75% of the amounts on Line 12 for parents seeking this adjustment"),
      p1:
        line13Value1 && line10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(line13Value1)
          : "",
      p2:
        line13Value2 && line10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(line13Value2)
          : "",
    },
    {
      description:
        t("14. If this adjustment is allowed, Line 13 will be subtracted from Line 9 and that amount will display on Line 2 of the Worksheet"),
      p1:
        line14Value1 && line10AValues.parent1 > 0
          ? "$" + formatNumberWithDigit(line14Value1)
          : "",
      p2:
        line14Value2 && line10AValues.parent2 > 0
          ? "$" + formatNumberWithDigit(line14Value2)
          : "",
    },
  ];

  return (
    <>
      {Number(
        WorkSheetData.adjusted_income_schedule_b.monthly_self_employment_income1
      ) > 0 ||
      Number(
        WorkSheetData.adjusted_income_schedule_b.monthly_self_employment_income2
      ) > 0 ||
      WorkSheetData.adjusted_income_schedule_b.pre_existing_child_support
        ?.length > 0 ||
      WorkSheetData.adjusted_income_schedule_b
        .proposed_adjustment_for_qualified_child?.length > 0 ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              className={style.contentHeading}
              marginTop={"15px !important"}
            >
              {t("CHILD SUPPORT SCHEDULE B ADJUSTED INCOME")}
            </Typography>
          </Box>
          {/* Table 1 Self Employment Tax Adjustment */}
          <Box mt={3}>
            <TableContainer sx={{ borderRadius: " 5px" }} component={Paper}>
              <Table
                className="custom-table"
                aria-label="customized table"
                sx={{ minWidth: 900 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {t("Schedule B - All amounts/data that display on Schedule B were entered using the Online Child Support Calculator and can only be changed by selecting the button “Open This Worksheet.” Amounts below will display as monthly sums. The totals from Line 9 or 14 will display on Line 2 of the Worksheet.")}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px", width: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_1}</Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_2}</Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.firstColumnStyle}
                      sx={{ width: "75%" }}
                    >
                      <Box>
                        {t("1. Total Gross Monthly Income from Schedule A, Line 24")}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={style.searchDeviationsCellStyle}
                    >
                      {WorkSheetData.gross_income_schedule_a.total_gross_income1
                        ? "$" +
                          formatNumber(
                            Number(
                              WorkSheetData.gross_income_schedule_a
                                .total_gross_income1
                            )
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={style.searchDeviationsCellStyle}
                    >
                      {WorkSheetData.gross_income_schedule_a.total_gross_income2
                        ? "$" +
                          formatNumber(
                            Number(
                              WorkSheetData.gross_income_schedule_a
                                .total_gross_income2
                            )
                          )
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableCell sx={{ padding: 0 }} align="right" colSpan={3}>
                    <Typography
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        background: "#F8F8F8",
                        border: "1px solid #DFDFDF",
                        fontWeight: "600",
                        color: "#404040",
                      }}
                    >
                      {t("Self Employment Tax Adjustment")}
                    </Typography>
                  </TableCell>
                </TableBody>
                <TableBody>
                  {selfEmpTaxAdj.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyle}
                        sx={{ width: "75%", padding: "10px" }}
                      >
                        <Box>{row.label}</Box>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchDeviationsCellStyle}
                        style={{ width: "7%" }}
                        sx={index === 0 ? { backgroundColor: "#CEFBC9" } : null}
                      >
                        {row.label1}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchDeviationsCellStyle}
                        style={{ width: "7%" }}
                        sx={index === 0 ? { backgroundColor: "#CEFBC9" } : null}
                      >
                        {row.label2}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {WorkSheetData.adjusted_income_schedule_b.pre_existing_child_support
            ?.length !== 0 ? (
            <>
              <Box mt={2}>
                <Typography
                  p={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "#F8F8F8",
                    border: "1px solid #DFDFDF",
                    fontWeight: "600",
                    color: "#404040",
                    borderRadius: "5px 5px 0px 0px",
                  }}
                >
                  {t("Adjustment for Preexisting Child Support Orders Being Paid for Other Children")}
                </Typography>
                <Typography
                  p={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "#F8F8F8",
                    border: "1px solid #DFDFDF",
                    color: "#404040",
                  }}
                >
                  {t("7. Information on child(ren) included in Preexisting Orders, and the child support actually paid monthly, excluding arrears payments, will display here.")}
                </Typography>
                <TableContainer
                  sx={{ borderRadius: "0px 0px 5px 5px" }}
                  component={Paper}
                >
                  <Table
                    className="custom-table"
                    aria-label="customized table"
                    sx={{ minWidth: 900 }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" className={style.headerStyle}>
                          <Box>{t("Court Name")}</Box>
                        </TableCell>
                        <TableCell className={style.headerStyle}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {t("Civil Action Case Number")}
                          </Box>
                        </TableCell>
                        <TableCell align="center" className={style.headerStyle}>
                          <Box>{t("Child Name/Birth Year")}</Box>
                        </TableCell>
                        <TableCell align="center" className={style.headerStyle}>
                          <Box>{t("Date/Time of Initial Order")}</Box>
                        </TableCell>
                        <TableCell align="center" className={style.headerStyle}>
                          <Box>
                            {t("Preexisting Child Support Amount Paid by")}{" "}
                            {WorkSheetData.parent_name_1}
                          </Box>
                        </TableCell>
                        <TableCell align="center" className={style.headerStyle}>
                          <Box>
                            {t("Preexisting Child Support Amount Paid by")}{" "}
                            {WorkSheetData.parent_name_2}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {WorkSheetData?.adjusted_income_schedule_b
                        ?.pre_existing_child_support &&
                        WorkSheetData.adjusted_income_schedule_b.pre_existing_child_support.map(
                          (
                            item: {
                              court_name: string;
                              civil_action_case_number: number;
                              children_names_years_of_birth: string;
                              date_of_initial_order: string;
                              time_of_initial_order: string;
                              preexisting_child_support_paid_monthly1: string;
                              preexisting_child_support_paid_monthly2: string;
                            },
                            index: number
                          ) => {
                            const alignment = index === 0 ? "left" : "left";
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    index === 0 ? "#CEFBC9" : "#CEFBC9",
                                }}
                              >
                                <TableCell
                                  align={alignment}
                                  className={style.searchDeviationsCellStyle}
                                >
                                  {item.court_name}
                                </TableCell>
                                <TableCell
                                  align={alignment}
                                  className={style.searchDeviationsCellStyle}
                                >
                                  {item.civil_action_case_number}
                                </TableCell>
                                <TableCell
                                  align={alignment}
                                  className={style.searchDeviationsCellStyle}
                                >
                                  {item.children_names_years_of_birth}
                                </TableCell>
                                <TableCell
                                  align={alignment}
                                  className={style.searchDeviationsCellStyle}
                                >
                                  {item.date_of_initial_order ||
                                    (item.time_of_initial_order &&
                                      item.date_of_initial_order +
                                        " @ " +
                                        item.time_of_initial_order)}
                                </TableCell>
                                <TableCell
                                  align={alignment}
                                  className={style.searchDeviationsCellStyle}
                                >
                                  {item.preexisting_child_support_paid_monthly1 ? "$" +
                                    item.preexisting_child_support_paid_monthly1 : ""}
                                </TableCell>
                                <TableCell
                                  align={alignment}
                                  className={style.searchDeviationsCellStyle}
                                >
                                  {item.preexisting_child_support_paid_monthly2 ?  "$" +
                                    item.preexisting_child_support_paid_monthly2 : ""}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      <TableRow>
                        <TableCell
                          className={style.searchDeviationsCellStyle}
                          colSpan={4}
                        >
                          {t("8. Total Adjustment for Preexisting Child Support Orders for each parent will display here")}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={style.searchDeviationsCellStyle}
                        >
                          {WorkSheetData?.adjusted_income_schedule_b
                            ?.preexisting_child_support_amount1
                            ? "$" +
                              formatNumber(
                                Number(
                                  WorkSheetData?.adjusted_income_schedule_b
                                    ?.preexisting_child_support_amount1
                                )
                              )
                            : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={style.searchDeviationsCellStyle}
                        >
                          {WorkSheetData?.adjusted_income_schedule_b
                            ?.preexisting_child_support_amount2
                            ? "$" +
                              formatNumber(
                                Number(
                                  WorkSheetData?.adjusted_income_schedule_b
                                    ?.preexisting_child_support_amount2
                                )
                              )
                            : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={style.searchDeviationsCellStyle}
                          colSpan={4}
                        >
                          {t("9. Line 8 subtracted from Line 6 If a discretionary adjustment is being claimed for other qualified children living in the home, information will appear on Line 10 below. Otherwise, the adjusted income amount on Line 9 will automatically display on line 2 of the worksheet.")}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={style.searchDeviationsCellStyle}
                        >
                          {line9Value1 ? "$" + formatNumber(line9Value1) : ""}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={style.searchDeviationsCellStyle}
                        >
                          {line9Value2 ? "$" + formatNumber(line9Value2) : ""}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              {WorkSheetData?.adjusted_income_schedule_b
                ?.proposed_adjustment_for_qualified_child?.length > 0 && (
                <Box mt={2}>
                  <Typography
                    p={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      background: "#F8F8F8",
                      border: "1px solid #DFDFDF",
                      fontWeight: "600",
                      color: "#404040",
                      borderRadius: "5px 5px 0px 0px",
                    }}
                  >
                    {t("Discretionary Adjustment to Income for Other Qualified Children Living in Parent’s Home")}
                  </Typography>
                  <Box
                    p={1}
                    sx={{
                      justifyContent: "center",
                      background: "#F8F8F8",
                      border: "1px solid #DFDFDF",
                      color: "#404040",
                    }}
                  >
                    <Typography>
                      {t("The Court has the discretion to consider an Adjustment to Income for qualified children under this section for the purpose of reducing a parent’s gross income, if failure to consider an adjustment would cause substantial hardship to the parent. If the Court considers an Adjustment to Income under this section, the Court must also consider whether this Adjustment to Income is in the best interest of the child(ren) for whom support is being determined.")}{" "}
                    </Typography>
                    <Typography pt={2}>
                      {t("Adjustment may be considered only for children who meet")}{" "}
                      <span
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        {t("ALL FIVE")}
                      </span>{" "}
                      {t("of the following requirements:")}
                    </Typography>
                    <Typography>
                      {t("A. The parent is legally responsible for the qualified child (Stepchildren do not qualify);")}
                    </Typography>
                    <Typography>
                      {t("B. The qualified child lives in the parent’s home;")}
                    </Typography>
                    <Typography>
                      {t("C. The parent is actually supporting the qualified child;")}
                    </Typography>

                    <Typography>
                      {t("D. The qualified child is not subject to a preexisting child support order; and")}
                    </Typography>

                    <Typography>
                      {t("E. The qualified child is not currently before the court to set, modify or enforce child support.")}
                    </Typography>
                  </Box>

                  <TableContainer>
                    <Table
                      className="custom-table"
                      aria-label="customized table"
                      sx={{ minWidth: 900 }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            className={style.headerStyle}
                            style={{ minWidth: "10px" }}
                          >
                            <Box>10</Box>
                          </TableCell>
                          <TableCell
                            className={style.headerStyle}
                            style={{ width: "63%" }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {t("Name(s)")}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={style.headerStyle}
                          >
                            <Box>{t("Birth Year")}</Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={style.headerStyle}
                          >
                            <Box>
                              {t("Number of children claimed by")}{" "}
                              {WorkSheetData.parent_name_1}
                            </Box>
                          </TableCell>

                          <TableCell
                            align="center"
                            className={style.headerStyle}
                          >
                            <Box>
                              {t("Number of children claimed by")}{" "}
                              {WorkSheetData.parent_name_2}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {WorkSheetData?.adjusted_income_schedule_b
                          ?.proposed_adjustment_for_qualified_child &&
                          WorkSheetData.adjusted_income_schedule_b.proposed_adjustment_for_qualified_child.map(
                            (
                              row: {
                                child_name: string;
                                year_of_birth: number;
                                adjustment_requested_by: string;
                              },
                              index: number
                            ) => {
                              // const alignment = index === 0 ? "left" : "right";
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    backgroundColor:
                                      index === 0 ? "#CEFBC9" : "#CEFBC9",
                                  }}
                                >
                                  <TableCell
                                    align="left"
                                    className={style.searchDeviationsCellStyle}
                                  >
                                    {index + 1}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={style.searchDeviationsCellStyle}
                                  >
                                    {row.child_name}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={style.searchDeviationsCellStyle}
                                  >
                                    {row.year_of_birth}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={style.searchDeviationsCellStyle}
                                  >
                                    {row.adjustment_requested_by ===
                                    WorkSheetData.parent_name_1
                                      ? "1"
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    className={style.searchDeviationsCellStyle}
                                  >
                                    {row.adjustment_requested_by ===
                                    WorkSheetData.parent_name_2
                                      ? "1"
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        <TableRow>
                          <TableCell
                            align="right"
                            className={style.searchDeviationsCellStyle}
                          >
                            10(a)
                          </TableCell>
                          <TableCell
                            className={style.searchDeviationsCellStyle}
                            colSpan={1}
                          >
                            {t("A in this box indicates child(ren) listed above are included as QUALIFIED children for whom an adjustment is claimed. Calculations will display below on Lines 11-14.")}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={style.searchDeviationsCellStyle}
                            style={{ background: "#CEFBC9" }}
                          >
                            <Checkbox
                              sx={{
                                "&:hover": { bgcolor: "transparent" },
                                padding: 0,
                              }}
                              checked={true}
                              disableRipple
                              color="default"
                              checkedIcon={<BpCheckedIcon />}
                              icon={<BpIcon />}
                              inputProps={{ "aria-label": "Checkbox demo" }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={style.searchDeviationsCellStyle}
                          >
                            {line10AValues ? line10AValues.parent1 : 0}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={style.searchDeviationsCellStyle}
                          >
                            {line10AValues ? line10AValues.parent2 : 0}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              <Box>
                {WorkSheetData?.adjusted_income_schedule_b
                  ?.proposed_adjustment_for_qualified_child?.length > 0 && (
                  <>
                    <Typography
                      p={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        border: "1px solid #DFDFDF",
                        fontWeight: "600",
                        color: "#404040",
                      }}
                    >
                      {t("Explain why you have included an Other Qualified Child in the Current Court Case")}
                    </Typography>

                    <Box sx={{ border: "1px solid #DFDFDF" }}>
                      <Typography marginY={1} px={2} pb={1}>
                        {WorkSheetData.parent_name_1}
                      </Typography>
                      <Typography
                        marginY={1}
                        p={1}
                        paddingBottom="2px"
                        sx={{ bgcolor: "#CEFBC9" }}
                      >
                        <TextareaField
                          name="messsage"
                          placeholder={""}
                          value={
                            WorkSheetData?.adjusted_income_schedule_b
                              ?.reason_for_including_other_child1
                          }
                          onChange={() => {}}
                          minRows={1}
                          disabled
                        />
                      </Typography>
                      <Typography marginY={1} px={2} pb={1}>
                        {WorkSheetData.parent_name_2}
                      </Typography>
                      <Typography
                        padding={1}
                        paddingBottom="2px"
                        sx={{ bgcolor: "#CEFBC9" }}
                      >
                        <TextareaField
                          name="messsage"
                          placeholder={""}
                          value={
                            WorkSheetData?.adjusted_income_schedule_b
                              ?.reason_for_including_other_child2
                          }
                          onChange={() => {}}
                          minRows={1}
                          disabled
                        />
                      </Typography>
                    </Box>
                    <Table
                      className="custom-table"
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={style.headerStyle}
                            style={{ width: "63%" }}
                          >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              {/* Header content */}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={style.headerStyle}
                          >
                            <Box>{WorkSheetData.parent_name_1}</Box>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={style.headerStyle}
                          >
                            <Box>{WorkSheetData.parent_name_2}</Box>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="left"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.description}
                            </TableCell>
                            <TableCell
                              className={style.searchDeviationsCellStyle}
                              colSpan={1}
                            >
                              {row.p1}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.p2}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
              </Box>
            </>
          ) : null}
        </>
      ) : (
        <Box textAlign={"center"} my={"4rem"}>
          <Box
            component="img"
            src={NoDataImage}
            alt="Footnotes"
            sx={{
              width: { xs: "70%", md: "25%" },
              height: "auto",
            }}
          />
          <Typography className={style.contentHeading}>
            {t("No Information To Display")}
          </Typography>
        </Box>
      )}

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SearchWorksheetButtons />
      </Box>
    </>
  );
};

export default AdjustedIncomeTab;
