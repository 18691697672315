import React, {
  FC,
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  FocusEvent,
} from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import { t } from "i18next";

// Styled components
const CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  borderRadius: "25px",
  border: "1px solid #ccc",
  width: "fit-content",
  background: "#f0f0f0",
  position: "relative",
  height: "30px!important",
});

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "25px",
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputBase-input": {
    padding: "6.5px 10px",
  },
});

const GrayBox = styled(Box)({
  padding: "6.5px 10px",
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
});

// Component Props Interface
interface CurrencyInputProps {
  options: string[];
  inputValue: string | number;
  onInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onDropdownChange: (value: string) => void;
}

// Main Component
const CurrencyInput: FC<CurrencyInputProps> = ({
  options,
  inputValue,
  onInputChange,
  onDropdownChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === "ArrowUp" ||
        event.key === "ArrowDown" ||
        event.key === "e" ||
        event.key === "E" ||
        event.key === "-"
      ) {
        event.preventDefault();
      }
    };

    const input = inputRef.current;
    if (input) {
      input.addEventListener("keydown", handleKeyDown);
      input.addEventListener("wheel", (e) => e.preventDefault());
    }

    return () => {
      if (input) {
        input.removeEventListener("keydown", handleKeyDown);
        input.removeEventListener("wheel", (e) => e.preventDefault());
      }
    };
  }, []);

  const handleArrowClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleDropdownItemClick = (option: string) => {
    onDropdownChange(option);
    setAnchorEl(null);
  };
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = parseFloat(value).toFixed(2);
    onInputChange({
      ...event,
      target: { ...event.target, value: formattedValue },
    });
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <CustomBox>
        <GrayBox>
          <div style={{ margin: "0px 6px" }}>$</div>
        </GrayBox>
        <CustomTextField
          variant="outlined"
          size="small"
          type="number"
          sx={{
            backgroundColor: "#FFFFA0",
            height: "30px",
            "& .MuiOutlinedInput-root": {
              color: "#6E6E6E",
              fontWeight: 600,
            },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
              WebkitAppearance: "none",
              margin: 0,
            },
          }}
          value={inputValue}
          onChange={(e) => {
            onInputChange(e);
          }}
          onBlur={handleBlur}
          ref={inputRef}
        />
        <GrayBox onClick={handleArrowClick}>
          <ExpandMoreIcon />
        </GrayBox>
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          <Paper sx={{ marginLeft: "-75px" }}>
            <MenuList>
              {options.map((option) => (
                <MenuItem
                  key={option}
                  onClick={() => handleDropdownItemClick(option)}
                >
                  {t(option)}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popper>
      </CustomBox>
    </ClickAwayListener>
  );
};

export default CurrencyInput;
