import { CreateWorksheetInitialState } from "../types/create-worksheet";
import { CalculateNarestChildValues } from "./calculateNarestChildValues";

/// calculation for final value
export const CalculateFinalValueForDeviation = (
  totalGrossIncome: number | undefined,
  monthlySelfEmploymentIncome: number | undefined,
  preexistingChildSupportAmount: number | undefined,
  numberOfChildren: number | undefined
): number => {
  const ficaValue = monthlySelfEmploymentIncome
    ? (Number(monthlySelfEmploymentIncome) * 0.062).toFixed(2)
    : "0";

  const line4Value = monthlySelfEmploymentIncome
    ? (Number(monthlySelfEmploymentIncome) * 0.0145).toFixed(2)
    : "0";

  const line5Value = Number(ficaValue) + Number(line4Value);

  const line6Value = totalGrossIncome
    ? Number(totalGrossIncome) - line5Value
    : 0;

  const line9Value =
    Number(line6Value) - Number(preexistingChildSupportAmount || 0);

  if (Number(numberOfChildren) > 0) {
    if (line6Value && line6Value > 0) {
      const line12Value = CalculateNarestChildValues(
        Number(line6Value),
        Number(numberOfChildren)
      );

      const line13Value = (line12Value * 75) / 100;

      const line14Value = line13Value ? line9Value - line13Value : 0;

      return line14Value;
    } else {
      return line9Value;
    }
  } else {
    return line9Value;
  }
};

/// get the final basic value for Deviation tab
export const getBasicFinalValueForDeviation = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  let FinalValue1;
  let FinalValue2;

  const preexistingValue1 =
    WorkSheetData.pre_existing_child_support?.reduce((acc, item) => {
      return acc + Number(item.preexisting_child_support_paid_monthly1);
    }, 0) || 0;

  const preexistingValue2 =
    WorkSheetData.pre_existing_child_support?.reduce((acc, item) => {
      return acc + Number(item.preexisting_child_support_paid_monthly2);
    }, 0) || 0;

  // const ChildCountNo = WorkSheetData?.add_child_to_worksheet
  //   ? WorkSheetData?.add_child_to_worksheet?.filter(
  //       (child) => child.child_status === "included"
  //     ).length
  //   : 0;

  const ChildValues = WorkSheetData?.proposed_adjustment_for_qualified_child
    ? WorkSheetData.proposed_adjustment_for_qualified_child.reduce(
        (
          acc: { parent1: number; parent2: number },
          item: { adjustment_requested_by: string }
        ) => {
          if (item.adjustment_requested_by === WorkSheetData.parent_name_1) {
            acc.parent1 = (acc.parent1 || 0) + 1;
          }

          if (item.adjustment_requested_by === WorkSheetData.parent_name_2) {
            acc.parent2 = (acc.parent2 || 0) + 1;
          }

          return acc;
        },
        { parent1: 0, parent2: 0 }
      )
    : { parent1: 0, parent2: 0 };

  if (
    WorkSheetData?.monthly_self_employment_income1 ||
    WorkSheetData?.monthly_self_employment_income2 ||
    preexistingValue1 ||
    preexistingValue2
  ) {
    FinalValue1 = CalculateFinalValueForDeviation(
      Number(WorkSheetData?.total_gross_income1),
      Number(WorkSheetData?.monthly_self_employment_income1),
      Number(preexistingValue1),
      ChildValues.parent1
    );

    FinalValue2 = CalculateFinalValueForDeviation(
      Number(WorkSheetData?.total_gross_income2),
      Number(WorkSheetData?.monthly_self_employment_income2),
      Number(preexistingValue2),
      ChildValues.parent2
    );
  } else {
    FinalValue1 = WorkSheetData?.total_gross_income1
      ? WorkSheetData?.total_gross_income1
      : 0;
    FinalValue2 = WorkSheetData?.total_gross_income2
      ? WorkSheetData?.total_gross_income2
      : 0;
  }

  return { FinalValue1, FinalValue2 };
};

/// get the final basic value for Deviation tab
export const getBasicFinalValueForTabE = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueForDeviation(WorkSheetData);

  const line1Total =
    (WorkSheetData?.health_insurance_premiums_parent1 ||
      WorkSheetData?.health_insurance_premiums_parent2 ||
      WorkSheetData?.health_insurance_premiums_parent3) &&
    (
      Number(WorkSheetData?.health_insurance_premiums_parent1) / 12 +
      Number(WorkSheetData?.health_insurance_premiums_parent2) / 12 +
      Number(WorkSheetData?.health_insurance_premiums_parent3) / 12
    ).toFixed(2);

  const line2Total =
    (WorkSheetData?.monthly_total1 ||
      WorkSheetData?.monthly_total2 ||
      WorkSheetData?.monthly_total3) &&
    Number(WorkSheetData.monthly_total1 || 0) +
      Number(WorkSheetData.monthly_total2 || 0) +
      Number(WorkSheetData.monthly_total3 || 0);
  const fetchValueTotal = (Number(FinalValue1) + Number(FinalValue2)).toFixed(
    2
  );

  const line3TotalValue = Number(line1Total) + Number(line2Total);

  const line4Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const line4Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const finalLine6Value1 = (Number(line4Value1) * line3TotalValue) / 100;
  const finalLine6Value2 = (Number(line4Value2) * line3TotalValue) / 100;

  return { finalLine6Value1, finalLine6Value2 };
};

export const finalCalculationForGrossAndAdjustedTabDeviation = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueForDeviation(WorkSheetData);
  const { finalLine6Value1, finalLine6Value2 } =
    getBasicFinalValueForTabE(WorkSheetData);

  const line2ValueTotal =
    Number(FinalValue1) || Number(FinalValue2)
      ? Number(FinalValue1) + Number(FinalValue2)
      : "";

  const line3Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line3Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const ChildCountNo = WorkSheetData?.add_child_to_worksheet
    ? WorkSheetData?.add_child_to_worksheet?.filter(
        (child) => child.child_status === "included"
      ).length
    : 0;
  const line4NarestValue = CalculateNarestChildValues(
    line2ValueTotal ? Number(line2ValueTotal) : 0,
    ChildCountNo
  ).toFixed(2);

  const line5Value1 = (
    (Number(line4NarestValue) * Number(line3Value1)) /
    100
  ).toFixed(2);
  const line5Value2 = (
    (Number(line4NarestValue) * Number(line3Value2)) /
    100
  ).toFixed(2);

  const line7Value1 =
    line5Value1 || finalLine6Value1
      ? Number(Number(line5Value1) + Number(finalLine6Value1))
      : "";
  const line7Value2 =
    line5Value2 || finalLine6Value2
      ? Number(Number(line5Value2) + Number(finalLine6Value2))
      : "";

  const Line8Value1 = (
    Number(WorkSheetData?.health_insurance_premiums_parent1) / 12 +
    Number(WorkSheetData?.monthly_total1)
  ).toFixed(2);
  const Line8Value2 = (
    Number(WorkSheetData?.health_insurance_premiums_parent2) / 12 +
    Number(WorkSheetData?.monthly_total2)
  ).toFixed(2);

  const tempValue1 = Number(line7Value1) - Number(Line8Value1);
  const tempValue2 = Number(line7Value2) - Number(Line8Value2);

  const deductionAmount = 50 * (1 + ChildCountNo);

  const lineDeviationValue1 = parseFloat(
    adjustSign(tempValue1 - deductionAmount).toFixed(2)
  );
  const lineDeviationValue2 = parseFloat(
    adjustSign(tempValue2 - deductionAmount).toFixed(2)
  );

  return { lineDeviationValue1, lineDeviationValue2 };
};

export function adjustSign(value: number): number {
  return -value;
}
